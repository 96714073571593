import * as PIXI from "pixi.js";
import * as Particles from "pixi-particles";

import Bjorn_Axe from "Game/Assets/ParticleSprite/Bjorn_Axe.png";
import Bjorn_Halo from "Game/Assets/ParticleSprite/Bjorn_Halo.png";
import Bjorn_N from "Game/Assets/ParticleSprite/Bjorn_N.png";
import Bjorn_NE from "Game/Assets/ParticleSprite/Bjorn_NE.png";
import Bjorn_NW from "Game/Assets/ParticleSprite/Bjorn_NW.png";
import Bjorn_S from "Game/Assets/ParticleSprite/Bjorn_S.png";
import Bjorn_SE from "Game/Assets/ParticleSprite/Bjorn_SE.png";
import Bjorn_SW from "Game/Assets/ParticleSprite/Bjorn_SW.png";
import Bjorn_E from "Game/Assets/ParticleSprite/Bjorn_E.png";
import Bjorn_W from "Game/Assets/ParticleSprite/Bjorn_W.png";
import * as Sentry from "@sentry/react";

const BjornParticle = {
  initial: async (app) => {
    return new Promise(async (resolve, reject) => {
      let particles = [
        "BjornAxe",
        "BjornN",
        "BjornNE",
        "BjornNW",
        "BjornS",
        "BjornSE",
        "BjornSW",
        "BjornE",
        "BjornW",
        "BjornCircle",
      ];
      let promises = [];
      particles.forEach((item) => {
        promises.push(
          new Promise((resolve, reject) => {
            let path = "";
            switch (item) {
              case "BjornAxe":
                path = Bjorn_Axe;
                break;
              case "BjornN":
                path = Bjorn_N;
                break;
              case "BjornNE":
                path = Bjorn_NE;
                break;
              case "BjornNW":
                path = Bjorn_NW;
                break;
              case "BjornS":
                path = Bjorn_S;
                break;
              case "BjornSE":
                path = Bjorn_SE;
                break;
              case "BjornSW":
                path = Bjorn_SW;
                break;
              case "BjornE":
                path = Bjorn_E;
                break;
              case "BjornW":
                path = Bjorn_W;
                break;
              case "BjornCircle":
                path = Bjorn_Halo;
                break;

              default:
                break;
            }
            let loader = new PIXI.Loader();
            loader.add(item, path);
            loader.onError.add((e) => {
              console.log(e);
              reject(e);
            });
            loader.load((loader, resources) => {
              if (app.loader.resources) {
                app.loader.resources[item] = resources[item];
              }
            });
            loader.onComplete.add(() => {
              resolve();
            });
          })
        );
      });
      return await Promise.all(promises)
        .then(() => {
          resolve();
        })
        .catch((e) => {
          Sentry.captureException(e);
          reject();
        });
    });
  },
  createParticle: (loader, name) => {
    return new Promise((resolve, reject) => {
      import(`./${name}.json`)
        .then((json) => {
          if (json.images && json.data) {
            let path = "";
            switch (name) {
              case "BjornAxe":
                path = Bjorn_Axe;
                break;
              case "BjornN":
                path = Bjorn_N;
                break;
              case "BjornNE":
                path = Bjorn_NE;
                break;
              case "BjornNW":
                path = Bjorn_NW;
                break;
              case "BjornS":
                path = Bjorn_S;
                break;
              case "BjornSE":
                path = Bjorn_SE;
                break;
              case "BjornSW":
                path = Bjorn_SW;
                break;
              case "BjornE":
                path = Bjorn_E;
                break;
              case "BjornW":
                path = Bjorn_W;
                break;
              case "BjornCircle":
                path = Bjorn_Halo;
                break;

              default:
                break;
            }
            // const loader = new PIXI.Loader();
            if (!loader.resources[name]) {
              loader.add(name, path);
            }
            loader.onError.add((e) => {
              console.log(e);
              reject({ error: "CANNOT_LOAD_PARTICLE" });
            });
            loader.load((loader, resources) => {
              const textures = resources[name].texture;
              const container = new PIXI.Container();
              const emitter = new Particles.Emitter(
                container,
                textures,
                json.data
              );

              resolve({ container, emitter });
            });
          }
        })
        .catch((e) => {
          Sentry.captureException(e);
          console.error(e);
          reject({ error: "CANNOT_IMPORT_CLASS" });
        });
    });
  },

  load: (app, containerAxe, containerHitFX) => {
    return new Promise(async (resolve, reject) => {
      let allParticle = {};
      await BjornParticle.createParticle(app.loader, "BjornAxe").then(
        (BjornAxe) => {
          containerAxe.addChild(BjornAxe.container);
          allParticle = { ...allParticle, BjornAxe };
        }
      );
      await BjornParticle.createParticle(app.loader, "BjornN").then(
        (BjornN) => {
          containerHitFX.addChild(BjornN.container);
          allParticle = { ...allParticle, BjornN };
        }
      );
      await BjornParticle.createParticle(app.loader, "BjornNE").then(
        (BjornNE) => {
          containerHitFX.addChild(BjornNE.container);
          allParticle = { ...allParticle, BjornNE };
        }
      );
      await BjornParticle.createParticle(app.loader, "BjornNW").then(
        (BjornNW) => {
          containerHitFX.addChild(BjornNW.container);
          allParticle = { ...allParticle, BjornNW };
        }
      );
      await BjornParticle.createParticle(app.loader, "BjornS").then(
        (BjornS) => {
          containerHitFX.addChild(BjornS.container);
          allParticle = { ...allParticle, BjornS };
        }
      );
      await BjornParticle.createParticle(app.loader, "BjornSE").then(
        (BjornSE) => {
          containerHitFX.addChild(BjornSE.container);
          allParticle = { ...allParticle, BjornSE };
        }
      );
      await BjornParticle.createParticle(app.loader, "BjornSW").then(
        (BjornSW) => {
          containerHitFX.addChild(BjornSW.container);
          allParticle = { ...allParticle, BjornSW };
        }
      );
      await BjornParticle.createParticle(app.loader, "BjornE").then(
        (BjornE) => {
          containerHitFX.addChild(BjornE.container);
          allParticle = { ...allParticle, BjornE };
        }
      );
      await BjornParticle.createParticle(app.loader, "BjornW").then(
        (BjornW) => {
          containerHitFX.addChild(BjornW.container);
          allParticle = { ...allParticle, BjornW };
        }
      );
      await BjornParticle.createParticle(app.loader, "BjornCircle").then(
        (BjornCircle) => {
          containerHitFX.addChild(BjornCircle.container);
          allParticle = { ...allParticle, BjornCircle };
        }
      );

      resolve(allParticle);

      app.loader.onError.add((e) => {
        reject(e);
      });
      // resolve("resolve")
    });
  },
};
export default BjornParticle;
