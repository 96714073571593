export const gameContextInitial = {
  allExplanation: [],
  allContentPointer: [],
  allDoubt: [],
  userAnswer: [],
};

export function gameContextReducer(state, action) {
  switch (action.type) {
    case "set_all_explanation":
      const isExist = state.allExplanation.find(
        (item) => item.id === action.payload.id
      );
      console.log("isExist", isExist);
      if (!isExist) {
        return {
          ...state,
          allExplanation: [...state.allExplanation, action.payload],
        };
      } else {
        return { ...state };
      }
    case "set_all_content_pointer":
      let data = [...state.allContentPointer];
      if (action.payload.microContents?.length !== 0) {
        action.payload.microContents.forEach((item, index) => {
          data.push({
            questionIndex: action.payload.questionIndex,
            contentSequence: 0,
          });
        });
      }
      data.push({
        questionIndex: action.payload.questionIndex,
        contentSequence: 0,
      });
      console.log("action.payload", action.payload);
      return {
        ...state,
        allContentPointer: [...state.allContentPointer],
      };
    case "set_all_doubt":
      return { ...state, allDoubt: [...state.allDoubt, action.payload] };
    case "set_user_answers":
      return { ...state, userAnswer: [...state.userAnswer, action.payload] };
    case "reset":
      return { ...gameContextInitial };
    default:
      return { ...state };
  }
}
