import { useCallback, useEffect, useMemo, useState } from "react";
import { useVonderGoContext } from "Service/VonderGOContext";

const useCalculateProgressBar = () => {
  const [prevValue, setPrevValue] = useState([]);
  const [progressValue, setProgressValue] = useState(null);
  const { currentGameData, animationState } = useVonderGoContext();

  const currentQuestion = currentGameData?.questionIndex
    ? currentGameData?.questionIndex
    : currentGameData?.totalQuestion;
  const scoreCondition = currentGameData?.scoreCondition;
  const totalQuestion = currentGameData?.totalQuestion;
  const averageScore = currentGameData?.scoreAverage;
  const passingRate = (scoreCondition * 100) / totalQuestion;

  const facNums = useMemo(() => {
    if (totalQuestion === 1) {
      return (averageScore * 100) / currentQuestion
    } else {
      return (averageScore * 100) / currentQuestion - 1
    }
  }, [averageScore, currentQuestion, totalQuestion])

  const currentValue = useMemo(() => {
    if (totalQuestion === 1) {
      if (currentGameData?.questionIndex && currentGameData?.questionIndex === 1) {
        return passingRate
      } else {
        return facNums
      }
    } else {
      if (currentQuestion < 2) {
        return passingRate
      } else {
        return facNums
      }
    }
  }, [currentQuestion, facNums, passingRate, totalQuestion, currentGameData])

  const findpercent = useCallback((passingRate, currentValue) => {
    const positiveValue = 100 - passingRate;
    if (passingRate < currentValue) {
      const percentage = ((currentValue - passingRate) * 100) / positiveValue;
      const val = 50 * (percentage / 100);
      return val + 50;
    } else if (passingRate > currentValue) {
      const result = currentValue * (100 / passingRate);
      const val = 50 * (result / 100);
      return val;
    } else {
      return 50;
    }
  }, []);

  useEffect(() => {
    if (animationState?.progressSlide) {
      setProgressValue(
        findpercent(passingRate, currentValue)
      );
    }
  }, [animationState.progressSlide, currentValue, currentQuestion,
    facNums, findpercent, passingRate]);

  useEffect(() => {
    const value = findpercent(
      passingRate,
      currentValue
    );
    if (value) {
      setPrevValue((prev) => [...prev, value]);
    }
  }, [currentValue, currentQuestion, facNums, findpercent, passingRate]);

  return {
    prevValue,
    progressValue,
    findpercent,
    currentValue,
    currentQuestion,
    passingRate
  }
};

export default useCalculateProgressBar;
