import { AnswerCountCard, TextTypo, AnswerCountProgress, Box, GoIcon } from "Game/Style/GameStyle";
import { memo, useMemo } from "react";
import icon_user from 'Game/Assets/group.svg'
import { useVonderGoContext } from "Service/VonderGOContext";
import { useLocalizationContext } from "Service/LocalizationProvider";

export default memo(function AnswerCountBox() {
  const { totalUsers, gameContextState, currentGameData, animationState } = useVonderGoContext()
  const { t } = useLocalizationContext()

  const findCurrentAnswer = useMemo(() => {
    return gameContextState?.userAnswer?.filter(item => item?.questionIndex === currentGameData?.questionIndex)
  }, [currentGameData, gameContextState])

  return (
    <AnswerCountCard align='center' justify='center' close={animationState?.closeSideCard}>
      <Box direction='column' align='center' justify='center' width='100%' height='100%'>
        <GoIcon src={icon_user} shape='square' size='2.5vw' />
        <TextTypo size='1vw' strong='true' type='secondary'>{t('GAMEPLAY.ANSWER')}</TextTypo>
        <TextTypo
          size='3.5vw'
          strong='true'
          type='secondary'
        >
          {findCurrentAnswer?.length || 0}
        </TextTypo>
      </Box>
      <AnswerCountProgress progress={findCurrentAnswer?.length} totalUser={totalUsers?.length} />
    </AnswerCountCard>
  )
})