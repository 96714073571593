// import { Box, GoImage, TextTypo } from "Game/Style/GameStyle";
import { Box } from "Game/Style/GameStyle";
import { memo, useMemo } from "react";
import styled from "styled-components";
import { StyleInputConverter } from "vonder-utils-input";
import AudioPlayer from "Game/Component/Media/AudioPlayer";
import ImageComponent from "Game/Component/Common/ImageComponent";

const ExplanationContext = memo(({ img, video, sound, context, header }) => {
  const YoutubeiFrame = useMemo(() => {
    return (
      <iframe
        width="100%"
        height="100%"
        src={video}
        title="YouTube video player"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
      ></iframe>
    );
  }, [video]);

  if (img) {
    return (
      <Box direction="column" align="center" margin="0 0 3% 0" padding="0 10%">
        <ImageBox direction="column" justify="center" align="center">
          <ImageComponent src={img} style={{ paddingBottom: "100%" }} />
        </ImageBox>
        <div>
          {header && (
            <StyleInputConverter
              width="100%"
              height="auto"
              fontSize={"1.2vw"}
              overflowX="hidden"
              value={header}
              style={{
                fontWeight: "bold",
                lineHeight: "2.1vw",
                margin: "3% 0 0 0",
              }}
            />
          )}
          {context && (
            <StyleInputConverter
              // width="100%"
              // height="auto"
              fontSize={"1.2vw"}
              overflowX="hidden"
              value={context}
              style={{ lineHeight: "2.1vw", margin: "3% 0 0 0" }}
            />
          )}
        </div>
      </Box>
    );
  }
  if (video) {
    return (
      <Box direction="column" align="center" margin="0 0 3% 0" padding="0 10%">
        <SquareBorder>
          <YTContainer>{YoutubeiFrame}</YTContainer>
        </SquareBorder>
      </Box>
    );
  }
  if (sound) {
    return (
      <Box direction="column" align="center" margin="0 0 3% 0" padding="0 10%">
        <AudioPlayer audioSrc={sound} />
      </Box>
    );
  } else {
    return (
      <Box padding="0 10%" width="100%" justify="center" margin="0 0 3% 0">
        {header && (
          <StyleInputConverter
            width="100%"
            height="auto"
            fontSize={"1.2vw"}
            overflowX="hidden"
            value={header}
            style={{ fontWeight: "bold", lineHeight: "2.1vw" }}
          />
        )}
        {context && (
          <StyleInputConverter
            width="100%"
            height="auto"
            fontSize={"1.2vw"}
            overflowX="hidden"
            value={context}
            style={{ lineHeight: "2.1vw" }}
          />
        )}
      </Box>
    );
  }
});

export default memo(function Explanation({ explanation }) {
  return (
    <ExplainationContainer>
      {explanation?.map((item, index) => {
        return (
          <ExplanationContext
            img={item?.imgMonitor}
            header={item?.header}
            context={item?.context}
            video={item?.video}
            sound={item?.soundURL}
            key={index}
          />
        );
      })}
    </ExplainationContainer>
  );
});

const ImageBox = styled(Box)`
  width: 15vw;
  height: 100%;
  /* margin-bottom: 3%; */
`;

const ExplainationContainer = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow-y: auto;
  width: 100%;
  padding: 3% 0;
`;

const SquareBorder = styled.div`
  overflow: hidden;
  display: inline-block;
  position: relative;
  width: ${(props) => (props.width ? props.width : "100%")};
  :after {
    content: "";
    display: block;
    padding-bottom: 56%;
  }
`;

const YTContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
`;
