import * as PIXI from "pixi.js";
import PIXISpine from "Plugins/pixi-spine";

import TenkoParticle from "Game/PixiCores/Particles/Tenko/TenkoParticle";
import { Tween, Tweener } from "Classes/Tween";
import * as Sentry from "@sentry/react";

const character = {
  controller: {},
  scale: 0.25,
  sequence: {
    idle: [{ name: "Idle", loop: true }],
    move: [{ name: "Walk", loop: true }],
    attack: [
      { name: "Attack", loop: false },
      { name: "Idle", loop: true, delay: 0 },
    ],
    hit: [
      { name: "Hit", loop: false, delay: 0 },
      { name: "Idle", loop: true, delay: 0 },
    ],
    win: [
      { name: "Win", loop: false },
      { name: "Idle", loop: true, delay: 0 },
    ],
    lose: [{ name: "Lose", loop: false }],
  },
  event: {
    Attack: () => {
      //   character.controller.setAttackTo(character.controller.targetPosition);
    },
    AttackFinal: () => {
      //   character.controller.setFinalAttackTo(
      //     character.controller.targetPosition
      //   );
    },
  },
  eventListener: {
    event: (entry, { data }) => {
      const { name } = data;
      character.event[name]?.();
    },
  },
  playSequence: (spine, sequenceData) => {
    if (!sequenceData || sequenceData.length === 0) return;
    spine.state.setAnimation(0, sequenceData[0].name, sequenceData[0].loop);
    for (let i = 1; i < sequenceData.length; i++)
      spine.state.addAnimation(
        0,
        sequenceData[i].name,
        sequenceData[i].loop,
        sequenceData[i].delay
      );
  },
  create: async (app, audioRef, path) => {
    return new Promise((resolve, reject) => {
      let promises = [
        new Promise((resolve, reject) => {
          const preload = new PIXI.Loader();
          preload.add("character", path);
          preload.load(async (loader, resources) => {
            let spineData = resources["character"].spineData;
            // console.log(spineData);
            if (spineData) {
              let spine = new PIXISpine.Spine(spineData);
              spine.scale.set(character.scale);
              spine.state.addListener(character.eventListener);

              let container = new PIXI.Container();
              let containerParticle = new PIXI.Container();
              let bulletParticle = new PIXI.Container();

              let emitterController;

              container.addChild(spine);
              container.addChild(containerParticle);
              container.addChild(bulletParticle);

              containerParticle.addChild(bulletParticle);

              await TenkoParticle.load(app, bulletParticle).then((particle) => {
                emitterController = particle;
                console.log(emitterController);
              });

              const controller = {
                spine,
                container,
                containerParticle,
                bulletParticle,
                emitterController,
                targetPosition: {
                  x: 0,
                  y: 0,
                },
                moveSpeed: 100,
                isMoving: false,
                infoCharacter: {
                  characterName: "tenko",
                },
                setActive: (isActive) => {
                  container.visible = isActive;
                },
                setTargetPosition: (x, y) => {
                  character.controller.targetPosition = {
                    x: x,
                    y: y,
                  };
                },
                setParticleActive: (isActive) => {},
                setAttackTo: (targetPosition) => {},
                setFinalAttackTo: (targetPosition) => {},
                attackPlayer: async (self, allTargetPlayer) => {
                  allTargetPlayer.forEach((player, index) => {
                    let bulletController =
                      emitterController.allBullet[index].controller;
                    let targetPosition = {
                      x:
                        player.container.position.x - self.container.position.x,
                      y: player.container.position.y,
                    };
                    console.log("bulletController", bulletController);
                    bulletController.container.visible = true;
                    bulletController.container.position.set(0, 0);
                    new Tweener(app.ticker)
                      .insert(
                        0.0,
                        new Tween(1.0, (pos, dt) => {
                          if (pos === 0) {
                            bulletController.TenkoBall_1.container.alpha = 1;
                            bulletController.TenkoBall_2.container.alpha = 1;
                            bulletController.TenkoBall_3.container.alpha = 1;
                          } else if (pos === 1) {
                          } else {
                            bulletController.TenkoBall_1.container.visible = true;
                            bulletController.TenkoBall_1.emitter.emit = true;
                            bulletController.TenkoBall_1.emitter.update(dt);
                            bulletController.TenkoBall_1.container.scale.set(
                              pos ** 4 * 2.5
                            );
                            bulletController.container.position.x = -300;
                            bulletController.container.position.y = -220;
                          }
                        })
                      )
                      .insert(
                        0.0,
                        new Tween(1.0, (pos, dt) => {
                          if (pos === 1) {
                          } else {
                            bulletController.TenkoBall_2.container.visible = true;
                            bulletController.TenkoBall_2.emitter.emit = true;
                            bulletController.TenkoBall_2.emitter.update(dt);
                            bulletController.TenkoBall_2.container.scale.set(
                              pos ** 4 * 2.5
                            );
                            bulletController.container.position.x = -300;
                            bulletController.container.position.y = -220;
                          }
                        })
                      )
                      .insert(
                        0.0,
                        new Tween(1.0, (pos, dt) => {
                          if (pos === 1) {
                          } else {
                            bulletController.TenkoBall_3.container.visible = true;
                            bulletController.TenkoBall_3.emitter.emit = true;
                            bulletController.TenkoBall_3.emitter.update(dt);
                            bulletController.TenkoBall_3.container.scale.set(
                              pos ** 4 * 2.5
                            );
                            bulletController.container.position.x = -300;
                            bulletController.container.position.y = -220;
                          }
                        })
                      )
                      .insert(
                        1.0,
                        new Tween(0.5, (pos, dt) => {
                          if (pos === 1) {
                          } else {
                            bulletController.TenkoBall_1.container.visible = true;
                            bulletController.TenkoBall_1.emitter.emit = true;
                            bulletController.TenkoBall_2.container.visible = true;
                            bulletController.TenkoBall_2.emitter.emit = true;
                            bulletController.TenkoBall_3.container.visible = true;
                            bulletController.TenkoBall_3.emitter.emit = true;
                            bulletController.TenkoBall_1.emitter.update(dt);
                            bulletController.TenkoBall_2.emitter.update(dt);
                            bulletController.TenkoBall_3.emitter.update(dt);

                            bulletController.container.position.x =
                              -300 + (targetPosition.x + 275) * pos;
                            bulletController.container.position.y =
                              -220 + pos * 120;
                          }
                        })
                      )
                      .insert(
                        1.5,
                        new Tween(0.25, (pos, dt) => {
                          if (pos === 1) {
                          } else {
                            bulletController.TenkoBall_1.container.visible = true;
                            bulletController.TenkoBall_1.emitter.emit = true;
                            bulletController.TenkoBall_2.container.visible = true;
                            bulletController.TenkoBall_2.emitter.emit = true;
                            bulletController.TenkoBall_3.container.visible = true;
                            bulletController.TenkoBall_3.emitter.emit = true;
                            bulletController.Tenko_Blast1.container.visible = true;
                            bulletController.Tenko_Blast1.emitter.emit = true;
                            bulletController.TenkoBall_1.emitter.update(dt);
                            bulletController.TenkoBall_2.emitter.update(dt);
                            bulletController.TenkoBall_3.emitter.update(dt);
                            bulletController.Tenko_Blast1.emitter.update(dt);

                            bulletController.TenkoBall_1.container.scale.set(
                              2.5 + pos * 3
                            );
                            bulletController.TenkoBall_1.container.alpha =
                              1 - pos;
                            bulletController.TenkoBall_2.container.scale.set(
                              2.5 + pos * 3
                            );
                            bulletController.TenkoBall_2.container.alpha =
                              1 - pos;
                            bulletController.TenkoBall_3.container.scale.set(
                              2.5 + pos * 3
                            );
                            bulletController.TenkoBall_3.container.alpha =
                              1 - pos;
                            bulletController.Tenko_Blast1.container.scale.set(
                              1 + pos * 2
                            );
                            bulletController.Tenko_Blast1.container.alpha =
                              1 - pos;
                          }
                        })
                      )
                      .insert(
                        1.75,
                        new Tween(0.75, (pos, dt) => {
                          if (pos === 1) {
                          } else {
                            bulletController.Tenko_Blast2.container.visible = true;
                            bulletController.Tenko_Blast2.emitter.emit = true;

                            bulletController.Tenko_Blast2.emitter.update(dt);

                            bulletController.Tenko_Blast2.container.scale.set(
                              2 + pos * 3
                            );
                            bulletController.Tenko_Blast2.container.alpha =
                              1 - pos ** 2;
                          }
                        })
                      )
                      .play();
                  });

                  if (audioRef.current._group["BOS-003"]) {
                    if (audioRef.current._group["BOS-003"]) {
                      audioRef.current._group["BOS-003"]._list[0].play(
                        "charge"
                      );
                    }
                    setTimeout(() => {
                      if (audioRef.current._group["BOS-003"]) {
                        audioRef.current._group["BOS-003"]._list[0].play(
                          "attack"
                        );
                      }
                    }, 1000);
                    setTimeout(() => {
                      if (audioRef.current._group["BOS-003"]) {
                        audioRef.current._group["BOS-003"]._list[0].play(
                          "hitTarget"
                        );
                        audioRef.current._group["BOS-003"]._list[0].play(
                          "hitTargetMusic"
                        );
                      }
                    }, 1500);
                  }

                  await character.controller.routineWait(1.5).then(() => {
                    console.log("particle hit");
                  });
                },
                finalAttack: async (self, allTargetPlayer) => {
                  console.log("final attack from boss");
                  allTargetPlayer.forEach((player, index) => {
                    let bulletController =
                      emitterController.allBullet[index].controller;
                    let targetPosition = {
                      x:
                        player.container.position.x - self.container.position.x,
                      y: player.container.position.y,
                    };
                    console.log("bulletController", bulletController);
                    bulletController.container.visible = true;
                    bulletController.container.position.set(0, 0);

                    new Tweener(app.ticker)
                      .insert(
                        0.0,
                        new Tween(1.0, (pos, dt) => {
                          if (pos === 1) {
                          } else {
                            bulletController.TenkoBall_1.container.alpha = 1;

                            bulletController.TenkoBall_1.container.visible = true;
                            bulletController.TenkoBall_1.emitter.emit = true;
                            bulletController.TenkoBall_1.emitter.update(dt);
                            bulletController.TenkoBall_1.container.scale.set(
                              pos ** 4 * 4
                            );
                            bulletController.container.position.x = 0;
                            bulletController.container.position.y = -680;
                          }
                        })
                      )
                      .insert(
                        0.0,
                        new Tween(1.0, (pos, dt) => {
                          if (pos === 1) {
                          } else {
                            bulletController.TenkoBall_2.container.alpha = 1;

                            bulletController.TenkoBall_2.container.visible = true;
                            bulletController.TenkoBall_2.emitter.emit = true;
                            bulletController.TenkoBall_2.emitter.update(dt);
                            bulletController.TenkoBall_2.container.scale.set(
                              pos ** 4 * 4
                            );
                            bulletController.container.position.x = 0;
                            bulletController.container.position.y = -680;
                          }
                        })
                      )
                      .insert(
                        0.0,
                        new Tween(1.0, (pos, dt) => {
                          if (pos === 1) {
                          } else {
                            bulletController.TenkoBall_3.container.alpha = 1;

                            bulletController.TenkoBall_3.container.visible = true;
                            bulletController.TenkoBall_3.emitter.emit = true;
                            bulletController.TenkoBall_3.emitter.update(dt);
                            bulletController.TenkoBall_3.container.scale.set(
                              pos ** 4 * 4
                            );
                            bulletController.container.position.x = 0;
                            bulletController.container.position.y = -680;
                          }
                        })
                      )
                      .insert(
                        1.0,
                        new Tween(0.5, (pos, dt) => {
                          if (pos === 1) {
                          } else {
                            bulletController.TenkoBall_1.container.visible = true;
                            bulletController.TenkoBall_1.emitter.emit = true;
                            bulletController.TenkoBall_2.container.visible = true;
                            bulletController.TenkoBall_2.emitter.emit = true;
                            bulletController.TenkoBall_3.container.visible = true;
                            bulletController.TenkoBall_3.emitter.emit = true;
                            bulletController.TenkoBall_1.emitter.update(dt);
                            bulletController.TenkoBall_2.emitter.update(dt);
                            bulletController.TenkoBall_3.emitter.update(dt);

                            bulletController.container.position.x =
                              0 + (targetPosition.x - 25) * pos;
                            bulletController.container.position.y =
                              -680 + pos ** 2 * 655;
                          }
                        })
                      )
                      .insert(
                        1.5,
                        new Tween(0.25, (pos, dt) => {
                          if (pos === 1) {
                          } else {
                            bulletController.TenkoBall_1.container.visible = true;
                            bulletController.TenkoBall_1.emitter.emit = true;
                            bulletController.TenkoBall_2.container.visible = true;
                            bulletController.TenkoBall_2.emitter.emit = true;
                            bulletController.TenkoBall_3.container.visible = true;
                            bulletController.TenkoBall_3.emitter.emit = true;
                            bulletController.Tenko_Blast1.container.visible = true;
                            bulletController.Tenko_Blast1.emitter.emit = true;
                            bulletController.TenkoBall_1.emitter.update(dt);
                            bulletController.TenkoBall_2.emitter.update(dt);
                            bulletController.TenkoBall_3.emitter.update(dt);
                            bulletController.Tenko_Blast1.emitter.update(dt);
                            bulletController.TenkoBall_1.container.scale.set(
                              4 + pos * 3
                            );
                            bulletController.TenkoBall_1.container.alpha =
                              1 - pos;
                            bulletController.TenkoBall_2.container.scale.set(
                              4 + pos * 3
                            );
                            bulletController.TenkoBall_2.container.alpha =
                              1 - pos;
                            bulletController.TenkoBall_3.container.scale.set(
                              4 + pos * 3
                            );
                            bulletController.TenkoBall_3.container.alpha =
                              1 - pos;
                            bulletController.Tenko_Blast1.container.scale.set(
                              4 + pos * 2
                            );
                            bulletController.Tenko_Blast1.container.alpha =
                              1 - pos;
                          }
                        })
                      )
                      .insert(
                        1.75,
                        new Tween(0.75, (pos, dt) => {
                          if (pos === 1) {
                          } else {
                            bulletController.Tenko_Blast2.container.visible = true;
                            bulletController.Tenko_Blast2.emitter.emit = true;

                            bulletController.Tenko_Blast2.emitter.update(dt);

                            bulletController.Tenko_Blast2.container.scale.set(
                              4 + pos * 3
                            );
                            bulletController.Tenko_Blast2.container.alpha =
                              1 - pos ** 2;
                          }
                        })
                      )
                      .play();
                  });

                  if (audioRef.current._group["BOS-003"]) {
                    if (audioRef.current._group["BOS-003"]) {
                      audioRef.current._group["BOS-003"]._list[0].play(
                        "charge"
                      );
                      audioRef.current._group["BOS-003"]._list[0].play(
                        "finalCharge"
                      );
                    }
                    setTimeout(() => {
                      if (audioRef.current._group["BOS-003"]) {
                        audioRef.current._group["BOS-003"]._list[0].play(
                          "attack"
                        );
                      }
                    }, 1000);
                    setTimeout(() => {
                      if (audioRef.current._group["BOS-003"]) {
                        audioRef.current._group["BOS-003"]._list[0].play(
                          "hitTarget"
                        );
                        audioRef.current._group["BOS-003"]._list[0].play(
                          "finalHitTarget"
                        );
                      }
                    }, 1500);
                  }

                  await character.controller.routineWait(1.75).then(() => {
                    console.log("particle hit");
                  });
                },
                playState: (stateName) => {
                  if (character.sequence[stateName])
                    character.playSequence(
                      controller.spine,
                      character.sequence[stateName]
                    );
                  if (stateName === "lose") {
                    if (audioRef.current._group["BOS-003"]) {
                      audioRef.current._group["BOS-003"]._list[0].play("dead");
                    }
                  }
                },
                destroy: () => {
                  controller.spine.destroy();
                  controller.container?.destroy();
                  controller.containerParticle?.destroy();
                },
                talk: () => {
                  if (audioRef.current._group["BOS-003"])
                    audioRef.current._group["BOS-003"]._list[0].play("talk");
                },
                routineWait: async (second) => {
                  await new Promise((resolve) => {
                    const ticker = app.ticker;
                    let current = 0;
                    const onUpdate = (elapsedTime) => {
                      const deltaTime = (1 / ticker.FPS) * ticker.speed;
                      current += deltaTime;
                      if (current > second) {
                        ticker.remove(onUpdate);
                        resolve();
                      }
                    };
                    ticker.add(onUpdate);
                  });
                },
              };
              character.controller = controller;
              resolve(controller);
            } else {
              reject({ error: "NO_SPINE_DATA_DMITRI" });
            }
          });
        }),
      ];
      Promise.all(promises)
        .then((results) => {
          const [character] = results;
          resolve(character);
        })
        .catch((e) => {
          Sentry.captureException(e);
          reject(e);
        });
    });
  },
};

export default character;
