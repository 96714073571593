import { useVonderGoContext } from "Service/VonderGOContext";
import { Box, GoImage } from "Game/Style/GameStyle";
import { memo, useEffect, useMemo, useState } from "react";
import { SHOW_FINAL_LEADERBOARD } from "locales/en/translation.json";
import styled, { css } from "styled-components";
import { scaleInCenter, fadeInForward } from "Game/Style/CSSAnimation";
import victory from "Game/Assets/Victory_Banner.png";
import defeat from "Game/Assets/Defeat_Banner.png";
import Lottie from "react-lottie";
import win from "Game/Component/LottyFiles/win.json";
import win2 from "Game/Component/LottyFiles/win2.json";

export default memo(() => {
  const [showResultText, setShowResultText] = useState(false);
  const {
    dispatchGameState,
    leaderboardData,
    dispatchAnimationState,
    playSFX,
  } = useVonderGoContext();

  //#region party pop
  const winOptions = {
    loop: true,
    autoplay: true,
    animationData: win,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  //#endregion
  
  //#region firework
  const winOptions2 = {
    loop: false,
    autoplay: true,
    animationData: win2,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  //#endregion

  //#region set time to show final leaderboard and check victory/defeat
  useEffect(() => {
    if (showResultText) {
      setTimeout(() => {
        dispatchGameState({ type: SHOW_FINAL_LEADERBOARD });
      }, 8000);
      if (leaderboardData?.isSessionWin) {
        playSFX("victory");
      } else {
        playSFX("defeat");
      }
    }
  }, [dispatchGameState, showResultText, leaderboardData, playSFX]);
  //#endregion

  //#region set time to show and hide victory/defeat
  useEffect(() => {
    setTimeout(() => {
      setShowResultText(true);
    }, 4000);
    setTimeout(() => {
      setShowResultText(false);
      dispatchAnimationState({ type: "show_progress_bar", payload: false });
    }, 12000);
  }, [dispatchAnimationState]);
  //#endregion

  //#region check show result image
  const showResult = useMemo(() => {
    if (leaderboardData?.isSessionWin) {
      return (
        <GoImage preview={false} src={victory} height="100%" width="35%" />
      );
    } else {
      return <GoImage preview={false} src={defeat} height="100%" width="35%" />;
    }
  }, [leaderboardData?.isSessionWin]);
  //#endregion

  return (
    <>
      {showResultText && leaderboardData?.isSessionWin && (
        <>
          <Lottie
            options={winOptions}
            width="80vw"
            height="80vw"
            isClickToPauseDisabled={false}
            style={{ zIndex: 5, position: "absolute", top: "-40%" }}
          />
          <Lottie
            options={winOptions2}
            width="40vw"
            height="40vw"
            isClickToPauseDisabled={false}
            style={{ zIndex: 5, position: "absolute", top: "0%" }}
          />
        </>
      )}
      <ResultText showResultText={showResultText} isWin={leaderboardData?.isSessionWin}>
        {showResult}
      </ResultText>
    </>
  );
});

const ResultText = styled(Box)`
  position: absolute;
  top: 10%;
  display: flex;
  justify-content: center;
  ${(props) => {
    if (props.showResultText) {
      if (props.isWin) {
        return css`
        opacity: 1;
        animation: ${scaleInCenter} 0.2s cubic-bezier(0.25, 0.46, 0.45, 0.94)
          both;
      `;
      } else {
        return css`
        opacity: 1;
        animation: ${fadeInForward} 2s cubic-bezier(0.39, 0.575, 0.565, 1) both;
      `;
      }
    } else {
      return css`opacity: 0;`;
    }
  }}
`;
