import { memo, useMemo, useState, useCallback, useEffect } from "react";
import styled from "styled-components";
import { useLocalizationContext } from "Service/LocalizationProvider";
import Tabs from "antd/lib/tabs";
import {
  Box,
  TextTypo,
  ChoiceList,
  SelectQuestion,
  GoIcon,
} from "Game/Style/GameStyle";
import Table from "antd/lib/table";
import { Select } from "antd";
import Correct_icon from "Game/Assets/Correct_color.svg";
import InCorrect_icon from "Game/Assets/Incorrect_color.svg";
import Timesup_icon from "Game/Assets/Timesup_color.svg";

const { TabPane } = Tabs;
const { Option } = Select;

export default memo(function ChoiceFreeText({
  isResult,
  freetextAnswer,
  resultScore,
}) {
  const { t } = useLocalizationContext();
  const [, setActiveKey] = useState("1");
  const [answer, setAnswer] = useState();
  const [answers, setAnswers] = useState([]);
  const [allAnswerData, setAllAnswerData] = useState();
  const [filter, setFilter] = useState("all");
  const [answerData, setAnswerData] = useState([]);
  const [countAllAnswer, setCountAllAnswer] = useState(0);

  const onChangeKey = useCallback((key) => {
    setActiveKey(key);
  }, []);

  const onChangeFilter = useCallback((key) => {
    setFilter(key);
  }, []);

  useEffect(() => {
    switch (filter) {
      case "correct":
        setAnswerData(
          allAnswerData?.filter((e) => e?.correction === "correct")
        );
        break;
      case "wrong":
        setAnswerData(allAnswerData?.filter((e) => e?.correction === "wrong"));
        break;
      case "timesUp":
        setAnswerData(
          allAnswerData?.filter((e) => e?.correction === "timesup")
        );
        break;
      default:
        setAnswerData(allAnswerData);
    }
  }, [filter, allAnswerData]);

  useEffect(() => {
    if (freetextAnswer) {
      let answerArray = freetextAnswer?.answers[0]?.contexts;
      let countObj = freetextAnswer?.countByAnswers;
      let resultArr = [];

      answerArray?.forEach((item) => {
        if (countObj[item]) {
          resultArr.push({
            key: item,
            value: countObj[item],
          });
        } else {
          resultArr.push({
            key: item,
            value: 0,
          });
        }
      });
      setAnswer(resultArr[0]);

      let newArr = [...resultArr];
      newArr.splice(0, 1);
      setAnswers(newArr);

      freetextAnswer?.allEnduserAnswers.forEach((e, n) => {
        e.key = n;
      });
      setAllAnswerData(freetextAnswer?.allEnduserAnswers);

      let countExceptTimesUp = [];

      freetextAnswer?.allEnduserAnswers.forEach((e, n) => {
        if (e.correction !== "timesup") {
          countExceptTimesUp.push(e);
        }
      });

      setCountAllAnswer(countExceptTimesUp?.length);
    }
  }, [freetextAnswer]);

  const correctAnsweerTab = useMemo(() => {
    const Answer = ({ answerObj }) => {
      return (
        <Box width="100%" margin="1% 0">
          <AnswerCard
            align="start"
            size={answers?.length !== 0 ? "1.3vw" : "1.5vw"}
          >
            {answerObj?.key}
          </AnswerCard>
          <CountAnswerText size="1.5vw" strong="true">
            {answerObj?.value}
          </CountAnswerText>
        </Box>
      );
    };

    return (
      <Box
        direction="column"
        height="100%"
        justify="space-between"
        style={{
          backgroundColor: "var(--silver-100)",
          borderRadius: "0 0 var(--border-radius) var(--border-radius)",
          overflow: "hidden",
        }}
      >
        <Container style={{ overflow: "auto" }}>
          <Answer answerObj={answer} />
          <Box margin="0.5vw 0 1vw 0">
            {answers?.length !== 0 && (
              <TextTypo type="secondary" size="1.2vw" strong>
                {t("GAMEPLAY.FREETEXT_ALTERNATIVE")}
              </TextTypo>
            )}
          </Box>
          <CustomChoiceList
            grid={{ column: 2 }}
            rowKey="key"
            dataSource={answers}
            renderItem={function (item) {
              return (
                <ChoiceList.Item>
                  <Answer answerObj={item} />
                </ChoiceList.Item>
              );
            }}
          />
        </Container>
        <Box
          width="100%"
          justify="center"
          style={{
            backgroundColor: "var(--white)",
            borderTop: "1px solid var(--silver-200)",
          }}
        >
          <ResultScoreContainer>{resultScore}</ResultScoreContainer>
        </Box>
      </Box>
    );
  }, [answer, answers, resultScore, t]);

  const Select = useMemo(() => {
    return (
      <Selecter
        onChange={onChangeFilter}
        defaultValue="all"
        dropdownStyle={{
          borderRadius: "var(--border-radius)",
        }}
      >
        <Option value="all" style={{}}>
          {t("GAMEPLAY.FREETEXT_ALL")}
        </Option>
        <Option value="correct" style={{}}>
          {t("GAMEPLAY.FREETEXT_CORRECT")}
        </Option>
        <Option value="wrong" style={{}}>
          {t("GAMEPLAY.FREETEXT_INCORRECT")}
        </Option>
        <Option value="timesUp" style={{}}>
          {t("GAMEPLAY.FREETEXT_TIMESUP")}
        </Option>
      </Selecter>
    );
  }, [onChangeFilter, t]);

  const allAnsweerTab = useMemo(() => {
    const columns = [
      {
        title: t("GAMEPLAY.FREETEXT_ANSWER"),
        dataIndex: "context",
        key: "context",
        width: "50%",
        render: (e) => {
          return (
            <TextTypo size="1.2vw" strong color="#242938">
              {e.length !== 0 ? e : "-"}
            </TextTypo>
          );
        },
      },
      {
        title: t("GAMEPLAY.FREETEXT_NAME"),
        dataIndex: "name",
        key: "name",
        width: "25%",
        render: (e) => {
          return (
            <TextTypo size="1.2vw" strong color="#80879B">
              {e}
            </TextTypo>
          );
        },
      },
      {
        title: Select,
        dataIndex: "correction",
        key: "correction",
        width: "25%",
        render: (e) => {
          return (
            <Box justify="flex-start" align="center">
              <Box
                style={{
                  backgroundColor:
                    e === "correct"
                      ? "#20D69326"
                      : e === "wrong"
                      ? "#EC5B6226"
                      : "#2680EB26",
                  marginRight: "0.4vw",
                  padding: "0.25vw",
                  borderRadius: "100%",
                }}
              >
                <GoIcon
                  src={
                    e === "correct"
                      ? Correct_icon
                      : e === "wrong"
                      ? InCorrect_icon
                      : Timesup_icon
                  }
                  shape="square"
                  size="1vw"
                  style={{ top: 1 }}
                />
              </Box>
              <TextTypo
                size="1.2vw"
                strong
                color={
                  e === "correct"
                    ? "#20D693"
                    : e === "wrong"
                    ? "#EC5B62"
                    : "#4274E3"
                }
              >
                {e === "correct"
                  ? t("GAMEPLAY.FREETEXT_ANSWER_CORRECT")
                  : e === "wrong"
                  ? t("GAMEPLAY.FREETEXT_ANSWER_INCORRECT")
                  : t("GAMEPLAY.FREETEXT_TIMESUP")}
              </TextTypo>
            </Box>
          );
        },
      },
    ];

    return (
      <Box padding="2%">
        <TableCustom
          scroll={{ y: answerData?.length !== 0 ? "16vw" : 0 }}
          dataSource={answerData}
          columns={columns}
          pagination={false}
          size="middle"
        />
      </Box>
    );
  }, [Select, answerData, t]);

  const summaryDisplay = useMemo(() => {
    if (isResult) {
      return (
        <TabContainer>
          <TabCustom
            animated={{ tabPane: true }}
            centered
            onChange={onChangeKey}
          >
            <TabPane tab={t("GAMEPLAY.FREETEXT_CORRECT_ANSWER")} key="1">
              {correctAnsweerTab}
            </TabPane>
            <TabPane
              tab={`${t("GAMEPLAY.FREETEXT_ALL_ANSWER")} (${countAllAnswer})`}
              key="2"
            >
              {allAnsweerTab}
            </TabPane>
          </TabCustom>
        </TabContainer>
      );
    } else {
      return (
        <InputPreview>
          <TextTypo strong size="1.5vw" color="#0B0B0B">
            {t("GAMEPLAY.FREETEXT")}
          </TextTypo>
        </InputPreview>
      );
    }
  }, [
    isResult,
    onChangeKey,
    t,
    correctAnsweerTab,
    allAnsweerTab,
    countAllAnswer,
  ]);

  return <CustomBox>{summaryDisplay}</CustomBox>;
});

const CustomChoiceList = styled(ChoiceList)`
  width: 100%;
  .ant-row {
    height: 0%;
    justify-content: flex-start;
  }
  .ant-empty.ant-empty-normal {
    display: none;
  }
`;
const TableCustom = styled(Table)`
  min-width: 100%;
  thead.ant-table-thead {
    font-size: 1vw;
  }
  td.ant-table-cell {
    padding: 0.8vw 1vw !important;
    border: 1px solid white;
  }
  th.ant-table-cell {
    background-color: #f4f5f7;
    color: #80879b !important;
    padding: 0.6vw 1vw !important;
  }
  th.ant-table-cell.ant-table-cell-fix-left {
    border: 1px solid #fafafa;
  }
  .ant-table-container table > thead > tr:first-child th:last-child {
    border-bottom-right-radius: var(--border-radius);
    border-top-right-radius: var(--border-radius);
  }
  .ant-table-container table > thead > tr:first-child th:first-child {
    border-bottom-left-radius: var(--border-radius);
    border-top-left-radius: var(--border-radius);
  }
  .ant-empty.ant-empty-normal {
    display: none;
  }
`;
const TabCustom = styled(Tabs)`
  width: 100%;
  .ant-tabs-content-holder {
    display: flex;
  }
  .ant-tabs-nav {
    margin: 0 0 0 0;
    height: 3.2vw;
  }
  .ant-tabs-nav-list * {
    font-size: 1.2vw;
  }
  .ant-tabs-tab.ant-tabs-tab-active * {
    /* font-weight: bold; */
    color: #242938 !important;
  }
  .ant-tabs-tab {
    padding: 0 3%;
    color: #242938 !important;
  }
  .ant-tabs-ink-bar.ant-tabs-ink-bar-animated {
    height: 0.4vw;
    border-radius: 4px 4px 0 0;
  }
`;
const Selecter = styled(SelectQuestion)`
  width: 100%;
  .ant-select-selector {
    display: flex;
    align-items: center !important;
    height: 2.2vw !important;
    font-size: 1vw;
    font-weight: 600;
    color: var(--silver-900);
    border-radius: 10em !important;
    padding: 0 10% !important;
    border: 2px solid #e4e4eb !important;
  }
`;
const InputPreview = styled(Box)`
  width: 100%;
  background-color: var(--silver-100);
  border: 1px solid var(--silver-400);
  padding: 7% 2%;
  border-radius: var(--border-radius);
  align-items: center;
  justify-content: center;
`;
const TabContainer = styled(Box)`
  width: 90%;
  height: 100%;
  box-shadow: 0px 3px 6px #00000029;
  background-color: var(--white);
  border-radius: var(--border-radius);
`;
const ResultScoreContainer = styled(Box)`
  width: 45%;
  align-self: center;
  padding: 1% 0;
  background-color: var(--white);
`;
const CountAnswerText = styled(TextTypo)`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 1.2vw 0 1vw;
  color: #20d693;
`;
const Container = styled(Box)`
  padding: 1% 2%;
  background-color: var(--silver-100);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  overflow-y: auto;
  overflow-x: hidden;
`;
const AnswerCard = styled(Box)`
  width: 100%;
  white-space: normal;
  text-align: ${(props) => (props.align ? props.align : "center")};
  font-size: ${(props) => (props.size ? props.size : "1vw")};
  font-weight: bold;
  color: #707070;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  background-color: var(--white);
  padding: 1vw;
  border-radius: var(--border-radius);
  box-shadow: 0px 3px 6px #00000029;
`;
const CustomBox = styled(Box)`
  width: 100%;
  height: inherit;
  justify-content: center;
`;
