import { createGlobalStyle } from "styled-components";

const GlobalStyles = createGlobalStyle`
  html {
        --silver-100: #fbfcfc;
        --silver-200: #f4f5f7;
        --silver-300: #eceef0;
        --silver-400: #e5e7ea;
        --silver-500: #dee0e3;
        --silver-600: #bcbec1;
        --silver-700: #9b9d9f;
        --silver-800: #7a7b7d;
        --silver-900: #585a5b;
        --blue-400: #378ef0;
        --blue-500: #2680eb;
        --blue-600: #1473e6;
        --blue-700: #0d66d0;
        --fuchsia-400: #cf3edc;
        --fuchsia-500: #c038cc;
        --fuchsia-600: #b130bd;
        --fuchsia-700: #a228ad;
        --gray-100: #80879b;
        --gray-200: #6c7488;
        --gray-300: #5a6175;
        --gray-400: #484e61;
        --gray-500: #363c4d;
        --gray-600: #2d3243;
        --gray-700: #242938;
        --gray-800: #1b202d;
        --gray-900: #131721;
        --green-300: #00DEA9;
        --green-400: #33ab84;
        --green-500: #2d9d78;
        --green-600: #268e6c;
        --green-700: #12805c;
        --indigo-400: #7575f1;
        --indigo-500: #6767ec;
        --indigo-600: #5c5ce0;
        --indigo-700: #5151d3;
        --orange-400: #f29423;
        --orange-500: #e68619;
        --orange-600: #da7b11;
        --orange-700: #cb6f10;
        --red-300: #FF4C4D;
        --red-400: #ec5b62;
        --red-500: #e34850;
        --red-600: #d7373f;
        --red-700: #c9252d;
        --red-800: #FF405C;
        --white: #ffffff;
        --font-10: 10px;
        --font-12: 12px;
        --font-14: 14px;
        --font-16: 16px;
        --font-18: 18px;
        --font-20: 20px;
        --font-24: 24px;
        --font-32: 32px;
        --font-36: 36px;
        --font-48: 48px;
        --font-64: 64px;
        --clamp-14-4: clamp(4px, 1.1vw, 14px);
        --clamp-16-4: clamp(4px, 1.5vw, 16px);
        --clamp-18-4: clamp(4px, 1.1vw, 18px);
        --clamp-22-4: clamp(4px, 1.5vw, 22px);
        --clamp-24-4: clamp(4px, 1.5vw, 24px);
        --clamp-28-4: clamp(4px, 1.5vw, 28px);
        --clamp-28-12: clamp(12px, 2vw, 28px);
        --clamp-32-4: clamp(4px, 2vw, 32px);
        --clamp-32-12: clamp(12px, 2vw, 32px);
        --clamp-36-12: clamp(12px, 2vw, 36px);
        --clamp-56-16: clamp(16px, 3vw, 56px);
        --clamp-67-16: clamp(16px, 4vw, 67px);
        --clamp-83-24: clamp(24px, 4vw, 83px);
        --border-radius: 12px;
  }
`;

export default GlobalStyles;
