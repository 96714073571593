const { keyframes } = require("styled-components");

export const pulseText = keyframes`
  0%
  {
    transform: scale( 1 );
    opacity: 1;
  }
  50%
  {
    transform: scale( 1.5);
    opacity: 1;
  }
  100%
  {
    transform: scale( 1 );
    opacity: 1;
  }
`;

export const slideFadeUp = keyframes`
  0% {
      opacity:0;
      transform:  translateY(100%)  ;
  }
  100% {
      opacity:1;
      transform:  translateY(0%)  ;
  }
`;

export const slideFadeDown = keyframes`
  0% {
      opacity:1;
      transform:  translateY(0%)  ;
  }
  100% {
      opacity:0;
      transform:  translateY(100%)  ;
  }
`;

export const slideDown = keyframes`
  0% {
    opacity:0;
    transform:  translateY(-50%)  ;
  }
  100% {
    opacity:1;
    transform:  translateY(0%)  ;
  }
`;
export const slideUp = keyframes`
  0% {
      opacity:1;
      transform:  translateY(0%)  ;
  }
  100% {
      opacity:0;
      transform:  translateY(-50%)  ;
  }
`;
export const slideInRight = keyframes`
  0% {
      opacity: 0;
      transform:  translateX(-50%)  ;
  }
  100% {
      opacity: 1;
      transform:  translateX(0%)  ;
  }
`;
export const slideOutRight = keyframes`
  0% {
      opacity:1;
      transform:  translateX(0%)  ;
  }
  100% {
      opacity:0;
      transform:  translateX(-50%)  ;
  }
`;
export const slideInLeft = keyframes`
  0% {
      opacity: 0;
      transform:  translateX(50%)  ;
  }
  100% {
      opacity: 1;
      transform:  translateX(0%)  ;
  }
`;
export const slideOutLeft = keyframes`
  0% {
      opacity:1;
      transform:  translateX(0%)  ;
  }
  100% {
      opacity:0;
      transform:  translateX(50%)  ;
  }
`;
export const scaleUp = keyframes`
  0% {
    transform: scale(0.9);
    opacity: 0;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
`;
export const scaleOut = keyframes`
  0% {
    transform: scale(1);
    opacity: 1;
  }
  100% {
    transform: scale(0.9);
    opacity: 0;
  }
`;
export const scaleDown = keyframes`
  0% {
    transform: scale(1.1);
    opacity: 0;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
`;
export const fadeInForward = keyframes`
0% {
    -webkit-transform: translateZ(-80px);
            transform: translateZ(-80px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateZ(0);
            transform: translateZ(0);
    opacity: 1;
  }
`;
export const fadeOutBack = keyframes`
  0% {
    -webkit-transform: translateZ(0);
            transform: translateZ(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: translateZ(-80px);
            transform: translateZ(-80px);
    opacity: 0;
  }
`;
export const jello = keyframes`
  0% {
    -webkit-transform: scale3d(1, 1, 1);
            transform: scale3d(1, 1, 1);
  }
  30% {
    -webkit-transform: scale3d(1.25, 0.75, 1);
            transform: scale3d(1.25, 0.75, 1);
  }
  40% {
    -webkit-transform: scale3d(0.75, 1.25, 1);
            transform: scale3d(0.75, 1.25, 1);
  }
  50% {
    -webkit-transform: scale3d(1.15, 0.85, 1);
            transform: scale3d(1.15, 0.85, 1);
  }
  65% {
    -webkit-transform: scale3d(0.95, 1.05, 1);
            transform: scale3d(0.95, 1.05, 1);
  }
  75% {
    -webkit-transform: scale3d(1.05, 0.95, 1);
            transform: scale3d(1.05, 0.95, 1);
  }
  100% {
    -webkit-transform: scale3d(1, 1, 1);
            transform: scale3d(1, 1, 1);
  }
`;
export const unJello = keyframes`
  0% {
    -webkit-transform: scale3d(1, 1, 1);
            transform: scale3d(1, 1, 1);
  }
  30% {
    -webkit-transform: scale3d(0.75, 1.25, 1);
            transform: scale3d(0.75, 1.25, 1);
  }
  40% {
    -webkit-transform: scale3d(1.25, 0.75, 1);
            transform: scale3d(1.25, 0.75, 1);
  }
  50% {
    -webkit-transform: scale3d(0.85, 1.15, 1);
            transform: scale3d(0.85, 1.15, 1);
  }
  65% {
    -webkit-transform: scale3d(1.05, 0.95, 1);
            transform: scale3d(1.05, 0.95, 1);
  }
  75% {
    -webkit-transform: scale3d(0.95, 1.05, 1);
            transform: scale3d(0.95, 1.05, 1);
  }
  100% {
    -webkit-transform: scale3d(1, 1, 1);
            transform: scale3d(1, 1, 1);
  }
`;
export const shadowCountdown = keyframes`
  0% {
    width: 70%;
  }
  50% {
    width: 40%;
  }
  100% {
    width: 70%;
  }
`
export const bounceCountdown = keyframes`
  0% {
    transform: translateY(0%);
  }
  50% {
    transform: translateY(15%);
  }
  100% {
    transform: translateY(0%);
  }
`
export const flipToExplanation = keyframes`
  0% {
    -webkit-transform: rotate3d(-1, 1, 0, 80deg);
            transform: rotate3d(-1, 1, 0, 80deg);
    opacity: 0;
  }
  100% {
    -webkit-transform: rotate3d(1, 1, 0, 0deg);
            transform: rotate3d(1, 1, 0, 0deg);
    opacity: 1;
  }
`
export const flipToResult = keyframes`
  0% {
    -webkit-transform: rotate3d(1, 1, 0, 80deg);
            transform: rotate3d(1, 1, 0, 80deg);
    opacity: 0;
  }
  100% {
    -webkit-transform: rotate3d(1, 1, 0, 0deg);
            transform: rotate3d(1, 1, 0, 0deg);
    opacity: 1;
  }
`
export const prevCard = keyframes`
  0% {
    -webkit-transform: translateZ(-1400px) translateX(-1000px);
            transform: translateZ(-1400px) translateX(-1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateZ(0) translateX(0);
            transform: translateZ(0) translateX(0);
    opacity: 1;
  }
`
export const nextCard = keyframes`
0% {
    -webkit-transform: translateZ(-1400px) translateX(1000px);
            transform: translateZ(-1400px) translateX(1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateZ(0) translateX(0);
            transform: translateZ(0) translateX(0);
    opacity: 1;
  }
`
export const shake = keyframes`
0%,
  100% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
  10%,
  30%,
  50%,
  70% {
    -webkit-transform: translateX(-10px);
            transform: translateX(-10px);
  }
  20%,
  40%,
  60% {
    -webkit-transform: translateX(10px);
            transform: translateX(10px);
  }
  80% {
    -webkit-transform: translateX(8px);
            transform: translateX(8px);
  }
  90% {
    -webkit-transform: translateX(-8px);
            transform: translateX(-8px);
  }
`
export const scaleInCenter = keyframes`
  0% {
    -webkit-transform: scale(0);
            transform: scale(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
    opacity: 1;
  }
`
export const fadeToLoading = keyframes`
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
`
export const heartBeat = keyframes`
from {
    -webkit-transform: scale(1);
            transform: scale(1);
    -webkit-transform-origin: center center;
            transform-origin: center center;
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
  }
  10% {
    -webkit-transform: scale(0.91);
            transform: scale(0.91);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
  }
  17% {
    -webkit-transform: scale(0.98);
            transform: scale(0.98);
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
  }
  33% {
    -webkit-transform: scale(0.87);
            transform: scale(0.87);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
  }
  45% {
    -webkit-transform: scale(1);
            transform: scale(1);
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
  }
`