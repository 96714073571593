import { memo, useEffect, useState } from "react";
import {
  Box,
  TextTypo,
  FlexCol,
  FullRow,
  GoIcon,
} from "Game/Style/GameStyle";
import { useVonderGoContext } from "Service/VonderGOContext";
import styled from "styled-components";
import useCheckType from "Hooks/useCheckType";
import YoutubePlayer from "Game/Component/Media/YoutubePlayer";
import AudioButton from "Game/Component/Media/AudioButton";
import { StyleInputConverter } from 'vonder-utils-input';
import ImageComponent from "Game/Component/Common/ImageComponent";

export default memo(function Question({
  questionName,
  type,
  image,
  video,
  sound,
  isHideChoice,
}) {
  const [countdown, setCountdown] = useState(null);
  const { dispatchGameState, wsSend, currentGameData, dispatchAnimationState } =
    useVonderGoContext();
  const { checkQuestionTypeIcon, descriptionType, descriptionContext } =
    useCheckType({ type });

  //#region set question time to countdown state
  useEffect(() => {
    if (currentGameData?.time) {
      setCountdown(currentGameData?.time);
    }
  }, [currentGameData]);
  //#endregion

  //#region countdown question time
  useEffect(() => {
    if (!isHideChoice) {
      const time =
        countdown > 0 &&
        setInterval(() => setCountdown((prev) => prev - 1), 1000);
      return () => clearInterval(time);
    }
  }, [countdown, isHideChoice]);
  //#endregion

  //#region if countdown is 0 switch component to show answering
  useEffect(() => {
    if (countdown === 0 && !isHideChoice) {
      dispatchAnimationState({
        type: "close_question_to_answering",
        payload: true,
      });
      setTimeout(() => {
        wsSend(JSON.stringify({ event: "answer-ready" }));
      }, 300);
    }
  }, [
    countdown,
    dispatchAnimationState,
    dispatchGameState,
    isHideChoice,
    wsSend,
  ]);
  //#endregion

  return (
    <FullRow height="100%" style={{ padding: '2%' }}>
      {image && (
        <FlexCol span={11} align="center" height="100%">
          {/* <Box align="center" justify="center">
            <GoImage height={"90%"} width={"90%"} src={image} />
          </Box> */}
          <ImageComponent src={image} height={"90%"} width={"100%"} />
        </FlexCol>
      )}
      {video && (
        <FlexCol span={11} align="center" height="100%">
          <Box align="center" justify="center" width="100%" height="100%">
            <YoutubePlayer youtubeID={video} width={"90%"} />
          </Box>
        </FlexCol>
      )}
      {sound && (
        <FlexCol span={11} align="center" height="100%">
          <Box align="center" justify="center" width="100%" height="100%">
            <AudioButton width={"90%"} />
            {/* <div style={{ width: '100%', height: '100%', backgroundColor: 'blue', display: 'block' }}>asdasd</div> */}
          </Box>
        </FlexCol>
      )}
      <FlexCol span={image || video || sound ? 13 : 24} height="100%">
        <FullRow>
          <FlexCol span={24} justify="flex-end" height="20%">
            {!isHideChoice && (
              <TextTypo size="3vw" strong="true" type="primary">
                {countdown}
              </TextTypo>
            )}
          </FlexCol>
          <FlexCol
            span={24}
            align="center"
            justify="center"
            height="60%"
            style={{
              overflowY: 'auto',
              paddingTop: "2%",
              // display: 'flex'
            }}
          >
            <StyleInputConverter
              width="100%"
              height="auto"
              fontSize={questionName?.length > 200 ? "1.3vw" : "1.5vw"}
              overflowX="hidden"
              value={questionName}
              style={{ lineHeight: '150%' }}
            />
          </FlexCol>
          <FlexCol
            span={24}
            height="20%"
            align="center"
            justify={!image || video || sound ? "center" : null}
          >
            <Box align="center" width="100%" justify="center">
              <QuestionTypeIconBox margin="0 3% 0 0">
                <GoIcon shape="square" src={checkQuestionTypeIcon} size="2vw" />
              </QuestionTypeIconBox>
              <TextTypo
                size="0.7vw"
                type="secondary"
                align="start"
                strong="true"
              >
                {descriptionType}
              </TextTypo>
              <TextTypo
                size="0.7vw"
                type="secondary"
                align="start"
                style={{ marginLeft: "1.5%" }}
              >
                {descriptionContext}
              </TextTypo>
            </Box>
          </FlexCol>
        </FullRow>
      </FlexCol>
    </FullRow>
  );
});

const QuestionTypeIconBox = styled(Box)`
  height: 100%;
  width: 2vw;
`;
