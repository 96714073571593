import {
  // ParallaxBlock,
  // ParallaxLayer,
  ParallaxLoop,
} from "Classes/Parallax.js";
import * as PIXI from "pixi.js";

const level = {
  areaLength: 4000,
  load: ({ app, assets }) => {
    // const isLarge = window.innerWidth >= 600 ? true : false;
    // const ypos = isLarge ? 210 : 140;
    // const ypos = -1080;
    // // const dynamicScale = isLarge ? 0.5 : 0.4;
    // const dynamicScale = 0.7;
    // const createGroundedSprite = (texture, yPos, scale) => {
    //   const sprite = new PIXI.Sprite(texture);
    //   // sprite.anchor.set(0.5, 1);
    //   sprite.anchor.set(0, 1);
    //   sprite.position.y = yPos;
    //   sprite.scale.set(scale);
    //   return sprite;
    // };
    // const createSpriteBlock = (size, texture, yPos, scale) => {
    //   const block = new ParallaxBlock(new PIXI.Container(), size);
    //   const sprite = createGroundedSprite(texture, yPos, scale);
    //   block.container.addChild(sprite);
    //   return block;
    // };
    return new Promise((resolve, reject) => {
      const loader = new PIXI.Loader();
      let spriteList = [
        { name: "sprites_multi_wall" },
        { name: "sprites_multi_building" },
        { name: "sprites_multi_large" },
        { name: "sprites_multi_environments_0" },
        { name: "sprites_multi_environments_1" },
        { name: "sprites_multi_environments_2" },
      ];
      assets.forEach((element) => {
        // console.log("element", element);
        const path = element.link;
        const arr = path.split("/");
        const filename = arr[arr.length - 1].split(".");
        const type = filename[0];
        if (
          spriteList.find((sprite) => {
            return sprite.name === type;
          })
        ) {
          loader.add(type, element.link);
        }
      });
      // loader.add(
      //   "sprites",
      //   "https://dh8bdvjvmxojs.cloudfront.net/go/theme/dolfindam/daylight/sprites_multi.json"
      // );

      // loader.add(
      //   "sprites_multi_wall",
      //   "https://dh8bdvjvmxojs.cloudfront.net/go/theme/dolfindam/daylight/sprites_multi_wall.json"
      // );
      // loader.add(
      //   "sprites_multi_large",
      //   "https://dh8bdvjvmxojs.cloudfront.net/go/theme/dolfindam/daylight/sprites_multi_large.json"
      // );
      // loader.add(
      //   "sprites_multi_building",
      //   "https://dh8bdvjvmxojs.cloudfront.net/go/theme/dolfindam/daylight/sprites_multi_building.json"
      // );
      // loader.add(
      //   "sprites_multi_environments_2",
      //   "https://dh8bdvjvmxojs.cloudfront.net/go/theme/dolfindam/daylight/sprites_multi_environments_2.json"
      // );
      // loader.add(
      //   "sprites_multi_environments_1",
      //   "https://dh8bdvjvmxojs.cloudfront.net/go/theme/dolfindam/daylight/sprites_multi_environments_1.json"
      // );
      // loader.add(
      //   "sprites_multi_environments_0",
      //   "https://dh8bdvjvmxojs.cloudfront.net/go/theme/dolfindam/daylight/sprites_multi_environments_0.json"
      // );

      loader.load((loader, resources) => {
        // const spritesheetTexture = resources["sprites"].spritesheet.textures;
        const spritesheetTextureWall =
          resources["sprites_multi_wall"].spritesheet.textures;
        const spritesheetTextureLarge =
          resources["sprites_multi_large"].spritesheet.textures;
        const spritesheetTextureBuilding =
          resources["sprites_multi_building"].spritesheet.textures;
        const spritesheetTextureEnv2 =
          resources["sprites_multi_environments_2"].spritesheet.textures;
        const spritesheetTextureEnv1 =
          resources["sprites_multi_environments_1"].spritesheet.textures;
        const spritesheetTextureEnv0 =
          resources["sprites_multi_environments_0"].spritesheet.textures;

        const parallax = new ParallaxLoop(new PIXI.Container());
        // console.log("spritesheetTexture", spritesheetTexture);
        // console.log("spritesheetTextureWall", spritesheetTextureWall);
        // console.log("spritesheetTextureLarge", spritesheetTextureLarge);
        // console.log("spritesheetTextureBuilding", spritesheetTextureBuilding);
        // console.log("spritesheetTextureEnv2", spritesheetTextureEnv2);
        // console.log("spritesheetTextureEnv1", spritesheetTextureEnv1);
        // console.log("spritesheetTextureEnv0", spritesheetTextureEnv0);

        // const BG = new PIXI.Sprite(spritesheetTexture["19_BG.png"]);
        const BG = new PIXI.Sprite(spritesheetTextureLarge["19_BG.png"]);
        BG.position.set(-1920 / 2, -1080);

        // const Clouds = new PIXI.Sprite(spritesheetTexture["18_Clouds.png"]);
        const Clouds = new PIXI.Sprite(
          spritesheetTextureLarge["18_Clouds.png"]
        );
        Clouds.position.set(-1920 / 2, -1020);

        // const layerClouds = new ParallaxLayer(
        //   new PIXI.Container(),
        //   0.1,
        //   1920 / 2
        // );
        // const blockCloud = createSpriteBlock(
        //   level.areaLength,
        //   spritesheetTexture["18_Clouds.png"],
        //   -1020,
        //   1
        // );

        // layerClouds.container.addChild(blockCloud.container);
        // layerClouds.addBlock(blockCloud);
        // layerClouds.position.set(0, 720);

        const LeftMountain = new PIXI.Sprite(
          spritesheetTextureEnv1["16_LeftMountain.png"]
        );
        LeftMountain.position.set(-520, -840);

        const MiddleMountain = new PIXI.Sprite(
          spritesheetTextureEnv0["15_MiddleMountain.png"]
        );
        MiddleMountain.position.set(380, -960);

        const Tree3 = new PIXI.Sprite(spritesheetTextureEnv1["14_Tree3.png"]);
        Tree3.position.set(300, -620);

        const MiddleHill = new PIXI.Sprite(
          spritesheetTextureLarge["13_MiddleHill.png"]
        );
        MiddleHill.position.set(-300, -400);

        const Tree2 = new PIXI.Sprite(spritesheetTextureEnv0["12_Tree2.png"]);
        Tree2.position.set(-1920 / 2, -960);
        Tree2.scale.set(1.2);

        const HeroesHill = new PIXI.Sprite(
          spritesheetTextureEnv2["11_HeroesHill.png"]
        );
        HeroesHill.position.set(-1920 / 2, -680);
        HeroesHill.scale.set(1.2);

        const Ravine = new PIXI.Sprite(spritesheetTextureEnv0["10_Ravine.png"]);
        Ravine.position.set(2600, -1080);

        const Tree1 = new PIXI.Sprite(spritesheetTextureEnv2["09_Tree1.png"]);
        Tree1.position.set(2450, -700);

        const Watchtower3 = new PIXI.Sprite(
          spritesheetTextureWall["08_Watchtower3.png"]
        );
        Watchtower3.position.set(2450, -820);

        const Camp = new PIXI.Sprite(spritesheetTextureBuilding["07_Camp.png"]);
        Camp.position.set(1950, -700);

        const Wall = new PIXI.Sprite(spritesheetTextureWall["06_Wall.png"]);
        Wall.position.set(1900, -400);

        const Watchtower2 = new PIXI.Sprite(
          spritesheetTextureWall["05_Watchtower2.png"]
        );
        Watchtower2.position.set(3000, -680);

        const Watchtower1 = new PIXI.Sprite(
          spritesheetTextureWall["04_Watchtower1.png"]
        );
        Watchtower1.position.set(1300, -500);

        const EnemyHill = new PIXI.Sprite(
          spritesheetTextureEnv1["03_EnemyHill.png"]
        );
        EnemyHill.position.set(1500, -340);

        const Castle = new PIXI.Sprite(
          spritesheetTextureBuilding["02_Castle.png"]
        );
        Castle.position.set(-780, -940);
        Castle.scale.set(1.25);

        // const Grass = new PIXI.Sprite(spritesheetTexture["01_Grass.png"]);
        const Grass = new PIXI.Sprite(spritesheetTextureLarge["01_Grass.png"]);

        Grass.position.set(-1920 / 2, -210);

        parallax.container.addChild(BG);
        parallax.container.addChild(Clouds);
        // // parallax.container.addChild(layerClouds.container);
        parallax.container.addChild(LeftMountain);
        parallax.container.addChild(MiddleMountain);
        parallax.container.addChild(Tree3);
        parallax.container.addChild(MiddleHill);
        parallax.container.addChild(Tree2);
        parallax.container.addChild(HeroesHill);
        parallax.container.addChild(Ravine);
        parallax.container.addChild(Tree1);
        parallax.container.addChild(Watchtower3);
        parallax.container.addChild(Camp);
        parallax.container.addChild(Wall);
        parallax.container.addChild(Watchtower2);
        parallax.container.addChild(Watchtower1);
        parallax.container.addChild(EnemyHill);
        parallax.container.addChild(Castle);
        parallax.container.addChild(Grass);

        // parallax.addLayer(layerClouds);

        const controller = {
          parallax,
          areaLength: level.areaLength,

          update: () => {
            parallax.updateParallaxPosition();
          },
          setSize: (size) => {
            parallax.setSize(size);
          },
          setPosition: (position) => {
            parallax.setPosition(position);
          },
          destroy: () => {
            parallax.destroy();
          },
        };
        resolve(controller);
      });
      loader.onError.add((e) => {
        reject(e);
      });
    });
  },
};

export default level;
