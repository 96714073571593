import * as PIXI from "pixi.js";

import smallLaser from "Game/PixiCores/Particles/Xarathrustra/Laser_0002_Small-laser.png";
import laserImpact from "Game/PixiCores/Particles/Xarathrustra/Laser_0003_Laser-impact.png";
import bigLaserBody from "Game/PixiCores/Particles/Xarathrustra/Laser_0000_Big-laser-body.png";
import bigLaserStart from "Game/PixiCores/Particles/Xarathrustra/Laser_0001_Big-laser-start.png";

const XarathrustraParticle = {
  load: ({ app, bulletParticle, containerParticle }) => {
    return new Promise(async (resolve, reject) => {
      let allParticle = {};
      let allBullet = [];

      for (let index = 0; index < 5; index++) {
        let bulletContainer = new PIXI.Container();
        bulletParticle.addChild(bulletContainer);
        bulletContainer.controller = {};
        let container = new PIXI.Container();
        bulletContainer.addChild(container);

        const smallLaserContainer = new PIXI.Sprite(
          PIXI.Texture.from(smallLaser)
        );
        smallLaserContainer.anchor.set(1, 0.5);
        smallLaserContainer.scale.set(0.5);
        smallLaserContainer.position.set(-100, -250);
        smallLaserContainer.visible = false;

        smallLaserContainer.angle = -16 + index * 2.5 - index * 0.1;
        smallLaserContainer.scale.x = 0.35 + index * 0.1;
        // smallLaserContainer.scale.x = index * 0.15 + 0.35;

        const laserImpactContainer = new PIXI.Sprite(
          PIXI.Texture.from(laserImpact)
        );
        laserImpactContainer.anchor.set(0.5);
        laserImpactContainer.scale.set(0.5);
        laserImpactContainer.visible = false;

        container.addChild(laserImpactContainer);
        container.addChild(smallLaserContainer);

        bulletContainer.controller = {
          ...bulletContainer.controller,
          container,
          smallLaserContainer,
          laserImpactContainer,
        };

        allBullet.push(bulletContainer);
      }
      allParticle = { ...allParticle, allBullet };

      const bigLaserBodyContainer = new PIXI.Sprite(
        PIXI.Texture.from(bigLaserBody)
      );
      bigLaserBodyContainer.anchor.set(1, 0.5);
      //   bigLaserBodyContainer.scale.set(10, 0.5);
      bigLaserBodyContainer.scale.set(10, 1);
      bigLaserBodyContainer.position.y = -150;
      bigLaserBodyContainer.position.x = -185;
      bigLaserBodyContainer.visible = false;

      const bigLaserStartContainer = new PIXI.Sprite(
        PIXI.Texture.from(bigLaserStart)
      );
      bigLaserStartContainer.anchor.set(1, 0.5);
      //   bigLaserStartContainer.scale.set(0.5);
      bigLaserStartContainer.scale.set(0.5, 1);
      bigLaserStartContainer.position.y = -150;
      bigLaserStartContainer.visible = false;

      containerParticle.addChild(bigLaserBodyContainer);
      containerParticle.addChild(bigLaserStartContainer);

      allParticle = {
        ...allParticle,
        bigLaserBodyContainer,
        bigLaserStartContainer,
      };

      resolve(allParticle);

      app.loader.onError.add((e) => {
        reject(e);
      });
    });
  },
};

export default XarathrustraParticle;
