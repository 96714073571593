function generateParabola(pos, curveAty2, y1, y2, y3) {

    const X = curveAty2

    const A = y1
    const B = y2
    const C = y3

    const equatation = generateMetrix(A, B, C, X)

    return (equatation.A * (pos ** 2)) + (equatation.B * pos) + equatation.C
}

function generateMetrix(A, B, C, X) {
    const R1 = [0, 0, 1, A]
    const R2 = [X ** 2, X, 1, B]
    const R3 = [1, 1, 1, C]

    return GaussJordanElimination(R1, R2, R3)
}

function GaussJordanElimination(Row1, Row2, Row3) {
    const R1 = Row2
    const R2 = R3Array(R1, Row3)
    const R3 = Row1

    const C = R3[3]
    const B = (R2[3] - (C * R2[2])) / R2[1]
    const A = (R1[3] - (C * R1[2]) - (B * R1[1])) / R1[0]
    return { A, B, C }
}

function R3Array(R1, R3) {
    const R1multiplied = R1.map((e) => e * 4)

    function R3new() {
        let newArr = []
        for (let index = 0; index < R3.length; index++) {
            newArr.push(R3[index] - R1multiplied[index])
        }
        return newArr
    }
    return R3new()
}

export { generateParabola }