import * as PIXI from "pixi.js";
import * as Particles from "pixi-particles";

import Ashoka_Particle_Flame_1 from "Game/Assets/ParticleSprite/AshokaMagicFire/Ashoka_Particle_Flame_1.png";
import Ashoka_Particle_Flame_2 from "Game/Assets/ParticleSprite/AshokaMagicFire/Ashoka_Particle_Flame_2.png";
import Ashoka_Particle_Flame_3 from "Game/Assets/ParticleSprite/AshokaMagicFire/Ashoka_Particle_Flame_3.png";
import Ashoka_Particle_Flame_4 from "Game/Assets/ParticleSprite/AshokaMagicFire/Ashoka_Particle_Flame_4.png";
import Ashoka_Particle_Flame_5 from "Game/Assets/ParticleSprite/AshokaMagicFire/Ashoka_Particle_Flame_5.png";
import Ashoka_Particle_Flame_6 from "Game/Assets/ParticleSprite/AshokaMagicFire/Ashoka_Particle_Flame_6.png";
import Ashoka_Particle_Flame_7 from "Game/Assets/ParticleSprite/AshokaMagicFire/Ashoka_Particle_Flame_7.png";
import Ashoka_Particle_Flame_8 from "Game/Assets/ParticleSprite/AshokaMagicFire/Ashoka_Particle_Flame_8.png";

import Ashoka_Feather from "Game/Assets/ParticleSprite/AshokaMagicFire/Ashoka_Attack.png";
import * as Sentry from "@sentry/react";

const AshokaParticle = {
  createParticle: (loader, name) => {
    return new Promise((resolve, reject) => {
      import(`./${name}.json`)
        .then((json) => {
          if (json.images && json.data) {
            // const loader = new PIXI.Loader();
            json.images.forEach((image, index) => {
              function getImg(name) {
                switch (name) {
                  case "AshokaFeature":
                    return Ashoka_Feather;
                  default:
                    break;
                }
              }
              const path = getImg(name);

              if (!loader.resources[name]) {
                loader.add(name, path);
              }
            });
            loader.onError.add((e) => {
              console.log(e);
              reject({ error: "CANNOT_LOAD_PARTICLE" });
            });
            loader.load((loader, resources) => {
              const textures = resources[name].texture;
              const container = new PIXI.Container();
              const emitter = new Particles.Emitter(
                container,
                textures,
                json.data
              );

              resolve({ container, emitter });
            });
          }
        })
        .catch((e) => {
          Sentry.captureException(e);
          console.error(e);
          reject({ error: "CANNOT_IMPORT_CLASS" });
        });
    });
  },

  createAnimatedSprite: (color, scale) => {
    const fireTexture = [
      Ashoka_Particle_Flame_1,
      Ashoka_Particle_Flame_2,
      Ashoka_Particle_Flame_3,
      Ashoka_Particle_Flame_4,
      Ashoka_Particle_Flame_5,
      Ashoka_Particle_Flame_6,
      Ashoka_Particle_Flame_7,
      Ashoka_Particle_Flame_8,
    ];
    for (let i = fireTexture.length - 1; i > 0; i--) {
      let j = Math.floor(Math.random() * (i + 1));
      let temp = fireTexture[i];
      fireTexture[i] = fireTexture[j];
      fireTexture[j] = temp;
    }
    return new Promise((resolve, reject) => {
      let textureArr = [];
      for (let i = 0; i < fireTexture.length; i++) {
        const texture = PIXI.Texture.from(fireTexture[i]);

        textureArr.push(texture);
      }
      const fire = new PIXI.AnimatedSprite(textureArr);
      fire.tint = color;
      fire.scale.set(scale);
      fire.alpha = 0.3;
      fire.loop = true;
      fire.autoUpdate = true;
      fire.anchor.set(0.5, 1.5);
      const container = new PIXI.Container();
      container.addChild(fire);
      resolve({ container, fire });
    });
  },
  load: (app, bulletParticle) => {
    return new Promise(async (resolve, reject) => {
      let allParticle = {};
      let allBullet = [];

      for (let index = 0; index < 5; index++) {
        let bulletContainer = new PIXI.Container();
        bulletParticle.addChild(bulletContainer);
        bulletContainer.controller = {};
        let container = new PIXI.Container();
        bulletContainer.addChild(container);
        container.visible = false;

        bulletContainer.controller = {
          ...bulletContainer.controller,
          container: container,
        };
        await AshokaParticle.createAnimatedSprite(0xff00cc, 0.13).then(
          (fireAnimated3) => {
            container.addChild(fireAnimated3.container);
            bulletContainer.controller = {
              ...bulletContainer.controller,
              fireAnimated3,
            };
          }
        );
        await AshokaParticle.createAnimatedSprite(0x710785, 0.12).then(
          (fireAnimated1) => {
            container.addChild(fireAnimated1.container);
            bulletContainer.controller = {
              ...bulletContainer.controller,
              fireAnimated1,
            };
          }
        );
        await AshokaParticle.createAnimatedSprite(0x71dac5, 0.1).then(
          (fireAnimated2) => {
            container.addChild(fireAnimated2.container);
            bulletContainer.controller = {
              ...bulletContainer.controller,
              fireAnimated2,
            };
          }
        );

        let AshokaFeather = new PIXI.Sprite(PIXI.Texture.from(Ashoka_Feather));
        container.addChild(AshokaFeather);
        AshokaFeather.anchor.set(0.5, 1);
        AshokaFeather.scale.set(0.3);
        bulletContainer.controller = {
          ...bulletContainer.controller,
          AshokaFeather,
        };
        allBullet.push(bulletContainer);
      }
      allParticle = { ...allParticle, allBullet };

      resolve(allParticle);

      app.loader.onError.add((e) => {
        reject(e);
      });
    });
  },
};

export default AshokaParticle;
