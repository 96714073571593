import { memo, useEffect, useState } from "react";
import { QACard, TextTypo, QABadge, Box } from 'Game/Style/GameStyle'
import { useVonderGoContext } from "Service/VonderGOContext";

export default memo(function QABox() {
  const [cardVisible, setCardVisible] = useState(false)
  const { gameContextState, dispatchAnimationState, animationState, currentGameState } = useVonderGoContext()

  useEffect(() => {
    if (gameContextState?.allDoubt?.length !== 0) {
      setCardVisible(true)
    }
  }, [gameContextState])

  return (
    <QACard
      isQA={animationState?.showQA}
      isEnd={currentGameState?.showGameResult || currentGameState?.finalLeaderboard ? true : false}
      align='center'
      justify='center'
      visible={cardVisible}
      onClick={() => dispatchAnimationState({ type: 'show_qa', payload: true })}
    >
      <Box position='relative'>
        <QABadge count={gameContextState?.allDoubt?.length} key={gameContextState?.allDoubt?.length}>
          <TextTypo size='1.3vw' strong='true' type='white'>{gameContextState?.allDoubt?.length || 0}</TextTypo>
        </QABadge>
        <TextTypo size='1.5vw' strong='true'>Q&A</TextTypo>
      </Box>
    </QACard>
  )
})