import { memo, useMemo } from "react";
import { useVonderGoContext } from "Service/VonderGOContext";
import { Box, TextTypo } from "Game/Style/GameStyle";
import singleSelectTextChoice from "Game/Component/LottyFiles/singleSelectTextChoice.json";
import singleSelectImageChoice from "Game/Component/LottyFiles/singleselectImageChoice.json";
import multiSelectTextChoice from "Game/Component/LottyFiles/multiselectTextChoice.json";
import multiSelectImageChoice from "Game/Component/LottyFiles/multiselectImageChoice.json";
import ordering from "Game/Component/LottyFiles/ordering.json";
import FreeTextChoice from "Game/Component/LottyFiles/freetext.json";
import Question from "Game/Component/Question";
import Answering from "Game/Component/Answering";
import MicroContent from "Game/Component/MicroContent";
import styled, { css } from "styled-components";
import { scaleUp, fadeOutBack } from "Game/Style/CSSAnimation";
import Leaderboard from "Game/Component/GamePage/Leaderboard";
import Lottie from "react-lottie";
import { useLocalizationContext } from "Service/LocalizationProvider";

export default memo(function CardContent({ currentMicroContentSequence }) {
  const {
    currentGameState,
    challengeContext,
    settings,
    currentGameData,
    animationState,
  } = useVonderGoContext();
  const { t } = useLocalizationContext();

  const showMicroContent = currentGameState?.showMicroContent;
  const showQuestionType = currentGameState?.showQuestionType;
  const showQuestion = currentGameState?.showQuestion;
  const answering = currentGameState?.showAnswering;

  const checkLottieByQuestionType = useMemo(() => {
    console.log(currentGameData?.type);
    switch (currentGameData?.type) {
      case "choice-text":
        return singleSelectTextChoice;
      case "multichoice-text":
        return multiSelectTextChoice;
      case "choice-image":
        return singleSelectImageChoice;
      case "multichoice-image":
        return multiSelectImageChoice;
      case "ordering-text":
        return ordering;
      case "free-text":
        return FreeTextChoice;
      default:
        break;
    }
  }, [currentGameData]);

  const defaultOptions = useMemo(() => {
    return {
      autoplay: true,
      animationData: checkLottieByQuestionType,
      rendererSettings: {
        preserveAspectRatio: "xMidYMid slice",
      },
    };
  }, [checkLottieByQuestionType]);

  const renderContent = useMemo(() => {
    if (currentGameState?.stanby) {
      if (currentGameData?.questionIndex === 1) {
        return (
          <ShowChallengeInfo out={animationState?.closeChallengeInfo}>
            <TextTypo size="2vw" strong="true">
              {challengeContext || "Challenge Name"}
            </TextTypo>
            <Box align="center">
              <TextTypo size="1.2vw" strong="true" type="secondary">
                {t("GAMEPLAY.PASSING_RATE")}&nbsp;
              </TextTypo>
              <TextTypo size="1.2vw" strong="true" type="primary">
                {settings?.conditionPercent || 0}%
              </TextTypo>
            </Box>
          </ShowChallengeInfo>
        );
      } else {
        return <Leaderboard />;
      }
    }

    if (showMicroContent || (answering && currentMicroContentSequence !== 0)) {
      //TODO: add microcontent component
      return (
        <MicroContent
          currentMicroContentSequence={currentMicroContentSequence}
        />
      );
    }
    if (showQuestionType) {
      return (
        <Box style={{ maxWidth: "20vw" }}>
          <Lottie options={defaultOptions} width="20vw" height="20vw" />
        </Box>
      );
    }
    if (showQuestion) {
      return (
        <ShowQuestion out={animationState?.closeQuestionToAnswering}>
          <Question
            questionName={currentGameData?.context}
            type={currentGameData?.type}
            image={currentGameData?.imgMonitor}
            video={currentGameData?.video}
            sound={currentGameData?.soundURL}
          />
        </ShowQuestion>
      );
    }
    if (answering || currentMicroContentSequence === 0) {
      return (
        <>
          {animationState?.isHideChoice ? (
            <ShowAnswering>
              <Question
                questionName={currentGameData?.context}
                type={currentGameData?.type}
                image={currentGameData?.imgMonitor}
                video={currentGameData?.video}
                sound={currentGameData?.soundURL}
                isHideChoice
              />
            </ShowAnswering>
          ) : (
            <Answering />
          )}
        </>
      );
    }
  }, [
    currentGameState?.stanby,
    showMicroContent,
    answering,
    currentMicroContentSequence,
    showQuestionType,
    showQuestion,
    currentGameData?.questionIndex,
    currentGameData?.context,
    currentGameData?.type,
    currentGameData?.imgMonitor,
    currentGameData?.video,
    currentGameData?.soundURL,
    animationState?.closeChallengeInfo,
    animationState?.closeQuestionToAnswering,
    animationState?.isHideChoice,
    challengeContext,
    t,
    settings?.conditionPercent,
    defaultOptions,
  ]);

  return <>{renderContent}</>;
});

const ShowChallengeInfo = styled(Box)`
  flex-direction: column;
  align-items: center;
  ${(props) => {
    if (props.out) {
      return css`
        opacity: 1;
        animation: ${fadeOutBack} 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
      `;
    }
  }}
`;
const ShowQuestion = styled.div`
  width: 100%;
  height: 100%;
  animation: ${scaleUp} 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  ${(props) => {
    if (props.out) {
      return css`
        animation: ${fadeOutBack} 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
      `;
    }
  }}
`;
const ShowAnswering = styled.div`
  width: 100%;
  height: 100%;
  animation: ${scaleUp} 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  /* flex: 1; */
  /* background-color: green; */
`;
