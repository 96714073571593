import * as PIXI from "pixi.js";
// import * as Particles from "pixi-particles";

import Zeke from "Game/Assets/ParticleSprite/BirdLeg.png";
import Zabu from "Game/Assets/ParticleSprite/Bone.png";
import Zulu from "Game/Assets/ParticleSprite/Club.png";
import * as Sentry from "@sentry/react";

const ShakaMinionParticle = {
  createThrowingWeapon: (name) => {
    return new Promise((resolve, reject) => {
      const loader = new PIXI.Loader();
      const path = name === "Zabu" ? Zabu : name === "Zeke" ? Zeke : Zulu;
      loader.add(name, path);
      loader.onError.add((e) => {
        console.log(e);
        reject({ error: "CANNOT_LOAD_PARTICLE" });
      });

      const container = new PIXI.Container();
      loader.load((loader, resources) => {
        const weaponSprite = new PIXI.Sprite(resources[name].texture);
        weaponSprite.anchor.set(0.5);
        weaponSprite.scale.set(0.2);
        container.addChild(weaponSprite);
        resolve(container);
      });
    });
  },
  load: (containerParticle, name) => {
    return new Promise(async (resolve, reject) => {
      const loader = new PIXI.Loader();
      let promises = [ShakaMinionParticle.createThrowingWeapon(name)];
      Promise.all(promises)
        .then(([weapon]) => {
          containerParticle.addChild(weapon);

          let particle = {
            weapon: weapon,
          };
          resolve(particle);
        })
        .catch((e) => {
          Sentry.captureException(e);
          reject(e);
        });

      loader.onError.add((e) => {
        reject(e);
      });
    });
  },
};

export default ShakaMinionParticle;
