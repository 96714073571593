import { AppContext } from "Game/AppContext";
import { useCallback, useContext, useMemo } from "react";
import { useLocalizationContext } from "Service/LocalizationProvider";

const useBossSay = () => {
  const { themeName } = useContext(AppContext);
  const { t } = useLocalizationContext();

  const bossBeginContext = useMemo(() => {
    const dolfindamBeginContext = [
      t("BOSS_GUSTAV_BEGIN.BEGIN_1"),
      t("BOSS_GUSTAV_BEGIN.BEGIN_2"),
      t("BOSS_GUSTAV_BEGIN.BEGIN_3"),
    ];

    switch (themeName) {
      case "dolfindam":
        return dolfindamBeginContext;
      case "flipperborea":
        return dolfindamBeginContext;
      case "tsurunotani":
        return dolfindamBeginContext;
      case "mayurabad":
        return dolfindamBeginContext;
      case "yosegoni":
        return dolfindamBeginContext;
      case "avesta-12b":
        return dolfindamBeginContext;
      case "nova-centauri":
        return dolfindamBeginContext;

      default:
        return dolfindamBeginContext;
      // break;
    }
  }, [t, themeName]);

  const bossFinalContext = useMemo(() => {
    const dolfindamFinalContext = [
      t("BOSS_GUSTAV_FINAL.FINAL_1"),
      t("BOSS_GUSTAV_FINAL.FINAL_2"),
      t("BOSS_GUSTAV_FINAL.FINAL_3"),
    ];

    switch (themeName) {
      case "dolfindam":
        return dolfindamFinalContext;
      case "flipperborea":
        return dolfindamFinalContext;
      case "tsurunotani":
        return dolfindamFinalContext;
      case "mayurabad":
        return dolfindamFinalContext;
      case "yosegoni":
        return dolfindamFinalContext;
      case "avesta-12b":
        return dolfindamFinalContext;
      case "nova-centauri":
        return dolfindamFinalContext;
      default:
        return dolfindamFinalContext;

      // break;
    }
  }, [t, themeName]);

  const randomBeginContext = useMemo(() => {
    return bossBeginContext[
      Math.floor(Math.random() * bossBeginContext?.length)
    ];
  }, [bossBeginContext]);

  const randomFinalContext = useMemo(() => {
    return bossFinalContext[
      Math.floor(Math.random() * bossFinalContext?.length)
    ];
  }, [bossFinalContext]);

  var introText = randomBeginContext;
  var introLength = 0;
  var introSpeed = 30;

  var finalText = randomFinalContext;
  var finalLength = 0;
  var finalSpeed = 30;

  const beginTypeWriter = useCallback(() => {
    if (introLength < introText?.length) {
      document.getElementById("intro").innerHTML +=
        introText?.charAt(introLength);
      introLength++;
      setTimeout(beginTypeWriter, introSpeed);
    }
  }, [introLength, introSpeed, introText]);

  const finalTypeWriter = useCallback(() => {
    if (finalLength < finalText?.length) {
      document.getElementById("final").innerHTML +=
        finalText?.charAt(finalLength);
      finalLength++;
      setTimeout(finalTypeWriter, finalSpeed);
    }
  }, [finalLength, finalSpeed, finalText]);

  return { beginTypeWriter, finalTypeWriter };
};

export default useBossSay;
