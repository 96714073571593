import * as PIXI from "pixi.js";
import * as Particles from "pixi-particles";

import TenkoBall_1 from "Game/Assets/ParticleSprite/Japan_Particle_0013_TenkoBall_1.png";
import TenkoBall_2 from "Game/Assets/ParticleSprite/Japan_Particle_0012_TenkoBall_2.png";
import TenkoBall_3 from "Game/Assets/ParticleSprite/Japan_Particle_0011_TenkoBall_3.png";
import Tenko_Blast1 from "Game/Assets/ParticleSprite/Japan_Particle_0007_Tenko_Blast1.png";
import Tenko_Blast2 from "Game/Assets/ParticleSprite/Japan_Particle_0006_Tenko_Blast2.png";
import * as Sentry from "@sentry/react";

const TenkoParticle = {
  createParticle: (loader, name) => {
    return new Promise((resolve, reject) => {
      import(`./${name}.json`)
        .then((json) => {
          if (json.images && json.data) {
            // const loader = new PIXI.Loader();
            json.images.forEach((image, index) => {
              function getImg(name) {
                switch (name) {
                  case "TenkoBall_1":
                    return TenkoBall_1;
                  case "TenkoBall_2":
                    return TenkoBall_2;
                  case "TenkoBall_3":
                    return TenkoBall_3;
                  case "Tenko_Blast1":
                    return Tenko_Blast1;
                  case "Tenko_Blast2":
                    return Tenko_Blast2;

                  default:
                    break;
                }
              }
              const path = getImg(name);

              if (!loader.resources[name]) {
                loader.add(name, path);
              }
            });
            loader.onError.add((e) => {
              console.log(e);
              reject({ error: "CANNOT_LOAD_PARTICLE" });
            });
            loader.load((loader, resources) => {
              const textures = resources[name].texture;
              const container = new PIXI.Container();
              const emitter = new Particles.Emitter(
                container,
                textures,
                json.data
              );

              resolve({ container, emitter });
            });
          }
        })
        .catch((e) => {
          Sentry.captureException(e);
          console.error(e);
          reject({ error: "CANNOT_IMPORT_CLASS" });
        });
    });
  },

  load: (app, bulletParticle) => {
    return new Promise(async (resolve, reject) => {
      let allParticle = {};
      let allBullet = [];

      for (let index = 0; index < 5; index++) {
        let bulletContainer = new PIXI.Container();
        bulletParticle.addChild(bulletContainer);
        bulletContainer.controller = {};
        let container = new PIXI.Container();
        bulletContainer.addChild(container);

        bulletContainer.controller = {
          ...bulletContainer.controller,
          container: container,
        };
        await TenkoParticle.createParticle(app.loader, "TenkoBall_1").then(
          (TenkoBall_1) => {
            container.addChild(TenkoBall_1.container);
            bulletContainer.controller = {
              ...bulletContainer.controller,
              TenkoBall_1,
            };
          }
        );
        await TenkoParticle.createParticle(app.loader, "TenkoBall_2").then(
          (TenkoBall_2) => {
            container.addChild(TenkoBall_2.container);
            bulletContainer.controller = {
              ...bulletContainer.controller,
              TenkoBall_2,
            };
          }
        );
        await TenkoParticle.createParticle(app.loader, "TenkoBall_3").then(
          (TenkoBall_3) => {
            container.addChild(TenkoBall_3.container);
            bulletContainer.controller = {
              ...bulletContainer.controller,
              TenkoBall_3,
            };
          }
        );
        await TenkoParticle.createParticle(app.loader, "Tenko_Blast1").then(
          (Tenko_Blast1) => {
            container.addChild(Tenko_Blast1.container);
            bulletContainer.controller = {
              ...bulletContainer.controller,
              Tenko_Blast1,
            };
          }
        );
        await TenkoParticle.createParticle(app.loader, "Tenko_Blast2").then(
          (Tenko_Blast2) => {
            container.addChild(Tenko_Blast2.container);
            bulletContainer.controller = {
              ...bulletContainer.controller,
              Tenko_Blast2,
            };
          }
        );
        allBullet.push(bulletContainer);
      }
      allParticle = { ...allParticle, allBullet };

      resolve(allParticle);

      app.loader.onError.add((e) => {
        reject(e);
      });
    });
  },
};

export default TenkoParticle;
