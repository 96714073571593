import * as PIXI from "pixi.js";
import * as Particles from "pixi-particles";

import outerPortal from "Game/Assets/ParticleSprite/Vonder_Particle_OuterPortal.png";
import innerPortal from "Game/Assets/ParticleSprite/Vonder_Particle_InnerPortal.png";
import acorn from "Game/Assets/ParticleSprite/Vonder_Particle_Acorn.png";
import acornReal from "Game/Assets/ParticleSprite/Vonder_Particle_AcornReal.png";
import * as Sentry from "@sentry/react";

const VonderParticle = {
  initial: async (app) => {
    return new Promise(async (resolve, reject) => {
      let particles = [
        "innerPortal",
        "innerPortalLoop",
        "outerPortal",
        "outerPortalLoop",
        "acornReal",
        "acornLoop",
        "acorn",
      ];
      let promises = [];
      particles.forEach((item) => {
        promises.push(
          new Promise((resolve, reject) => {
            let path = "";
            switch (item) {
              case "innerPortal":
              case "innerPortalLoop":
                path = innerPortal;
                break;
              case "outerPortal":
              case "outerPortalLoop":
                path = outerPortal;
                break;
              case "acornReal":
              case "acornLoop":
                path = acornReal;
                break;
              case "acorn":
                path = acorn;
                break;
              default:
                break;
            }
            let loader = new PIXI.Loader();
            loader.add(item, path);
            loader.onError.add((e) => {
              console.log(e);
              reject(e);
            });
            loader.load((loader, resources) => {
              if (app.loader.resources) {
                app.loader.resources[item] = resources[item];
              }
            });
            loader.onComplete.add(() => {
              resolve();
            });
          })
        );
      });
      return await Promise.all(promises)
        .then(() => {
          resolve();
        })
        .catch((e) => {
          Sentry.captureException(e);
          reject();
        });
    });
  },
  createParticle: (loader, name) => {
    return new Promise((resolve, reject) => {
      import(`./${name}.json`)
        .then((json) => {
          if (json.images && json.data) {
            // const loader = new PIXI.Loader();
            // json.images.forEach((image, index) => {
            // const path = EnvUrl.getGameParticles(image);
            let path = "";
            switch (name) {
              case "innerPortal":
              case "innerPortalLoop":
                path = innerPortal;
                break;
              case "outerPortal":
              case "outerPortalLoop":
                path = outerPortal;
                break;
              case "acornReal":
              case "acornLoop":
                path = acornReal;
                break;
              case "acorn":
                path = acorn;
                break;
              default:
                break;
            }
            // const path =
            //   name === "innerPortal" || name === "innerPortalLoop"
            //     ? innerPortal
            //     : name === "outerPortal" || name === "outerPortalLoop"
            //     ? outerPortal
            //     : name === "acorn"
            //     ? acorn
            //     : name === "acornReal" || name === "acornLoop"
            //     ? acornReal
            //     : acornReal;
            // loader.add(index.toString(), path);
            if (!loader.resources[name]) {
              loader.add(name, path);
            }
            // });
            loader.onError.add((e) => {
              console.log(e);
              reject({ error: "CANNOT_LOAD_PARTICLE" });
            });
            loader.load((loader, resources) => {
              // const textures = [];
              // json.images.forEach((image, index) => {
              //   // console.log(
              //   //   "resources[index].texture",
              //   //   resources[index].texture
              //   // );
              //   textures.push(resources[index].texture);
              // });
              const textures = resources[name].texture;
              const container = new PIXI.Container();
              const emitter = new Particles.Emitter(
                container,
                textures,
                json.data
              );

              resolve({ container, emitter });
            });
          }
        })
        .catch((e) => {
          Sentry.captureException(e);
          console.error(e);
          reject({ error: "CANNOT_IMPORT_CLASS" });
        });
    });
  },

  load: (app, containerParticle, containerBullet) => {
    return new Promise(async (resolve, reject) => {
      // const loader = new PIXI.Loader();
      // loader.add();

      let allParticle = {};
      await VonderParticle.createParticle(app.loader, "outerPortal").then(
        (outerPortal) => {
          containerParticle.addChild(outerPortal.container);
          outerPortal.container.position.set(0, -160);
          allParticle = { ...allParticle, outerPortal };
        }
      );
      await VonderParticle.createParticle(app.loader, "outerPortalLoop").then(
        (outerPortalLoop) => {
          containerParticle.addChild(outerPortalLoop.container);
          outerPortalLoop.container.position.set(0, -160);
          allParticle = { ...allParticle, outerPortalLoop };
        }
      );
      await VonderParticle.createParticle(app.loader, "innerPortal").then(
        (innerPortal) => {
          containerParticle.addChild(innerPortal.container);
          innerPortal.container.position.set(0, -160);
          allParticle = { ...allParticle, innerPortal };
        }
      );
      await VonderParticle.createParticle(app.loader, "innerPortalLoop").then(
        (innerPortalLoop) => {
          containerParticle.addChild(innerPortalLoop.container);
          innerPortalLoop.container.position.set(0, -160);
          allParticle = { ...allParticle, innerPortalLoop };
        }
      );

      await VonderParticle.createParticle(app.loader, "acorn").then((acorn) => {
        containerParticle.addChild(acorn.container);
        acorn.container.position.set(0, -160);
        allParticle = { ...allParticle, acorn };
      });
      await VonderParticle.createParticle(app.loader, "acornLoop").then(
        (acornLoop) => {
          containerParticle.addChild(acornLoop.container);
          acornLoop.container.position.set(0, -160);
          allParticle = { ...allParticle, acornLoop };
        }
      );
      await VonderParticle.createParticle(app.loader, "acornReal").then(
        (acornBullet) => {
          containerBullet.addChild(acornBullet.container);
          acornBullet.container.position.set(0, -160);
          acornBullet.container.angle = -90;
          allParticle = { ...allParticle, acornBullet };
        }
      );

      resolve(allParticle);
      // let promises = [
      //   VonderParticle.createParticle(app.loader, "outerPortal"),
      //   VonderParticle.createParticle(app.loader, "outerPortalLoop"),
      //   VonderParticle.createParticle(app.loader, "innerPortal"),
      //   VonderParticle.createParticle(app.loader, "innerPortalLoop"),
      //   VonderParticle.createParticle(app.loader, "acorn"),
      //   VonderParticle.createParticle(app.loader, "acornLoop"),
      //   VonderParticle.createParticle(app.loader, "acornReal"),
      // ];
      // Promise.all(promises)
      //   .then(
      //     ([
      //       // outerPortal,
      //       outerPortalLoop,
      //       innerPortal,
      //       innerPortalLoop,
      //       acorn,
      //       acornLoop,
      //       acornBullet,
      //     ]) => {
      //       // containerParticle.addChild(outerPortal.container);
      //       // outerPortal.container.position.set(0, -160);

      //       containerParticle.addChild(innerPortal.container);
      //       innerPortal.container.position.set(0, -160);

      //       containerParticle.addChild(acorn.container);
      //       acorn.container.position.set(0, -160);

      //       containerParticle.addChild(outerPortalLoop.container);
      //       outerPortalLoop.container.position.set(0, -160);

      //       containerParticle.addChild(innerPortalLoop.container);
      //       innerPortalLoop.container.position.set(0, -160);

      //       containerParticle.addChild(acornLoop.container);
      //       acornLoop.container.position.set(0, -160);

      //       containerBullet.addChild(acornBullet.container);
      //       acornBullet.container.position.set(0, -160);
      //       acornBullet.container.angle = -90;

      //       let particle = {
      //         // outerPortal: outerPortal,
      //         outerPortalLoop: outerPortalLoop,
      //         innerPortal: innerPortal,
      //         innerPortalLoop: innerPortalLoop,
      //         acorn: acorn,
      //         acornLoop: acornLoop,
      //         acornBullet: acornBullet,
      //       };
      //       resolve(particle);
      //     }
      //   )
      //   .catch((e) => {
      //     reject(e);
      //   });

      app.loader.onError.add((e) => {
        reject(e);
      });
    });
  },
};

export default VonderParticle;
