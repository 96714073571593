import { memo, useCallback, useEffect, useMemo, useState } from "react";
import {
  BottomBar,
  Box,
  ChallengeCardBox,
  TextTypo,
  ChallengeBox,
  FullRow,
  FlexCol,
  GoLayout,
  GoIcon,
} from "Game/Style/GameStyle";
import ChoiceText from "Game/Component/ChoiceType/ChoiceText";
import ChoiceImage from "Game/Component/ChoiceType/ChoiceImage";
import ChoiceOrdering from "Game/Component/ChoiceType/ChoiceOrdering";
import ChoiceFreeText from "Game/Component/ChoiceType/ChoiceFreeText";
import { useVonderGoContext } from "Service/VonderGOContext";
import Explanation from "Game/Component/Explanation";
import Correct_icon from "Game/Assets/Correct_color.svg";
import InCorrect_icon from "Game/Assets/Incorrect_color.svg";
import Almost_icon from "Game/Assets/Almost_color.svg";
import Timesup_icon from "Game/Assets/Timesup_color.svg";
import styled, { css } from "styled-components";
import orderBy from "lodash/orderBy";
import Arrow_active_icon from "Game/Assets/Arrow_active.svg";
import Arrow_inActive_icon from "Game/Assets/Arrow_disabled.svg";
import isEmpty from "lodash/isEmpty";
import { useLocalizationContext } from "Service/LocalizationProvider";
import YoutubePlayer from "Game/Component/Media/YoutubePlayer";
import AudioButton from "Game/Component/Media/AudioButton";
import { StyleInputConverter } from "vonder-utils-input";
import ImageComponent from "Game/Component/Common/ImageComponent";
import MicroContent from "../MicroContent";

const { Header, Content } = GoLayout;

export default memo(function AnswerResult() {
  const [isPrevCard, setIsPrevCard] = useState(false);
  const [isNextCard, setIsNextCard] = useState(false);
  const [isFlip, setIsFlip] = useState(false);
  const [showExplanation, setShowExplanation] = useState(false);
  const [prevQuestion, setPrevQuestion] = useState(null);
  const [nextQuestion, setNextQuestion] = useState(null);
  const [explanationData, setExplanationData] = useState(null);

  const [microContentData, setMicroContentData] = useState([]);
  const [currentMicroContentSequence, setCurrentMicroContentSequence] =
    useState(0);
  const [maxContentSequece, setMaxContentSequece] = useState(0);

  const [hasPrevMicroContent, setHasPrevMicroContent] = useState(null);
  const [hasNextMicroContent, setHasNextMicroContent] = useState(null);

  const [showBottomBar, setShowBottomBar] = useState(false);
  const {
    currentGameState,
    gameContextState,
    currentGameData,
    animationState,
    currentQuestionIndex,
    setCurrentQuestionIndex,
    playSFX,
    isJumpQuestionIndexFromDropDown,
    setIsJumpQuestionIndexFromDropDown,
  } = useVonderGoContext();
  const { t } = useLocalizationContext();

  //#region order answer when answer type is ordering
  const orderResult = useMemo(() => {
    if (
      gameContextState?.allExplanation[currentQuestionIndex]?.type ===
      "ordering-text"
    ) {
      return orderBy(
        gameContextState?.allExplanation[currentQuestionIndex]?.answers,
        ["id"],
        ["asc", "desc"]
      );
    }
  }, [gameContextState, currentQuestionIndex]);
  //#endregion

  //#region set up and checking micro centent
  useEffect(() => {
    if (gameContextState) {
      setMicroContentData(
        gameContextState?.allExplanation[currentQuestionIndex]?.microContents
      );
      setMaxContentSequece(
        gameContextState?.allExplanation[currentQuestionIndex]?.microContents
          ?.length
      );
      setHasPrevMicroContent(
        gameContextState?.allExplanation[currentQuestionIndex]?.microContents
          ?.length !== 0
      );
    }
  }, [currentQuestionIndex, gameContextState]);

  useEffect(() => {
    console.log("microContentData", microContentData);
  }, [microContentData]);

  useEffect(() => {
    if (microContentData) {
      setHasPrevMicroContent(
        microContentData?.length !== 0 && currentMicroContentSequence !== 1
      );
      setHasNextMicroContent(
        currentMicroContentSequence !== 0 && microContentData?.length !== 0
      );
    }
  }, [microContentData, currentMicroContentSequence, maxContentSequece]);
  //#endregion

  //#region set time to show bottom bar
  useEffect(() => {
    if (animationState?.answerResultVisible) {
      setTimeout(() => {
        setShowBottomBar(true);
      }, 900);
    }
  }, [animationState]);
  //#endregion

  //#region set current question index
  useEffect(() => {
    setCurrentQuestionIndex(currentGameData?.questionIndex - 1);
  }, [currentGameData, animationState, setCurrentQuestionIndex]);
  //#endregion

  const checMicroContentSequenceFromDropdown = useCallback(() => {
    if (isJumpQuestionIndexFromDropDown) {
      if (
        gameContextState?.allExplanation[currentQuestionIndex]?.microContents
          ?.length === 0
      ) {
        setCurrentMicroContentSequence(0);
      } else {
        setCurrentMicroContentSequence(1);
      }
      setIsJumpQuestionIndexFromDropDown(false);
    }
  }, [
    currentQuestionIndex,
    gameContextState?.allExplanation,
    isJumpQuestionIndexFromDropDown,
    setIsJumpQuestionIndexFromDropDown,
  ]);

  useEffect(() => {
    checMicroContentSequenceFromDropdown();
  }, [checMicroContentSequenceFromDropdown, currentQuestionIndex]);
  //#region check prev/next explanation
  useEffect(() => {
    if (currentGameData) {
      const checkHasPrev =
        currentQuestionIndex + 1 !== 1 &&
        currentQuestionIndex + 1 <= gameContextState?.allExplanation.length;

      const checkHasNext =
        currentQuestionIndex + 1 !== currentGameData?.questionIndex &&
        currentQuestionIndex + 1 < gameContextState?.allExplanation.length;
      setPrevQuestion(checkHasPrev);
      setNextQuestion(checkHasNext);
      setHasPrevMicroContent(
        gameContextState?.allExplanation[currentQuestionIndex]?.microContents
          ?.length
      );
    }
  }, [currentGameData, currentQuestionIndex, gameContextState?.allExplanation, gameContextState?.allExplanation.length]);
  //#endregion

  //#region set new explanation
  useEffect(() => {
    if (gameContextState) {
      const explanation =
        gameContextState?.allExplanation[currentQuestionIndex]?.explanations;
      console.log("explanation", explanation);
      gameContextState?.allExplanation[currentQuestionIndex]?.answers?.forEach(
        (element) => {
          const countByAnswers =
            gameContextState?.allExplanation[currentQuestionIndex]
              ?.countByAnswers;
          element.countByAnswers = countByAnswers[element?.id];
        }
      );
      setExplanationData(explanation);
    }
  }, [currentQuestionIndex, gameContextState]);
  //#endregion

  //#region onClick prev explanation
  const prevExplanation = useCallback(() => {
    playSFX("major");

    setCurrentQuestionIndex((prev) => prev - 1);
    setIsFlip(false);
    setShowExplanation(false);
    setIsPrevCard(true);
    setTimeout(() => {
      setIsPrevCard(false);
    }, 600);
    setCurrentMicroContentSequence(0);
  }, [playSFX, setCurrentQuestionIndex]);
  //#endregion

  //#region onClick next explanation
  const nextExplanation = useCallback(() => {
    playSFX("major");
    setCurrentQuestionIndex((prev) => {
      if (
        gameContextState?.allExplanation[prev + 1]?.microContents?.length !== 0
      ) {
        setCurrentMicroContentSequence(1);
      } else {
        setCurrentMicroContentSequence(0);
      }
      return prev + 1;
    });
    // setCurrentQuestionIndex((prev) => prev + 1);
    setShowExplanation(false);
    setIsFlip(false);
    setIsNextCard(true);
    setTimeout(() => {
      setIsNextCard(false);
    }, 600);
  }, [gameContextState?.allExplanation, playSFX, setCurrentQuestionIndex]);
  //#endregion
  //#region check next/prev microcontent sequence
  const prevMicroContent = useCallback(() => {
    setCurrentMicroContentSequence((prev) =>
      prev > 0 ? prev - 1 : maxContentSequece
    );
  }, [maxContentSequece]);
  const nextMicroContent = useCallback(() => {
    setCurrentMicroContentSequence((prev) =>
      prev === maxContentSequece ? 0 : prev + 1
    );
  }, [maxContentSequece]);
  const checkShouldBeChangeMicroContentOrQuestion = useCallback(
    (changeTo) => {
      if (changeTo === "prev") {
        if (
          prevQuestion &&
          (currentMicroContentSequence === 1 || maxContentSequece === 0)
        ) {
          prevExplanation();
        }
        if (hasPrevMicroContent && currentMicroContentSequence !== 1) {
          prevMicroContent();
        }
      }
      if (changeTo === "next") {
        if (
          nextQuestion &&
          (currentMicroContentSequence === 0 || maxContentSequece === 0)
        ) {
          nextExplanation();
        }
        if (hasNextMicroContent && currentMicroContentSequence !== 0) {
          nextMicroContent();
        }
      }
    },
    [
      currentMicroContentSequence,
      hasNextMicroContent,
      hasPrevMicroContent,
      maxContentSequece,
      nextExplanation,
      nextMicroContent,
      nextQuestion,
      prevExplanation,
      prevMicroContent,
      prevQuestion,
    ]
  );
  //#endregion

  //#region check display answer or explanation
  const checkDisplay = useCallback(() => {
    playSFX("major");
    setShowExplanation(!showExplanation);
    setIsFlip(true);
  }, [playSFX, showExplanation]);
  //#endregion

  const answers = useMemo(() => {
    if (gameContextState) {
      return gameContextState?.allExplanation[currentQuestionIndex]?.answers;
    } else {
      return [];
    }
  }, [currentQuestionIndex, gameContextState]);

  const freetextAnswer = useMemo(() => {
    if (gameContextState) {
      return gameContextState?.allExplanation[currentQuestionIndex];
    } else {
      return [];
    }
  }, [currentQuestionIndex, gameContextState]);

  //#region result score Correct, Incorrect, Almost, Times'up
  const resultScore = useMemo(
    () => (
      <FullRow align="center">
        <FlexCol span={6} justify="center">
          <Box direction="column" align="center">
            <TextTypo size="1vw" strong="true" type="correct">
              {t("GAMEPLAY.CORRECT")}
            </TextTypo>
            <GoIcon src={Correct_icon} shape="square" size="1.6vw" />
            <TextTypo size="1.2vw" strong="true">
              {gameContextState?.allExplanation[currentQuestionIndex]?.result
                ?.correct || 0}
            </TextTypo>
          </Box>
        </FlexCol>
        <FlexCol span={6} justify="center">
          <Box direction="column" align="center">
            <TextTypo size="1vw" strong="true" type="inCorrect">
              {t("GAMEPLAY.INCORRECT")}
            </TextTypo>
            <GoIcon src={InCorrect_icon} shape="square" size="1.6vw" />
            <TextTypo size="1.2vw" strong="true">
              {gameContextState?.allExplanation[currentQuestionIndex]?.result
                ?.wrong || 0}
            </TextTypo>
          </Box>
        </FlexCol>
        {gameContextState?.allExplanation[currentQuestionIndex]?.type !==
          "free-text" && (
            <FlexCol span={6} justify="center">
              <Box direction="column" align="center">
                <TextTypo size="1vw" strong="true" type="almost">
                  {t("GAMEPLAY.ALMOST")}
                </TextTypo>
                <GoIcon src={Almost_icon} shape="square" size="1.6vw" />
                <TextTypo size="1.2vw" strong="true">
                  {gameContextState?.allExplanation[currentQuestionIndex]?.result
                    ?.almost || 0}
                </TextTypo>
              </Box>
            </FlexCol>
          )}
        <FlexCol span={6} justify="center">
          <Box direction="column" align="center">
            <TextTypo size="1vw" strong="true" type="primary">
              {t("GAMEPLAY.TIMESUP")}
            </TextTypo>
            <GoIcon src={Timesup_icon} shape="square" size="1.6vw" />
            <TextTypo size="1.2vw" strong="true">
              {gameContextState?.allExplanation[currentQuestionIndex]?.result
                ?.timesup || 0}
            </TextTypo>
          </Box>
        </FlexCol>
      </FullRow>
    ),
    [gameContextState, currentQuestionIndex, t]
  );

  const checkAnswerByType = useMemo(() => {
    switch (gameContextState?.allExplanation[currentQuestionIndex]?.type) {
      case "choice-text":
      case "multichoice-text":
        return <ChoiceText isResult choice={answers} />;
      case "choice-image":
      case "multichoice-image":
        return <ChoiceImage isResult choice={answers} />;
      case "ordering-text":
        return <ChoiceOrdering isResult choice={orderResult} />;
      case "free-text":
        return (
          <ChoiceFreeText
            isResult
            freetextAnswer={freetextAnswer}
            resultScore={resultScore}
          />
        );
      default:
        break;
    }
  }, [
    gameContextState,
    currentQuestionIndex,
    orderResult,
    answers,
    resultScore,
    freetextAnswer,
  ]);
  //#endregion

  //#region check show explanation or content in result challenge card
  const resultContent = useMemo(() => {
    if (showExplanation) {
      return (
        <Box
          style={{
            position: "relative",
            width: "100%",
            height: "100%",
            overflow: "auto",
          }}
        >
          <Explanation explanation={explanationData} />
        </Box>
      );
    } else {
      if (currentMicroContentSequence !== 0) {
        return (
          <Box width="100%" height="100%" style={{ overflow: "auto" }}>
            <MicroContent
              currentMicroContentSequence={currentMicroContentSequence}
              isExplanationPhase={true}
              microContentExplanation={microContentData}
            />
          </Box>
        );
      } else {
        return (
          <GoLayout>
            <AnsweringHeader>
              <FullRow style={{ justifyContent: "center" }} wrap={false}>
                {gameContextState?.allExplanation[currentQuestionIndex]
                  ?.imgMonitor && (
                    <FlexCol span={4} justify="center">
                      <ImageComponent
                        src={
                          gameContextState?.allExplanation[currentQuestionIndex]
                            ?.imgMonitor
                        }
                        height={"100%"}
                        width={"76%"}
                      />
                    </FlexCol>
                  )}
                {gameContextState?.allExplanation[currentQuestionIndex]
                  ?.video && (
                    <FlexCol span={4} justify="center">
                      <YoutubePlayer
                        youtubeID={
                          gameContextState?.allExplanation[currentQuestionIndex]
                            ?.video
                        }
                        width={"76%"}
                      />
                    </FlexCol>
                  )}
                {gameContextState?.allExplanation[currentQuestionIndex]
                  ?.soundURL && (
                    <FlexCol span={4} justify="center">
                      <AudioButton width={"76%"} />
                    </FlexCol>
                  )}
                <FlexCol
                  span={
                    gameContextState?.allExplanation[currentQuestionIndex]
                      ?.imgMonitor ||
                      gameContextState?.allExplanation[currentQuestionIndex]
                        ?.video ||
                      gameContextState?.allExplanation[currentQuestionIndex]
                        ?.soundURL
                      ? "auto"
                      : 24
                  }
                  justify="center"
                  align="center"
                  style={{
                    overflowY: "auto",
                    padding: "2% 2% 0 2%",
                    display: "block",
                  }}
                  height="100%"
                >
                  <StyleInputConverter
                    width="100%"
                    height="auto"
                    fontSize={
                      gameContextState?.allExplanation[currentQuestionIndex]
                        ?.context?.length > 200
                        ? "1.3vw"
                        : "1.5vw"
                    }
                    overflowX="hidden"
                    value={
                      gameContextState?.allExplanation[currentQuestionIndex]
                        ?.context
                      // `Lorem, ipsum dolor sit amet consectetur adipisicing elit. Debitis, dolorum molestias vero voluptas eos vel alias expedita tempore harum nostrum?`
                    }
                    style={{ lineHeight: "150%" }}
                  />
                </FlexCol>
              </FullRow>
            </AnsweringHeader>
            <Content style={{ flex: 1, padding: "1% 2%", overflowY: "auto" }}>
              <FullRow>
                <FlexCol
                  style={{
                    height:
                      gameContextState?.allExplanation[currentQuestionIndex]
                        ?.type === "free-text" && "inherit",
                  }}
                  span={24}
                  align="center"
                  justify="center"
                >
                  {checkAnswerByType}
                </FlexCol>
              </FullRow>
            </Content>

            {gameContextState?.allExplanation[currentQuestionIndex]?.type !==
              "free-text" && (
                <ScoreResult>
                  <FlexCol span={10} offset={7} align="center" justify="center">
                    {resultScore}
                  </FlexCol>
                </ScoreResult>
              )}
          </GoLayout>
        );
      }
    }
  }, [
    showExplanation,
    explanationData,
    currentMicroContentSequence,
    microContentData,
    gameContextState?.allExplanation,
    currentQuestionIndex,
    checkAnswerByType,
    resultScore,
  ]);
  //#endregion

  //#region bottom bar component
  const bottomBar = useMemo(() => {
    if (showBottomBar) {
      return (
        <BottomBar
          justify="center"
          align="center"
          close={!currentGameState?.showAnswerResult}
          onClick={!isEmpty(explanationData) ? checkDisplay : null}
          noExplanation={isEmpty(explanationData)}
        >
          <TextTypo
            size="1.2vw"
            strong={explanationData ? "true" : "false"}
            type="secondary"
          >
            {showExplanation
              ? t("GAMEPLAY.SHOW_ANSWER")
              : !isEmpty(explanationData)
                ? t("GAMEPLAY.EXPLANATION")
                : t("GAMEPLAY.NO_EXPLANATION")}
          </TextTypo>
        </BottomBar>
      );
    }
  }, [
    showBottomBar,
    currentGameState,
    explanationData,
    checkDisplay,
    showExplanation,
    t,
  ]);
  //#endregion

  return (
    <ExplanationContainer>
      {
        !showExplanation &&
        <ArrowBox isPrev="true" disabled={!prevQuestion && !hasPrevMicroContent}>
          {(prevQuestion || hasPrevMicroContent) ? (
            <GoIcon
              shape="square"
              src={Arrow_active_icon}
              size="4vw"
              // onClick={prevExplanation}
              onClick={() => { checkShouldBeChangeMicroContentOrQuestion("prev"); }}
            />
          ) : (
            <GoIcon shape="square" src={Arrow_inActive_icon} size="4vw" />
          )}
        </ArrowBox>
      }
      {
        !showExplanation &&
        <ArrowBox isNext="true" disabled={!nextQuestion && !hasNextMicroContent}>
          {(nextQuestion || hasNextMicroContent) ? (
            <GoIcon
              shape="square"
              src={Arrow_active_icon}
              size="4vw"
              // onClick={nextExplanation}
              onClick={() => { checkShouldBeChangeMicroContentOrQuestion("next"); }}
            />
          ) : (
            <GoIcon shape="square" src={Arrow_inActive_icon} size="4vw" />
          )}
        </ArrowBox>
      }
      <ChallengeBox
        direction="column"
        width="70%"
        height="78%"
        marginTop="2%"
        top="3%"
        isFlip={isFlip}
        flip={showExplanation}
        isPrev={isPrevCard}
        isNext={isNextCard}
      >
        <ChallengeCardBox
          justify="center"
          align="center"
          direction="column"
          visible={animationState?.answerResultVisible}
        >
          {resultContent}
        </ChallengeCardBox>
        {!currentMicroContentSequence && bottomBar}
      </ChallengeBox>
    </ExplanationContainer>
  );
});

const AnsweringHeader = styled(Header)`
  background: none;
  padding: 0px;
  height: 25%;
  /* margin-bottom: 1%; */
  padding-top: 2%;
`;
const ScoreResult = styled(Box)`
  width: 100%;
  height: 20%;
  border-top: 1px solid #fafafa;
`;

const ExplanationContainer = styled(Box)`
  background-color: var(--silver-200);
  width: 100%;
  height: 100vh;
  justify-content: center;
  position: relative;
`;
const ArrowBox = styled.div`
  position: absolute;
  opacity: 80%;
  cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};
  ${(props) => {
    if (props.isNext) {
      return css`
        top: 35%;
        right: 4.5%;
      `;
    }
    if (props.isPrev) {
      return css`
        top: 35%;
        left: 4.5%;
        transform: rotate(180deg);
      `;
    }
  }}
`;
