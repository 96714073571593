import * as PIXI from "pixi.js";
import * as Particles from "pixi-particles";

import smoke from "Game/Assets/ParticleSprite/smoke.png";
import GustavAttackPortal from "Game/Assets/ParticleSprite/GustavAttackPortal.png";
import GustavSwordParticle from "Game/Assets/ParticleSprite/GustavSwordParticle.png";
import GustavGroundHitParticle from "Game/Assets/ParticleSprite/GustavGroundHitParticle.png";
import GustavDustParticleFrontLeft from "Game/Assets/ParticleSprite/GustavDustParticleFrontLeft.png";
import GustavDustParticleBackLeft from "Game/Assets/ParticleSprite/GustavDustParticleBackLeft.png";
import GustavDustParticleFrontRight from "Game/Assets/ParticleSprite/GustavDustParticleFrontRight.png";
import GustavDustParticleBackRight from "Game/Assets/ParticleSprite/GustavDustParticleBackRight.png";
import * as Sentry from "@sentry/react";

const GustavParticle = {
  createParticle: (loader, name) => {
    return new Promise((resolve, reject) => {
      import(`./${name}.json`)
        .then((json) => {
          if (json.images && json.data) {
            let path =
              name === "GustavAttackPortal" ||
              name === "GustavFinalAttackPortal"
                ? GustavAttackPortal
                : name === "GustavSwordParticle" ||
                  name === "GustavFinalSwordParticle"
                ? GustavSwordParticle
                : name === "GustavGroundHitParticle" ||
                  name === "GustavFinalGroundHitParticle"
                ? GustavGroundHitParticle
                : name === "GustavDustParticleFrontLeft" ||
                  name === "GustavFinalDustParticleFrontLeft"
                ? GustavDustParticleFrontLeft
                : name === "GustavDustParticleBackLeft" ||
                  name === "GustavFinalDustParticleBackLeft"
                ? GustavDustParticleBackLeft
                : name === "GustavDustParticleFrontRight" ||
                  name === "GustavFinalDustParticleFrontRight"
                ? GustavDustParticleFrontRight
                : name === "GustavDustParticleBackRight" ||
                  name === "GustavFinalDustParticleBackRight"
                ? GustavDustParticleBackRight
                : smoke;
            if (!loader.resources[name]) {
              loader.add(name, path);
            }

            loader.onError.add((e) => {
              console.log(e);
              reject({ error: "CANNOT_LOAD_PARTICLE" });
            });
            loader.load((loader, resources) => {
              // const textures = [];
              // json.images.forEach((image, index) => {
              //   textures.push(resources[name].texture);
              // });
              const textures = resources[name].texture;
              const container = new PIXI.Container();
              const emitter = new Particles.Emitter(
                container,
                textures,
                json.data
              );

              resolve({ container, emitter });
            });
          }
        })
        .catch((e) => {
          Sentry.captureException(e);
          console.error(e);
          reject({ error: "CANNOT_IMPORT_CLASS" });
        });
    });
  },

  load: (
    app,
    containerParticle,
    containerUltimate,
    containerBulletGenerate
  ) => {
    return new Promise(async (resolve, reject) => {
      let allParticle = {};
      let allBullet = [];

      for (let index = 0; index < 5; index++) {
        let bulletContainer = new PIXI.Container();
        containerBulletGenerate.addChild(bulletContainer);
        bulletContainer.controller = {};
        let containerSpawnBullet = new PIXI.Container();
        let containerGroundEffect = new PIXI.Container();
        let containerSmokeEffect = new PIXI.Container();
        bulletContainer.addChild(containerSpawnBullet);
        bulletContainer.addChild(containerGroundEffect);
        bulletContainer.addChild(containerSmokeEffect);
        bulletContainer.controller = {
          ...bulletContainer.controller,
          containerSpawnBullet,
          containerGroundEffect,
          containerSmokeEffect,
        };
        await GustavParticle.createParticle(
          app.loader,
          "GustavAttackPortal"
        ).then((GustavAttackPortal) => {
          containerSpawnBullet.addChild(GustavAttackPortal.container);
          GustavAttackPortal.container.position.set(0, -300);
          bulletContainer.controller = {
            ...bulletContainer.controller,
            GustavAttackPortal,
          };
        });
        await GustavParticle.createParticle(
          app.loader,
          "GustavSwordParticle"
        ).then((GustavSwordParticle) => {
          containerGroundEffect.addChild(GustavSwordParticle.container);

          bulletContainer.controller = {
            ...bulletContainer.controller,
            GustavSwordParticle,
          };
        });

        await GustavParticle.createParticle(
          app.loader,
          "GustavGroundHitParticle"
        ).then((GustavGroundHit) => {
          containerGroundEffect.addChild(GustavGroundHit.container);
          GustavGroundHit.container.position.set(0, 0);
          bulletContainer.controller = {
            ...bulletContainer.controller,
            GustavGroundHit,
          };
        });
        await GustavParticle.createParticle(
          app.loader,
          "GustavDustParticleBackLeft"
        ).then((GustavDustParticleBackLeft) => {
          containerSmokeEffect.addChild(GustavDustParticleBackLeft.container);
          bulletContainer.controller = {
            ...bulletContainer.controller,
            GustavDustParticleBackLeft,
          };
        });
        await GustavParticle.createParticle(
          app.loader,
          "GustavDustParticleBackRight"
        ).then((GustavDustParticleBackRight) => {
          containerSmokeEffect.addChild(GustavDustParticleBackRight.container);
          bulletContainer.controller = {
            ...bulletContainer.controller,
            GustavDustParticleBackRight,
          };
        });
        await GustavParticle.createParticle(
          app.loader,
          "GustavDustParticleFrontLeft"
        ).then((GustavDustParticleFrontLeft) => {
          containerSmokeEffect.addChild(GustavDustParticleFrontLeft.container);
          bulletContainer.controller = {
            ...bulletContainer.controller,
            GustavDustParticleFrontLeft,
          };
        });
        await GustavParticle.createParticle(
          app.loader,
          "GustavDustParticleFrontRight"
        ).then((GustavDustParticleFrontRight) => {
          containerSmokeEffect.addChild(GustavDustParticleFrontRight.container);
          bulletContainer.controller = {
            ...bulletContainer.controller,
            GustavDustParticleFrontRight,
          };
        });

        allBullet.push(bulletContainer);
      }
      allParticle = { ...allParticle, allBullet };

      //final attack
      await GustavParticle.createParticle(
        app.loader,
        "GustavFinalAttackPortal"
      ).then((GustavFinalAttackPortal) => {
        containerUltimate.addChild(GustavFinalAttackPortal.container);
        allParticle = { ...allParticle, GustavFinalAttackPortal };
      });
      await GustavParticle.createParticle(
        app.loader,
        "GustavFinalDustParticleFrontLeft"
      ).then((GustavFinalDustParticleFrontLeft) => {
        containerUltimate.addChild(GustavFinalDustParticleFrontLeft.container);
        allParticle = { ...allParticle, GustavFinalDustParticleFrontLeft };
      });
      await GustavParticle.createParticle(
        app.loader,
        "GustavFinalDustParticleBackLeft"
      ).then((GustavFinalDustParticleBackLeft) => {
        containerUltimate.addChild(GustavFinalDustParticleBackLeft.container);
        allParticle = { ...allParticle, GustavFinalDustParticleBackLeft };
      });
      await GustavParticle.createParticle(
        app.loader,
        "GustavFinalDustParticleFrontRight"
      ).then((GustavFinalDustParticleFrontRight) => {
        containerUltimate.addChild(GustavFinalDustParticleFrontRight.container);
        allParticle = { ...allParticle, GustavFinalDustParticleFrontRight };
      });
      await GustavParticle.createParticle(
        app.loader,
        "GustavFinalDustParticleBackRight"
      ).then((GustavFinalDustParticleBackRight) => {
        containerUltimate.addChild(GustavFinalDustParticleBackRight.container);
        allParticle = { ...allParticle, GustavFinalDustParticleBackRight };
      });
      await GustavParticle.createParticle(
        app.loader,
        "GustavFinalSwordParticle"
      ).then((GustavFinalSwordParticle) => {
        containerUltimate.addChild(GustavFinalSwordParticle.container);
        GustavFinalSwordParticle.container.position.set(0, 0);
        allParticle = { ...allParticle, GustavFinalSwordParticle };
      });
      await GustavParticle.createParticle(
        app.loader,
        "GustavFinalGroundHitParticle"
      ).then((GustavFinalGroundHitParticle) => {
        containerUltimate.addChild(GustavFinalGroundHitParticle.container);
        GustavFinalGroundHitParticle.container.position.set(0, 0);
        allParticle = { ...allParticle, GustavFinalGroundHitParticle };
      });
      await GustavParticle.createParticle(app.loader, "smoke").then(
        (disappeared) => {
          containerParticle.addChild(disappeared.container);
          disappeared.container.position.set(0, 0);
          allParticle = { ...allParticle, disappeared };
        }
      );

      resolve(allParticle);

      app.loader.onError.add((e) => {
        reject(e);
      });
    });
  },
};

export default GustavParticle;
