import { memo } from "react";
import styled from 'styled-components'
import { useVonderGoContext } from "Service/VonderGOContext";
// import { CaretRightOutlined } from "@ant-design/icons";
import Sound from 'Game/Assets/sound.svg';

export default memo(function AudioButton({ width }) {
    const { setShowMediaModal } = useVonderGoContext();

    return (
        <SquareBorder width={width}>
            <AudioPlay onClick={() => setShowMediaModal(true)} />
            <AudioContainer>
                {/* <CaretRightOutlined style={{ fontSize: '5em', color: '#0d66d0' }} /> */}
                <img src={Sound} alt='audio player' style={{ width: '100%', height: '100%' }} />
            </AudioContainer>
        </SquareBorder>
    )
})

const SquareBorder = styled.div`
                overflow: hidden;
                display: inline-block;
                position: relative;
                width: ${(props) => (props.width ? props.width : '100%')};
                border-radius: 0.8vw;

                :after {
                    content: "";
                display: block;
                padding-bottom: 100%;
    }
`
const AudioContainer = styled.div`
                display: flex;
                justify-content: center;
                align-items: center;
                position: absolute;
                top: 0;
                bottom: 0;
                left: 0;
                right: 0;
                background-color: #e5e7ea;
`

const AudioPlay = styled.div`
                position: absolute;
                top: 0;
                bottom: 0;
                left: 0;
                right: 0;
                z-index: 10;
                cursor: pointer;
`