export const animationInitial = {
  challengeVisible: '',
  answerResultVisible: 'down',
  progressVisible: false,
  footerVisible: false,
  closeSideCard: false,
  isHideChoice: false,
  showQA: false,
  closeChallengeInfo: false,
  closeQuestionToAnswering: false,
  lobbyToGamePlay: 'paused',
  progressSlide: false,
  actionButtonVisible: false,
  doubtCardVisible: false,
  notificationVisible: false,
  saving: false,
  swordLoading: false,
  bossSay: '',
  finalBossSay: ''
}

export function animationReducer(state, action) {
  switch (action.type) {
    case 'show_challenge_card':
      return { ...state, challengeVisible: action.payload }
    case 'show_answer_result_card':
      return { ...state, answerResultVisible: action.payload }
    case 'show_progress_bar':
      return { ...state, progressVisible: action.payload }
    case 'show_footer_bar':
      return { ...state, footerVisible: action.payload }
    case 'close_side_card':
      return { ...state, closeSideCard: action.payload }
    case 'hide_choice':
      return { ...state, isHideChoice: action.payload }
    case 'show_qa':
      return { ...state, showQA: action.payload }
    case 'close_challenge_info':
      return { ...state, closeChallengeInfo: action.payload }
    case 'close_question_to_answering':
      return { ...state, closeQuestionToAnswering: action.payload }
    case 'lobby_to_gameplay':
      return { ...state, lobbyToGamePlay: action.payload }
    case 'slide_progress':
      return { ...state, progressSlide: action.payload }
    case 'button_visible':
      return { ...state, actionButtonVisible: action.payload }
    case 'doubt_card_visible':
      return { ...state, qaCardVisible: action.payload }
    case 'notification_visible':
      return { ...state, notificationVisible: action.payload }
    case 'saving_visible':
      return { ...state, saving: action.payload }
    case 'boss_say':
      return { ...state, bossSay: action.payload }
    case 'final_boss_say':
      return { ...state, finalBossSay: action.payload }
    case 'sword_loading':
      return { ...state, swordLoading: action.payload }
    case 'reset':
      return { ...animationInitial }
    default:
      return { ...state }
  }
}