import * as PIXI from "pixi.js";
import * as Particles from "pixi-particles";

import AshokaMinionParticleImpact from "Game/Assets/ParticleSprite/AshokaMinionParticleImpact.png";
import AshokaMinionParticleWave from "Game/Assets/ParticleSprite/AshokaMinionParticleWave.png";
import smoke from "Game/Assets/ParticleSprite/smoke.png";
import * as Sentry from "@sentry/react";

const AshokaMinionParticle = {
  createParticle: (name) => {
    return new Promise((resolve, reject) => {
      import(`./${name}.json`)
        .then((json) => {
          if (json.data) {
            const loader = new PIXI.Loader();
            json.images.forEach((image, index) => {
              // const path = EnvUrl.getGameParticles(image);
              const path =
                name === "AshokaMinionParticleImpact"
                  ? AshokaMinionParticleImpact
                  : name === "AshokaMinionParticleWave"
                  ? AshokaMinionParticleWave
                  : smoke;
              loader.add(index.toString(), path);
            });
            loader.onError.add((e) => {
              console.log(e);
              reject({ error: "CANNOT_LOAD_PARTICLE" });
            });
            loader.load((loader, resources) => {
              const textures = [resources[0].texture];
              const container = new PIXI.Container();
              const emitter = new Particles.Emitter(
                container,
                textures,
                json.data
              );

              resolve({ container, emitter });
            });
          }
        })
        .catch((e) => {
          Sentry.captureException(e);
          console.error(e);
          reject({ error: "CANNOT_IMPORT_CLASS" });
        });
    });
  },

  load: (app, containerParticle, name, containerSmoke) => {
    return new Promise((resolve, reject) => {
      const loader = new PIXI.Loader();
      // loader.add();
      let promises = [
        AshokaMinionParticle.createParticle("AshokaMinionParticleImpact"),
        AshokaMinionParticle.createParticle("AshokaMinionParticleWave"),
        AshokaMinionParticle.createParticle("smoke"),
      ];
      Promise.all(promises)
        .then(
          ([
            AshokaMinionParticleImpact,
            AshokaMinionParticleWave,
            disappeared,
          ]) => {
            containerParticle.addChild(AshokaMinionParticleImpact.container);
            // AshokaMinionParticleImpact.container.position.set(0, -80);

            containerParticle.addChild(AshokaMinionParticleWave.container);
            AshokaMinionParticleWave.container.position.set(0, -40);

            containerParticle.addChild(disappeared.container);
            disappeared.container.position.set(0, 0);

            let particle = {
              AshokaMinionParticleImpact,
              AshokaMinionParticleWave,
              disappeared,
            };
            resolve(particle);
          }
        )
        .catch((e) => {
          Sentry.captureException(e);
          reject(e);
        });

      loader.onError.add((e) => {
        reject(e);
      });
    });
  },
};

export default AshokaMinionParticle;
