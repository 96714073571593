class ParallaxLoop {
  constructor(container) {
    if (!container)
      throw new Error(
        "PIXI.Container is required while construct ParallaxManager."
      );
    this._container = container;
    this._position = 0;
    this._size = 0;
    this._layer = [];
  }
  get container() {
    return this._container;
  }
  addLayer(layer) {
    this._layer.push(layer);
  }
  clearLayers() {
    this._layer = [];
  }
  setSize(size) {
    this._size = size;
  }
  setPosition(position) {
    this._position = position;
  }
  updateParallaxPosition() {
    this._layer.forEach((layer) => {
      layer.updateParallax(this._position, this._size);
    });
  }
  destroy() {
    console.warn(
      "This level will be destroyed and remove memory, but not fully implement this feature."
    );
    this._container.destroy({ children: true });
  }
  // debug() {
  //   let str = '';
  //   for (let i = 0; i < this._layer.length; i++) {
  //     str += this._layer[i].debug() + '<br />';
  //   }
  //   return str;
  // }
}

class ParallaxLayer {
  constructor(container, weight, offset) {
    if (!container)
      throw new Error(
        "PIXI.Container is required while construct ParallaxLayer."
      );
    this._container = container;
    this._weight = weight || 0;
    this._offset = offset || 0;
    this._activeBlocks = [];
    this._deactiveBlocks = [];
  }
  get container() {
    return this._container;
  }
  get position() {
    return this._container.position;
  }
  addBlock(block) {
    this._deactiveBlocks.push(block);
    block.setActive(false);
  }
  clearBlocks() {
    this._activeBlocks = [];
    this._deactiveBlocks = [];
  }
  updateParallax(position, size) {
    const weightedPosition = position * this._weight + this._offset;
    this._container.position.x = -weightedPosition;
    var [
      encapsulateBoundLeft,
      encapsulateBoundRight,
    ] = this.calculateEncapsulatedActiveBound();
    // console.log(
    //   `[encapLeft:${encapsulateBoundLeft},encapRight:${encapsulateBoundRight}]`
    // );
    const viewBoundLeft = weightedPosition - size / 2;
    const viewBoundRight = weightedPosition + size / 2;
    while (viewBoundLeft < encapsulateBoundLeft) {
      let activeBlock = this.getActiveBlock();
      if (activeBlock) {
        activeBlock.position.set(
          encapsulateBoundLeft - activeBlock.width / 2,
          0
        );
        [
          encapsulateBoundLeft,
          encapsulateBoundRight,
        ] = this.calculateEncapsulatedActiveBound();
        // console.log(
        //   `[encapLeft:${encapsulateBoundLeft},encapRight:${encapsulateBoundRight}]`
        // );
      } else break;
    }
    while (viewBoundRight > encapsulateBoundRight) {
      let activeBlock = this.getActiveBlock();
      if (activeBlock) {
        activeBlock.position.set(
          encapsulateBoundRight + activeBlock.width / 2,
          0
        );
        [
          encapsulateBoundLeft,
          encapsulateBoundRight,
        ] = this.calculateEncapsulatedActiveBound();
        // console.log(
        //   `[encapLeft:${encapsulateBoundLeft},encapRight:${encapsulateBoundRight}]`
        // );
      } else break;
    }
    if (this._activeBlocks.length > 0) {
      for (let i = 0; i < this._activeBlocks.length; i++) {
        let block = this._activeBlocks[i];
        if (block.left > viewBoundRight || block.right < viewBoundLeft) {
          block.setActive(false);
          this._activeBlocks.splice(i, 1);
          this._deactiveBlocks.push(block);
          i--;
        }
      }
    }
  }
  calculateEncapsulatedActiveBound() {
    if (this._activeBlocks.length > 0) {
      let left = this._activeBlocks[0].left;
      let right = this._activeBlocks[0].right;
      for (let i = 1; i < this._activeBlocks.length; i++) {
        const abLeft = this._activeBlocks[i].left;
        const abRight = this._activeBlocks[i].right;
        if (left > abLeft) left = abLeft;
        if (right < abRight) right = abRight;
      }
      return [left, right];
    } else {
      const block = this.getActiveBlock();
      if (block) {
        return [block.left, block.right];
      } else {
        return [0, 0];
      }
    }
  }
  getActiveBlock() {
    if (this._deactiveBlocks.length > 0) {
      let block = this._deactiveBlocks[0];
      block.setActive(true);
      this._deactiveBlocks.splice(0, 1);
      this._activeBlocks.push(block);
      return block;
    } else return undefined;
  }
  clearAllActiveBlock() {
    if (this._activeBlocks.length > 0) {
      this._deactiveBlocks = [...this._activeBlocks];
      this._activeBlocks.length = [];
    }
  }
  // debug() {
  //   return `active:${this._activeBlocks.length}|deactive:${this._deactiveBlocks.length}`;
  // }
}

class ParallaxBlock {
  constructor(container, width) {
    if (!container)
      throw new Error(
        "PIXI.Container is required while construct ParallaxBound."
      );
    this._container = container;
    this._width = width || 0;
  }
  get position() {
    return this._container.position;
  }
  get left() {
    return this.position.x - this._width / 2;
  }
  get right() {
    return this.position.x + this._width / 2;
  }
  get container() {
    return this._container;
  }
  get width() {
    return this._width;
  }
  set width(value) {
    this._width = value;
    return this._width;
  }
  setActive(value) {
    this._container.visible = value;
  }
}

export { ParallaxLoop, ParallaxLayer, ParallaxBlock };
