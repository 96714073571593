import { memo } from "react";
import { GoImage } from "Game/Style/GameStyle";
import styled from "styled-components";

export default memo(function ImageComponent({
    src,
    width,
    height,
    style
}) {
    return (
        <ImageContainer width={width} height={height} style={{ ...style }}>
            <GoImageCustom height={"100%"} width={"100%"} src={src} />
        </ImageContainer>
    )
})

const ImageContainer = styled.div`
    position: relative;
    width: ${(props) => props.width ? props.width : '100%'};
    height: ${(props) => props.height ? props.height : '100%'};;
    background-color: #000;
    border-radius: var(--border-radius);
    overflow: hidden;
    .ant-image{
        position: static;
        display: flex !important;
    }
`

const GoImageCustom = styled(GoImage)`
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    object-fit: contain;
`