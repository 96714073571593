import * as PIXI from "pixi.js";
import * as Particles from "pixi-particles";

import Jin_LeftWaterSplash from "Game/Assets/ParticleSprite/Jin_LeftWaterSplash.png";
import Jin_WaterErupt from "Game/Assets/ParticleSprite/Jin_WaterErupt.png";
import Jin_WaterEruptParticle from "Game/Assets/ParticleSprite/Jin_WaterEruptParticle.png";
import Jin_RightWaterSplash from "Game/Assets/ParticleSprite/Jin-RightWaterSplash.png";
import Jin_HydropumpImpact from "Game/Assets/ParticleSprite/Jin_HydropumpImpact.png";
import * as Sentry from "@sentry/react";

const JinParticle = {
  initial: async (app) => {
    return new Promise(async (resolve, reject) => {
      let particles = [
        "Jin_LeftWaterSplash",
        "Jin_WaterErupt",
        "Jin_WaterEruptParticle",
        "Jin_HydropumpImpact",
        "Jin-RightWaterSplash",
      ];
      let promises = [];
      particles.forEach((item) => {
        promises.push(
          new Promise((resolve, reject) => {
            let path = "";
            switch (item) {
              case "Jin_LeftWaterSplash":
                path = Jin_LeftWaterSplash;
                break;
              case "Jin_WaterErupt":
                path = Jin_WaterErupt;
                break;
              case "Jin_WaterEruptParticle":
                path = Jin_WaterEruptParticle;
                break;
              case "Jin_HydropumpImpact":
                path = Jin_HydropumpImpact;
                break;
              case "Jin-RightWaterSplash":
                path = Jin_RightWaterSplash;
                break;

              default:
                break;
            }
            let loader = new PIXI.Loader();
            loader.add(item, path);
            loader.onError.add((e) => {
              console.log(e);
              reject(e);
            });
            loader.load((loader, resources) => {
              if (app.loader.resources) {
                app.loader.resources[item] = resources[item];
              }
            });
            loader.onComplete.add(() => {
              resolve();
            });
          })
        );
      });
      return await Promise.all(promises)
        .then(() => {
          resolve();
        })
        .catch((e) => {
          Sentry.captureException(e);
          reject();
        });
    });
  },
  createParticle: (loader, name) => {
    return new Promise((resolve, reject) => {
      import(`./${name}.json`)
        .then((json) => {
          if (json.images && json.data) {
            let path = "";
            switch (name) {
              case "Jin_LeftWaterSplash":
                path = Jin_LeftWaterSplash;
                break;
              case "Jin_WaterErupt":
                path = Jin_WaterErupt;
                break;
              case "Jin_WaterEruptParticle":
                path = Jin_WaterEruptParticle;
                break;
              case "Jin_HydropumpImpact":
                path = Jin_HydropumpImpact;
                break;
              case "Jin-RightWaterSplash":
                path = Jin_RightWaterSplash;
                break;

              default:
                break;
            }
            // const loader = new PIXI.Loader();
            if (!loader.resources[name]) {
              loader.add(name, path);
            }
            loader.onError.add((e) => {
              console.log(e);
              reject({ error: "CANNOT_LOAD_PARTICLE" });
            });
            loader.load((loader, resources) => {
              const textures = resources[name].texture;
              const container = new PIXI.Container();
              const emitter = new Particles.Emitter(
                container,
                textures,
                json.data
              );

              resolve({ container, emitter });
            });
          }
        })
        .catch((e) => {
          Sentry.captureException(e);
          console.error(e);
          reject({ error: "CANNOT_IMPORT_CLASS" });
        });
    });
  },

  load: (app, containerWaterHit, containerWater) => {
    return new Promise(async (resolve, reject) => {
      let allParticle = {};

      await JinParticle.createParticle(app.loader, "Jin_LeftWaterSplash").then(
        (JinLeft) => {
          containerWater.addChild(JinLeft.container);
          allParticle = { ...allParticle, JinLeft };
        }
      );
      await JinParticle.createParticle(app.loader, "Jin_WaterErupt").then(
        (JinErupt) => {
          containerWater.addChild(JinErupt.container);
          allParticle = { ...allParticle, JinErupt };
        }
      );
      await JinParticle.createParticle(
        app.loader,
        "Jin_WaterEruptParticle"
      ).then((JinEruptDot) => {
        containerWater.addChild(JinEruptDot.container);
        allParticle = { ...allParticle, JinEruptDot };
      });
      await JinParticle.createParticle(app.loader, "Jin_HydropumpImpact").then(
        (JinSplash) => {
          containerWaterHit.addChild(JinSplash.container);
          allParticle = { ...allParticle, JinSplash };
        }
      );
      await JinParticle.createParticle(app.loader, "Jin-RightWaterSplash").then(
        (JinRight) => {
          containerWater.addChild(JinRight.container);
          allParticle = { ...allParticle, JinRight };
        }
      );
      resolve(allParticle);

      app.loader.onError.add((e) => {
        reject(e);
      });
    });
  },
};
export default JinParticle;
