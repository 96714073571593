import { memo, lazy, useMemo } from "react";
import { useVonderGoContext } from "Service/VonderGOContext";
import styled from "styled-components";
import GameBody from "Game/Component/GameBody";

const Lobby = lazy(() => import("Game/Component/GamePage/Lobby"));

export default memo(function GameState({ loadingUser, gameQRCode }) {
  const { currentGameState } = useVonderGoContext();

  window.onbeforeunload = function () {
    return 'You have unsaved changes!';
  }

  const renderGameState = useMemo(() => {
    if (currentGameState?.showLobby) {
      return <Lobby loadingUser={loadingUser} gameQRCode={gameQRCode} />;
    } else {
      return <GameBody />;
    }
  }, [currentGameState, loadingUser, gameQRCode]);

  return (
    <GameContainer>
      {renderGameState}
    </GameContainer>
  );
});

const GameContainer = styled.div`
  /* @media screen and (min-width: 320px) and (max-width: 767px) and (orientation: portrait) {
    width: 100%;
    display: flex;
    height: 100%;
    min-height: 100%;
    flex-direction: column;
    overflow: hidden;
    background-repeat: round;
  }

  @media screen and  (min-width: 768px) { */
    /* background-repeat: round; */
  /* background-color: var(--gray-700); */
  width: 100vw;
  max-height: 56.25vw;
  max-width: 177.78vh;
  padding: 0px;
  margin: auto;
  border: 0px;
  position: absolute;
  overflow: hidden;
  /* background-color: black; */
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 2;
  /* } */
`;
