export const getQueryString = (name) => {
  const { search } = window.location;
  const params = new URLSearchParams(search);
  const queryString = params.get(name);
  return queryString;
};

export const getDateTime = (timeStamp) => {
  return new Intl.DateTimeFormat("default", {
    hour: "numeric",
    minute: "numeric",
  }).format(new Date(timeStamp));
};