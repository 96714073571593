import { memo, useMemo } from "react";
import styled from 'styled-components'
import { useVonderGoContext } from "Service/VonderGOContext";
import { Box } from "Game/Style/GameStyle";

export default memo(function YoutubePlayer({ width, youtubeID, playable }) {
    const { showMediaModal, setShowMediaModal } = useVonderGoContext();

    const YoutubeiFrame = useMemo(() => {
        return (
            <iframe
                width="100%"
                height="100%"
                src={youtubeID}
                title="YouTube video player"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
            ></iframe>
        )
    }, [youtubeID])

    const ShowYT = useMemo(() => {
        return (
            <SquareBorder width={width}>
                <YoutubePlay onClick={() => setShowMediaModal(true)} />
                <YTContainer>
                    {YoutubeiFrame}
                </YTContainer>
            </SquareBorder>
        )
    }, [width, setShowMediaModal, YoutubeiFrame])

    const PlayYT = useMemo(() => {
        return (
            <Box
                width='100%'
                height="32vw"
                style={{ borderRadius: 'var(--border-radius)', overflow: 'hidden' }}
            >
                {
                    showMediaModal && YoutubeiFrame
                }
            </Box>
        )
    }, [showMediaModal, YoutubeiFrame])

    return (
        <>
            {playable ? PlayYT : ShowYT}
        </>
    )
})

const SquareBorder = styled.div`
                overflow: hidden;
                display: inline-block;
                position: relative;
                width: ${(props) => (props.width ? props.width : '100%')};
                border-radius: 0.8vw;

                :after {
                    content: "";
                display: block;
                padding-bottom: 100%;
    }
`
const YTContainer = styled.div`
                display: flex;
                justify-content: center;
                align-items: center;
                position: absolute;
                top: 0;
                bottom: 0;
                left: 0;
                right: 0;
`

const YoutubePlay = styled.div`
                position: absolute;
                top: 0;
                bottom: 0;
                left: 0;
                right: 0;
                z-index: 10;
                cursor: pointer;
`