import * as PIXI from "pixi.js";
import * as Particles from "pixi-particles";

import smoke from "Game/Assets/ParticleSprite/smoke.png";
import bandits_blade from "Game/Assets/ParticleSprite/bandits_blade.png";
import * as Sentry from "@sentry/react";

const BanditParticle = {
  createParticle: (name) => {
    return new Promise((resolve, reject) => {
      import(`./${name}.json`)
        .then((json) => {
          if (json.images && json.data) {
            const loader = new PIXI.Loader();
            json.images.forEach((image, index) => {
              // const path = EnvUrl.getGameParticles(image);
              const path = name === "bandits_blade" ? bandits_blade : smoke;
              loader.add(index.toString(), path);
            });
            loader.onError.add((e) => {
              console.log(e);
              reject({ error: "CANNOT_LOAD_PARTICLE" });
            });
            loader.load((loader, resources) => {
              const textures = [];
              json.images.forEach((image, index) => {
                textures.push(resources[index].texture);
              });
              const container = new PIXI.Container();
              const emitter = new Particles.Emitter(
                container,
                textures,
                json.data
              );

              resolve({ container, emitter });
            });
          }
        })
        .catch((e) => {
          Sentry.captureException(e);
          console.error(e);
          reject({ error: "CANNOT_IMPORT_CLASS" });
        });
    });
  },

  load: (app, containerParticle) => {
    return new Promise((resolve, reject) => {
      const loader = new PIXI.Loader();
      // loader.add();
      let promises = [
        BanditParticle.createParticle("bandits_blade"),
        BanditParticle.createParticle("smoke"),
      ];
      Promise.all(promises)
        .then(([banditBlade, disappeared]) => {
          containerParticle.addChild(banditBlade.container);
          banditBlade.container.position.set(0, -80);

          containerParticle.addChild(disappeared.container);
          disappeared.container.position.set(0, 0);

          let particle = {
            banditBlade: banditBlade,
            disappeared: disappeared,
          };
          resolve(particle);
        })
        .catch((e) => {
          Sentry.captureException(e);
          reject(e);
        });

      loader.onError.add((e) => {
        reject(e);
      });
    });
  },
};

export default BanditParticle;
