import { Box, ChangeScenceContainer, TextTypo } from "Game/Style/GameStyle";
import { memo, useContext, useMemo } from "react";
import "App.css";
import Main from "Main";
import content from "Game/PixiCores/GameContent";
import PixiApp from "Game/PixiApp";
import { useVonderGoContext } from "Service/VonderGOContext";
import styled from "styled-components";
import swordLoading from "Game/Component/LottyFiles/swordLoading.json";
import Lottie from "react-lottie";
import { AppContext } from "Game/AppContext";
import loadingArt from "Game/Assets/loading_artwork.png";
import { useLocalizationContext } from "Service/LocalizationProvider";

export default memo(function GlobalComponent() {
  const { currentGameState, animationState } = useVonderGoContext();
  const { themeAsset, themeName } = useContext(AppContext);
  const { t } = useLocalizationContext();

  const lobbyAsset = themeAsset?.find((item) => item?.type === "lobby");
  const podium = themeAsset?.find((item) => item?.type === "podium");

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: swordLoading,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  const showLobbyAsset = useMemo(() => {
    if (currentGameState?.finalLeaderboard) {
      return themeName === "loventure" || themeName === "loventures" ? (
        <LoventureLeaderboard src={podium?.link} />
      ) : (
        <PodiumLeaderboard src={podium?.link} />
      );
    }
  }, [currentGameState?.finalLeaderboard, podium?.link, themeName]);

  const checkLoading = () => {
    if (!currentGameState?.finalLeaderboard) {
      if (animationState?.swordLoading) {
        return (
          <>
            <LoadingArt src={loadingArt} alt="art" />
            <LoadingBox>
              <Lottie options={defaultOptions} width="15vw" height="15vw" />
              <TextTypo size="2.5vw" strong="true" type="white" loading="true">
                {t("LOADING")}
              </TextTypo>
            </LoadingBox>
          </>
        );
      }
    }
  };

  return (
    <>
      {checkLoading()}
      <ChangeScenceContainer loading={animationState?.lobbyToGamePlay}>
        {showLobbyAsset}
        <BackgoundImage src={lobbyAsset?.link} />
        <PixiApp content={content} />
        <Main />
      </ChangeScenceContainer>
    </>
  );
});

const LoadingBox = styled(Box)`
  width: 100%;
  height: 100%;
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;
const LoadingArt = styled.img`
  width: 100%;
  height: 100%;
  position: absolute;
`;
const PodiumLeaderboard = styled.img`
  height: 60%;
  width: auto;
  position: absolute;
  bottom: 9%;
  left: 3%;
`;
const LoventureLeaderboard = styled.img`
  height: 42%;
  width: auto;
  position: absolute;
  bottom: 6.7%;
  left: -3.3%;
`;
const BackgoundImage = styled.img`
  width: 100vw;
  max-height: 56.25vw;
  max-width: 177.78vh;
  height: 100%;
`;
