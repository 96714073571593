import { Box, ChoiceList, TextTypo } from "Game/Style/GameStyle";
import { memo, useMemo } from "react";
import styled, { css } from "styled-components";
import ImageComponent from "Game/Component/Common/ImageComponent";

export default memo(function ChoiceImage({ isResult, choice }) {
  const checkDisplayImageType = useMemo(() => {
    if (isResult) {
      return (
        <ChoiceList
          type="image"
          size={choice?.length}
          grid={{
            column:
              choice?.length === 5
                ? 5
                : choice?.length === 6
                ? 3
                : choice?.length === 2
                ? 2
                : 4,
          }}
          dataSource={choice}
          renderItem={(item) => (
            <ChoiceList.Item>
              <ImageBox isCorrect={item?.isCorrect} size={choice?.length}>
                <ImageComponent src={item?.imgMonitor} />
              </ImageBox>
              <Box margin="0 0 0 2%" width="10%">
                <TextTypo
                  size="1.5vw"
                  strong="true"
                  type={item?.isCorrect ? "correct" : "secondary"}
                >
                  {/* {item?.count} */}
                  {item?.countByAnswers}
                </TextTypo>
              </Box>
            </ChoiceList.Item>
          )}
        />
      );
    } else {
      return (
        <ChoiceList
          type="image"
          size={choice?.length}
          grid={{
            column:
              choice?.length === 5
                ? 5
                : choice?.length === 6
                ? 3
                : choice?.length === 2
                ? 2
                : 4,
          }}
          dataSource={choice}
          renderItem={(item) => (
            <ChoiceList.Item>
              <ImageBox size={choice?.length}>
                <ImageComponent
                  src={item?.imgMonitor}
                  width={"100%"}
                  height={"100%"}
                />
              </ImageBox>
            </ChoiceList.Item>
          )}
        />
      );
    }
  }, [choice, isResult]);

  return <>{checkDisplayImageType}</>;
});

const ImageBox = styled.div`
  float: left;
  position: relative;
  width: ${(props) =>
    props.size <= 4 ? "10vw" : props.size === 5 ? "8.5vw" : "7vw"};
  padding-bottom: 30%;
  margin: 1.66%;
  height: ${(props) =>
    props.size <= 4 ? "10vw" : props.size === 5 ? "8.5vw" : "7vw"};
  padding: 3%;
  background-color: ${(props) =>
    props.isCorrect ? css`var(--green-400)` : css`var(--white)`};
  border-radius: var(--border-radius);
  box-shadow: 0px 3px 15px #00000029;
  display: flex;
  align-items: center;
  overflow: hidden;
  @media screen and (max-width: 850px) {
    border-radius: 3px;
  }
`;
