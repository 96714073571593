import {
  Box,
  GoIcon,
  TextTypo,
  FullRow,
  FlexCol,
  TextParagraph,
  SelectQuestion,
  QABox,
  UserQABox
} from "Game/Style/GameStyle";
import { memo, useCallback, useMemo, useState } from "react";
import styled from "styled-components";
import icon_bookmark from "Game/Assets/bookmark.svg";
import List from "antd/lib/list";
import icon_bookmark_blue from "Game/Assets/bookmark_blue.svg";
import icon_close from "Game/Assets/close.svg";
import icon_heart from "Game/Assets/heart.svg";
import icon_undo_hidden from "Game/Assets/undo_hidden.svg";
import { useVonderGoContext } from "Service/VonderGOContext";
import { useLocalizationContext } from "Service/LocalizationProvider";
import { getDateTime } from 'Service/Utils'

const { Option } = SelectQuestion;

export default memo(function QApage({ qaList, setQAList }) {
  const [filterQA, setFilterQA] = useState("all");
  const [filterQAData, setFilterQAData] = useState([]);
  const { wsSend, animationState, playSFX } = useVonderGoContext();
  const { t } = useLocalizationContext();

  //#region onClick bookmarl Q&A
  const onClickBookmark = useCallback(
    (qaIndex, qaItem) => {
      playSFX("major");
      const findQA = qaList?.find(
        (item) => item?.questionIndex === qaItem?.questionIndex
      );
      if (findQA?.doubtList[qaIndex]?.isBookmark === false) {
        setQAList((prev) => {
          const findPrevQA = prev?.find(
            (item) => item?.questionIndex === qaItem?.questionIndex
          );
          findPrevQA.doubtList[qaIndex].isBookmark = true;
          return [...prev];
        });
        wsSend(
          JSON.stringify({
            event: "doubt-bookmark",
            id: qaItem?.id,
            questionIndex: qaItem?.questionIndex,
            isBookmark: true,
          })
        );
      } else {
        setQAList((prev) => {
          const findPrevQA = prev?.find(
            (item) => item?.questionIndex === qaItem?.questionIndex
          );
          findPrevQA.doubtList[qaIndex].isBookmark = false;
          return [...prev];
        });
      }
    },
    [playSFX, qaList, setQAList, wsSend]
  );
  //#endregion

  //#region hide Q&A context
  const hideQA = useCallback(
    (qaIndex, qaItem) => {
      playSFX("major");
      const findQA = qaList?.find(
        (item) => item?.questionIndex === qaItem?.questionIndex
      );
      if (findQA?.doubtList[qaIndex]?.isHidden === false) {
        setQAList((prev) => {
          const findPrevQA = prev?.find(
            (item) => item?.questionIndex === qaItem?.questionIndex
          );
          findPrevQA.doubtList[qaIndex].isHidden = true;
          return [...prev];
        });
        wsSend(
          JSON.stringify({
            event: "doubt-hidden",
            id: qaItem?.id,
            questionIndex: qaItem?.questionIndex,
            isHidden: true,
          })
        );
      } else {
        setQAList((prev) => {
          const findPrevQA = prev?.find(
            (item) => item?.questionIndex === qaItem?.questionIndex
          );
          findPrevQA.doubtList[qaIndex].isHidden = false;
          return [...prev];
        });
      }
    },
    [playSFX, qaList, setQAList, wsSend]
  );
  //#endregion

  //#region filter Q&A by question function
  const onChangeFilter = useCallback(
    (value) => {
      setFilterQA(value);
      if (value !== "all") {
        const filterQA = qaList?.filter((item, index) => index === value);
        setFilterQAData(filterQA);
      } else {
        setFilterQAData(qaList);
      }
    },
    [qaList]
  );
  //#endregion

  //#region List of Q&A box
  const qaBoxList = useMemo(() => (
    (filterQA === "all" ? qaList : filterQAData)?.map(
      (listItem, listIndex) => (
        <QABox
          margin="0 0 1.5% 0"
          direction="column"
          align="flex-start"
          visible={animationState?.showQA}
          key={listIndex}
        >
          <FullRow>
            <FlexCol span={24}>
              <TextTypo size="1.5vw" type="secondary" strong="true">
                {t("GAMEPLAY.QUESTION")} {listItem?.questionIndex}
              </TextTypo>
            </FlexCol>
            <FlexCol span={24}>
              <QAList
                grid={{ column: 3 }}
                dataSource={listItem?.doubtList}
                renderItem={(qaItem, qaIndex) => (
                  <List.Item key={qaIndex}>
                    <UserQABox
                      justify="space-between"
                      direction="column"
                      visible={animationState?.qaCardVisible}
                    >
                      <CloseBox onClick={() => hideQA(qaIndex, qaItem)}>
                        <GoIcon
                          src={
                            qaItem?.isHidden ? icon_undo_hidden : icon_close
                          }
                          size="1vw"
                          shape="square"
                        />
                      </CloseBox>
                      <UserNameBox>
                        <TextTypo size="1.2vw" type="secondary" strong="true">
                          {qaItem?.anonymous ? "Anonymous" : qaItem?.name}
                        </TextTypo>
                      </UserNameBox>
                      <BookmarkBox
                        onClick={() => onClickBookmark(qaIndex, qaItem)}
                      >
                        <GoIcon
                          bookmark={qaItem?.isBookmark?.toString()}
                          isanimate="true"
                          src={
                            qaItem?.isBookmark
                              ? icon_bookmark_blue
                              : icon_bookmark
                          }
                          size="2.5vw"
                          shape="square"
                        />
                      </BookmarkBox>
                      <Box padding="15% 0 0 0">
                        <TextParagraph
                          size="1vw"
                          strong="true"
                          align="start"
                          ellipsis={{ rows: 3 }}
                        >
                          {qaItem?.isHidden ? "Hidden" : qaItem?.context}
                        </TextParagraph>
                      </Box>
                      <Box justify="space-between">
                        <TextTypo size="1vw" type="secondary">
                          {getDateTime(qaItem?.timestamp)}
                        </TextTypo>
                        <Box
                          width="12%"
                          align="center"
                          justify="space-between"
                        >
                          <GoIcon
                            src={icon_heart}
                            size="1.2vw"
                            shape="square"
                          />
                          <TextTypo size="1vw" type="secondary">
                            {qaItem?.likeCount}
                          </TextTypo>
                        </Box>
                      </Box>
                    </UserQABox>
                  </List.Item>
                )}
              />
            </FlexCol>
          </FullRow>
        </QABox>
      )
    )
  ), [animationState, filterQA, filterQAData,
    hideQA, onClickBookmark, qaList, t])
  //#endregion

  return (
    <QAContainer direction="column">
      <QABox align="center" margin="0 0 3% 0" visible={animationState?.showQA}>
        <SelectQuestion
          defaultValue="all"
          dropdownStyle={{ borderRadius: "12px" }}
          onChange={onChangeFilter}
        >
          <Option value="all" style={{ margin: "3%" }}>
            <TextTypo size="1.2vw">{t("GAMEPLAY.ALL_QUESTION")}</TextTypo>
          </Option>
          {qaList?.map((item, index) => (
            <Option value={index} style={{ margin: "3%" }} key={index}>
              <TextTypo size="1.2vw">
                {t("GAMEPLAY.QUESTION")} {item?.questionIndex}
              </TextTypo>
            </Option>
          ))}
        </SelectQuestion>
      </QABox>
      {qaBoxList}
    </QAContainer>
  );
});

const CloseBox = styled(Box)`
  position: absolute;
  top: 7%;
  right: 3%;
  cursor: pointer;
  width: auto;
`;
const BookmarkBox = styled(Box)`
  position: absolute;
  top: -5%;
  right: 13%;
  cursor: pointer;
  width: auto;
`;
const UserNameBox = styled(Box)`
  position: absolute;
  top: -5%;
  left: 5%;
  width: auto;
  background-color: var(--silver-300);
  border-radius: 0px 0px 6px 6px;
  padding: 1% 2%;
`;
const QAList = styled(List)`
  padding: 2% 0;
  width: 93%;
`;
const QAContainer = styled(Box)`
  background-color: var(--white);
  width: 100%;
  height: 100%;
  justify-content: flex-start;
  display: flex;
  align-items: center;
  padding-top: 3%;
  overflow: auto;
  padding-bottom: 5%;
`;

