import {
  // ParallaxBlock,
  // ParallaxLayer,
  ParallaxLoop,
} from "Classes/Parallax.js";
import * as PIXI from "pixi.js";
import * as Particles from "pixi-particles";

import bluecometTexture from "Game/Assets/LevelParticleSprite/Galaxy/bluecomet.png";
import starTexture from "Game/Assets/LevelParticleSprite/Galaxy/star.png";
import star from "Game/PixiCores/Levels/star.json";
import comet from "Game/PixiCores/Levels/comet.json";

const level = {
  areaLength: 4000,
  load: ({ app, assets }) => {
    console.log(assets);
    return new Promise((resolve, reject) => {
      console.log("assets", assets);
      const loader = new PIXI.Loader();
      let spriteList = [
        { name: "sprites_multi_bg_1" },
        { name: "sprites_multi_bg_2" },
        { name: "sprites_multi_bg_3" },
        { name: "sprites_multi_bg_4" },
        { name: "sprites_multi_ground" },
        { name: "sprites_multi_portal" },
      ];
      assets.forEach((element) => {
        console.log("element", element);
        const path = element.link;
        const arr = path.split("/");
        const filename = arr[arr.length - 1].split(".");
        const type = filename[0];
        if (
          spriteList.find((sprite) => {
            return sprite.name === type;
          })
        ) {
          loader.add(type, element.link);
        }
      });

      console.log("loader", loader);

      // loader.add(
      //   "sprites_multi_bg_1",
      //   "https://dh8bdvjvmxojs.cloudfront.net/go/theme/Avesta-12b/multi/sprites_multi_bg_1.json"
      // );
      // loader.add(
      //   "sprites_multi_bg_2",
      //   "https://dh8bdvjvmxojs.cloudfront.net/go/theme/Avesta-12b/multi/sprites_multi_bg_2.json"
      // );
      // loader.add(
      //   "sprites_multi_bg_3",
      //   "https://dh8bdvjvmxojs.cloudfront.net/go/theme/Avesta-12b/multi/sprites_multi_bg_3.json"
      // );
      // loader.add(
      //   "sprites_multi_bg_4",
      //   "https://dh8bdvjvmxojs.cloudfront.net/go/theme/Avesta-12b/multi/sprites_multi_bg_4.json"
      // );
      // loader.add(
      //   "sprites_multi_ground",
      //   "https://dh8bdvjvmxojs.cloudfront.net/go/theme/Avesta-12b/multi/sprites_multi_ground.json"
      // );
      // loader.add(
      //   "sprites_multi_portal",
      //   "https://dh8bdvjvmxojs.cloudfront.net/go/theme/Avesta-12b/multi/sprites_multi_portal.json"
      // );

      loader.load((loader, resources) => {
        console.log(resources);
        // const spritesheetTextureClouds =
        //   resources["sprites_multi_clouds"].spritesheet.textures;

        const spritesheetTextureBG1 =
          resources["sprites_multi_bg_1"].spritesheet.textures;
        const spritesheetTextureBG2 =
          resources["sprites_multi_bg_2"].spritesheet.textures;
        const spritesheetTextureBG3 =
          resources["sprites_multi_bg_3"].spritesheet.textures;
        const spritesheetTextureBG4 =
          resources["sprites_multi_bg_4"].spritesheet.textures;
        const spritesheetTextureGround =
          resources["sprites_multi_ground"].spritesheet.textures;
        const spritesheetTexturePortal =
          resources["sprites_multi_portal"].spritesheet.textures;

        console.log("spritesheetTextureBG1", spritesheetTextureBG1);
        console.log("spritesheetTextureBG2", spritesheetTextureBG2);
        console.log("spritesheetTextureBG3", spritesheetTextureBG3);
        console.log("spritesheetTextureBG4", spritesheetTextureBG4);
        console.log("spritesheetTextureGround", spritesheetTextureGround);
        console.log("spritesheetTexturePortal", spritesheetTexturePortal);

        const parallax = new ParallaxLoop(new PIXI.Container());

        const BG1 = new PIXI.Sprite(
          spritesheetTextureBG1["Avesta_12b_BG_1.png"]
        );
        // BG1.position.set(-900, -1080);
        BG1.position.set(-760, -1080);
        BG1.scale.set(0.65);

        const BG2 = new PIXI.Sprite(
          spritesheetTextureBG2["Avesta_12b_BG_2.png"]
        );
        BG2.position.set(502, -1080);
        // BG2.position.set(620, -1080);
        BG2.scale.set(0.65);

        const BG3 = new PIXI.Sprite(
          spritesheetTextureBG3["Avesta_12b_BG_3.png"]
        );
        // BG3.position.set(-900, -1080);
        BG3.position.set(1762, -1080);
        BG3.scale.set(0.65);

        const BG4 = new PIXI.Sprite(
          spritesheetTextureBG4["Avesta_12b_BG_4.png"]
        );
        // BG4.position.set(-900, -1080);
        BG4.position.set(3020, -1080);

        BG4.scale.set(0.65);

        // const Baobab = new PIXI.Sprite(
        //   spritesheetTextureTrees["Yesegoni-Smaller_0006_Layer7_Baobab.png"]
        // );
        // Baobab.position.set(2200, -1010);
        // Baobab.scale.set(1.15);

        const Portal = new PIXI.Sprite(
          spritesheetTexturePortal["Avesta_12b_Portal.png"]
        );

        Portal.position.set(-220, -300);
        Portal.scale.set(0.5);
        Portal.anchor.set(0.5);

        const Floor = new PIXI.Sprite(
          spritesheetTextureGround["Avesta_12b_Floor_1.png"]
        );
        // Floor.position.set(-960, -168);
        // Floor.position.set(-960, -160);
        Floor.position.set(-760, -120);

        const Floor2 = new PIXI.Sprite(
          spritesheetTextureGround["Avesta_12b_Floor_1.png"]
        );
        // Floor.position.set(-960, -168);
        // Floor.position.set(-960, -160);
        Floor2.position.set(1183, -120);
        Floor2.scale.set(-1, 1);
        Floor2.anchor.set(1, 0);

        const Floor3 = new PIXI.Sprite(
          spritesheetTextureGround["Avesta_12b_Floor_1.png"]
        );
        Floor3.position.set(3100, -120);

        parallax.container.addChild(BG1);
        parallax.container.addChild(BG2);
        parallax.container.addChild(BG3);
        parallax.container.addChild(BG4);

        //#region particle star
        const particleContainer = new PIXI.Container();
        const cometParticleContainer = new PIXI.Container();

        const emitter = new Particles.Emitter(
          particleContainer,
          PIXI.Texture.from(starTexture),
          star.data
        );
        emitter.emit = true;
        const cometEmitter = new Particles.Emitter(
          cometParticleContainer,
          PIXI.Texture.from(bluecometTexture),
          comet.data
        );
        cometEmitter.emit = true;

        let ticker = app.ticker;
        let deltaTime = (1 / ticker.FPS) * ticker.speed;
        let updateStar = () => {
          emitter.update(deltaTime);
          cometEmitter.update(deltaTime);
        };
        app.ticker.add(updateStar);
        parallax.container.addChild(particleContainer);
        parallax.container.addChild(cometParticleContainer);
        particleContainer.position.set(0, -1080);
        //#endregion

        parallax.container.addChild(Portal);
        parallax.container.addChild(Floor);
        parallax.container.addChild(Floor2);
        parallax.container.addChild(Floor3);

        let updatePortal = () => {
          Portal.angle += 0.1;
        };
        app.ticker.add(updatePortal);

        const controller = {
          parallax,
          areaLength: level.areaLength,

          update: () => {
            parallax.updateParallaxPosition();
          },
          setSize: (size) => {
            parallax.setSize(size);
          },
          setPosition: (position) => {
            parallax.setPosition(position);
          },
          destroy: () => {
            app.ticker.remove(updatePortal);
            app.ticker.remove(updateStar);
            parallax.destroy();
          },
        };
        resolve(controller);
      });
      loader.onError.add((e) => {
        reject(e);
      });
    });
  },
};

export default level;
