import { memo, useContext, useEffect, useState } from "react";
import styled from "styled-components";

import { useVonderGoContext } from "Service/VonderGOContext";
import { Box } from "Game/Style/GameStyle";
import { StyleInputConverter } from "vonder-utils-input";
import { Image } from "antd";
import ReactPlayer from "react-player";
import { AppContext } from "Game/AppContext";

const Speaker = () => (
  <svg
    width="90"
    height="90"
    data-name="Layer 1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    style={{ margin: "2%" }}
  >
    <path
      id="Path_600"
      data-name="Path 600"
      d="M14.886,20.116a.772.772,0,0,1-.18-1.522,6.782,6.782,0,0,0,0-13.189.772.772,0,0,1,.362-1.5,8.326,8.326,0,0,1,0,16.19A.8.8,0,0,1,14.886,20.116Z"
      fill="#BEC3CE"
    ></path>
    <path
      id="Path_601"
      data-name="Path 601"
      d="M14.887,16.352a.772.772,0,0,1-.212-1.514h0a2.954,2.954,0,0,0,0-5.68A.772.772,0,0,1,15.1,7.674h0a4.5,4.5,0,0,1,0,8.647A.78.78,0,0,1,14.887,16.352Z"
      fill="#BEC3CE"
    ></path>
    <path
      id="Path_602"
      data-name="Path 602"
      d="M7.523,7.154,6.177,8.469a.867.867,0,0,1-.6.245H3.419a.864.864,0,0,0-.865.864v4.846a.864.864,0,0,0,.865.864H5.573a.867.867,0,0,1,.6.245l1.346,1.316,3.5,3.414a.865.865,0,0,0,1.468-.619V4.356a.864.864,0,0,0-1.468-.619Z"
      fill="#BEC3CE"
    ></path>
  </svg>
);

// assume that there is only 1 layout pattern
const RenderMicroContent = memo(
  ({ isDisplay, title, context, img, video, soundURL, multiplySize }) => {
    // const hasText = title || context;
    // const hasMedia = img || video || soundURL;

    const [isPlaying, setIsPlaying] = useState(false);
    const { setSoundVolume } = useContext(AppContext);
    let tempVolume = localStorage.getItem("soundVolume") || 1;

    useEffect(() => {
      if (isPlaying) {
        setSoundVolume(0);
      } else {
        setSoundVolume(tempVolume);
      }
    }, [isPlaying, setSoundVolume, tempVolume]);

    useEffect(() => {
      if (!isDisplay) setIsPlaying(false);
    }, [isDisplay]);

    return (
      <SlideCard>
        <TextContainerBox>
          <Box direction='column' justify="center" style={{ flex: 1 }}>
            {title && (
              <StyleInputConverter
                width="auto"
                height="auto"
                fontSize={`${multiplySize * 1.7}vw`}
                overflowX="hidden"
                value={title}
                style={{
                  lineHeight: `${multiplySize * 2}vw`,
                  margin: "3% 0 0 0",
                }}
              />
            )}
            {context && (
              <StyleInputConverter
                fontSize={`${multiplySize * 0.95}vw`}
                overflowX="hidden"
                value={context}
                style={{ lineHeight: `${multiplySize * 1.8}vw`, margin: "3% 0 0 0" }}
              />
            )}
          </Box>
        </TextContainerBox>
        <MediaContainerBox
          direction="column"
          justify="center"
          align="center"
        >
          {img && (
            <Image
              src={img}
              alt="img"
              style={{ objectFit: "cover" }}
              width="100%"
              height="100%"
            />
          )}
          {video && (
            <VideoContainer>
              <ReactPlayer
                url={video}
                width="100%"
                height="100%"
                playing={isPlaying}
                style={{
                  position: 'absolute',
                  top: 0,
                  bottom: 0,
                  left: 0,
                  right: 0
                }}
                controls
                config={{
                  file: {
                    attributes: {
                      controlsList: "nodownload",
                    },
                  },
                }}
                onPlay={() => setIsPlaying(true)}
                onPause={() => setIsPlaying(false)}
                onEnded={() => setIsPlaying(false)}
              />
            </VideoContainer>
          )}
          {soundURL && (
            <AudioContainer>
              {/* <img src={Speaker} alt="autio" width="80px" height="auto" /> */}
              <Speaker />
              <ReactPlayer
                url={soundURL}
                width="100%"
                // height="40px"
                height="100%"
                playing={isPlaying}
                controls
                config={{
                  file: {
                    attributes: {
                      controlsList: "nodownload",
                    },
                  },
                }}
                onPlay={() => setIsPlaying(true)}
                onPause={() => setIsPlaying(false)}
                onEnded={() => setIsPlaying(false)}
                style={{ paddingTop: "10px" }}
              />
            </AudioContainer>
          )}
        </MediaContainerBox>
      </SlideCard>
    );
  }
);
function MicroContent({
  currentMicroContentSequence,
  isExplanationPhase,
  microContentExplanation,
}) {
  const { currentGameData } = useVonderGoContext();

  return (
    <>
      {!isExplanationPhase &&
        currentGameData?.microContents.map((item) => {
          return (
            <SlideContainer showSlideByIndex={currentMicroContentSequence === item?.seq}>
              <RenderMicroContent
                isDisplay={currentMicroContentSequence === item?.seq}
                title={item?.title}
                context={item?.context}
                img={item?.img}
                soundURL={item?.soundURL}
                video={item?.video}
                multiplySize={1}
              />
            </SlideContainer>
          );
        })}
      {isExplanationPhase &&
        microContentExplanation.map((item) => {
          return (
            <SlideContainerRatio showSlideByIndex={currentMicroContentSequence === item?.seq}>
              <RenderMicroContent
                isDisplay={currentMicroContentSequence === item?.seq}
                title={item?.title}
                context={item?.context}
                img={item?.img}
                soundURL={item?.soundURL}
                video={item?.video}
                multiplySize={1.26}
              />
            </SlideContainerRatio>
          );
        })}
    </>
  );
}

export default memo(MicroContent);

const TextContainerBox = styled(Box)`
  flex: 5;
  flex-direction: column;
  overflow-y: auto;
  height: 100%;
  /* background-color: teal; */
`;
const MediaContainerBox = styled(Box)`
  /* background-color: violet; */
  flex: 4;
  height: 100%;
  /* background-color: ${(props) =>
    props.showOnly ? "transparent" : "#ccc"}; */
  /* background-color: #1b202d; */
  border-radius: 6px;
  overflow: hidden;

  .ant-image {
    display: block;
  }
  .ant-image-img {
    border-radius: 6px;
    overflow: hidden;
  }
`;

const VideoContainer = styled(Box)`
  width: 100%;
  /* min-height: 56.25vw; */
  /* max-height: 20vh; */
  /* height: 50%; */
  /* max-height: 20vh; */
  overflow: hidden;
  /* padding-top: 56.25%; */
  /* border-radius: var(--border-radius); */
  position: relative;
  width: 100%;
  overflow: hidden;
  padding-top: 56.25%;
  border-radius: var(--border-radius);
  z-index: 1;
`;
const AudioContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: auto;
  align-items: center;
  justify-content: space-evenly;
  border-radius: var(--border-radius);
  background: #1b202d;
  padding: 20px;
`;

const SlideContainer = styled.div`
  position: relative;
  display: ${(props) => props.showSlideByIndex ? 'block' : 'none'};
  width: 100%;
  height: 100%;
  /* padding-top: ${(623 / 1060) * 100}%; */
  /* border: 1px solid red; */
`

const SlideContainerRatio = styled.div`
  position: relative;
  display: ${(props) => props.showSlideByIndex ? 'block' : 'none'};
  width: 100%;
  height: 100%;
  padding-top: ${(742 / 1300) * 100}%;
  /* border: 1px solid red; */
`

const SlideCard = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  padding: 2%;
  gap: 2%;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
`