import { FlexCol, FullRow, GoLayout } from "Game/Style/GameStyle";
import { memo } from "react";
import styled from "styled-components";
import { useVonderGoContext } from "Service/VonderGOContext";
import useCheckType from "Hooks/useCheckType";
import YoutubePlayer from "Game/Component/Media/YoutubePlayer";
import AudioButton from "Game/Component/Media/AudioButton";
import { StyleInputConverter } from "vonder-utils-input";
import ImageComponent from "Game/Component/Common/ImageComponent";

const { Header, Content } = GoLayout;

export default memo(function Answering() {
  const { currentGameData } = useVonderGoContext();
  const { checkAnswerByType } = useCheckType({ type: currentGameData?.type });

  return (
    <GoLayout>
      <AnsweringHeader>
        <FullRow style={{ justifyContent: "center" }} wrap={false}>
          {currentGameData?.imgMonitor && (
            <FlexCol span={4} style={{ marginRight: "3%" }}>
              <ImageComponent
                src={currentGameData?.imgMonitor}
                height={"100%"}
                width={"75%"}
              />
            </FlexCol>
          )}
          {currentGameData?.video && (
            <FlexCol span={4} style={{ marginRight: "3%" }}>
              <YoutubePlayer youtubeID={currentGameData?.video} width={"75%"} />
            </FlexCol>
          )}
          {currentGameData?.soundURL && (
            <FlexCol span={4} style={{ marginRight: "3%" }}>
              <AudioButton width={"75%"} />
            </FlexCol>
          )}
          <FlexCol
            span={
              currentGameData?.imgMonitor ||
              currentGameData?.video ||
              currentGameData?.soundURL
                ? "auto"
                : 24
            }
            justify="center"
            align="center"
            style={{ overflowY: "auto", paddingTop: "2%", display: "block" }}
            height="100%"
          >
            <StyleInputConverter
              width="100%"
              height="auto"
              fontSize={
                currentGameData?.context?.length > 200 ? "1.3vw" : "1.5vw"
              }
              overflowX="hidden"
              style={{ lineHeight: "150%" }}
              value={
                currentGameData?.context
                // `Lorem, ipsum dolor sit amet consectetur adipisicing elit. Debitis, dolorum molestias vero voluptas eos vel alias expedita tempore harum nostrum?`
              }
            />
          </FlexCol>
        </FullRow>
      </AnsweringHeader>
      <Content style={{ height: "70%", padding: "1% 2%", overflowY: "auto" }}>
        <FullRow>
          <FlexCol span={24} align="center" justify="center">
            {checkAnswerByType}
          </FlexCol>
        </FullRow>
      </Content>
    </GoLayout>
  );
});

const AnsweringHeader = styled(Header)`
  background: none;
  padding: 0px;
  /* flex: 3; */
  height: 30%;
  /* margin-bottom: 3%; */
  padding: 2%;
`;
