import { useMemo } from "react";
import Single_text_icon from 'Game/Assets/Icon_SingleSelectTextChoice.svg'
import Multi_text_icon from 'Game/Assets/Icon_MultipleSelectTextChoice.svg'
import Single_image_icon from 'Game/Assets/Icon_SingleSelectImageChoice.svg'
import Multi_image_icon from 'Game/Assets/Icon_MultipleSelectImageChoice.svg'
import ordering_icon from 'Game/Assets/Icon_Odering.svg'
import Free_Text_icon from 'Game/Assets/Icon_FreeTextChoice.svg'
import { useLocalizationContext } from "Service/LocalizationProvider";
import ChoiceText from 'Game/Component/ChoiceType/ChoiceText'
import ChoiceImage from 'Game/Component/ChoiceType/ChoiceImage'
import ChoiceOrdering from 'Game/Component/ChoiceType/ChoiceOrdering'
import ChoiceFreeText from 'Game/Component/ChoiceType/ChoiceFreeText'
import { useVonderGoContext } from "Service/VonderGOContext";

const useCheckType = ({ type }) => {
  const { currentGameData } = useVonderGoContext()
  const { t } = useLocalizationContext()

  const checkQuestionTypeIcon = useMemo(() => {
    switch (type) {
      case 'choice-text':
        return Single_text_icon
      case 'multichoice-text':
        return Multi_text_icon
      case 'choice-image':
        return Single_image_icon
      case 'multichoice-image':
        return Multi_image_icon
      case 'ordering-text':
        return ordering_icon
      case 'free-text':
        return Free_Text_icon
      default:
        break;
    }
  }, [type])

  const descriptionType = useMemo(() => {
    switch (type) {
      case 'choice-text':
        return t('SINGLE_TEXT_CHOICE')
      case 'multichoice-text':
        return t('MUTI_TEXT_CHOICE')
      case 'choice-image':
        return t('SINGLE_IMAGE_CHOICE')
      case 'multichoice-image':
        return t('MUTI_IMAGE_CHOICE')
      case 'ordering-text':
        return t('ORDERING')
      case 'free-text':
        return t('FREE_TEXT')
      default:
        break;
    }
  }, [type, t])

  const descriptionContext = useMemo(() => {
    switch (type) {
      case 'choice-text':
        return t('SINGLE_TEXT_CHOICE_DESCRIPTION')
      case 'multichoice-text':
        return t('MUTI_TEXT_CHOICE_DESCRIPTION')
      case 'choice-image':
        return t('SINGLE_IMAGE_CHOICE_DESCRIPTION')
      case 'multichoice-image':
        return t('MUTI_IMAGE_CHOICE_DESCRIPTION')
      case 'ordering-text':
        return t('ORDERING_DESCRIPTION')
      case 'free-text':
        return t('FREE_TEXT_DESCRIPTION')
      default:
        break;
    }
  }, [type, t])

  const checkAnswerByType = useMemo(() => {
    switch (type) {
      case 'choice-text':
      case 'multichoice-text':
        return <ChoiceText choice={currentGameData?.answers} />
      case 'choice-image':
      case 'multichoice-image':
        return <ChoiceImage choice={currentGameData?.answers} />
      case 'ordering-text':
        return <ChoiceOrdering choice={currentGameData?.answers} />
      case 'free-text':
        return <ChoiceFreeText choice={currentGameData?.answers} />
      default:
        break;
    }
  }, [currentGameData, type])

  return { checkQuestionTypeIcon, descriptionType, descriptionContext, checkAnswerByType }
};

export default useCheckType;
