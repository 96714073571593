import { memo, useMemo } from "react"
import { ChoiceList, GoIcon, ChoiceCard } from "Game/Style/GameStyle"
import Hambergur_icon from 'Game/Assets/Hambergur_icon.svg'
import { StyleInputConverter } from 'vonder-utils-input';

export default memo(function ChoiceOrdering({ isResult, choice }) {

  const checkDisplayOrderingType = useMemo(() => {
    if (isResult) {
      return (
        <ChoiceList
          grid={{ column: 1 }}
          dataSource={choice}
          renderItem={item => (
            <ChoiceList.Item>
              <ChoiceCard
                align='center'
                justify='space-between'
                padding='0.5% 3%'
                isCorrect={item?.isCorrect}
                width={item?.context?.length > 50 ? '85%' : '60%'}
              >
                <StyleInputConverter
                  width="100%"
                  height="auto"
                  fontSize={item?.context?.length > 50 ? "1.2vw" : "1.4vw"}
                  overflowX="hidden"
                  value={item?.context}
                  style={{ lineHeight: '150%', color: item?.isCorrect ? "var(--white)" : "var(--silver-900)" }}
                />
                {/* <TextTypo
                  size={item?.context?.length > 50 ? '1vw' : '1.2vw'}
                  strong='true'
                  type='white'
                >
                  Lorem ipsum dolor sit amet.
                </TextTypo> */}
                <GoIcon src={Hambergur_icon} size='1vw' shape='square' />
              </ChoiceCard>
            </ChoiceList.Item>
          )}
        />
      )
    } else {
      return (
        <ChoiceList
          grid={{ column: 1 }}
          dataSource={choice}
          renderItem={item => (
            <ChoiceList.Item>
              <ChoiceCard
                align='center'
                justify='space-between'
                padding='0.5% 3%'
                width={item?.context?.length > 50 ? '100%' : '70%'}
              >
                <StyleInputConverter
                  width="inherit"
                  height="auto"
                  fontSize={item?.context?.length > 50 ? "1.2vw" : "1.4vw"}
                  overflowX="hidden"
                  value={item?.context}
                  style={{ lineHeight: '150%' }}
                />
                {/* <TextTypo
                  size={item?.context?.length > 50 ? '0.8vw' : '1.2vw'}
                  strong='true'
                  type='secondary'
                >
                  Lorem, ipsum dolor.
                </TextTypo> */}
                <GoIcon src={Hambergur_icon} size='1vw' shape='square' />
              </ChoiceCard>
            </ChoiceList.Item>
          )}
        />
      )
    }
  }, [choice, isResult])

  return (
    <>
      {checkDisplayOrderingType}
    </>
  )
})