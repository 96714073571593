import * as PIXI from "pixi.js";

import Oliver_ParticleSmoke1 from "Game/Assets/ParticleSprite/gunfired/Oliver_ParticleSmoke-29.png";
import Oliver_ParticleSmoke2 from "Game/Assets/ParticleSprite/gunfired/Oliver_ParticleSmoke-30.png";
import Oliver_ParticleSmoke3 from "Game/Assets/ParticleSprite/gunfired/Oliver_ParticleSmoke-31.png";
import Oliver_ParticleSmoke4 from "Game/Assets/ParticleSprite/gunfired/Oliver_ParticleSmoke-32.png";
import Oliver_ParticleSmoke5 from "Game/Assets/ParticleSprite/gunfired/Oliver_ParticleSmoke-33.png";
import Oliver_ParticleSmoke6 from "Game/Assets/ParticleSprite/gunfired/Oliver_ParticleSmoke-34.png";
import Oliver_ParticleSmoke7 from "Game/Assets/ParticleSprite/gunfired/Oliver_ParticleSmoke-35.png";
import Oliver_ParticleSmoke8 from "Game/Assets/ParticleSprite/gunfired/Oliver_ParticleSmoke-36.png";
import Oliver_ParticleSmoke9 from "Game/Assets/ParticleSprite/gunfired/Oliver_ParticleSmoke-37.png";
import Oliver_ParticleSmoke10 from "Game/Assets/ParticleSprite/gunfired/Oliver_ParticleSmoke-38.png";
import * as Sentry from "@sentry/react";

const OliverParticle = {
  initial: async (app) => {},
  createAnimatedSprite: () => {
    const gunfiredTexture = [
      Oliver_ParticleSmoke1,
      Oliver_ParticleSmoke2,
      Oliver_ParticleSmoke3,
      Oliver_ParticleSmoke4,
      Oliver_ParticleSmoke5,
      Oliver_ParticleSmoke6,
      Oliver_ParticleSmoke7,
      Oliver_ParticleSmoke8,
      Oliver_ParticleSmoke9,
      Oliver_ParticleSmoke10,
    ];
    return new Promise((resolve, reject) => {
      let textureArr = [];
      for (let i = 0; i < gunfiredTexture.length; i++) {
        const texture = PIXI.Texture.from(gunfiredTexture[i]);
        // console.log(texture);
        textureArr.push(texture);
      }
      const gunfired = new PIXI.AnimatedSprite(textureArr);
      const container = new PIXI.Container();
      container.addChild(gunfired);
      resolve({ container, gunfired });
    });
  },
  load: (app, containerParticle, containerBullet) => {
    return new Promise((resolve, reject) => {
      let promises = [OliverParticle.createAnimatedSprite()];
      Promise.all(promises)
        .then(([animatedSprite]) => {
          containerParticle.addChild(animatedSprite.container);

          animatedSprite.container.position.set(0, -180);
          containerParticle.visible = false;
          animatedSprite.gunfired.animationSpeed = 0.2;
          animatedSprite.container.scale.set(0.2);

          let particle = {
            animatedSprite: animatedSprite,
          };
          resolve(particle);
        })
        .catch((e) => {
          Sentry.captureException(e);
          reject(e);
        });
    });
  },
};

export default OliverParticle;
