import * as PIXI from "pixi.js";
import PIXISpine from "Plugins/pixi-spine";

import BjornParticle from "Game/PixiCores/Particles/Bjorn/BjornParticle";
import { Tween, Tweener } from "Classes/Tween";
import { generateParabola } from "Service/Parabola";
import * as Sentry from "@sentry/react";

let character = {
  controller: {},
  scale: 0.13,
  sequence: {
    idle: [{ name: "Idle", loop: true }],
    move: [{ name: "Move", loop: true }],
    charge: [
      { name: "Charge", loop: false },
      { name: "Charging Loop", loop: true, delay: 0 },
    ],
    attack: [
      { name: "Attack", loop: false },
      { name: "Idle", loop: true, delay: 0 },
    ],
    attackOnce: [
      { name: "Idle", loop: false },
      { name: "Charge", loop: false, delay: 0 },
      { name: "Attack", loop: false, delay: 0 },
      { name: "Idle", loop: true, delay: 0 },
    ],
    hit: [
      { name: "Idle", loop: true },
      { name: "Hit", loop: false, delay: 0.15 },
      { name: "Idle", loop: true, delay: 0 },
    ],
    win: [{ name: "Win", loop: true, delay: 0 }],
    lose: [{ name: "Lose", loop: false }],
  },
  event: {
    Attack: () => {
      // character.controller.setAttackTo(
      //     character.controller.targetPosition
      // )
    },
  },
  eventListener: {
    event: (entry, { data }) => {
      const { name } = data;
      character.event[name]?.();
    },
  },
  playSequence: (spine, sequenceData) => {
    if (!sequenceData || sequenceData.length === 0) return;
    spine.state.setAnimation(0, sequenceData[0].name, sequenceData[0].loop);
    for (let i = 1; i < sequenceData.length; i++)
      spine.state.addAnimation(
        0,
        sequenceData[i].name,
        sequenceData[i].loop,
        sequenceData[i].delay
      );
  },
  initial: async (app, path) => {
    return new Promise((resolve, reject) => {
      const preload = new PIXI.Loader();
      preload.add("character_VND_005", path);
      preload.load(async (loader, resources) => {
        // console.log("resources", resources);
        if (app.loader.resources) {
          app.loader.resources.character_VND_005 = resources;
        }
        // console.log("app", app);
      });
      preload.onComplete.add(async () => {
        await BjornParticle.initial(app);
        resolve("load Bjorn complete");
      });
      preload.onError.add((e) => {
        reject(e);
      });
    });
  },
  create: async (app, audioRef, path, preload) => {
    return new Promise((resolve, reject) => {
      let promises = [
        new Promise((resolve, reject) => {
          // const preload = new PIXI.Loader();
          if (!preload.resources.character_VND_005) {
            preload.add(
              "character_VND_005",
              // process.env.REACT_APP_API_URL_GO_CHARACTER + "/VND-001/VND-001.json"
              path
            );
          }
          preload.load(async (loader, resources) => {
            console.log({ resources });
            let spineData =
              resources["character_VND_005"].character_VND_005.spineData;
            console.log({ spineData });
            if (spineData) {
              let spine = new PIXISpine.Spine(spineData);
              spine.scale.set(character.scale);
              spine.state.addListener(character.eventListener);

              let container = new PIXI.Container();
              let containerAxe = new PIXI.Container();
              let containerHitFX = new PIXI.Container();
              let emitterController;

              container.addChild(spine);
              container.addChild(containerHitFX);
              container.addChild(containerAxe);

              await BjornParticle.load(app, containerAxe, containerHitFX).then(
                (particle) => {
                  emitterController = particle;
                }
              );

              const controller = {
                spine,
                container,
                containerAxe,
                containerHitFX,
                emitterController,
                targetPosition: {
                  x: 0,
                  y: 0,
                },
                moveSpeed: 100,
                isMoving: false,
                infoCharacter: {
                  characterName: "bjorn",
                },
                setActive: (isActive) => {
                  container.visible = isActive;
                },
                setTargetPosition: (x, y) => {
                  character.controller.targetPosition = {
                    x: x,
                    y: y,
                  };
                },
                setParticleActive: (isActive) => {},
                charging: () => {},
                setFiredBulletTo: async (targetPosition) => {
                  const delay = 0.65;

                  containerAxe.position.set(0, 0);
                  containerHitFX.position.x = targetPosition.x - 15;
                  containerHitFX.position.y = -40;

                  const electricRadiusStart = 30;
                  const electricRadiusEnd = 210;
                  const electricCurve = 2;

                  new Tweener(app.ticker)
                    .insert(
                      0.0 + delay,
                      new Tween(0.35, (pos, dt) => {
                        if (pos === 0) {
                          audioRef.current._group["VND-005"]._list[0].play(
                            "attack"
                          );
                        }
                        if (pos === 1) {
                          audioRef.current._group["VND-005"]._list[0].play(
                            "hitTarget"
                          );
                        } else {
                          emitterController.BjornAxe.container.visible = true;
                          emitterController.BjornAxe.container.position.x =
                            pos ** 1 * (targetPosition.x - 30);
                          emitterController.BjornAxe.container.position.y =
                            generateParabola(pos, 0.5, -140, -200, -40);
                          emitterController.BjornAxe.emitter.emit = true;
                          emitterController.BjornAxe.emitter.update(dt);
                        }
                      })
                    )
                    .insert(
                      1.25 + delay,
                      new Tween(0.4, (pos, dt) => {
                        if (pos === 1) {
                          emitterController.BjornAxe.container.visible = false;
                          emitterController.BjornAxe.container.alpha = 1;
                        } else {
                          emitterController.BjornAxe.container.alpha = 1 - pos;
                        }
                      })
                    )
                    .insert(
                      0.3 + delay,
                      new Tween(0.3, (pos, dt) => {
                        if (pos === 1) {
                          emitterController.BjornCircle.container.visible = false;
                          emitterController.BjornCircle.container.alpha = 1;
                        } else {
                          emitterController.BjornCircle.container.visible = true;
                          emitterController.BjornCircle.emitter.emit = true;
                          emitterController.BjornCircle.emitter.update(dt);
                          emitterController.BjornCircle.container.scale.set(
                            0.5 + pos ** 2 * 3
                          );
                          emitterController.BjornCircle.container.alpha =
                            1 - pos ** 4;
                        }
                      })
                    )
                    .insert(
                      0.3 + delay,
                      new Tween(0.4, (pos, dt) => {
                        if (pos === 1) {
                        } else {
                          emitterController.BjornN.container.visible = true;
                          emitterController.BjornN.emitter.emit = true;
                          emitterController.BjornN.emitter.update(dt);

                          emitterController.BjornS.container.visible = true;
                          emitterController.BjornS.emitter.emit = true;
                          emitterController.BjornS.emitter.update(dt);

                          emitterController.BjornW.container.visible = true;
                          emitterController.BjornW.emitter.emit = true;
                          emitterController.BjornW.emitter.update(dt);

                          emitterController.BjornE.container.visible = true;
                          emitterController.BjornE.emitter.emit = true;
                          emitterController.BjornE.emitter.update(dt);

                          emitterController.BjornNW.container.visible = true;
                          emitterController.BjornNW.emitter.emit = true;
                          emitterController.BjornNW.emitter.update(dt);

                          emitterController.BjornNE.container.visible = true;
                          emitterController.BjornNE.emitter.emit = true;
                          emitterController.BjornNE.emitter.update(dt);

                          emitterController.BjornSW.container.visible = true;
                          emitterController.BjornSW.emitter.emit = true;
                          emitterController.BjornSW.emitter.update(dt);

                          emitterController.BjornSE.container.visible = true;
                          emitterController.BjornSE.emitter.emit = true;
                          emitterController.BjornSE.emitter.update(dt);

                          emitterController.BjornN.container.scale.set(
                            1 + pos ** 2 * 1.5
                          );
                          emitterController.BjornS.container.scale.set(
                            1 + pos ** 2 * 1.5
                          );
                          emitterController.BjornW.container.scale.set(
                            1 + pos ** 2 * 1.5
                          );
                          emitterController.BjornE.container.scale.set(
                            1 + pos ** 2 * 1.5
                          );
                          emitterController.BjornNW.container.scale.set(
                            1 + pos ** 2 * 1.5
                          );
                          emitterController.BjornNE.container.scale.set(
                            1 + pos ** 2 * 1.5
                          );
                          emitterController.BjornSW.container.scale.set(
                            1 + pos ** 2 * 1.5
                          );
                          emitterController.BjornSE.container.scale.set(
                            1 + pos ** 2 * 1.5
                          );

                          emitterController.BjornN.container.position.y =
                            -electricRadiusStart +
                            pos ** electricCurve * -electricRadiusEnd;
                          emitterController.BjornS.container.position.y =
                            electricRadiusStart -
                            pos ** electricCurve * -electricRadiusEnd;
                          emitterController.BjornW.container.position.x =
                            -electricRadiusStart +
                            pos ** electricCurve * -electricRadiusEnd;
                          emitterController.BjornE.container.position.x =
                            electricRadiusStart -
                            pos ** electricCurve * -electricRadiusEnd;

                          emitterController.BjornNW.container.position.x =
                            electricRadiusStart -
                            15 -
                            pos ** electricCurve * -(electricRadiusEnd - 15);
                          emitterController.BjornNW.container.position.y =
                            -(electricRadiusStart - 15) +
                            pos ** electricCurve * -(electricRadiusEnd - 15);

                          emitterController.BjornNE.container.position.x =
                            electricRadiusStart -
                            15 -
                            pos ** electricCurve * -(electricRadiusEnd - 15);
                          emitterController.BjornNE.container.position.y =
                            electricRadiusStart -
                            15 -
                            pos ** electricCurve * -(electricRadiusEnd - 15);

                          emitterController.BjornSW.container.position.x =
                            -(electricRadiusStart - 15) +
                            pos ** electricCurve * -(electricRadiusEnd - 15);
                          emitterController.BjornSW.container.position.y =
                            -(electricRadiusStart - 15) +
                            pos ** electricCurve * -(electricRadiusEnd - 15);

                          emitterController.BjornSE.container.position.x =
                            -(electricRadiusStart - 15) +
                            pos ** electricCurve * -(electricRadiusEnd - 15);
                          emitterController.BjornSE.container.position.y =
                            electricRadiusStart -
                            15 -
                            pos ** electricCurve * -(electricRadiusEnd - 15);
                        }
                      })
                    )
                    .insert(
                      0.5 + delay,
                      new Tween(0.2, (pos, dt) => {
                        if (pos === 1) {
                          emitterController.BjornN.container.visible = false;
                          emitterController.BjornS.container.visible = false;
                          emitterController.BjornW.container.visible = false;
                          emitterController.BjornE.container.visible = false;
                          emitterController.BjornNW.container.visible = false;
                          emitterController.BjornNE.container.visible = false;
                          emitterController.BjornSW.container.visible = false;
                          emitterController.BjornSE.container.visible = false;

                          emitterController.BjornN.container.alpha = 1;
                          emitterController.BjornS.container.alpha = 1;
                          emitterController.BjornW.container.alpha = 1;
                          emitterController.BjornE.container.alpha = 1;
                          emitterController.BjornNW.container.alpha = 1;
                          emitterController.BjornNE.container.alpha = 1;
                          emitterController.BjornSW.container.alpha = 1;
                          emitterController.BjornSE.container.alpha = 1;
                        } else {
                          emitterController.BjornN.container.alpha = 1 - pos;
                          emitterController.BjornS.container.alpha = 1 - pos;
                          emitterController.BjornW.container.alpha = 1 - pos;
                          emitterController.BjornE.container.alpha = 1 - pos;
                          emitterController.BjornNW.container.alpha = 1 - pos;
                          emitterController.BjornNE.container.alpha = 1 - pos;
                          emitterController.BjornSW.container.alpha = 1 - pos;
                          emitterController.BjornSE.container.alpha = 1 - pos;
                        }
                      })
                    )
                    .play();
                  await character.controller.routineWait(1).then(() => {
                    console.log("particle hit");
                  });
                },
                hurt: () => {},
                playState: (stateName) => {
                  if (character.sequence[stateName])
                    character.playSequence(
                      controller.spine,
                      character.sequence[stateName]
                    );
                },
                destroy: () => {
                  controller.spine.destroy();
                  controller.container?.destroy();
                },
                routineWait: async (second) => {
                  await new Promise((resolve) => {
                    const ticker = app.ticker;
                    let current = 0;
                    const onUpdate = (elapsedTime) => {
                      const deltaTime = (1 / ticker.FPS) * ticker.speed;
                      current += deltaTime;
                      if (current > second) {
                        ticker.remove(onUpdate);
                        resolve();
                      }
                    };
                    ticker.add(onUpdate);
                  });
                },
              };
              character.controller = controller;
              resolve(controller);
            } else {
              reject({ error: "NO_SPINE_DATA_BJORN" });
            }
          });
        }),
      ];
      Promise.all(promises)
        .then((results) => {
          const [character] = results;
          resolve(character);
        })
        .catch((e) => {
          Sentry.captureException(e);
          reject(e);
        });
    });
  },
};

export default character;
