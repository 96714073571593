import { useRef, useState, useCallback } from 'react';

const useWebSocket = () => {
  const wsRef = useRef();

  const eventRef = useRef({
    open: null,
    close: null,
    message: null,
    error: null,
  });

  const [readyState, setReadyState] = useState(WebSocket.CONNECTING);

  const _onOpen = useCallback(() => {
    setReadyState(wsRef.current.readyState);
    eventRef.current?.open?.();
  }, []);

  const _onClose = useCallback(() => {
    if (wsRef.current) setReadyState(wsRef.current.readyState);
    else setReadyState(WebSocket.CLOSED);
    eventRef.current?.close?.();
  }, []);

  const _onMessage = useCallback((e) => {
    setReadyState(wsRef.current.readyState);
    eventRef.current?.message?.(e.data);
  }, []);

  const _onError = useCallback((e) => {
    if (wsRef.current) setReadyState(wsRef.current.readyState);
    else setReadyState(WebSocket.CLOSED);
    eventRef.current?.error?.(e);
  }, []);

  const connect = useCallback(
    (url, protocols) => {
      if (wsRef.current) wsRef.current.close();
      const ws = new WebSocket(url, protocols);
      ws.onopen = _onOpen;
      ws.onclose = _onClose;
      ws.onmessage = _onMessage;
      ws.onerror = _onError;
      wsRef.current = ws;
    },
    [_onClose, _onError, _onMessage, _onOpen]
  );

  const send = useCallback((data) => {
    if (wsRef.current) wsRef.current.send(data);
  }, []);

  const close = useCallback((code, reason) => {
    if (wsRef.current) wsRef.current.close(code, reason);
  }, []);

  const on = useCallback((eventName, func) => {
    if (eventName === 'open') {
      eventRef.current.open = func;
    } else if (eventName === 'close') {
      eventRef.current.close = func;
    } else if (eventName === 'message') {
      eventRef.current.message = func;
    } else if (eventName === 'error') {
      eventRef.current.error = func;
    } else {
      throw new Error('Unknown websocket event name.');
    }
  }, []);

  return [readyState, connect, send, close, on];
};

export default useWebSocket;
