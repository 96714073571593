import * as PIXI from "pixi.js";
import * as Particles from "pixi-particles";

import Celeste_CloudBack from "Game/Assets/ParticleSprite/Celeste_CloudBack.png";
import Celeste_CloudFront from "Game/Assets/ParticleSprite/Celeste_CloudFront.png";
import Celeste_Flare from "Game/Assets/ParticleSprite/Celeste_Flare.png";
import * as Sentry from "@sentry/react";

const CelesteParticle = {
  initial: async (app) => {
    return new Promise(async (resolve, reject) => {
      let particles = [
        "CelesteCloudBack",
        "CelesteCloudFront",
        "CelesteGroundHitParticle",
      ];
      let promises = [];
      particles.forEach((item) => {
        promises.push(
          new Promise(async (resolve, reject) => {
            let path = "";
            switch (item) {
              case "CelesteCloudBack":
                path = Celeste_CloudBack;
                break;
              case "CelesteCloudFront":
                path = Celeste_CloudFront;
                break;
              case "CelesteGroundHitParticle":
                path = Celeste_Flare;
                break;
              default:
                break;
            }
            let loader = new PIXI.Loader();
            loader.add(item, path);
            loader.onError.add((e) => {
              console.log(e);
              reject(e);
            });
            loader.load((loader, resources) => {
              if (app.loader.resources) {
                app.loader.resources[item] = resources[item];
              }
            });
            loader.onComplete.add(() => {
              resolve();
            });
          })
        );
      });
      return await Promise.all(promises)
        .then(() => {
          resolve();
        })
        .catch((e) => {
          Sentry.captureException(e);
          reject();
        });
    });
  },
  createParticle: (loader, name) => {
    return new Promise((resolve, reject) => {
      import(`./${name}.json`)
        .then((json) => {
          if (json.images && json.data) {
            let path = "";
            switch (name) {
              case "CelesteCloudBack":
                path = Celeste_CloudBack;
                break;
              case "CelesteCloudFront":
                path = Celeste_CloudFront;
                break;
              case "CelesteGroundHitParticle":
                path = Celeste_Flare;
                break;

              default:
                break;
            }
            // const loader = new PIXI.Loader();
            if (!loader.resources[name]) {
              loader.add(name, path);
            }
            loader.onError.add((e) => {
              console.log(e);
              reject({ error: "CANNOT_LOAD_PARTICLE" });
            });
            loader.load((loader, resources) => {
              const textures = resources[name].texture;
              const container = new PIXI.Container();
              const emitter = new Particles.Emitter(
                container,
                textures,
                json.data
              );

              resolve({ container, emitter });
            });
          }
        })
        .catch((e) => {
          Sentry.captureException(e);
          console.error(e);
          reject({ error: "CANNOT_IMPORT_CLASS" });
        });
    });
  },

  load: (app, containerCloud, containerGround) => {
    return new Promise(async (resolve, reject) => {
      let allParticle = {};
      await CelesteParticle.createParticle(app.loader, "CelesteCloudBack").then(
        (CelesteCloudBack) => {
          containerCloud.addChild(CelesteCloudBack.container);
          CelesteCloudBack.container.position.set(0, -20);
          allParticle = { ...allParticle, CelesteCloudBack };
        }
      );
      await CelesteParticle.createParticle(
        app.loader,
        "CelesteCloudFront"
      ).then((CelesteCloudFront) => {
        containerCloud.addChild(CelesteCloudFront.container);
        CelesteCloudFront.container.position.set(0, -20);
        allParticle = { ...allParticle, CelesteCloudFront };
      });
      await CelesteParticle.createParticle(
        app.loader,
        "CelesteGroundHitParticle"
      ).then((CelesteGroundHit) => {
        containerGround.addChild(CelesteGroundHit.container);
        CelesteGroundHit.container.position.set(0, -35);
        allParticle = { ...allParticle, CelesteGroundHit };
      });

      resolve(allParticle);

      app.loader.onError.add((e) => {
        reject(e);
      });
      // resolve("resolve")
    });
  },
};
export default CelesteParticle;
