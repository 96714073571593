import * as PIXI from "pixi.js";
import * as Particles from "pixi-particles";

import arrow from "Game/Assets/ParticleSprite/Robin_Arrow.png";
import * as Sentry from "@sentry/react";

const RobinParticle = {
  initial: async (app) => {
    return new Promise(async (resolve, reject) => {
      let path = arrow;

      let loader = new PIXI.Loader();
      loader.add("robin_arrow", path);
      loader.onError.add((e) => {
        console.log(e);
        reject(e);
      });
      loader.load((loader, resources) => {
        if (app.loader.resources) {
          app.loader.resources["robin_arrow"] = resources["robin_arrow"];
        }
      });
      loader.onComplete.add(() => {
        resolve();
      });
    });
  },
  createParticle: (loader) => {
    return new Promise((resolve, reject) => {
      import("./arrow.json")
        .then((json) => {
          if (json.images && json.data) {
            // console.log("LOADED JSON");
            // const loader = new PIXI.Loader();
            if (!loader.resources["robin_arrow"]) {
              loader.add("robin_arrow", arrow);
            }
            // json.images.forEach((image, index) => {
            //   // const path = EnvUrl.getGameParticles(image);
            //   const path = arrow;
            //   loader.add(index.toString(), path);
            // });
            loader.onError.add((e) => {
              console.log(e);
              reject({ error: "CANNOT_LOAD_PARTICLE" });
            });
            loader.load((loader, resources) => {
              // const textures = [];
              // json.images.forEach((image, index) => {
              //   textures.push(resources[index].texture);
              // });
              // const container = new PIXI.Container();
              // const emitter = new Particles.Emitter(
              //   container,
              //   textures,
              //   json.data
              // );
              const textures = resources["robin_arrow"].texture;
              const container = new PIXI.Container();
              const emitter = new Particles.Emitter(
                container,
                textures,
                json.data
              );
              resolve({ container, emitter });
            });
          }
        })
        .catch((e) => {
          Sentry.captureException(e);
          console.error(e);
          reject({ error: "CANNOT_IMPORT_ARROW_JSON" });
        });
    });
  },
  load: (app, containerBullet) => {
    return new Promise((resolve, reject) => {
      // const loader = new PIXI.Loader();
      // loader.add();
      let promises = [RobinParticle.createParticle(app.loader)];
      Promise.all(promises)
        .then(([arrow]) => {
          containerBullet.addChild(arrow.container);
          arrow.container.position.set(0, -90);
          arrow.container.angle = -90;

          let particle = {
            arrow: arrow,
          };
          resolve(particle);
        })
        .catch((e) => {
          Sentry.captureException(e);
          reject(e);
        });

      app.loader.onError.add((e) => {
        reject(e);
      });
    });
  },
};

export default RobinParticle;
