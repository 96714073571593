import Typography from "antd/lib/typography";
import styled, { css, keyframes } from "styled-components";
import Space from "antd/lib/space";
import Image from "antd/lib/image";
import List from "antd/lib/list";
import Row from "antd/lib/row";
import Col from "antd/lib/col";
import Button from "antd/lib/button";
import Layout from "antd/lib/layout";
import Popover from "antd/lib/popover";
import Avatar from "antd/lib/avatar";
import Drawer from "antd/lib/drawer";
import Modal from "antd/lib/modal";
import Slider from "antd/lib/slider";
import Progress from "antd/lib/progress";
import Select from "antd/lib/select";
import {
  pulseText,
  slideFadeDown,
  slideFadeUp,
  slideDown,
  slideUp,
  slideInRight,
  prevCard,
  nextCard,
  slideOutRight,
  slideInLeft,
  slideOutLeft,
  jello,
  bounceCountdown,
  heartBeat,
  scaleDown,
  scaleUp,
  scaleOut,
  fadeToLoading,
  fadeOutBack,
  unJello,
  shake,
} from "Game/Style/CSSAnimation";

const { Text, Paragraph } = Typography;

export const Container = styled.div`
  width: 100%;
  display: flex;
  height: 100%;
  min-height: 100%;
  background-color: var(--silver-300);
  flex-direction: column;
  overflow: hidden;
`;
export const ProgressBox = styled.div`
  background-color: white;
  position: absolute;
  top: 0px;
  border-radius: 0 0 10px 10px;
  box-shadow: 0px 3px 6px #00000029;
  width: 55%;
  height: 210%;
  padding: 1.5% 0;
  z-index: 3;
  ${(props) => {
    if (props.visible === null) {
      return css`
        display: none;
      `;
    }
    if (props.visible === true)
      return css`
        opacity: 1;
        animation: 1s ${slideDown};
      `;
    if (props.visible === false) {
      return css`
        opacity: 0;
        animation: 1s ${slideUp};
      `;
    }
  }};
  @media screen and (max-width: 850px) {
    border-radius: 0 0 6px 6px;
  }
`;
export const Box = styled.div`
  display: ${(props) => (props.display ? props.display : "flex")};
  justify-content: ${(props) => props.justify};
  align-items: ${(props) => props.align};
  flex-direction: ${(props) => props.direction};
  position: ${(props) => props.position};
  height: ${(props) => props.height};
  width: ${(props) => props.width};
  padding: ${(props) => props.padding};
  margin: ${(props) => props.margin};
`;
export const ChoiceCard = styled(Box)`
  background-color: var(--white);
  border-radius: var(--border-radius);
  box-shadow: 0px 3px 15px #00000029;
  width: ${(props) => (props.width ? props.width : "25vw")};
  height: 100%;
  text-align: start;
  margin: ${(props) => props.margin};
  ${(props) => {
    if (props.isCorrect) {
      return css`
        background-color: var(--green-400);
      `;
    }
  }}
  @media screen and (max-width: 1080px) {
    border-radius: 6px;
  }
  @media screen and (max-width: 850px) {
    border-radius: 2px;
  }
`;
export const TextTypo = styled(Text)`
  font-size: ${(props) => (props.size ? props.size : "14px")};
  color: ${(props) => props.color && props.color};
  text-align: ${(props) => (props.align ? props.align : "center")};
  line-height: ${(props) => props.lineheight};
  ${(props) => {
    if (props.strong === "true") {
      if (props.type === "primary") {
        return css`
          &.ant-typography strong {
            font-weight: bold;
          }
          color: var(--blue-500);
        `;
      }
      if (props.type === "secondary") {
        return css`
          &.ant-typography strong {
            font-weight: bold;
          }
          color: var(--silver-900);
        `;
      }
      if (props.type === "white") {
        return css`
          &.ant-typography strong {
            font-weight: bold;
          }
          color: var(--white);
        `;
      }
      if (props.type === "correct") {
        return css`
          &.ant-typography strong {
            font-weight: bold;
          }
          color: var(--green-400);
        `;
      }
      if (props.type === "inCorrect") {
        return css`
          &.ant-typography strong {
            font-weight: bold;
          }
          color: var(--red-400);
        `;
      }
      if (props.type === "almost") {
        return css`
          &.ant-typography strong {
            font-weight: bold;
          }
          color: var(--orange-400);
        `;
      }
      if (!props.type) {
        return css`
          &.ant-typography strong {
            font-weight: bold;
          }
        `;
      }
    } else {
      if (props.type === "primary")
        return css`
          color: var(--blue-500);
        `;
      if (props.type === "secondary")
        return css`
          color: var(--silver-900);
        `;
      if (props.type === "white")
        return css`
          color: var(--white);
        `;
      if (props.type === "correct")
        return css`
          color: var(--green-400);
        `;
      if (props.type === "inCorrect")
        return css`
          color: var(--red-400);
        `;
      if (props.type === "almost")
        return css`
          color: var(--orange-400);
        `;
    }
  }};
  ${(props) => {
    if (props.countdown) {
      return css`
        animation: ${bounceCountdown} 1s ease;
        text-transform: uppercase;
      `;
    }
    if (props.loading === "true") {
      return css`
        animation: ${heartBeat} 2s infinite;
      `;
    }
  }}
`;

export const TextParagraph = styled(Paragraph)`
  font-size: ${(props) => (props.size ? props.size : "14px")};
  color: ${(props) => props.color && props.color};
  text-align: ${(props) => (props.align ? props.align : "center")};
  line-height: ${(props) => props.lineheight};

  ${(props) => {
    if (props.strong === "true") {
      if (props.type === "primary") {
        return css`
          &.ant-typography strong {
            font-weight: bold;
          }
          color: var(--blue-500);
        `;
      }
      if (props.type === "secondary") {
        return css`
          &.ant-typography strong {
            font-weight: bold;
          }
          color: var(--silver-900);
        `;
      }
      if (props.type === "white") {
        return css`
          &.ant-typography strong {
            font-weight: bold;
          }
          color: var(--white);
        `;
      }
      if (props.type === "correct") {
        return css`
          &.ant-typography strong {
            font-weight: bold;
          }
          color: var(--green-400);
        `;
      }
      if (props.type === "inCorrect") {
        return css`
          &.ant-typography strong {
            font-weight: bold;
          }
          color: var(--red-400);
        `;
      }
      if (props.type === "almost") {
        return css`
          &.ant-typography strong {
            font-weight: bold;
          }
          color: var(--orange-400);
        `;
      }
      if (!props.type) {
        return css`
          &.ant-typography strong {
            font-weight: bold;
          }
        `;
      }
    } else {
      if (props.type === "primary")
        return css`
          color: var(--blue-500);
        `;
      if (props.type === "secondary")
        return css`
          color: var(--silver-900);
        `;
      if (props.type === "white")
        return css`
          color: var(--white);
        `;
      if (props.type === "correct")
        return css`
          color: var(--green-400);
        `;
      if (props.type === "inCorrect")
        return css`
          color: var(--red-400);
        `;
      if (props.type === "almost")
        return css`
          color: var(--orange-400);
        `;
    }
  }};
`;
export const ChallengeCardBox = styled(Box)`
  position: relative;
  /* padding: 2% 3%; */
  background-color: var(--white);
  border-radius: var(--border-radius);
  box-shadow: 0px 3px 6px #00000029;
  width: 100%;
  height: 100%;
  /* flex: 1; */
  z-index: 2;
  transform-origin: bottom center;
  ${(props) => {
    if (props.visible === "up")
      return css`
        opacity: 1;
        animation: 1s ${slideFadeUp};
      `;
    if (props.visible === "down")
      return css`
        opacity: 0;
        animation: 1s ${slideFadeDown};
      `;
    if (props.visible === "" || !props.visible)
      return css`
        display: none;
      `;
  }};
  @media screen and (max-width: 850px) {
    border-radius: 3px;
  }
`;
export const ChallengeBox = styled(Box)`
  width: ${(props) => (props.width ? props.width : "55%")};
  max-height: 82%;
  /* height: 55%; */
  /* height: 62%; */
  min-height: ${(props) => (props.height ? props.height : "55%")};
  position: relative;
  margin-top: ${(props) => (props.marginTop ? props.marginTop : "5%")};
  /* top: ${(props) => (props.top ? props.top : "10%")}; */
  ${(props) => {
    if (props.isPrev) {
      return css`
        animation: ${prevCard} 0.6s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
      `;
    }
    if (props.isNext) {
      return css`
        animation: ${nextCard} 0.6s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
      `;
    }
    if (props.flip && props.isFlip) {
      return css`
        animation: ${scaleUp} 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
      `;
    }
    if (!props.flip && props.isFlip) {
      return css`
        animation: ${scaleDown} 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
      `;
    }
  }}
`;
export const FullSpace = styled(Space)`
  width: 100%;
  .ant-space-item {
    height: 100%;
  }
`;
export const GoImage = styled(Image)`
  border-radius: ${(props) =>
    props.radius ? props.radius : css`var(--border-radius)`};
  object-fit: cover;
  height: ${(props) => (props.height ? props.height : "90%")};
  @media screen and (max-width: 850px) {
    border-radius: 3px;
  }
`;
export const ChoiceList = styled(List)`
  width: 100%;
  height: 100%;
  ${(props) => {
    if (props.size === 2)
      return css`
        width: 55%;
      `;
    if (props.size === 6)
      return css`
        width: 70%;
      `;
    if (props.size > 6)
      return css`
        width: 90%;
      `;
  }}
  &.ant-list-grid .ant-col > .ant-list-item {
    margin-bottom: 1%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .ant-row {
    height: 100%;
    justify-content: center;
  }
  .ant-spin-nested-loading {
    height: 100%;
  }
  .ant-spin-container {
    height: 100%;
  }
  .ant-col {
    height: 100%;
  }
  .ant-list-item {
    height: ${(props) => (props.type === "image" ? "95%" : "80%")};
  }
`;
export const BottomBar = styled(Box)`
  position: absolute;
  bottom: -12%;
  top: 100%;
  left: 5%;
  /* display: grid; */
  /* align-self: center; */
  width: 90%;
  height: 2.5vw;
  border-radius: 0 0 12px 12px;
  background-color: var(--silver-400);
  box-shadow: 0px 3px 6px #00000029;
  cursor: ${(props) => (props.noExplanation ? "not-allowed" : "pointer")};
  ${(props) => {
    if (props.close) {
      return css`
        opacity: 0;
        animation: 0.5s ${slideUp};
      `;
    } else {
      return css`
        opacity: 1;
        animation: 1s ${slideDown};
      `;
    }
  }};
  @media screen and (max-width: 850px) {
    border-radius: 0 0 3px 3px;
  }
`;
export const QACard = styled(Box)`
  position: absolute;
  z-index: 5;
  right: 5%;
  top: 12%;
  width: 6.5vw;
  height: 4vw;
  border-radius: 12px;
  background-color: var(--white);
  box-shadow: 0px 3px 6px #00000029;
  cursor: pointer;
  display: ${(props) => props.isQA && "none"};
  ${(props) => {
    if (props.isEnd) {
      return css`
        display: none;
      `;
    }
    if (props.visible) {
      return css`
        opacity: 1;
        animation: ${pulseText} 300ms;
      `;
    }
    if (!props.visible) {
      return css`
        display: none;
      `;
    }
  }}
  @media screen and (max-width: 850px) {
    border-radius: 3px;
  }
`;
export const TimeCard = styled(Box)`
  position: absolute;
  right: -16%;
  top: 21%;
  width: 18%;
  height: 49%;
  border-radius: 0 12px 12px 0;
  background-color: var(--white);
  box-shadow: 0px 3px 6px #00000029;
  ${(props) => {
    if (props.close) {
      return css`
        opacity: 0;
        animation: 0.5s ${slideOutRight};
      `;
    } else {
      return css`
        opacity: 1;
        animation: 1s ${slideInRight};
      `;
    }
  }};
  @media screen and (max-width: 850px) {
    border-radius: 0 3px 3px 0;
  }
`;
export const TimeCardProgress = styled(Box)`
  position: absolute;
  bottom: 0px;
  width: 100%;
  opacity: 15%;
  background-color: var(--blue-400);
  border-radius: 0 12px 12px 0;
  ${(props) => {
    const progress = (98 * props.progress) / props.time;
    return css`
      height: ${Math.abs(progress)}%;
      border-radius: 0 0 12px 0;
      transition: height 500ms linear;
    `;
  }};
  @media screen and (max-width: 850px) {
    border-radius: 0 3px 3px 0;
  }
`;
export const AnswerCountCard = styled(Box)`
  position: absolute;
  left: -16%;
  top: 21%;
  width: 18%;
  height: 49%;
  border-radius: 12px 0 0 12px;
  background-color: var(--white);
  box-shadow: 0px 3px 6px #00000029;
  ${(props) => {
    if (props.close) {
      return css`
        opacity: 0;
        animation: 0.5s ${slideOutLeft};
      `;
    } else {
      return css`
        opacity: 1;
        animation: 1s ${slideInLeft};
      `;
    }
  }};
  @media screen and (max-width: 850px) {
    border-radius: 3px 0 0 3px;
  }
`;
export const AnswerCountProgress = styled(Box)`
  position: absolute;
  bottom: 0px;
  width: 100%;
  opacity: 15%;
  background-color: var(--silver-600);
  border-radius: 12px 0 0 12px;
  ${(props) => {
    const progress = (100 * props.progress) / props.totalUser;
    return css`
      height: ${progress}%;
      max-height: 100%;
      border-radius: ${progress >= 100 ? "12px 0 0 12px" : "0 0 12px 0"};
      transition: height 500ms linear;
    `;
  }};
  @media screen and (max-width: 850px) {
    border-radius: 3px 0 0 3px;
  }
`;
export const QABadge = styled.div`
  top: -80%;
  right: -80%;
  width: 2.8vw;
  height: 2.8vw;
  border-radius: 30px;
  justify-content: center;
  position: absolute;
  display: flex;
  align-items: center;
  background-color: var(--red-800);
  ${(props) => {
    const count = props.count;
    if (count) {
      return css`
        animation: ${pulseText} 300ms;
      `;
    }
  }}
`;
export const FullRow = styled(Row)`
  height: ${(props) => (props.height ? props.height : "100%")};
  width: 100%;
  justify-content: ${(props) => props.justify};
  align-items: ${(props) => props.align};
`;
export const FlexCol = styled(Col)`
  display: flex;
  height: ${(props) => props.height && props.height};
  justify-content: ${(props) => props.justify};
  align-items: ${(props) => props.align};
`;
export const GoLayout = styled(Layout)`
  height: 100%;
  width: 100%;
  &.ant-layout {
    background: none;
  }
`;
export const GoButton = styled(Button)`
  border-radius: 6px;
  font-size: 1.5vw;
  font-weight: 600;
  height: 3vw;
  display: flex;
  justify-content: center;
  align-items: center;
  width: auto;
  opacity: 1;
  ${(props) => {
    if (props.type === "primary") {
      return css`
        background-color: var(--blue-700);
        :hover {
          background-color: var(--blue-700);
          border-color: var(--blue-700);
        }
      `;
    }
    if (props.type === "warning") {
      return css`
        background-color: var(--red-500);
        :hover {
          background-color: var(--red-500);
          border-color: var(--red-500);
        }
      `;
    }
  }}
  ${(props) => {
    if (props.size === "small")
      return css`
        width: 7vw;
      `;
    if (props.size === "large")
      return css`
        width: 12vw;
      `;
  }}
  ${(props) => {
    if (props.empty === "true") {
      return css`
        animation: ${shake} 0.2s cubic-bezier(0.455, 0.03, 0.515, 0.955) both;
        background-color: var(--red-500) !important;
      `;
    }
  }}
  @media screen and (max-width: 850px) {
    border-radius: 3px;
  }
`;

export const ButtonAction = styled(Button)`
  border-radius: 6px;
  font-size: 1.5vw;
  padding: 1% 16px;
  font-weight: 600;
  height: 3vw;
  display: flex;
  justify-content: center;
  align-items: center;
  width: auto;
  opacity: 1;
  ${(props) => {
    if (props.type === "primary") {
      return css`
        background-color: var(--blue-700);

        &:focus{
          background-color: var(--blue-700);
        }
      `;
    }
  }}
  ${(props) => {
    if (props.size === "small")
      return css`
        width: 7vw;
      `;
    if (props.size === "large")
      return css`
        width: 15vw;
      `;
  }}
  ${(props) => {
    if (props.visible === "true") {
      return css`
        /* opacity: 1; */
        animation: ${scaleUp} 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
      `;
    } else {
      return css`
        display: none;
        animation: ${fadeOutBack} 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
      `;
    }
  }}
  @media screen and (max-width: 850px) {
    border-radius: 3px;
  }
`;

export const GoIcon = styled(Avatar)`
  width: ${(props) => props.size};
  height: ${(props) => props.size};
  ${(props) => {
    if (props.isanimate === "true" && props.bookmark === "true") {
      return css`
        animation: ${jello} 0.7s both;
      `;
    }
    if (props.isanimate === "true" && props.bookmark === "false") {
      return css`
        animation: ${unJello} 0.7s both;
      `;
    }
  }}
`;
export const UserList = styled(List)`
  width: 100%;
  max-height: 100%;
  overflow: auto;
  .ant-list-item {
    border-bottom: none;
    padding: 2% 0;
  }
  &.ant-list-grid .ant-col > .ant-list-item {
    margin: 3%;
  }
  ${(props) => {
    if (props.minileaderboard) {
      return css`
        width: 60%;
        padding: 1%;
      `;
    }
  }}
`;
export const UserNameBox = styled(Box)`
  width: 100%;
  background-color: var(--white);
  border-radius: 6px;
  padding: 3% 8%;
  box-shadow: 0px 0px 3px #00000029;
  ${(props) => {
    if (props.minileaderboard) {
      return css`
        padding: 2% 5%;
      `;
    }
  }};
  @media screen and (max-width: 850px) {
    border-radius: 3px;
  } ;
`;
export const ChangeScenceContainer = styled.div`
  height: 100%;
  z-index: 5;
  ${(props) => {
    if (props.loading === "play") {
      return css`
        animation: 1s ${fadeToLoading} normal;
        opacity: 0;
      `;
    }
  }}
`;
export const DrawerQA = styled(Drawer)`
  width: 100vw;
  max-height: 56.25vw;
  max-width: 177.78vh;
  padding: 0px;
  margin: auto;
  border: 0px;
  position: absolute;
  overflow: hidden;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  .ant-drawer-content-wrapper {
    height: 100% !important;
  }
  .ant-drawer-header {
    height: 4vw;
    align-items: center;
    justify-content: center;
    display: flex;
  }
  .ant-drawer-body {
    padding: 0px;
  }
  &.ant-drawer.ant-drawer-top.ant-drawer-open {
    width: 100vw;
    max-height: 56.25vw;
    max-width: 177.78vh;
    padding: 0px;
    margin: auto;
    border: 0px;
    position: absolute;
    overflow: hidden;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }
`;
export const IconBox = styled.div`
  background-color: white;
  border-radius: 6px;
  border: ${(props) =>
    props.noBorder === "true" ? "none" : "1px solid #E5E7EA"};
  display: flex;
  justify-content: center;
  align-items: center;
  height: 2.5vw;
  width: 2.5vw;
  padding: 1.5%;
  cursor: pointer;
  @media only screen and (max-width: 850px) {
    border-radius: 2px;
  }
`;
export const PopoverLanguage = styled(Popover)`
  .ant-popover-inner-content {
    width: 8vw !important;
    display: flex !important;
    flex-direction: column !important;
    padding: 1vh 0 !important;
  }
`;
export const PopoverMusic = styled(Popover)`
  .ant-popover-inner-content {
    width: 2.5vw;
    display: flex;
    justify-content: center;
    padding: 2vh 0 !important;
  }
`;
export const LanguageMenu = styled.div`
  height: auto;
  width: 9vw;
  padding: 10% 0;
  border-radius: var(--border-radius);
  background-color: var(--white);
  position: absolute;
  top: 0%;
  right: 130%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  overflow: auto;
  @media only screen and (max-width: 850px) {
    border-radius: 6px;
  }
`;
export const Circle = styled.div`
  width: 1.5vw;
  border-radius: 20px;
  height: 1.5vw;
  left: ${(props) => props.progress + "%"};
  z-index: 2;
  background-color: ${(props) =>
    props.progress + 5 >= 50 ? css`var(--green-300)` : css`var(--red-800)`};
  border: 3px solid var(--silver-500);
  padding: 0.5%;
  position: absolute;
  ${(props) => {
    const slide = keyframes`
      0% {
        left: ${props.prevPosition + "%"};
      }
      100% {
        left: ${props.progress + "%"};
      }
    `;
    return css`
      animation: ${slide} 1s both;
      animation-play-state: ${(props) => (props.play ? `running` : `paused`)};
    `;
  }}
  @media only screen and (max-width: 850px) {
    border: 1px solid var(--silver-500);
  }
`;
export const ProgressBar = styled(Progress)`
  position: relative;
  height: 0.5vw;
  .ant-progress-outer {
    display: flex;
    height: 0.5vw;
  }
  .ant-progress-inner {
    display: flex;
    height: 0.5vw;
  }
  .ant-progress-success-bg {
    height: 0.5vw;
    background-color: var(--green-300);
    width: ${(props) => props.prevposition + "%"};
    transition: width 1s;
    ${(props) => {
    if (props.play) {
      return css`
          width: ${props.progress + "%"};
        `;
    }
  }}
  }
  .ant-progress-bg {
    height: 0.5vw;
    background-color: var(--red-300);
  }
`;
export const GoModal = styled(Modal)`
  &.ant-modal {
    width: 30vw !important;
  }
  .ant-modal-content {
    border-radius: var(--border-radius);
    width: 30vw;
    @media only screen and (max-width: 850px) {
      border-radius: 3px;
    }
  }
  .ant-modal-body {
    padding: 3%;
  }
`;
export const LobbyFooter = styled(Box)`
  position: absolute;
  bottom: 0px;
  height: 9%;
  width: 100%;
  padding: 0 3%;
  z-index: 3;
`;
export const UserNameLobby = styled(Box)`
  width: 100%;
  /* background-color: var(--silver-100); */
  background-color: ${(props) =>
    props.isConnect ? "var(--silver-100)" : "var(--gray-100)"};
  border-radius: 6px;
  padding: 3% 8%;
  opacity: 80%;
  span > img {
    opacity: 0;
  }
  :hover > span > img {
    opacity: 1;
    cursor: pointer;
  }
  animation: ${(props) => (props.isKicked ? fadeOutBack : scaleUp)} 0.2s
    cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  @media screen and (max-width: 850px) {
    border-radius: 3px;
  }
`;
export const SoundSlider = styled(Slider)`
  &.ant-slider-vertical {
    height: 8vw;
    padding: 0px;
    margin: 0px;
    display: flex;
    justify-content: center;
  }
  &.ant-slider-vertical .ant-slider-rail {
    width: 0.5vw;
    background-color: var(--silver-500);
  }
  &.ant-slider-vertical .ant-slider-track {
    width: 0.5vw;
    background-color: var(--blue-400);
  }
  .ant-slider-handle {
    width: 1.2vw;
    height: 1.2vw;
    background-color: var(--blue-700);
    border: none;
  }
  &.ant-slider-vertical .ant-slider-handle {
    margin: 0px;
  }
`;
export const SayBox = styled(Box)`
  width: 27vw;
  height: 15vh;
  background: var(--white);
  border-radius: var(--border-radius);
  position: relative;
  padding: 2% 4%;
  box-shadow: 0px 3px 6px #00000029;
  opacity: 0;
  display: flex;
  align-items: center;
  ${(props) => {
    if (props.visible === "in") {
      return css`
        opacity: 1;
        animation: ${scaleUp} 0.7s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
      `;
    }
    if (props.visible === "out") {
      return css`
        opacity: 0;
        animation: ${scaleOut} 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
      `;
    }
  }}
  ::after {
    content: "";
    position: absolute;
    left: 80%;
    top: 95%;
    border: 2.3vw solid transparent;
    border-top-color: var(--white);
    border-bottom: 0;
    border-right: 0;
  }
`;
export const SelectQuestion = styled(Select)`
  width: 12vw;
  .ant-select-selector {
    display: flex;
    align-items: center;
    height: 3vw !important;
    font-size: 1.5vw;
    font-weight: 600;
    color: var(--silver-900);
    border-radius: var(--border-radius) !important;
    padding: 0 5% !important;
    border: 2px solid #e4e4eb !important;
    @media screen and (max-width: 1080px) {
      border-radius: 6px !important;
    }
    @media screen and (max-width: 850px) {
      border-radius: 6px !important;
    }
  }
  .ant-select-arrow {
    font-size: 1.3vw;
    top: 42%;
    width: 2vw;
    height: 100%;
    @media screen and (max-width: 850px) {
      top: 60%;
      right: 10%;
    }
  }
  span.ant-select-selection-item {
    height: 100%;
    display: contents;
  }
`;
export const QABox = styled(Box)`
  width: 80vw;
  padding: 2%;
  display: flex;
  box-shadow: 6px 12px 24px #0000000d;
  border-radius: var(--border-radius);
  border: 1px solid var(--silver-200);
  ${(props) => {
    if (props.visible) {
      return css`
        opacity: 1;
        animation: 1s ${slideFadeUp};
      `;
    } else {
      return css`
        opacity: 0;
        animation: 1s ${slideFadeDown};
      `;
    }
  }}
  @media only screen and (max-width: 850px) {
    border-radius: 6px;
  }
`;
export const UserQABox = styled(Box)`
  position: relative;
  width: 22vw;
  height: 12vw;
  padding: 3%;
  margin-bottom: 5%;
  border-radius: var(--border-radius);
  border: 2px solid #f2f2f5;
  ${(props) => {
    if (props.visible) {
      return css`
        animation: ${scaleUp} 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
      `;
    }
  }}
  @media only screen and (max-width: 850px) {
    border-radius: 3px;
  }
`;
