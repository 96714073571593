import * as PIXI from "pixi.js";

function Tweener(ticker) {
  const self = this;
  self.tweens = [];
  self.duration = 0;
  self.second = 0;
  self.insert = (position, tween) => {
    tween.position = position;
    self.tweens.push(tween);
    self._sortTweens();
    if (self.duration < tween.position + tween.duration) {
      self.duration = tween.position + tween.duration;
    }
    return self;
  };
  self.play = () => {
    self.second = 0;
    self._tickPlay();
  };
  self.stop = () => {
    self.second = 0;
    self._tickStop();
  };
  self.pause = () => {
    self._tickStop();
  };
  self.resume = () => {
    self._tickPlay();
  };
  self._tick = (elapsedFrame) => {
    const fps = (PIXI.settings.TARGET_FPMS * 1000) / elapsedFrame;
    const deltaTime = (1 / fps) * ticker.speed;
    self.second += deltaTime;
    if (self.second > self.duration) {
      self.second = self.duration;
      self._tickUpdate(self.second, deltaTime);
      self._tickStop();
    } else {
      self._tickUpdate(self.second, deltaTime);
    }
  };
  self._sortTweens = () => {
    self.tweens.sort((lhs, rhs) => {
      if (lhs.position > rhs.position) return 1;
      else if (lhs.position < rhs.position) return -1;
      else return 0;
    });
  };
  self._tickUpdate = (tweenPosition, deltaTime) => {
    self.tweens.forEach((tween) => {
      tween.execute(tweenPosition, deltaTime);
    });
  };
  self._tickPlay = () => {
    if (!self.isPlaying) {
      self.isPlaying = true;
      ticker.add(self._tick);
      self._setActiveTweens(false);
    }
  };
  self._tickStop = () => {
    if (self.isPlaying) {
      self.isPlaying = false;
      ticker.remove(self._tick);
    }
  };
  self._setActiveTweens = (isActive) => {
    self.tweens.forEach((tween) => {
      tween.isActive = isActive;
    });
  };
  return self;
}

function Tween(duration, tween) {
  const self = this;
  self.position = null;
  self.duration = duration;
  self.tween = tween;
  self.isActive = false;
  self.execute = (position, deltaTime) => {
    const tweenPosition = inverseLerp(
      self.position,
      self.position + self.duration,
      position
    );
    if (!self.isActive && inRatio01(tweenPosition)) {
      self.isActive = true;
      self.tween(0, deltaTime);
    }
    if (self.isActive) {
      self.tween(clamp01(tweenPosition), deltaTime);
      if (!inRatio01(tweenPosition)) self.isActive = false;
    }
  };
  return self;
}

function inverseLerp(a, b, value) {
  if (a === b) return 0;
  else return (value - a) / (b - a);
}

function clamp01(value) {
  if (value < 0) return 0;
  if (value > 1) return 1;
  return value;
}

function inRatio01(value) {
  return value >= 0 && value <= 1;
}

export { Tweener, Tween };
