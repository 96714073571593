import { useEffect, useState } from "react";
import useSound from "./useSound";

const useSoundButton = (audioRef, src, autoplay) => {
  const [isOn, setIsOn] = useState(autoplay);
  const [volume, setVolume] = useState(1.0);
  const [isReady, howlRef] = useSound(
    audioRef,
    {
      src,
      loop: false,
      sprite: {
        major: [0, 500],
        toggle: [1000, 500],
        minor: [2000, 500],
        tick: [3000, 500],
        victory: [4000, 8000],
        defeat: [12000, 5000],
        explanation: [18000, 2000],
      },
    },
    "ui"
  );

  useEffect(() => {
    if (isReady) {
      console.log("howlRef.current", howlRef.current);
    }
  }, [audioRef, howlRef, isReady]);
  useEffect(() => {
    if (audioRef.current) audioRef.current.volume("ui", volume);
  }, [audioRef, volume]);
  return { isReady, isOn, setIsOn, volume, setVolume, howlRef };
};

export default useSoundButton;
