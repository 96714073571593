import {
  memo,
  useEffect,
  useState,
  useContext,
  useRef,
  useCallback,
} from "react";
import { Box, GoButton, TextTypo } from "Game/Style/GameStyle";
import YoutubePlayer from "Game/Component/Media/YoutubePlayer";
import AudioPlayer from "Game/Component/Media/AudioPlayer";
import styled from "styled-components";
import Modal from "antd/lib/modal";
import { useVonderGoContext } from "Service/VonderGOContext";
import { AppContext } from "Game/AppContext";

export default memo(function MediaModal({ visible, setVisible }) {
  const [time, setTime] = useState(null);
  // const [prevVol, setPrevVol] = useState(1);
  const {
    showMediaModal,
    currentQuestionIndex,
    currentGameData,
    gameContextState,
  } = useVonderGoContext();
  const { setSoundVolume } = useContext(AppContext);
  const prevVol = useRef(1);
  const didMount = useRef(false);

  const functionEffect = useCallback(() => {
    if (showMediaModal) {
      setSoundVolume((prev) => {
        prevVol.current = prev;
        return 0;
      });
    } else {
      prevVol.current && setSoundVolume(prevVol.current);
    }
  }, [setSoundVolume, showMediaModal, prevVol]);

  useEffect(() => {
    if (didMount.current) functionEffect();
    else didMount.current = true;
  }, [functionEffect]);

  const videoIndex = () => {
    if (currentGameData?.phase === "explanation") {
      return gameContextState?.allExplanation[currentQuestionIndex]?.video;
    } else {
      return currentGameData?.video;
    }
  };

  const audioIndex = () => {
    if (currentGameData?.phase === "explanation") {
      return gameContextState?.allExplanation[currentQuestionIndex]?.soundURL;
    } else {
      return currentGameData?.soundURL;
    }
  };

  useEffect(() => {
    if (currentGameData) {
      setTime(currentGameData?.time);
    }
  }, [currentGameData]);

  useEffect(() => {
    const countTime = setInterval(() => setTime((prev) => prev - 1), 1000);
    return () => clearInterval(countTime);
  }, [time]);

  return (
    <ModalCustom
      centered
      visible={visible}
      footer={null}
      closable={false}
      onCancel={() => {
        setVisible(false);
      }}
      destroyOnClose={true}
    >
      <Box direction="column">
        <Box direction="row-reverse" margin="0 0 2% 0" justify="space-between">
          <GoButton
            type="primary"
            size="small"
            onClick={() => {
              setVisible(false);
            }}
          >
            <TextTypo size="1.3vw" strong="true" type="white">
              Close
            </TextTypo>
          </GoButton>
          {time > 0 && (
            <Box align="center">
              <TextTypo size="1.3vw" strong="true">
                Game Time :
              </TextTypo>
              <TextTypo size="1.3vw" strong="true" type="primary">
                {time} Seconds
              </TextTypo>
            </Box>
          )}
        </Box>
        {videoIndex() ? (
          <YoutubePlayer playable width="100%" youtubeID={videoIndex()} />
        ) : (
          <AudioPlayer audioSrc={audioIndex()} />
        )}
      </Box>
    </ModalCustom>
  );
});

const ModalCustom = styled(Modal)`
  &.ant-modal {
    width: 60vw !important;
  }
  .ant-modal-content {
    border-radius: var(--border-radius);
    width: 60vw;
    @media only screen and (max-width: 850px) {
      border-radius: 3px;
    }
  }
  .ant-modal-body {
    padding: 2%;
  }
`;
