const UtilsCalculate = {
  clipInput: (k, arr) => {
    if (k < 0) k = 0;
    if (k > arr.length - 1) k = arr.length - 1;
    return arr[k];
  },
  getTangent: (k, factor, array) => {
    return (
      (factor *
        (UtilsCalculate.clipInput(k + 1, array) -
          UtilsCalculate.clipInput(k - 1, array))) /
      2
    );
  },
  cubicInterpolation: (array, t, tangentFactor) => {
    if (tangentFactor == null) tangentFactor = 1;

    const k = Math.floor(t);
    const m = [
      UtilsCalculate.getTangent(k, tangentFactor, array),
      UtilsCalculate.getTangent(k + 1, tangentFactor, array),
    ];
    const p = [
      UtilsCalculate.clipInput(k, array),
      UtilsCalculate.clipInput(k + 1, array),
    ];
    t -= k;
    const t2 = t * t;
    const t3 = t * t2;
    return (
      (2 * t3 - 3 * t2 + 1) * p[0] +
      (t3 - 2 * t2 + t) * m[0] +
      (-2 * t3 + 3 * t2) * p[1] +
      (t3 - t2) * m[1]
    );
  },
};

export default UtilsCalculate;
