import * as PIXI from "pixi.js";
import PIXISpine from "Plugins/pixi-spine";

import ShakaParticle from "Game/PixiCores/Particles/Shaka/ShakaParticle";
import { Tween, Tweener } from "Classes/Tween";
import * as Sentry from "@sentry/react";

const character = {
  controller: {},
  scale: 0.25,
  sequence: {
    idle: [{ name: "Idle", loop: true }],
    move: [{ name: "Move", loop: true }],
    attack: [
      { name: "Attack", loop: false },
      { name: "Idle", loop: true, delay: 0 },
    ],
    hit: [
      { name: "Hit", loop: false, delay: 0 },
      { name: "Idle", loop: true, delay: 0 },
    ],
    win: [
      { name: "Win", loop: false },
      { name: "Idle", loop: true, delay: 0 },
    ],
    lose: [{ name: "Lose", loop: false }],
  },
  event: {
    Attack: () => {
      //   character.controller.setAttackTo(character.controller.targetPosition);
    },
    AttackFinal: () => {
      //   character.controller.setFinalAttackTo(
      //     character.controller.targetPosition
      //   );
    },
  },
  eventListener: {
    event: (entry, { data }) => {
      const { name } = data;
      character.event[name]?.();
    },
  },
  playSequence: (spine, sequenceData) => {
    if (!sequenceData || sequenceData.length === 0) return;
    spine.state.setAnimation(0, sequenceData[0].name, sequenceData[0].loop);
    for (let i = 1; i < sequenceData.length; i++)
      spine.state.addAnimation(
        0,
        sequenceData[i].name,
        sequenceData[i].loop,
        sequenceData[i].delay
      );
  },
  create: async (app, audioRef, path) => {
    return new Promise((resolve, reject) => {
      let promises = [
        new Promise((resolve, reject) => {
          const preload = new PIXI.Loader();
          preload.add("character", path);
          preload.load(async (loader, resources) => {
            let spineData = resources["character"].spineData;
            // console.log(spineData);
            if (spineData) {
              let spine = new PIXISpine.Spine(spineData);
              spine.scale.set(character.scale);
              spine.state.addListener(character.eventListener);

              let container = new PIXI.Container();
              let containerParticle = new PIXI.Container();
              let armyParticle = new PIXI.Container();

              let containerBulletGenerate = new PIXI.Container();

              let emitterController;

              container.addChild(spine);
              container.addChild(containerParticle);
              container.addChild(armyParticle);
              container.addChild(containerBulletGenerate);
              containerParticle.addChild(containerBulletGenerate);

              await ShakaParticle.load({
                app,
                armyParticle,
                containerBulletGenerate,
              }).then((particle) => {
                emitterController = particle;
              });

              const controller = {
                spine,
                container,
                containerParticle,
                armyParticle,
                containerBulletGenerate,
                emitterController,
                targetPosition: {
                  x: 0,
                  y: 0,
                },
                moveSpeed: 100,
                isMoving: false,
                infoCharacter: {
                  characterName: "Shaka",
                },
                setActive: (isActive) => {
                  container.visible = isActive;
                },
                setTargetPosition: (x, y) => {
                  character.controller.targetPosition = {
                    x: x,
                    y: y,
                  };
                },
                setParticleActive: (isActive) => {},
                attackPlayer: async (self, allTargetPlayer) => {
                  allTargetPlayer.forEach((player, index) => {
                    let bulletController =
                      emitterController.allBullet[index].controller;
                    let targetPosition = {
                      x:
                        player.container.position.x - self.container.position.x,
                      y: player.container.position.y,
                    };
                    console.log("bulletController", bulletController);
                    bulletController.container.visible = true;
                    bulletController.rhino.position.set(600, 0);
                    bulletController.smoke.visible = false;
                    new Tweener(app.ticker)
                      .insert(
                        0.833,
                        new Tween(1.5, (pos, dt) => {
                          if (pos === 0) {
                            if (audioRef.current._group["BOS-005"]) {
                              audioRef.current._group["BOS-005"]._list[0].play(
                                "talk"
                              );
                              audioRef.current._group["BOS-005"]._list[0].play(
                                "running"
                              );
                            }
                            bulletController.container.visible = true;
                            bulletController.rhino.visible = true;
                            bulletController.rhino.alpha = 1;
                            bulletController.rhino.play();
                          }
                          if (pos === 1) {
                            if (audioRef.current._group["BOS-002"]) {
                              // audioRef.current._group["BOS-002"]._list[0].play(
                              //   "arrowHit"
                              // );
                            }
                            // bulletController.container.visible = false;
                            // bulletController.rhino.stop();
                          } else {
                            bulletController.rhino.position.x =
                              600 + (targetPosition.x - 600) * pos;
                          }
                        })
                      )
                      .insert(
                        2.3,
                        new Tween(0.3, (pos, dt) => {
                          if (pos === 0) {
                          } else if (pos === 1) {
                            bulletController.rhino.stop();
                            bulletController.rhino.visible = false;
                            bulletController.rhino.alpha = 0;
                          } else {
                            bulletController.rhino.alpha = 1 - pos;
                          }
                        })
                      )
                      .insert(
                        2.3,
                        new Tween(0.5, (pos, dt) => {
                          if (pos === 0) {
                            bulletController.smoke.position.x =
                              targetPosition.x;
                            bulletController.smoke.visible = true;
                            bulletController.smoke.alpha = 1;
                            bulletController.smoke.scale.set(0);
                          } else if (pos === 1) {
                            bulletController.container.visible = false;
                            bulletController.smoke.visible = false;
                            bulletController.smoke.alpha = 0;
                            bulletController.smoke.scale.set(1 / 2);
                          } else {
                            bulletController.smoke.alpha = 1 - pos;
                            bulletController.smoke.scale.set(pos / 2);
                          }
                        })
                      )
                      .play();
                  });
                  if (audioRef.current._group["BOS-002"]) {
                  }
                  await character.controller.routineWait(2.3).then(() => {
                    console.log("particle hit");
                    if (audioRef.current._group["BOS-005"]) {
                      audioRef.current._group["BOS-005"]._list[0].play(
                        "hitTarget"
                      );
                    }
                  });
                },
                finalAttack: async (self, allTargetPlayer) => {
                  console.log("final attack from boss");
                  let tweener = new Tweener(app.ticker);
                  let initialTime = 0.8;
                  let runningDuration = 3;

                  emitterController.allArmy.forEach((armyContainer, index) => {
                    console.log("armyContainer", armyContainer);
                    armyContainer.controller.container.visible = true;
                    armyContainer.controller.rhino.position.set(600, 0);
                    armyContainer.controller.smoke.visible = false;

                    // bulletController.container.visible = true;
                    // bulletController.rhino.position.set(600, 0);
                    // spike.position.x = -120 * (index + 1) - 120;
                    // let iceSpikeController = spike.controller;
                    // console.log("iceSpikeController", iceSpikeController);
                    // if (index % 2 === 1) {
                    //   iceSpikeController.SpikeImpactDust.container.scale.x = -1;
                    //   iceSpikeController.SpikeImpactParticle.container.scale =
                    //     -1;
                    // }
                    tweener.insert(
                      initialTime + index * 0.2,
                      new Tween(runningDuration, (pos, dt) => {
                        if (pos === 0) {
                          armyContainer.controller.container.visible = true;
                          armyContainer.controller.rhino.play();
                        }
                        if (pos === 1) {
                          armyContainer.controller.container.visible = false;
                          armyContainer.controller.rhino.stop();
                        } else {
                          armyContainer.controller.container.position.x =
                            -2500 * pos;
                        }
                      })
                    );
                  });

                  tweener.play();
                  if (audioRef.current._group["BOS-005"]) {
                    setTimeout(() => {
                      audioRef.current._group["BOS-005"]._list[0].play(
                        "command"
                      );
                      audioRef.current._group["BOS-005"]._list[0].play(
                        "finalRunning"
                      );
                    }, 800);
                  }

                  await character.controller.routineWait(4).then(() => {
                    console.log("particle hit");
                  });
                },
                playState: (stateName) => {
                  if (character.sequence[stateName])
                    character.playSequence(
                      controller.spine,
                      character.sequence[stateName]
                    );
                  if (stateName === "lose") {
                    if (audioRef.current._group["BOS-005"]) {
                      audioRef.current._group["BOS-005"]._list[0].play("dead");
                    }
                  }
                },
                destroy: () => {
                  controller.spine.destroy();
                  controller.container?.destroy();
                  controller.containerParticle?.destroy();
                },
                talk: () => {
                  if (audioRef.current._group["BOS-005"])
                    audioRef.current._group["BOS-005"]._list[0].play("talk");
                },
                routineWait: async (second) => {
                  await new Promise((resolve) => {
                    const ticker = app.ticker;
                    let current = 0;
                    const onUpdate = (elapsedTime) => {
                      const deltaTime = (1 / ticker.FPS) * ticker.speed;
                      current += deltaTime;
                      if (current > second) {
                        ticker.remove(onUpdate);
                        resolve();
                      }
                    };
                    ticker.add(onUpdate);
                  });
                },
              };
              character.controller = controller;
              resolve(controller);
            } else {
              reject({ error: "NO_SPINE_DATA_SHAKA" });
            }
          });
        }),
      ];
      Promise.all(promises)
        .then((results) => {
          const [character] = results;
          resolve(character);
        })
        .catch((e) => {
          Sentry.captureException(e);
          reject(e);
        });
    });
  },
};

export default character;
