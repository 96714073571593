import * as PIXI from "pixi.js";
import PIXISpine from "Plugins/pixi-spine";

import YamatoParticle from "Game/PixiCores/Particles/Yamato/YamatoParticle";
import { Tween, Tweener } from "Classes/Tween";
import * as Sentry from "@sentry/react";

let character = {
  controller: {},
  scale: 0.11,
  sequence: {
    idle: [
      { name: "Idle", loop: true },
      { name: "Idle", loop: true, delay: 0.25 },
    ],
    move: [{ name: "Move", loop: true }],
    charge: [
      { name: "Charge", loop: false },
      { name: "Charging Loop", loop: true, delay: 0 },
    ],
    attack: [
      { name: "Attack", loop: false },
      { name: "Idle", loop: true, delay: 0 },
    ],
    attackOnce: [
      { name: "Idle", loop: true },
      { name: "Charge", loop: false, delay: 0.6 },
      { name: "Attack", loop: false, delay: 0 },
      { name: "Idle", loop: true, delay: 0 },
    ],
    hit: [
      { name: "Idle", loop: true },
      { name: "Hit", loop: false, delay: 0.05 },
      { name: "Idle", loop: true, delay: 0 },
    ],
    win: [{ name: "Win", loop: true }],
    lose: [{ name: "Lose", loop: false }],
  },
  event: {
    Attack: () => {},
  },
  eventListener: {
    event: (entry, { data }) => {
      const { name } = data;
      character.event[name]?.();
    },
  },
  playSequence: (spine, sequenceData) => {
    if (!sequenceData || sequenceData.length === 0) return;
    spine.state.setAnimation(0, sequenceData[0].name, sequenceData[0].loop);
    for (let i = 1; i < sequenceData.length; i++)
      spine.state.addAnimation(
        0,
        sequenceData[i].name,
        sequenceData[i].loop,
        sequenceData[i].delay
      );
  },
  initial: async (app, path) => {
    return new Promise((resolve, reject) => {
      const preload = new PIXI.Loader();
      preload.add("character_VND_010", path);
      preload.load(async (loader, resources) => {
        if (app.loader.resources) {
          app.loader.resources.character_VND_010 = resources;
        }
      });
      preload.onComplete.add(async () => {
        await YamatoParticle.initial(app);
        resolve("load Yamato complete");
      });
      preload.onError.add((e) => {
        reject(e);
      });
    });
  },
  create: async (app, audioRef, path, preload) => {
    return new Promise((resolve, reject) => {
      let promises = [
        new Promise((resolve, reject) => {
          // const preload = new PIXI.Loader();
          if (!preload.resources.character_VND_010) {
            preload.add("character_VND_010", path);
          }
          preload.load(async (loader, resources) => {
            let spineData =
              resources["character_VND_010"].character_VND_010.spineData;
            if (spineData) {
              let spine = new PIXISpine.Spine(spineData);
              console.log(spine);
              spine.scale.set(character.scale);

              spine.state.addListener({
                event: function (entry, event) {
                  if (event.data.name === "FireworkStart") {
                    controller.setFinisher();
                  }
                },
              });

              let container = new PIXI.Container();
              let containerFirework = new PIXI.Container();
              let containerFireworkParticle = new PIXI.Container();
              let containerBullet = new PIXI.Container();
              let containerBlast = new PIXI.Container();
              let emitterController;

              container.addChild(spine);
              container.addChild(containerFirework);
              container.addChild(containerFireworkParticle);
              container.addChild(containerBullet);
              container.addChild(containerBlast);

              await YamatoParticle.load(
                app,
                containerFirework,
                containerFireworkParticle,
                containerBullet,
                containerBlast
              ).then((particle) => {
                emitterController = particle;
              });

              const controller = {
                spine,
                container,
                infoCharacter: {
                  characterName: "yamato",
                },
                targetPosition: {
                  x: 0,
                  y: 0,
                },
                moveSpeed: 100,
                isMoving: false,
                setActive: (isActive) => {
                  container.visible = isActive;
                },
                setTargetPosition: (x, y) => {
                  character.controller.targetPosition = {
                    x: x,
                    y: y,
                  };
                },
                setParticleActive: (isActive) => {},
                charging: () => {},
                setFiredBulletTo: async (targetPosition) => {
                  const delay = 0.65;
                  const startAtX = 110;
                  containerBullet.position.set(startAtX, 0);
                  containerBlast.position.set(
                    targetPosition.x,
                    targetPosition.y - 30
                  );

                  new Tweener(app.ticker)
                    .insert(
                      0.0 + delay,
                      new Tween(0.4, (pos, dt) => {
                        if (pos === 0) {
                          if (audioRef.current._group["VND-010"]) {
                            audioRef.current._group["VND-010"]._list[0].play(
                              "attack"
                            );
                          }
                        }
                        if (pos === 1) {
                          emitterController.Fireball.container.visible = false;
                        } else {
                          emitterController.Fireball.container.visible = true;
                          emitterController.Fireball.emitter.emit = true;
                          emitterController.Fireball.emitter.update(dt);
                          emitterController.Fireball.container.position.x =
                            pos * (targetPosition.x - startAtX);
                          emitterController.Fireball.container.position.y =
                            -125;
                          emitterController.Fireball.container.scale.set(
                            0.8 + pos ** 1.5 * 1
                          );
                        }
                      })
                    )
                    .insert(
                      0.35 + delay,
                      new Tween(0.5, (pos, dt) => {
                        if (pos === 0) {
                          if (audioRef.current._group["VND-010"]) {
                            audioRef.current._group["VND-010"]._list[0].play(
                              "hitTarget"
                            );
                          }
                        }
                        if (pos === 1) {
                          emitterController.Blast1.container.visible = false;
                          emitterController.Blast2.container.visible = false;
                        } else {
                          emitterController.Blast1.container.visible = true;
                          emitterController.Blast1.emitter.emit = true;
                          emitterController.Blast1.emitter.update(dt);
                          emitterController.Blast1.container.scale.set(
                            1 + pos ** (1 / 6) * 2
                          );
                          emitterController.Blast1.container.alpha =
                            1 - pos ** 2;

                          emitterController.Blast2.container.visible = true;
                          emitterController.Blast2.emitter.emit = true;
                          emitterController.Blast2.emitter.update(dt);
                          emitterController.Blast2.container.scale.set(
                            0 + pos ** (1 / 2) * 4
                          );
                          emitterController.Blast2.container.alpha =
                            1 - pos ** 6;
                        }
                      })
                    )
                    .play();
                  await character.controller.routineWait(1).then(() => {
                    console.log("particle hit");
                  });

                  // console.log(emitterController)
                },
                setFinisher: async () => {
                  // const delay = 1.8;
                  const heightOfFirework = 80;
                  const startOfFirework = -170;
                  const xOfFirework = spine.skeleton.scaleX === 1 ? 12 : -12;

                  containerFireworkParticle.position.set(
                    xOfFirework,
                    startOfFirework - heightOfFirework - 50
                  );
                  containerFirework.position.set(xOfFirework, 0);

                  console.log("setFinisher work");

                  new Tweener(app.ticker)
                    .insert(
                      0.0,
                      new Tween(0.3, (pos, dt) => {
                        if (pos === 0) {
                          if (audioRef.current._group["VND-010"]) {
                            audioRef.current._group["VND-010"]._list[0].play(
                              "fireworkLaunch"
                            );
                          }
                        }
                        if (pos === 1) {
                          emitterController.Fireworkstart.container.visible = false;
                        } else {
                          emitterController.Fireworkstart.container.visible = true;
                          emitterController.Fireworkstart.emitter.emit = true;
                          emitterController.Fireworkstart.emitter.update(dt);
                          emitterController.Fireworkstart.container.position.y =
                            startOfFirework - pos * heightOfFirework;
                          emitterController.Fireworkstart.container.scale.set(
                            0.8 + pos ** 1.5 * 1
                          );
                        }
                      })
                    )
                    .insert(
                      0.25,
                      new Tween(0.75, (pos, dt) => {
                        if (pos === 0) {
                          if (audioRef.current._group["VND-010"]) {
                            audioRef.current._group["VND-010"]._list[0].play(
                              "fireworkExplode"
                            );
                          }
                        }
                        if (pos === 1) {
                          emitterController.Firework1.container.visible = false;
                          emitterController.Firework2.container.visible = false;
                        } else {
                          emitterController.Firework1.container.visible = true;
                          emitterController.Firework1.emitter.emit = true;
                          emitterController.Firework1.emitter.update(dt);
                          emitterController.Firework1.container.scale.set(
                            0 + pos ** (1 / 2) * 4
                          );
                          emitterController.Firework1.container.alpha =
                            1 - pos ** 2;

                          emitterController.Firework2.container.visible = true;
                          emitterController.Firework2.emitter.emit = true;
                          emitterController.Firework2.emitter.update(dt);
                          emitterController.Firework2.container.scale.set(
                            0 + pos ** (1 / 4) * 4
                          );
                          emitterController.Firework2.container.alpha =
                            1 - pos ** 4;
                        }
                      })
                    )
                    .play();
                  await character.controller.routineWait(3);
                },
                hurt: () => {},
                playState: (stateName) => {
                  if (character.sequence[stateName]) {
                    character.playSequence(
                      controller.spine,
                      character.sequence[stateName]
                    );
                  }
                  if (stateName === "charge") {
                    if (audioRef.current._group["VND-010"]) {
                      audioRef.current._group["VND-010"]._list[0].play("pour");
                    }
                    if (audioRef.current._group["VND-010"]) {
                      setTimeout(() => {
                        audioRef.current._group["VND-010"]._list[0].play(
                          "sipping"
                        );
                      }, 800);
                    }
                  }
                  if (stateName === "win") {
                    if (audioRef.current._group["VND-010"]) {
                      setTimeout(() => {
                        audioRef.current._group["VND-010"]._list[0].play(
                          "sipping"
                        );
                      }, 500);
                    }
                  }
                  if (stateName === "lose") {
                    if (audioRef.current._group["VND-010"]) {
                      audioRef.current._group["VND-010"]._list[0].play("dead");
                    }
                    if (audioRef.current._group["VND-010"]) {
                      setTimeout(() => {
                        audioRef.current._group["VND-010"]._list[0].play(
                          "afterDead"
                        );
                      }, 750);
                    }
                  }
                },
                destroy: () => {
                  controller.spine.destroy();
                  controller.container?.destroy();
                  controller.containerFirework?.destroy();
                  controller.containerFireworkParticle?.destroy();
                  controller.containerBullet?.destroy();
                  controller.containerBlast?.destroy();
                },
                routineWait: async (second) => {
                  await new Promise((resolve) => {
                    const ticker = app.ticker;
                    let current = 0;
                    const onUpdate = (elapsedTime) => {
                      const deltaTime = (1 / ticker.FPS) * ticker.speed;
                      current += deltaTime;
                      if (current > second) {
                        ticker.remove(onUpdate);
                        resolve();
                      }
                    };
                    ticker.add(onUpdate);
                  });
                },
              };
              character.controller = controller;
              resolve(controller);
            } else {
              reject({ error: "NO_SPINE_DATA_YAMATO" });
            }
          });
        }),
      ];
      Promise.all(promises)
        .then((results) => {
          const [character] = results;
          resolve(character);
        })
        .catch((e) => {
          Sentry.captureException(e);
          reject(e);
        });
    });
  },
};

export default character;
