import {
  lazy,
  memo,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import styled from "styled-components";
import icon_exit from "Game/Assets/exit.svg";
import {
  TextTypo,
  ProgressBox,
  FullRow,
  FlexCol,
  Box,
  GoIcon,
  IconBox,
  ProgressBar,
  Circle,
  SoundSlider,
} from "Game/Style/GameStyle";
import { useVonderGoContext } from "Service/VonderGOContext";
import { useLocalizationContext } from "Service/LocalizationProvider";
import Grid from "antd/lib/grid";
import { AppContext } from "Game/AppContext";
import useCalculateProgressBar from "Hooks/useCalculateProgressBar";
import icon_music from "Game/Assets/music_on.svg";
import icon_music_off from "Game/Assets/music_off.svg";
import icon_language from "Game/Assets/change_language.svg";
import Popover from "antd/lib/popover";

const ExitGameModal = lazy(() => import("Game/Component/Common/ExitGameModal"));

const { useBreakpoint } = Grid;

const languageList = [
  { context: "ภาษาไทย", value: "th" },
  { context: "English", value: "en" },
];

export default memo(function Topbar({
  isLobby,
  height,
  showLeaderboard,
  userInLobby,
}) {
  const [exitVisible, setExitVisible] = useState(false);
  const {
    currentGameData,
    currentGameState,
    pin,
    animationState,
    dispatchAnimationState,
    challengeContext,
    playSFX,
  } = useVonderGoContext();
  const screens = useBreakpoint();
  const ipadPro = !screens?.xs && !screens?.xxl;
  const ipad = !screens?.xl && !screens?.xs && !screens?.xxl;
  const { t, changeLanguage, currentLang } = useLocalizationContext();
  const { castleIcon, bossIcon, soundVolume, setSoundVolume } =
    useContext(AppContext);
  const {
    prevValue,
    progressValue,
    findpercent,
    currentValue,
    currentQuestion,
    passingRate,
  } = useCalculateProgressBar();

  //#region check progress bar visible
  useEffect(() => {
    if (animationState?.progressVisible) {
      dispatchAnimationState({ type: "slide_progress", payload: true });
    } else {
      dispatchAnimationState({ type: "slide_progress", payload: false });
    }
  }, [animationState.progressVisible, dispatchAnimationState]);
  //#endregion

  //#region check question number
  const showQuestionNumber = useMemo(() => {
    if (currentGameState?.stanby) {
      return (
        <TextTypo size="2vw" strong="true">
          {currentGameData?.totalQuestion}
        </TextTypo>
      );
    } else {
      return (
        <TextTypo size="2vw" strong="true">
          {currentGameData?.questionIndex || currentGameData?.totalQuestion}{" "}
          /&nbsp;
          {currentGameData?.totalQuestion}
        </TextTypo>
      );
    }
  }, [currentGameData, currentGameState]);
  //#endregion

  //#region onChange sound volumn
  const onChangesoundVolume = useCallback(
    (value) => {
      setSoundVolume(value);
      localStorage.setItem("soundVolume", value);
    },
    [setSoundVolume]
  );
  //#endregion

  //#region function select language
  const onSelectLanguage = useCallback(
    ({ value }) => {
      playSFX("major");
      if (value === "th") {
        changeLanguage(value);
        dispatchAnimationState({ type: "show_language", payload: false });
      }
      if (value === "en") {
        changeLanguage(value);
        dispatchAnimationState({ type: "show_language", payload: false });
      }
    },
    [changeLanguage, dispatchAnimationState, playSFX]
  );
  //#endregion

  //#region sound volumn slider
  const slidesoundVolume = useMemo(
    () => (
      <SoundSlider
        min={0}
        max={1}
        onChange={onChangesoundVolume}
        value={soundVolume ? soundVolume : 0}
        step={0.1}
        vertical
        tooltipVisible={false}
      />
    ),
    [onChangesoundVolume, soundVolume]
  );
  //#endregion

  //#region language list
  const languageMenu = useMemo(
    () => (
      <>
        {languageList?.map((item, index) => (
          <TextTypo
            size="1vw"
            strong="true"
            type={item?.value === currentLang ? "primary" : "secondary"}
            style={{ margin: "5%", cursor: "pointer" }}
            key={index}
            onClick={() => onSelectLanguage({ value: item?.value })}
          >
            {item?.context}
          </TextTypo>
        ))}
      </>
    ),
    [currentLang, onSelectLanguage]
  );
  //#endregion

  //#region left colum component
  const leftColumn = useMemo(() => {
    if (!isLobby && !showLeaderboard) {
      return (
        <TextTypo size="1.5vw" strong="true">
          Enter PIN : {pin}
        </TextTypo>
      );
    }
  }, [isLobby, showLeaderboard, pin]);
  //#endregion

  //#region center column component
  const centerColumn = useMemo(() => {
    if (currentGameState?.stanby && currentQuestion !== 1) {
      return null;
    }
    if (isLobby || showLeaderboard) {
      return (
        <TextTypo size="1.5vw" strong="true">
          {challengeContext || "Challenge Name"}
        </TextTypo>
      );
    } else {
      return (
        <ProgressBox visible={animationState?.progressVisible}>
          <FullRow>
            <FlexCol span={6} align="center" justify="flex-end">
              <ProgressIcon src={castleIcon} shape="circle" size="4vw" />
            </FlexCol>
            <FlexCol span={12} align="center" justify="center">
              <FullRow>
                <FlexCol span={24} justify="center" align="center">
                  <Box direction="column" padding="2% 0 0 0">
                    <TextTypo size="1vw" strong="true" type="secondary">
                      {t("GAMEPLAY.QUESTION")}
                    </TextTypo>
                    {showQuestionNumber}
                  </Box>
                </FlexCol>
                <FlexCol span={24} justify="center" align="center">
                  <ProgressBar
                    percent={100}
                    success={{
                      percent: progressValue || 0,
                    }}
                    showInfo={false}
                    progress={progressValue || 0}
                    prevposition={
                      currentQuestion >= 2 ? prevValue[currentQuestion - 2] : 50
                    }
                    play={animationState?.progressSlide?.toString()}
                  />
                  <Circle
                    progress={findpercent(passingRate, currentValue) - 5}
                    prevPosition={
                      currentQuestion >= 2 ? prevValue[currentQuestion - 2] : 50
                    }
                    play={animationState?.progressSlide}
                  />
                </FlexCol>
              </FullRow>
            </FlexCol>
            <FlexCol span={6} align="center" justify="flex-start">
              <ProgressIcon src={bossIcon} shape="circle" size="4vw" />
            </FlexCol>
          </FullRow>
        </ProgressBox>
      );
    }
  }, [
    currentGameState.stanby,
    currentQuestion,
    isLobby,
    showLeaderboard,
    challengeContext,
    animationState.progressVisible,
    animationState.progressSlide,
    castleIcon,
    t,
    showQuestionNumber,
    progressValue,
    prevValue,
    findpercent,
    passingRate,
    currentValue,
    bossIcon,
  ]);
  //#endregion

  //#region right column component
  const rightColumn = useMemo(
    () => (
      <>
        <Popover
          id="language"
          content={languageMenu}
          trigger="click"
          overlayStyle={{ top: (ipadPro || ipad) && "130px" }}
        >
          <IconBox
            style={{ marginRight: "3%" }}
            onClick={() => {
              playSFX("major");
              dispatchAnimationState({
                type: "show_language",
                payload: false,
              });
            }}
          >
            <GoIcon src={icon_language} size="100%" shape="square" />
          </IconBox>
        </Popover>
        <Popover
          id="music"
          content={slidesoundVolume}
          trigger="click"
          overlayStyle={{ top: (ipadPro || ipad) && "130px" }}
        >
          <IconBox
            style={{ marginRight: "3%" }}
            onClick={() => {
              dispatchAnimationState({
                type: "show_language",
                payload: false,
              });
            }}
          >
            <GoIcon
              src={soundVolume === 0 ? icon_music_off : icon_music}
              size="100%"
              shape="square"
            />
          </IconBox>
        </Popover>
        <IconBox
          onClick={() => {
            playSFX("major");
            setExitVisible(true);
          }}
        >
          <GoIcon src={icon_exit} size="100%" shape="square" />
        </IconBox>
      </>
    ),
    [
      dispatchAnimationState,
      ipad,
      ipadPro,
      playSFX,
      slidesoundVolume,
      soundVolume,
      languageMenu,
    ]
  );
  //#endregion

  return (
    <WhiteBar height={height}>
      <FullRow>
        <FlexCol span={6} justify="flex-start" align="center">
          {leftColumn}
        </FlexCol>
        <FlexCol span={12} justify="center" align="center">
          {centerColumn}
        </FlexCol>
        <FlexCol span={6} justify="flex-end" align="center">
          {rightColumn}
        </FlexCol>
      </FullRow>
      <ExitGameModal visible={exitVisible} setVisible={setExitVisible} />
    </WhiteBar>
  );
});

const WhiteBar = styled.div`
  background-color: white;
  padding: 0 24px;
  width: 100%;
  height: ${(props) => (props.height ? props.height : "7%")};
  box-shadow: 0px 1px 5px #00000029;
  position: relative;
  display: flex;
  z-index: 3;
`;
const ProgressIcon = styled(GoIcon)`
  border: 3px solid #cdd2d1;
  padding: 1%;
  @media only screen and (max-width: 850px) {
    border: 1px solid var(--silver-500);
  }
`;
