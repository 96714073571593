import * as PIXI from "pixi.js";
import * as Particles from "pixi-particles";

import LaserParticle_1 from "Game/Assets/ParticleSprite/Nesha_Laser_0000_Particle_1.png";
import LaserParticle_2 from "Game/Assets/ParticleSprite/Nesha_Laser_0001_Particle_2.png";
import LaserParticle_3 from "Game/Assets/ParticleSprite/Nesha_Laser_0002_Particle_3.png";
import LaserParticle_4 from "Game/Assets/ParticleSprite/Nesha_Laser_0003_Particle_4.png";
import LaserParticle_5 from "Game/Assets/ParticleSprite/Nesha_Laser_0004_Particle_5.png";
import LaserParticle_6 from "Game/Assets/ParticleSprite/Nesha_Laser_0005_Particle_6.png";
import LaserParticle_7 from "Game/Assets/ParticleSprite/Nesha_Laser_0006_Particle_7.png";
import Smoke from "Game/Assets/ParticleSprite/Nesha_Laser_0007_Smoke.png";
import Laser_1 from "Game/Assets/ParticleSprite/Nesha_Laser_0008_Laser_1.png";
import Laser_2 from "Game/Assets/ParticleSprite/Nesha_Laser_0009_Laser_2.png";
import Laser_3 from "Game/Assets/ParticleSprite/Nesha_Laser_0010_Laser_3.png";
import Laser_4 from "Game/Assets/ParticleSprite/Nesha_Laser_0011_Laser_4.png";
import Laser_5 from "Game/Assets/ParticleSprite/Nesha_Laser_0012_Laser_5.png";
import Laser_6 from "Game/Assets/ParticleSprite/Nesha_Laser_0013_Laser_6.png";
import Laser_7 from "Game/Assets/ParticleSprite/Nesha_Laser_0014_Laser_7.png";
import Laser_8 from "Game/Assets/ParticleSprite/Nesha_Laser_0015_Laser_8.png";
import Laser_9 from "Game/Assets/ParticleSprite/Nesha_Laser_0016_Laser_9.png";
import * as Sentry from "@sentry/react";

const NeshaParticle = {
  initial: async (app) => {
    return new Promise(async (resolve, reject) => {
      let particles = [
        "LaserParticle_1",
        "LaserParticle_2",
        "LaserParticle_3",
        "LaserParticle_4",
        "LaserParticle_5",
        "LaserParticle_6",
        "LaserParticle_7",
        "Laser_1",
        "Laser_2",
        "Laser_3",
        "Laser_4",
        "Laser_5",
        "Laser_6",
        "Laser_7",
        "Laser_8",
        "Laser_9",
        "Smoke",
      ];
      let promises = [];
      particles.forEach((item) => {
        promises.push(
          new Promise((resolve, reject) => {
            let path = "";
            switch (item) {
              case "LaserParticle_1":
                path = LaserParticle_1;
                break;
              case "LaserParticle_2":
                path = LaserParticle_2;
                break;
              case "LaserParticle_3":
                path = LaserParticle_3;
                break;
              case "LaserParticle_4":
                path = LaserParticle_4;
                break;
              case "LaserParticle_5":
                path = LaserParticle_5;
                break;
              case "LaserParticle_6":
                path = LaserParticle_6;
                break;
              case "LaserParticle_7":
                path = LaserParticle_7;
                break;
              case "Laser_1":
                path = Laser_1;
                break;
              case "Laser_2":
                path = Laser_2;
                break;
              case "Laser_3":
                path = Laser_3;
                break;
              case "Laser_4":
                path = Laser_4;
                break;
              case "Laser_5":
                path = Laser_5;
                break;
              case "Laser_6":
                path = Laser_6;
                break;
              case "Laser_7":
                path = Laser_7;
                break;
              case "Laser_8":
                path = Laser_8;
                break;
              case "Laser_9":
                path = Laser_9;
                break;
              case "Smoke":
                path = Smoke;
                break;

              default:
                break;
            }
            let loader = new PIXI.Loader();
            loader.add(item, path);
            loader.onError.add((e) => {
              console.log(e);
              reject(e);
            });
            loader.load((loader, resources) => {
              if (app.loader.resources) {
                app.loader.resources[item] = resources[item];
              }
            });
            loader.onComplete.add(() => {
              resolve();
            });
          })
        );
      });
      return await Promise.all(promises)
        .then(() => {
          resolve();
        })
        .catch((e) => {
          Sentry.captureException(e);
          reject();
        });
    });
  },
  createParticle: (loader, name) => {
    return new Promise((resolve, reject) => {
      import(`./${name}.json`)
        .then((json) => {
          if (json.images && json.data) {
            let path = "";
            switch (name) {
              case "LaserParticle_1":
                path = LaserParticle_1;
                break;
              case "LaserParticle_2":
                path = LaserParticle_2;
                break;
              case "LaserParticle_3":
                path = LaserParticle_3;
                break;
              case "LaserParticle_4":
                path = LaserParticle_4;
                break;
              case "LaserParticle_5":
                path = LaserParticle_5;
                break;
              case "LaserParticle_6":
                path = LaserParticle_6;
                break;
              case "LaserParticle_7":
                path = LaserParticle_7;
                break;
              case "Laser_1":
                path = Laser_1;
                break;
              case "Laser_2":
                path = Laser_2;
                break;
              case "Laser_3":
                path = Laser_3;
                break;
              case "Laser_4":
                path = Laser_4;
                break;
              case "Laser_5":
                path = Laser_5;
                break;
              case "Laser_6":
                path = Laser_6;
                break;
              case "Laser_7":
                path = Laser_7;
                break;
              case "Laser_8":
                path = Laser_8;
                break;
              case "Laser_9":
                path = Laser_9;
                break;
              case "Smoke":
                path = Smoke;
                break;

              default:
                break;
            }
            // const loader = new PIXI.Loader();
            if (!loader.resources[name]) {
              loader.add(name, path);
            }
            loader.onError.add((e) => {
              console.log(e);
              reject({ error: "CANNOT_LOAD_PARTICLE" });
            });
            loader.load((loader, resources) => {
              const textures = resources[name].texture;
              const container = new PIXI.Container();
              const emitter = new Particles.Emitter(
                container,
                textures,
                json.data
              );

              resolve({ container, emitter });
            });
          }
        })
        .catch((e) => {
          Sentry.captureException(e);
          console.error(e);
          reject({ error: "CANNOT_IMPORT_CLASS" });
        });
    });
  },

  load: (app, containerSmoke, containerLaser) => {
    return new Promise(async (resolve, reject) => {
      let allParticle = {};

      await NeshaParticle.createParticle(app.loader, "Laser_9").then(
        (Laser_9) => {
          containerLaser.addChild(Laser_9.container);
          allParticle = { ...allParticle, Laser_9 };
        }
      );
      await NeshaParticle.createParticle(app.loader, "Laser_1").then(
        (Laser_1) => {
          containerLaser.addChild(Laser_1.container);
          allParticle = { ...allParticle, Laser_1 };
        }
      );
      await NeshaParticle.createParticle(app.loader, "Laser_2").then(
        (Laser_2) => {
          containerLaser.addChild(Laser_2.container);
          allParticle = { ...allParticle, Laser_2 };
        }
      );
      await NeshaParticle.createParticle(app.loader, "Laser_3").then(
        (Laser_3) => {
          containerLaser.addChild(Laser_3.container);
          allParticle = { ...allParticle, Laser_3 };
        }
      );
      await NeshaParticle.createParticle(app.loader, "Laser_4").then(
        (Laser_4) => {
          containerLaser.addChild(Laser_4.container);
          allParticle = { ...allParticle, Laser_4 };
        }
      );
      await NeshaParticle.createParticle(app.loader, "Laser_5").then(
        (Laser_5) => {
          containerLaser.addChild(Laser_5.container);
          allParticle = { ...allParticle, Laser_5 };
        }
      );
      await NeshaParticle.createParticle(app.loader, "Laser_6").then(
        (Laser_6) => {
          containerLaser.addChild(Laser_6.container);
          allParticle = { ...allParticle, Laser_6 };
        }
      );
      await NeshaParticle.createParticle(app.loader, "Laser_7").then(
        (Laser_7) => {
          containerLaser.addChild(Laser_7.container);
          allParticle = { ...allParticle, Laser_7 };
        }
      );
      await NeshaParticle.createParticle(app.loader, "Laser_8").then(
        (Laser_8) => {
          containerLaser.addChild(Laser_8.container);
          allParticle = { ...allParticle, Laser_8 };
        }
      );
      await NeshaParticle.createParticle(app.loader, "Smoke").then((Smoke) => {
        containerSmoke.addChild(Smoke.container);
        allParticle = { ...allParticle, Smoke };
      });
      await NeshaParticle.createParticle(app.loader, "LaserParticle_1").then(
        (LaserParticle_1) => {
          containerLaser.addChild(LaserParticle_1.container);
          allParticle = { ...allParticle, LaserParticle_1 };
        }
      );
      await NeshaParticle.createParticle(app.loader, "LaserParticle_2").then(
        (LaserParticle_2) => {
          containerLaser.addChild(LaserParticle_2.container);
          allParticle = { ...allParticle, LaserParticle_2 };
        }
      );
      await NeshaParticle.createParticle(app.loader, "LaserParticle_3").then(
        (LaserParticle_3) => {
          containerLaser.addChild(LaserParticle_3.container);
          allParticle = { ...allParticle, LaserParticle_3 };
        }
      );
      await NeshaParticle.createParticle(app.loader, "LaserParticle_4").then(
        (LaserParticle_4) => {
          containerLaser.addChild(LaserParticle_4.container);
          allParticle = { ...allParticle, LaserParticle_4 };
        }
      );
      await NeshaParticle.createParticle(app.loader, "LaserParticle_5").then(
        (LaserParticle_5) => {
          containerLaser.addChild(LaserParticle_5.container);
          allParticle = { ...allParticle, LaserParticle_5 };
        }
      );
      await NeshaParticle.createParticle(app.loader, "LaserParticle_6").then(
        (LaserParticle_6) => {
          containerLaser.addChild(LaserParticle_6.container);
          allParticle = { ...allParticle, LaserParticle_6 };
        }
      );
      await NeshaParticle.createParticle(app.loader, "LaserParticle_7").then(
        (LaserParticle_7) => {
          containerLaser.addChild(LaserParticle_7.container);
          allParticle = { ...allParticle, LaserParticle_7 };
        }
      );

      resolve(allParticle);

      app.loader.onError.add((e) => {
        reject(e);
      });
    });
  },
};

export default NeshaParticle;
