import Vector2 from "Service/Vector2";
import { difference, intersection, shuffle, findIndex } from "lodash";

const moveEpsilon = 0.01;

class ScenarioManager {
  constructor(app, containerLevel, containerStage, options) {
    this._app = app;
    this._containerLevel = containerLevel;
    this._containerStage = containerStage;
    this._level = null;
    this._characterPlayers = [];
    this._characterEnemies = [];
    this._characterBoss = null;
    this._size = options?.size || 0;
    this._position = options?.position || 0;
    this._groundHeight = options?.groundHeight || 0;
    this._offsetScreen = options?.offsetScreen || 0;
    this._offsetCharacter = options?.offsetCharacter || 0;
    this._eventUpdate = [];
    this._allCharacterPlayer = [];
    this._playerAttackerList = [];
    this._playerDefenderList = [];
    this._enemiesAttackerList = [];
    this._enemiesDefenderList = [];
    this._characterEnemiesArmy = [];
  }
  get containerLevel() {
    return this._containerLevel;
  }
  get containerStage() {
    return this._containerStage;
  }
  get size() {
    return this._size;
  }
  set size(value) {
    this._size = value;
    this.updateSize();
  }
  get position() {
    return this._position;
  }
  set position(value) {
    this._position = value;
  }
  get offset() {
    return this._offsetScreen;
  }
  set offset(value) {
    this._offsetScreen = value;
  }
  initialize(level, player, enemies, boss, allCharacterList, stage) {
    let enemiesList = [...enemies];
    this.reset();
    this._level = level;
    this._characterPlayers = [...player];
    for (let index = 0; index < 3; index++) {
      this._characterEnemies = [...this._characterEnemies, enemiesList.shift()];
    }
    // this._characterEnemies = [...enemies];
    this._characterBoss = boss;
    this._allCharacterPlayer = [...allCharacterList];
    this._containerStage = stage;
    this._characterEnemiesArmy = [...enemiesList];

    this.updateSize();
  }
  reset() {
    if (this._level) {
      this._level.destroy();
    }
    this._position = 0;
    this._characterPlayers = [];
    this._characterEnemies = [];
    this._characterBoss = null;
    this._allCharacterPlayer = [];
    this._playerAttackerList = [];
    this._playerDefenderList = [];
    this._enemiesAttackerList = [];
    this._enemiesDefenderList = [];
    this._characterEnemiesArmy = [];
  }
  updateSize() {
    if (this._level) {
      this._level.setSize(this._size);
    }
  }
  updateRatio(width, height) {
    this._containerLevel.position.set(
      this._size / 2 + this._position,
      height / 2
    );
    this._containerStage.position.set(
      this._position,
      height - this._groundHeight
    );
  }
  update(deltaTime) {
    if (this._level) {
      this._level.setPosition(this._position);
      this._level.update();
    }
    this._containerStage.position.x = -this._position;
    this._eventUpdate.forEach((event) => event(deltaTime));
  }

  getNextPosition() {
    return this._level.areaLength + this._position;
  }
  getCharacterLeftPosition(position, index) {
    return position + this._offsetScreen + index * this._offsetCharacter;
  }
  getCharacterRightPosition(position, index) {
    return (
      position + this._size - this._offsetScreen - index * this._offsetCharacter
    );
  }
  removeCharacterFromList(playerID) {
    this._allCharacterPlayer = this._allCharacterPlayer.filter((character) => {
      console.log("romove character", playerID);
      return character.userID !== playerID;
    });
  }
  updateCharacterList(playerID, character) {
    console.log("before : this._allCharacterPlayer", this._allCharacterPlayer);
    let newCharacterIndex = findIndex(this._allCharacterPlayer, [
      "userID",
      playerID,
    ]);
    if (newCharacterIndex < 0) {
      this._allCharacterPlayer.push(character);
      console.log("push");
    }
    console.log("after : this._allCharacterPlayer", this._allCharacterPlayer);
  }
  swapContainerIndexOfStage(attackerContainer, defenderContainer) {
    // console.log("attackerContainer", attackerContainer);
    // console.log("defenderContainer", defenderContainer);
    const attIndex = this._containerStage.getChildIndex(
      attackerContainer //.container
    );
    const defIndex = this._containerStage.getChildIndex(
      defenderContainer //.container
    );
    if (attIndex < defIndex) {
      this._containerStage.swapChildren(
        attackerContainer, //.container,
        defenderContainer //.container
      );
    }
  }

  addPlayerAttacker(attacker) {
    this._playerAttackerList.push(attacker);
    // this._playerAttackerList = [...this._playerAttackerList, attacker];
  }

  clearPlayerAttacker() {
    this._playerAttackerList = [];
  }

  addEnemiesAttacker(attacker) {
    this._enemiesAttackerList.push(attacker);
    // this._enemiesAttackerList = [...this._enemiesAttackerList, attacker];
  }
  clearEnemiesAttacker() {
    this._enemiesAttackerList = [];
  }

  addPlayerDefender(defender) {
    this._playerDefenderList.push(defender);
    // this._playerDefenderList = [...this._playerDefenderList, defender];
  }

  clearPlayerDefender() {
    this._playerDefenderList = [];
  }

  addEnemiesDefender(defender) {
    this._enemiesDefenderList.push(defender);
    // this._enemiesDefenderList = [...this._enemiesDefenderList, defender];
  }
  clearEnemiesDefender() {
    this._enemiesDefenderList = [];
  }

  clearAllAttackerDefenderList() {
    this.clearPlayerAttacker();
    this.clearPlayerDefender();
    this.clearEnemiesAttacker();
    this.clearEnemiesDefender();
  }
  checkTopPlayerAnswer(userID) {
    let player = this._characterPlayers.find((character) => {
      console.log("character", character);
      return character.userID === userID;
    });
    if (player) {
      // console.log("player:", player);
      // console.log(" this._characterEnemies", this._characterEnemies);

      // let defenderLength = this._enemiesDefenderList.length
      //   ? this._enemiesDefenderList.length
      //   : 0;
      // // if (isShowBoss) {
      // //   this.addEnemiesDefender(this._characterBoss);
      // // } else {
      // this.addEnemiesDefender(this._characterEnemies[defenderLength - (1 % 3)]);
      // // }
      player.playState("charge");
      player.charging();
      this.addPlayerAttacker(player);
    }
  }
  async scenarioAttack(oldTopEndUser) {
    console.log("this._characterEnemies", this._characterEnemies);
    for (let index = 0; index < this._playerAttackerList.length; index++) {
      this.addEnemiesDefender(this._characterEnemies[index % 3]);
    }

    this._enemiesDefenderList = shuffle(this._enemiesDefenderList);

    let defenderPlayerIDList = oldTopEndUser?.filter(
      (user) => user.correction !== "correct"
    );

    let surviveEnemiesQuantity = defenderPlayerIDList.length;
    defenderPlayerIDList.forEach((defender) => {
      this.addPlayerDefender(
        this._characterPlayers.find(
          (character) => character.userID === defender.userID
        )
      );
    });

    let promises = this._playerAttackerList.map(async (character, index) => {
      await this.routineWait(index * 0.5).then(async () => {
        return await this.playerAttackEnemy(
          character,
          this._enemiesDefenderList[index]
        );
      });
    });

    await Promise.all(promises);

    if (surviveEnemiesQuantity !== 0) {
      for (let index = 0; index < surviveEnemiesQuantity; index++) {
        this.addEnemiesAttacker(this._characterEnemies[index % 3]);
      }

      let enemyDisappear = difference(
        this._enemiesDefenderList,
        this._enemiesAttackerList
      );

      enemyDisappear.forEach(async (character) => {
        await this.routineWait(0)
          .then(() => {
            character.playState("lose");
          })
          .then(() => {
            //0.5
            this.routineWait(1.5)
              .then(() => {
                character.setDisappearedParticleActive();
              })
              .then(() => {
                this.routineWait(0.25).then(() => {
                  character.setActive(false);
                  let index = this._characterEnemies.indexOf(character);
                  if (index > -1) {
                    this._characterEnemies.splice(index, 1);
                  }
                  this._characterEnemiesArmy.push(character);
                });
              });
          });
      });
      let promise = this._playerDefenderList.map(async (defender, index) => {
        await this.routineWait(index * 0.5).then(async () => {
          return await this.enemyAttackPlayer(
            this._enemiesAttackerList[index % this._enemiesAttackerList.length],
            defender
          );
        });
      });
      await Promise.all(promise);
      return await this.routineWait(1);
    } else {
      let promise = this._enemiesDefenderList.map(async (character) => {
        await this.routineWait(1)
          .then(() => {
            character.playState("lose");
          })
          .then(() => {
            //0.5
            this.routineWait(1.5)
              .then(() => {
                character.setDisappearedParticleActive();
              })
              .then(async () => {
                return await this.routineWait(0.25).then(() => {
                  character.setActive(false);
                  let index = this._characterEnemies.indexOf(character);
                  if (index > -1) {
                    this._characterEnemies.splice(index, 1);
                  }
                  this._characterEnemiesArmy.push(character);
                });
              });
          });
      });
      await Promise.all(promise);
      return await this.routineWait(1);
    }
  }

  async scenarioAttackBoss(oldTopEndUser) {
    let defenderPlayerIDList = oldTopEndUser?.filter(
      (user) => user.correction !== "correct"
    );
    let playerDefenderLength = defenderPlayerIDList.length;

    defenderPlayerIDList.forEach((defender) => {
      this.addPlayerDefender(
        this._characterPlayers.find(
          (character) => character.userID === defender.userID
        )
      );
    });
    let promises = this._playerAttackerList.map(async (character, index) => {
      await this.routineWait(index * 0.5 + 1).then(async () => {
        return await this.playerAttackBoss(character, this._characterBoss);
      });
    });
    await Promise.all(promises);
    await this.routineWait(0.5);

    if (playerDefenderLength !== 0) {
      return await this.bossAttackPlayer();
    } else {
      return;
    }
  }
  async playerAttackEnemy(player, enemy) {
    this.swapContainerIndexOfStage(player?.container, enemy?.container);

    player.playState("attack");
    let targetPosition = {
      x: Math.abs(player.container.position.x - enemy.container.position.x),
      y: enemy.container.position.y,
    };
    // player.setFiredBulletTo(targetPosition);
    // return await this.routineWait(0.5).then(() => {
    //   // player.setParticleActive(false);
    //   // console.log("enemy hit ", enemy);
    //   enemy.playState("hit");
    // });
    await player.setFiredBulletTo(targetPosition).then(() => {
      enemy.playState("hit");
    });
    return await this.routineWait(0.5);
  }
  async playerAttackBoss(player, boss) {
    this.swapContainerIndexOfStage(player.container, boss.container);
    player.playState("attack");
    let targetPosition = {
      x: Math.abs(player.container.position.x - boss.container.position.x),
      y: boss.container.position.y,
    };
    // player.setFiredBulletTo(targetPosition);
    // return await this.routineWait(0.25).then(async () => {
    //   // player.setParticleActive(false);
    //   boss.playState("hit");
    // });
    await player.setFiredBulletTo(targetPosition).then(() => {
      boss.playState("hit");
    });
    return await this.routineWait(0.5);
  }
  async playerFinalAttackBoss(player, boss) {
    this.swapContainerIndexOfStage(player.container, boss.container);
    player.playState("attack");
    let targetPosition = {
      x: Math.abs(player.container.position.x - boss.container.position.x),
      y: boss.container.position.y,
    };
    // player.setFiredBulletTo(targetPosition);
    // return await this.routineWait(0.25).then(async () => {
    //   // player.setParticleActive(false);
    //   boss.playState("hit");
    //   await this.routineWait(1).then(() => {
    //     boss.playState("lose");
    //   });
    //   player.playState("win");
    // });
    return await player.setFiredBulletTo(targetPosition).then(async () => {
      boss.playState("hit");
    });
  }
  async enemyAttackPlayer(enemy, player) {
    if (enemy?.container && player?.container) {
      this.swapContainerIndexOfStage(enemy.container, player.container);

      enemy.playState("attack");
      let targetPosition = {
        x: player.container.position.x - enemy.container.position.x,
        y: player.container.position.y,
      };
      // enemy.setFiredBulletTo(targetPosition);
      // return await this.routineWait(0.75).then(() => {
      //   player.playState("hit");
      // });
      await enemy.setFiredBulletTo(targetPosition).then(() => {
        player.playState("hit");
        player.hurt();
      });
      return await this.routineWait(0.5);
    }
  }

  async bossAttackPlayer() {
    this._playerDefenderList.forEach((player) => {
      if (player) {
        this.swapContainerIndexOfStage(
          this._characterBoss.container,
          player.container
        );
      }
    });

    this._characterBoss.playState("attack");
    // this._characterBoss.attackPlayer(
    //   this._characterBoss,
    //   this._playerDefenderList
    // );
    // return await this.routineWait(1).then(() => {
    //   this._playerDefenderList.forEach((player) => {
    //     player.playState("hit");
    //   });
    // });
    await this._characterBoss
      .attackPlayer(this._characterBoss, this._playerDefenderList)
      .then(() => {
        this._playerDefenderList.forEach((player) => {
          player.playState("hit");
          player.hurt();
        });
      });
    return await this.routineWait(1);
  }
  async scenarioBossWalkInStage(isOneQuestion) {
    this._characterBoss.setActive(true);
    if (isOneQuestion) {
      this._characterBoss?.container?.position?.set(1000, -108);
      await this.routineMoveEnemyToPosition(
        this._characterBoss,
        700,
        -108,
        500
      );
      await this._characterEnemies.forEach(async (enemy, index) => {
        enemy.setActive(false);
      });
    } else {
      return await this.routineWait(5.5).then(async () => {
        this.routineWait(1).then(async () => {
          await this._characterEnemies.forEach(async (enemy, index) => {
            await this.routineMoveEnemyToPosition(enemy, 1920, -108, 500);
          });
        });
        await this.routineMoveEnemyToPosition(
          this._characterBoss,
          700,
          -108,
          300
        ).then(() => {
          this._characterEnemies.forEach(async (enemy, index) => {
            enemy.setActive(false);
          });
        });
      });
    }
  }

  scenarioBossSay() {
    if (this._characterBoss.talk) this._characterBoss.talk();
  }
  async spawnEnemy() {
    this.clearAllAttackerDefenderList();
    await this.routineWait(0.5);
    for (let index = 0; this._characterEnemies.length < 3; index++) {
      let enemy = this._characterEnemiesArmy.shift();
      enemy.container.position?.set(
        1200 + this._characterEnemies.length * 150,
        -108
      );
      enemy.setActive(true);
      this._characterEnemies.push(enemy);
    }

    let promise = this._characterEnemies.map(async (enemy, index) => {
      if (enemy.container.position.x === 500 + index * 150) {
        return await this.routineWait(0);
      } else {
        return await this.routineMoveEnemyToPosition(
          enemy,
          500 + index * 150,
          -108,
          500
        );
      }
    });
    await Promise.all(promise);
  }
  async scenarioSwitchPosition(topEndUser) {
    this.clearAllAttackerDefenderList();

    //console.log("this._characterEnemies", this._characterEnemies);

    // while (this._characterEnemies.length < 3) {
    //   let enemy = this._characterEnemiesArmy.shift();
    //   enemy.container.position?.set(
    //     1000 + this._characterEnemies.length - 1 * 150,
    //     -108
    //   );
    //   enemy.setActive(true);
    //   this._characterEnemies.push(enemy);
    //   await this.routineMoveEnemyToPosition(
    //     enemy,
    //     500 + this._characterEnemies.length - 1 * 150,
    //     -108,
    //     500
    //   );
    // }

    //TODO: spawnEnemy new set
    // check oldtopEnduser with TopEndUser
    // switch topEnduser to this.characterplayer
    // console.log("topEndUser", topEndUser);
    console.log("this._allCharacterPlayer", this._allCharacterPlayer);
    let newPlayerSet = [];
    topEndUser.forEach((user) => {
      // console.log("user from top user", user);
      newPlayerSet.push(
        this._allCharacterPlayer.find(
          (character) => character.userID === user.userID
        )
      );
    });
    // console.log("newPlayerSet", newPlayerSet);
    let oldPlayer = this._characterPlayers;
    let samePlayer = intersection(oldPlayer, newPlayerSet);
    let newPlayerComeForth = difference(newPlayerSet, samePlayer);

    // console.log("oldPlayer", oldPlayer);
    // console.log("samePlayer", samePlayer);
    // console.log("newPlayerComeForth", newPlayerComeForth);
    let promises = [];

    oldPlayer.forEach(async (player) => {
      let newRanking = findIndex(newPlayerSet, ["userID", player.userID]);
      console.log("newRanking", newRanking);
      if (newRanking !== -1) {
        promises.push(await this.playerMoveToNewPosition(player, newRanking));
      } else {
        promises.push(await this.playerBacktoCastle(player));
      }
    });
    newPlayerComeForth.forEach(async (player) => {
      let newRanking = findIndex(newPlayerSet, ["userID", player.userID]);
      console.log("newRanking", newRanking);
      if (newRanking !== -1) {
        promises.push(await this.playerMoveToNewPosition(player, newRanking));
      }
    });

    return Promise.all(promises).then(() => {
      this._characterPlayers = [...newPlayerSet];
    });
  }

  async playerBacktoCastle(player) {
    return await this.routineMoveCharacterToPosition(
      player,
      -560,
      -108,
      500
    ).then(() => {
      player.setActive(false);
    });
  }
  async playerMoveToNewPosition(player, index) {
    player.setActive(true);
    await this.routineMoveCharacterToPosition(
      player,
      index * -200,
      -108,
      500
    ).then(() => {
      player.spine.skeleton.scaleX = 1;
    });
  }

  async scenarioWin() {
    await this.routineWait(1);
    let promise = this._characterPlayers.map(async (player) => {
      await this.playerFinalAttackBoss(player, this._characterBoss);
    });
    await Promise.all(promise).then(() => {
      this._characterBoss.playState("lose");
      this._characterPlayers.forEach((player) => {
        player.playState("win");
      });
    });
  }

  async scenarioLose() {
    console.log("this._characterPlayers", this._characterPlayers);
    this._characterPlayers.forEach((player) => {
      console.log("player", player);
      if (player) {
        this.swapContainerIndexOfStage(
          this._characterBoss.container,
          player.container
        );
      }
    });
    await this.routineWait(1);
    this._characterBoss.playState("win");
    // this._characterBoss.finalAttack(
    //   this._characterBoss,
    //   this._characterPlayers
    // );

    await this._characterBoss
      .finalAttack(this._characterBoss, this._characterPlayers)
      .then(() => {
        this._characterPlayers.forEach(async (player) => {
          player.playState("lose");
        });
      });

    // await this.routineWait(1.5).then(() => {
    //   this._characterPlayers.forEach(async (player) => {
    //     player.playState("lose");
    //   });
    // });
  }
  async routineWait(second) {
    await new Promise((resolve) => {
      const ticker = this._app.ticker;
      let current = 0;
      const onUpdate = (elapsedTime) => {
        const deltaTime = (1 / ticker.FPS) * ticker.speed;
        current += deltaTime;
        if (current > second) {
          ticker.remove(onUpdate);
          resolve();
        }
      };
      ticker.add(onUpdate);
    });
  }
  async routineMoveStageToPosition(position, moveSpeed) {
    const sign = (value) => {
      return value >= 0 ? 1 : -1;
    };
    const abs = (value) => {
      if (value < 0) return -value;
      else return value;
    };
    const moveToward = (current, target, maxDelta) => {
      if (abs(target - current) <= maxDelta) {
        return target;
      }
      return current + sign(target - current) * maxDelta;
    };

    await new Promise((resolve) => {
      const ticker = this._app.ticker;
      const onUpdate = (elapsedTime) => {
        const deltaTime = (1 / ticker.FPS) * ticker.speed;
        const moveDelta = deltaTime * moveSpeed;
        this._position = moveToward(this._position, position, moveDelta);
        this.update();
        if (this._position === position) {
          ticker.remove(onUpdate);
          resolve();
        }
      };
      ticker.add(onUpdate);
    });
  }
  async routineMoveCharacterToPosition(character, x, y, moveSpeed) {
    await new Promise((resolve) => {
      const ticker = this._app.ticker;
      character.playState("move");
      const onUpdate = (elapsedTime) => {
        const deltaTime = (1 / ticker.FPS) * ticker.speed;
        let currentPosition = character.container.position;
        let magnitude = Vector2.Magnitude(
          x,
          y,
          currentPosition.x,
          currentPosition.y
        );
        if (magnitude > moveEpsilon) {
          character.spine.skeleton.scaleX = x > currentPosition.x ? 1 : -1;
          const [moveX, moveY] = Vector2.MoveToward(
            currentPosition.x,
            currentPosition.y,
            x,
            y,
            deltaTime * moveSpeed
          );
          character.container.position.set(moveX, moveY);
        } else {
          character.container.position.set(x, y);
          character.playState("idle");
          ticker.remove(onUpdate);

          resolve();
        }
      };
      ticker.add(onUpdate);
    });
  }
  async routineMoveEnemyToPosition(character, x, y, moveSpeed) {
    await new Promise((resolve) => {
      const ticker = this._app.ticker;
      character.playState("move");
      const onUpdate = (elapsedTime) => {
        const deltaTime = (1 / ticker.FPS) * ticker.speed;
        let currentPosition = character.container.position;
        let magnitude = Vector2.Magnitude(
          x,
          y,
          currentPosition.x,
          currentPosition.y
        );
        if (magnitude > moveEpsilon) {
          character.spine.skeleton.scaleX = x > currentPosition.x ? -1 : 1;
          const [moveX, moveY] = Vector2.MoveToward(
            currentPosition.x,
            currentPosition.y,
            x,
            y,
            deltaTime * moveSpeed
          );
          character.container.position.set(moveX, moveY);
        } else {
          character.container.position.set(x, y);
          character.playState("idle");
          ticker.remove(onUpdate);
          resolve();
        }
      };
      ticker.add(onUpdate);
    });
  }

  async firstPlayerMove() {
    this._characterPlayers[0].setActive(true);
    return await this.routineMoveCharacterToPosition(
      this._characterPlayers[0],
      -800,
      -108,
      500
    ).then(() => {
      this._characterPlayers[0].spine.skeleton.scaleX = 1;
    });
  }

  async firstPlayerMoveToCastle() {
    return await this.routineMoveCharacterToPosition(
      this._characterPlayers[0],
      -560,
      -108,
      500
    ).then(() => {
      this._characterPlayers[0].setActive(false);
    });
  }

  async playerMoveOutCastle() {
    this._characterPlayers.forEach((player, index) => {
      setTimeout(async () => {
        player.setActive(true);
        await this.routineMoveCharacterToPosition(
          player,
          index * -200,
          -108,
          500
        ).then(() => {
          player.spine.skeleton.scaleX = 1;
        });
      }, index * 500);
    });
  }

  async enemyMoveIn() {
    // for (let index = 0; index < 3; index++) {
    //   this._characterEnemies[index].setActive(true);
    //   this._characterEnemies[index]?.container?.position?.set(
    //     1000 + index * 150,
    //     -108
    //   );
    //   await this.routineMoveEnemyToPosition(
    //     this._characterEnemies[index],
    //     500 + index * 150,
    //     -108,
    //     500
    //   );
    // }

    this._characterEnemies.forEach(async (enemy, index) => {
      enemy.setActive(true);
      enemy?.container?.position?.set(1000 + index * 150, -108);
      await this.routineMoveEnemyToPosition(
        enemy,
        500 + index * 150,
        -108,
        500
      );
    });
  }
}

export { ScenarioManager };
