import { useState, useEffect, useCallback } from "react";

function useCountdownTimer(
  callback,
  count = 5,
  endIntervalTriggerValue,
  delayWhenTrigger = 1
) {
  const [timeLeft, setTimeLeft] = useState(0);
  const [trigger, setTrigger] = useState(endIntervalTriggerValue);

  useEffect(() => {
    if (timeLeft > 0) {
      let intervalId = setInterval(() => {
        setTimeLeft(timeLeft - 1);
      }, 1000);

      if (trigger !== endIntervalTriggerValue) {
        setTrigger(endIntervalTriggerValue);
        setTimeLeft(delayWhenTrigger);
      }

      return () => {
        clearInterval(intervalId);
      };
    } else {
      callback();
    }
  }, [timeLeft, callback, trigger, endIntervalTriggerValue, delayWhenTrigger]);

  const countdown = useCallback(() => {
    setTimeLeft(count);
  }, [count]);

  return [countdown, timeLeft];
}

export default useCountdownTimer;
