const Vector2 = {
  Magnitude: (x1, y1, x2, y2) => {
    return Math.sqrt(Math.pow(x2 - x1, 2) + Math.pow(y2 - y1, 2));
  },
  MoveToward: (currentX, currentY, targetX, targetY, maxDistanceDelta) => {
    let toX = targetX - currentX;
    let toY = targetY - currentY;
    let sqDist = toX * toX + toY * toY;
    if (
      sqDist === 0 ||
      (maxDistanceDelta >= 0 && sqDist <= maxDistanceDelta * maxDistanceDelta)
    )
      return [targetX, targetY];
    let dist = Math.sqrt(sqDist);
    return [
      currentX + (toX / dist) * maxDistanceDelta,
      currentY + (toY / dist) * maxDistanceDelta,
    ];
  },
};

export default Vector2;
