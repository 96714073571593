import * as dolfindam from "Game/PixiCores/Levels/Dolfindam.js";
import * as flipperborea from "Game/PixiCores/Levels/Flipperborea.js";
import * as tsuronotani from "Game/PixiCores/Levels/Tsuronotani.js";
import * as mayurabad from "Game/PixiCores/Levels/Mayurabad.js";
import * as yosegoni from "Game/PixiCores/Levels/Yosegoni";
import * as avesta_12b from "Game/PixiCores/Levels/Avesta_12b";
import * as novaCentauri from "Game/PixiCores/Levels/NovaCentauri";
import * as loventures from "Game/PixiCores/Levels/Loventures";

const Level = {
  default: dolfindam,
  dolfindam: dolfindam,
  flipperborea: flipperborea,
  tsurunotani: tsuronotani,
  mayurabad: mayurabad,
  yosegoni: yosegoni,
  "avesta-12b": avesta_12b,
  "nova-centauri": novaCentauri,
  loventures: loventures,
};
export default Level;

// {
//   "default": "./Levels/Dolfindam.js",
//   "Dolfindam":"./Levels/Dolfindam.js",
//   "dolfindamdaylight":"./Levels/Dolfindam.js"
// }
