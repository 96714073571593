import React from 'react';
import ReactDOM from 'react-dom';
//import VConsole from 'vconsole';
import App from 'App';
import reportWebVitals from 'reportWebVitals';
// import 'reset.css';
// import 'default.css';
import 'index.css';
import 'antd/dist/antd.css'
import 'i18n'

// Add vConsole to react app when in development.
// eslint-disable-next-line no-undef
//if (process.env.NODE_ENV === 'development') new VConsole();

const rootName = 'root';
const rootDOM = document.getElementById(rootName);
if (rootDOM) {
  ReactDOM.render(<App />, document.getElementById("root"));
}

reportWebVitals();
console.log('© Vonder (Thailand) Co.,Ltd.');
