import {
  Box,
  FlexCol,
  FullRow,
  GoButton,
  GoIcon,
  GoImage,
  TextTypo,
  UserList,
  UserNameBox,
} from "Game/Style/GameStyle";
import { memo, useCallback, useMemo } from "react";
import List from "antd/lib/list";
import styled, { css, keyframes } from "styled-components";
import { useVonderGoContext } from "Service/VonderGOContext";
import take from "lodash/take";
import CountUp from "react-countup";
import { useLocalizationContext } from "Service/LocalizationProvider";
import victory from "Game/Assets/Victory_Banner.png";
import defeat from "Game/Assets/Defeat_Banner.png";

export default memo(function Leaderboard() {
  const {
    currentGameData,
    leaderboardData,
    endSessionLoading,
    currentGameState,
    wsSend,
    setEndSessionLoading,
    playSFX,
  } = useVonderGoContext();
  const { t } = useLocalizationContext();

  const isEndLeaderboard = currentGameState?.finalLeaderboard;
  const findTop3 = take(leaderboardData?.topEndUser, 3);
  const isWin = leaderboardData?.isSessionWin;

  const showLeaderboard = useMemo(() => {
    if (isEndLeaderboard) {
      return (
        <FullRow>
          <FlexCol span={11} height="100%">
            <Box position="absolute" style={{ left: "25%", top: "-2%" }}>
              <GoImage
                preview={false}
                src={isWin ? victory : defeat}
                width="60%"
                height="100%"
              />
            </Box>
            <Box
              align="flex-end"
              justify="center"
              width="100%"
              position="relative"
            >
              <PodiumBox bottom="17%" left="10%">
                <Top3BoxName>
                  <TextTypo size="1vw" type="white" ellipsis>
                    {findTop3[1]?.name}
                  </TextTypo>
                </Top3BoxName>
                <Box style={{ opacity: 0.5 }}>
                  <TextTypo
                    size="1vw"
                    type="white"
                    strong="true"
                    style={{ whiteSpace: "nowrap" }}
                  >
                    {findTop3[1]?.scoreRank}
                  </TextTypo>
                  <TextTypo
                    size="1vw"
                    type="white"
                    style={{ marginLeft: "5%", whiteSpace: "nowrap" }}
                  >
                    {t("GAMEPLAY.POINT")}
                  </TextTypo>
                </Box>
              </PodiumBox>
              <PodiumBox bottom="28%" left="35.3%">
                <Top3BoxName>
                  <TextTypo size="1vw" type="white" ellipsis>
                    {findTop3[0]?.name}
                  </TextTypo>
                </Top3BoxName>
                <Box style={{ opacity: 0.5 }}>
                  <TextTypo
                    size="1vw"
                    type="white"
                    strong="true"
                    style={{ whiteSpace: "nowrap" }}
                  >
                    {findTop3[0]?.scoreRank || 0}
                  </TextTypo>
                  <TextTypo
                    size="1vw"
                    type="white"
                    style={{ marginLeft: "5%", whiteSpace: "nowrap" }}
                  >
                    {t("GAMEPLAY.POINT")}
                  </TextTypo>
                </Box>
              </PodiumBox>
              <PodiumBox bottom="11%" left="60.2%">
                <Top3BoxName>
                  <TextTypo size="1vw" type="white" ellipsis>
                    {findTop3[2]?.name}
                  </TextTypo>
                </Top3BoxName>
                <Box style={{ opacity: 0.5 }}>
                  <TextTypo
                    size="1vw"
                    type="white"
                    strong="true"
                    style={{ whiteSpace: "nowrap" }}
                  >
                    {findTop3[2]?.scoreRank}
                  </TextTypo>
                  <TextTypo
                    size="1vw"
                    type="white"
                    style={{ marginLeft: "5%", whiteSpace: "nowrap" }}
                  >
                    {t("GAMEPLAY.POINT")}
                  </TextTypo>
                </Box>
              </PodiumBox>
            </Box>
          </FlexCol>
          <FlexCol span={13} height="100%" justify="center">
            <Box width="100%" padding="18% 8% 0 8%">
              <UserListLeaderboard
                grid={{ column: 2 }}
                dataSource={leaderboardData?.topEndUser}
                renderItem={(item) => (
                  <List.Item>
                    <UserNameBox
                      align="center"
                      justify="space-between"
                      size="2vw"
                    >
                      <Box width="100%" align="center">
                        <TextTypo size="1vw" style={{ marginRight: "5%" }}>
                          {item?.rank}
                        </TextTypo>
                        <GoIcon
                          src={item?.characterIcon}
                          size="2.2vw"
                          style={{ marginRight: "3%" }}
                        />
                        <TextTypo size="1vw" strong="true">
                          {item?.name}
                        </TextTypo>
                      </Box>
                      <TextTypo size="1vw" style={{ whiteSpace: "nowrap" }}>
                        {item?.scoreRank}
                      </TextTypo>
                    </UserNameBox>
                  </List.Item>
                )}
              />
            </Box>
          </FlexCol>
        </FullRow>
      );
    } else {
      return (
        <>
          <LeaderboardHeader>
            <TextTypo size="2vw" strong="true">
              {t("GAMEPLAY.RANKING")}
            </TextTypo>
          </LeaderboardHeader>
          <UserList
            style={{ overflow: "hidden" }}
            minileaderboard="true"
            dataSource={currentGameData?.topEndUser}
            renderItem={(item, index) => (
              <UserList.Item>
                <UserNameBox
                  align="center"
                  justify="space-between"
                  minileaderboard="true"
                >
                  <Box width="100%" align="center">
                    <TextTypo size="1.2vw" style={{ marginRight: "5%" }}>
                      {index + 1}
                    </TextTypo>
                    <GoIcon
                      src={item?.characterIcon}
                      size="2.5vw"
                      style={{ marginRight: "3%" }}
                    />
                    <TextTypo size="1.2vw" strong="true">
                      {item?.name}
                    </TextTypo>
                  </Box>
                  <TextTypo size="1.2vw" style={{ whiteSpace: "nowrap" }}>
                    <CountUp end={item?.scoreRank} />
                  </TextTypo>
                </UserNameBox>
              </UserList.Item>
            )}
          />
        </>
      );
    }
  }, [currentGameData, findTop3, isEndLeaderboard, leaderboardData, t, isWin]);

  const endSession = useCallback(() => {
    playSFX("major");
    wsSend(JSON.stringify({ event: "session-end" }));
    setEndSessionLoading(true);
  }, [playSFX, setEndSessionLoading, wsSend]);

  return (
    <>
      {endSessionLoading && (
        <Saving visible={endSessionLoading}>
          <TextTypo size="2.8vw" strong="true" type="white">
            {t("GAMEPLAY.SAVING")}
          </TextTypo>
        </Saving>
      )}
      {showLeaderboard}
      {isEndLeaderboard && (
        <Box
          width="100%"
          justify="flex-end"
          position="absolute"
          padding="0 3%"
          style={{ bottom: "2%" }}
        >
          <GoButton type="primary" onClick={endSession}>
            {t("BUTTON.SAVE_SESSION_AND_EXIT")}
          </GoButton>
        </Box>
      )}
    </>
  );
});

const UserListLeaderboard = styled(UserList)`
  width: 85%;
  max-height: 57%;
  overflow: auto;
  position: absolute;
  top: 27%;
  right: 10%;
  overflow-x: hidden;
`;
const Saving = styled(Box)`
  position: absolute;
  z-index: 5;
  top: 0;
  width: 100%;
  align-items: center;
  justify-content: center;
  height: 100%;
  ${(props) => {
    const savingScaleUp = keyframes`
    0% {
      transform: scale(0.9);
      opacity: 0;
    }
    100% {
      transform: scale(1);
      opacity: 0.8;
    }
  `;
    if (props.visible) {
      return css`
        background-color: black;
        animation: ${savingScaleUp} 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94)
          both;
      `;
    } else {
      return css`
        opacity: 0;
      `;
    }
  }}
`;
const Top3BoxName = styled(Box)`
  width: 94%;
  height: 2.5vw;
  padding: 2%;
  align-items: center;
  justify-content: center;
  background-color: var(--gray-800);
  border-radius: var(--border-radius);
  @media screen and (max-width: 850px) {
    border-radius: 3px;
  }
`;

const PodiumBox = styled.div`
  width: 24%;
  height: 9%;
  padding: 0.5%;
  position: absolute;
  display: flex;
  bottom: ${(props) => props.bottom};
  left: ${(props) => props.left};
  flex-direction: column;
  align-items: center;
`;
const LeaderboardHeader = styled.div`
  position: absolute;
  top: -6%;
  color: var(--white);
  padding: 1% 2%;
  height: 3.5vw;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: var(--border-radius);
  background-color: var(--silver-400);
  @media screen and (max-width: 850px) {
    border-radius: 3px;
  }
`;
