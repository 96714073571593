import { ParallaxLoop } from "Classes/Parallax.js";
import * as PIXI from "pixi.js";

import * as Particles from "pixi-particles";
import sakura_01 from "Game/Assets/LevelParticleSprite/Tsuronotani/Sakura-petal_0000_Layer-3.png";
import sakura_02 from "Game/Assets/LevelParticleSprite/Tsuronotani/Sakura-petal_0001_Layer-4.png";
import sakura_03 from "Game/Assets/LevelParticleSprite/Tsuronotani/Sakura-petal_0002_Layer-5.png";
import sakura_04 from "Game/Assets/LevelParticleSprite/Tsuronotani/Sakura-petal_0003_Layer-6.png";
import sakura_05 from "Game/Assets/LevelParticleSprite/Tsuronotani/Sakura-petal_0004_Layer-7.png";
import sakura_06 from "Game/Assets/LevelParticleSprite/Tsuronotani/Sakura-petal_0005_Layer-8.png";
import sakura_07 from "Game/Assets/LevelParticleSprite/Tsuronotani/Sakura-petal_0006_Layer-9.png";
import sakura_08 from "Game/Assets/LevelParticleSprite/Tsuronotani/Sakura-petal_0007_Layer-10.png";
import sakura_09 from "Game/Assets/LevelParticleSprite/Tsuronotani/Sakura-petal_0008_Layer-11.png";
import sakura_10 from "Game/Assets/LevelParticleSprite/Tsuronotani/Sakura-petal_0009_Layer-2.png";
import sakura from "Game/PixiCores/Levels/sakura.json";

const FrontSeat_Y = -230;
const BackSeat_Y = -208;

const level = {
  areaLength: 4000,
  load: ({ app, assets }) => {
    console.log(assets);
    return new Promise((resolve, reject) => {
      const loader = new PIXI.Loader();
      let spriteList = [
        // { name: "sprites_multi_building" },
        { name: "sprites_multi_large_0" },
        // { name: "sprites_multi_large_1" },
        { name: "sprites_multi_environments_0" },
        // { name: "sprites_multi_environments_1" },
      ];
      assets.forEach((element) => {
        console.log("element", element);
        const path = element.link;
        const arr = path.split("/");
        const filename = arr[arr.length - 1].split(".");
        const type = filename[0];
        if (
          spriteList.find((sprite) => {
            return sprite.name === type;
          })
        ) {
          loader.add(type, element.link);
        }
      });

      let particleList = [
        sakura_01,
        sakura_02,
        sakura_03,
        sakura_04,
        sakura_05,
        sakura_06,
        sakura_07,
        sakura_08,
        sakura_09,
        sakura_10,
      ];

      particleList.forEach((element, index) => {
        loader.add(index.toString(), element);
      });

      loader.load((loader, resources) => {
        console.log(resources);
        const spritesheetTextureLarge0 =
          resources["sprites_multi_large_0"].spritesheet.textures;
        const spritesheetTextureEnv0 =
          resources["sprites_multi_environments_0"].spritesheet.textures;

        const parallax = new ParallaxLoop(new PIXI.Container());

        const BG = new PIXI.Sprite(
          spritesheetTextureLarge0["Wedding_scene-extract_0026_skyBG.png"]
        );
        BG.position.set(-700, -1020);
        BG.scale.set(2);

        const Floor = new PIXI.Sprite(
          spritesheetTextureLarge0[
            "Wedding_scene-extract_0000_flower_ground.png"
          ]
        );
        Floor.position.set(-690, -115);
        Floor.scale.set(1.86);

        const Bush = new PIXI.Sprite(
          spritesheetTextureLarge0["Wedding_scene-extract_0022_forest.png"]
        );
        Bush.position.set(-920, -672);
        Bush.scale.set(2);

        const Church = new PIXI.Sprite(
          spritesheetTextureEnv0["Wedding_scene-extract_0005_-church.png"]
        );
        Church.position.set(-890, -890);
        Church.scale.set(1.24);

        const FlowerTunnel = new PIXI.Sprite(
          spritesheetTextureEnv0[
            "Wedding_scene-extract_0001_new_flower_tunnel.png"
          ]
        );
        FlowerTunnel.position.set(2440, -442);
        FlowerTunnel.scale.set(1);

        const BigTree = new PIXI.Sprite(
          spritesheetTextureEnv0["Wedding_scene-extract_0010_BigdecTree.png"]
        );
        BigTree.position.set(1620, -680);
        BigTree.scale.set(1);

        const FrontSeat1 = new PIXI.Sprite(
          spritesheetTextureEnv0["Wedding_scene-extract_0006_front_seat.png"]
        );
        FrontSeat1.position.set(1340, FrontSeat_Y);
        FrontSeat1.scale.set(1);

        const FrontSeat2 = new PIXI.Sprite(
          spritesheetTextureEnv0["Wedding_scene-extract_0006_front_seat.png"]
        );
        FrontSeat2.position.set(1132, FrontSeat_Y);
        FrontSeat2.scale.set(1);

        const FrontSeat3 = new PIXI.Sprite(
          spritesheetTextureEnv0["Wedding_scene-extract_0006_front_seat.png"]
        );
        FrontSeat3.position.set(840, FrontSeat_Y);
        FrontSeat3.scale.set(1);

        const FrontSeat4 = new PIXI.Sprite(
          spritesheetTextureEnv0["Wedding_scene-extract_0006_front_seat.png"]
        );
        FrontSeat4.position.set(632, FrontSeat_Y);
        FrontSeat4.scale.set(1);

        const BackSeat1 = new PIXI.Sprite(
          spritesheetTextureEnv0["Wedding_scene-extract_0007_back_seat.png"]
        );
        BackSeat1.position.set(1460, BackSeat_Y);
        BackSeat1.scale.set(1);

        const BackSeat2 = new PIXI.Sprite(
          spritesheetTextureEnv0["Wedding_scene-extract_0007_back_seat.png"]
        );
        BackSeat2.position.set(1258, BackSeat_Y);
        BackSeat2.scale.set(1);

        const BackSeat3 = new PIXI.Sprite(
          spritesheetTextureEnv0["Wedding_scene-extract_0007_back_seat.png"]
        );
        BackSeat3.position.set(1020, BackSeat_Y);
        BackSeat3.scale.set(1);

        const BackSeat4 = new PIXI.Sprite(
          spritesheetTextureEnv0["Wedding_scene-extract_0007_back_seat.png"]
        );
        BackSeat4.position.set(818, BackSeat_Y);
        BackSeat4.scale.set(1);

        // const Flower1 = new PIXI.Sprite(
        //   spritesheetTextureEnv0["Wedding_scene-extract_0021_flower_1.png"]
        // );
        // Flower1.position.set(-1000, -340);
        // Flower1.scale.set(1);

        // const Flower2 = new PIXI.Sprite(
        //   spritesheetTextureEnv0["Wedding_scene-extract_0020_flower_2.png"]
        // );
        // Flower2.position.set(-800, -340);
        // Flower2.scale.set(1);

        const Flower3 = new PIXI.Sprite(
          spritesheetTextureEnv0["Wedding_scene-extract_0019_flower_3.png"]
        );
        Flower3.position.set(100, -420);
        Flower3.scale.set(1.24);

        const Flower4 = new PIXI.Sprite(
          spritesheetTextureEnv0["Wedding_scene-extract_0018_flower_4.png"]
        );
        Flower4.position.set(670, -418);
        Flower4.scale.set(1);

        const Flower5 = new PIXI.Sprite(
          spritesheetTextureEnv0["Wedding_scene-extract_0017_flower_5.png"]
        );
        Flower5.position.set(1132, -438);
        Flower5.scale.set(1);

        const Flower6 = new PIXI.Sprite(
          spritesheetTextureEnv0["Wedding_scene-extract_0016_flower_6.png"]
        );
        Flower6.position.set(1860, -470);
        Flower6.scale.set(1);

        const Flower7 = new PIXI.Sprite(
          spritesheetTextureEnv0["Wedding_scene-extract_0015_flower_7.png"]
        );
        Flower7.position.set(2068, -490);
        Flower7.scale.set(1);

        const Fog1 = new PIXI.Sprite(
          spritesheetTextureEnv0["Wedding_scene-extract_0011_fog_1.png"]
        );
        Fog1.position.set(-750, -242);
        Fog1.scale.set(1);

        const Fog2 = new PIXI.Sprite(
          spritesheetTextureEnv0["Wedding_scene-extract_0012_fog_2.png"]
        );
        Fog2.position.set(1540, -260);
        Fog2.scale.set(1);

        const Fog3 = new PIXI.Sprite(
          spritesheetTextureEnv0["Wedding_scene-extract_0013_fog_3.png"]
        );
        Fog3.position.set(2068, -256);
        Fog3.scale.set(1);

        const Fog4 = new PIXI.Sprite(
          spritesheetTextureEnv0["Wedding_scene-extract_0014_fog_4.png"]
        );
        Fog4.position.set(2668, -260);
        Fog4.scale.set(1);

        const Cloud1 = new PIXI.Sprite(
          spritesheetTextureEnv0[
            "Wedding_scene-extract_0023_clound_large_1.png"
          ]
        );
        Cloud1.position.set(-560, -1000);
        Cloud1.scale.set(1.24);

        const Cloud2 = new PIXI.Sprite(
          spritesheetTextureEnv0[
            "Wedding_scene-extract_0024_clound_large_2.png"
          ]
        );
        Cloud2.position.set(1860, -1000);
        Cloud2.scale.set(1.24);

        const Cloud3 = new PIXI.Sprite(
          spritesheetTextureEnv0[
            "Wedding_scene-extract_0025_clound_small_1.png"
          ]
        );
        Cloud3.position.set(680, -700);
        Cloud3.scale.set(1);

        const Cloud4 = new PIXI.Sprite(
          spritesheetTextureEnv0[
            "Wedding_scene-extract_0025_clound_small_1.png"
          ]
        );
        Cloud4.position.set(1260, -900);
        Cloud4.scale.set(1.4);

        const Cloud5 = new PIXI.Sprite(
          spritesheetTextureEnv0[
            "Wedding_scene-extract_0025_clound_small_1.png"
          ]
        );
        Cloud5.position.set(3100, -750);
        Cloud5.scale.set(0.8);

        const BackFlowerStand = new PIXI.Sprite(
          spritesheetTextureEnv0[
            "Wedding_scene-extract_0009_back_flowerstand.png"
          ]
        );
        BackFlowerStand.position.set(1760, -240);
        BackFlowerStand.scale.set(1);

        const FrontFlowerStand = new PIXI.Sprite(
          spritesheetTextureEnv0[
            "Wedding_scene-extract_0008_front_flowerstand.png"
          ]
        );
        FrontFlowerStand.position.set(2060, -240);
        FrontFlowerStand.scale.set(1.24);

        const FlowerPot1 = new PIXI.Sprite(
          spritesheetTextureEnv0["Wedding_scene-extract_0002_flowerpot.png"]
        );
        FlowerPot1.position.set(-500, -244);
        FlowerPot1.scale.set(1.24);

        const FlowerPot2 = new PIXI.Sprite(
          spritesheetTextureEnv0["Wedding_scene-extract_0002_flowerpot.png"]
        );
        FlowerPot2.position.set(-160, -244);
        FlowerPot2.scale.set(1.24);

        const FlowerPot3 = new PIXI.Sprite(
          spritesheetTextureEnv0["Wedding_scene-extract_0002_flowerpot.png"]
        );
        FlowerPot3.position.set(1660, -244);
        FlowerPot3.scale.set(1.24);

        const FlowerPot4 = new PIXI.Sprite(
          spritesheetTextureEnv0["Wedding_scene-extract_0002_flowerpot.png"]
        );
        FlowerPot4.position.set(2290, -244);
        FlowerPot4.scale.set(1.24);

        const FlowerPot5 = new PIXI.Sprite(
          spritesheetTextureEnv0["Wedding_scene-extract_0002_flowerpot.png"]
        );
        FlowerPot5.position.set(2660, -244);
        FlowerPot5.scale.set(1.24);

        const DecTree1 = new PIXI.Sprite(
          spritesheetTextureEnv0["Wedding_scene-extract_0003_dec_tree.png"]
        );
        DecTree1.position.set(-390, -404);
        DecTree1.scale.set(1.24);

        const DecTree2 = new PIXI.Sprite(
          spritesheetTextureEnv0["Wedding_scene-extract_0003_dec_tree.png"]
        );
        DecTree2.position.set(-120, -404);
        DecTree2.scale.set(1.24);

        const Balloon = new PIXI.Sprite(
          spritesheetTextureEnv0["Wedding_scene-extract_0004_balloon.png"]
        );
        Balloon.position.set(80, -584);
        Balloon.scale.set(1.24);

        parallax.container.addChild(BG);

        //layer11
        parallax.container.addChild(Cloud3);
        parallax.container.addChild(Cloud4);
        parallax.container.addChild(Cloud5);

        //layer10
        parallax.container.addChild(Cloud1);
        parallax.container.addChild(Cloud2);
        // parallax.container.addChild(layer10);

        //layer9
        parallax.container.addChild(Bush);
        // parallax.container.addChild(layer9);

        //layer8
        parallax.container.addChild(Fog1);
        parallax.container.addChild(Fog2);
        // parallax.container.addChild(layer8);

        //layer7
        parallax.container.addChild(Flower3);
        parallax.container.addChild(Flower4);
        parallax.container.addChild(Flower5);
        parallax.container.addChild(Flower6);
        // parallax.container.addChild(layer7);

        //layer6
        parallax.container.addChild(Fog3);
        parallax.container.addChild(Fog4);
        // parallax.container.addChild(layer6);

        // layer5
        parallax.container.addChild(BackFlowerStand);
        parallax.container.addChild(FlowerPot4);
        parallax.container.addChild(Balloon);
        // parallax.container.addChild(layer5);

        //layer4
        parallax.container.addChild(Church);
        parallax.container.addChild(FlowerTunnel);
        parallax.container.addChild(BigTree);
        // parallax.container.addChild(layer4);

        //layer3
        parallax.container.addChild(Flower7);
        parallax.container.addChild(FrontFlowerStand);
        // parallax.container.addChild(layer3);

        //layer2
        parallax.container.addChild(BackSeat2);
        parallax.container.addChild(BackSeat1);
        parallax.container.addChild(BackSeat4);
        parallax.container.addChild(BackSeat3);

        parallax.container.addChild(DecTree1);
        parallax.container.addChild(DecTree2);
        // parallax.container.addChild(layer2);

        // layer1
        parallax.container.addChild(FlowerPot1);
        parallax.container.addChild(FlowerPot2);
        parallax.container.addChild(FlowerPot3);
        parallax.container.addChild(FlowerPot5);

        parallax.container.addChild(FrontSeat2);
        parallax.container.addChild(FrontSeat1);
        parallax.container.addChild(FrontSeat4);
        parallax.container.addChild(FrontSeat3);
        // parallax.container.addChild(layer1);

        parallax.container.addChild(Floor);

        const textures = [];
        for (let index = 0; index < 8; index++) {
          textures.push(resources[index].texture);
        }
        const particleContainer = new PIXI.Container();
        const emitter = new Particles.Emitter(
          particleContainer,
          textures,
          sakura.data
        );
        emitter.emit = true;
        let ticker = app.ticker;
        let deltaTime = (1 / ticker.FPS) * ticker.speed;
        let updateSakura = () => {
          emitter.update(deltaTime);
        };
        app.ticker.add(updateSakura);

        parallax.container.addChild(particleContainer);
        particleContainer.position.set(-1000, -1500);

        const controller = {
          parallax,
          areaLength: level.areaLength,

          update: () => {
            parallax.updateParallaxPosition();
          },
          setSize: (size) => {
            parallax.setSize(size);
          },
          setPosition: (position) => {
            parallax.setPosition(position);
          },
          destroy: () => {
            parallax.destroy();
            app.ticker.remove(updateSakura);
          },
        };
        resolve(controller);
      });
      loader.onError.add((e) => {
        reject(e);
      });
    });
  },
};

export default level;
