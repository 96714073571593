import * as PIXI from "pixi.js";
import * as Particles from "pixi-particles";

import Blast1 from "Game/Assets/ParticleSprite/Japan_Particle_0004_Yamato_Blast1.png";
import Blast2 from "Game/Assets/ParticleSprite/Japan_Particle_0003_Yamato_Blast2.png";
import Fireball from "Game/Assets/ParticleSprite/Japan_Particle_0005_Yamato_Fireball.png";
import Firework1 from "Game/Assets/ParticleSprite/Japan_Particle_0009_Yamato_Firework1.png";
import Firework2 from "Game/Assets/ParticleSprite/Japan_Particle_0008_Yamato_Firework2.png";
import Fireworkstart from "Game/Assets/ParticleSprite/Japan_Particle_0010_Yamato_Fireworkstart.png";
import * as Sentry from "@sentry/react";

const YamatoParticle = {
  initial: async (app) => {
    return new Promise(async (resolve, reject) => {
      let particles = [
        "Blast1",
        "Blast2",
        "Fireball",
        "Firework1",
        "Firework2",
        "Fireworkstart",
      ];
      let promises = [];
      particles.forEach((item) => {
        promises.push(
          new Promise(async (resolve, reject) => {
            let path = "";
            switch (item) {
              case "Blast1":
                path = Blast1;
                break;
              case "Blast2":
                path = Blast2;
                break;
              case "Fireball":
                path = Fireball;
                break;
              case "Firework1":
                path = Firework1;
                break;
              case "Firework2":
                path = Firework2;
                break;
              case "Fireworkstart":
                path = Fireworkstart;
                break;
              default:
                break;
            }
            let loader = new PIXI.Loader();
            loader.add(item, path);
            loader.onError.add((e) => {
              console.log(e);
              reject(e);
            });
            loader.load((loader, resources) => {
              if (app.loader.resources) {
                app.loader.resources[item] = resources[item];
              }
            });
            loader.onComplete.add(() => {
              resolve();
            });
          })
        );
      });
      return await Promise.all(promises)
        .then(() => {
          resolve();
        })
        .catch((e) => {
          Sentry.captureException(e);
          reject();
        });
    });
  },

  createParticle: (loader, name) => {
    return new Promise((resolve, reject) => {
      import(`./${name}.json`)
        .then((json) => {
          if (json.images && json.data) {
            let path = "";
            switch (name) {
              case "Blast1":
                path = Blast1;
                break;
              case "Blast2":
                path = Blast2;
                break;
              case "Fireball":
                path = Fireball;
                break;
              case "Firework1":
                path = Firework1;
                break;
              case "Firework2":
                path = Firework2;
                break;
              case "Fireworkstart":
                path = Fireworkstart;
                break;

              default:
                break;
            }
            if (!loader.resources[name]) {
              loader.add(name, path);
            }
            loader.onError.add((e) => {
              console.log(e);
              reject({ error: "CANNOT_LOAD_PARTICLE" });
            });
            loader.load((loader, resources) => {
              const textures = resources[name].texture;
              const container = new PIXI.Container();
              const emitter = new Particles.Emitter(
                container,
                textures,
                json.data
              );

              resolve({ container, emitter });
            });
          }
        })
        .catch((e) => {
          Sentry.captureException(e);
          console.error(e);
          reject({ error: "CANNOT_IMPORT_CLASS" });
        });
    });
  },

  load: (
    app,
    containerFirework,
    containerFireworkParticle,
    containerBullet,
    containerBlast
  ) => {
    return new Promise(async (resolve, reject) => {
      let allParticle = {};

      await YamatoParticle.createParticle(app.loader, "Blast1").then(
        (Blast1) => {
          containerBlast.addChild(Blast1.container);
          allParticle = { ...allParticle, Blast1 };
        }
      );

      await YamatoParticle.createParticle(app.loader, "Blast2").then(
        (Blast2) => {
          containerBlast.addChild(Blast2.container);
          allParticle = { ...allParticle, Blast2 };
        }
      );

      await YamatoParticle.createParticle(app.loader, "Fireball").then(
        (Fireball) => {
          containerBullet.addChild(Fireball.container);
          allParticle = { ...allParticle, Fireball };
        }
      );

      await YamatoParticle.createParticle(app.loader, "Firework1").then(
        (Firework1) => {
          containerFireworkParticle.addChild(Firework1.container);
          allParticle = { ...allParticle, Firework1 };
        }
      );

      await YamatoParticle.createParticle(app.loader, "Firework2").then(
        (Firework2) => {
          containerFireworkParticle.addChild(Firework2.container);
          allParticle = { ...allParticle, Firework2 };
        }
      );

      await YamatoParticle.createParticle(app.loader, "Fireworkstart").then(
        (Fireworkstart) => {
          containerFirework.addChild(Fireworkstart.container);
          allParticle = { ...allParticle, Fireworkstart };
        }
      );

      resolve(allParticle);

      app.loader.onError.add((e) => {
        reject(e);
      });
    });
  },
};

export default YamatoParticle;
