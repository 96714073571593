import {
  SHOW_LOBBY,
  STANBY,
  SHOW_MICRO_CONTENT,
  SHOW_QUESTION_TYPE,
  SHOW_QUESTION,
  SHOW_ANSWERING,
  END_QUESTION,
  SHOW_ANSWER_RESULT,
  SHOW_FINAL_LEADERBOARD,
  SHOW_GAME_RESULT,
} from "locales/en/translation.json";

export const initialState = {
  showLobby: false,
  stanby: false,
  showMicroContent: false,
  showQuestionType: false,
  showQuestion: false,
  showAnswering: false,
  endQuestion: false,
  showAnswerResult: false,
  finalLeaderboard: false,
  showGameResult: false,
};

export function reducer(state, action) {
  switch (action.type) {
    case SHOW_LOBBY:
      return { ...initialState, showLobby: true };
    case STANBY:
      return { ...initialState, stanby: true };
    case SHOW_MICRO_CONTENT:
      return { ...initialState, showMicroContent: true };
    case SHOW_QUESTION_TYPE:
      return { ...initialState, showQuestionType: true };
    case SHOW_QUESTION:
      return { ...initialState, showQuestion: true };
    case SHOW_ANSWERING:
      return { ...initialState, showAnswering: true };
    case END_QUESTION:
      return { ...initialState, endQuestion: true };
    case SHOW_ANSWER_RESULT:
      return { ...initialState, showAnswerResult: true };
    case SHOW_GAME_RESULT:
      return { ...initialState, showGameResult: true };
    case SHOW_FINAL_LEADERBOARD:
      return { ...initialState, finalLeaderboard: true };
    default:
      return { ...state };
  }
}
