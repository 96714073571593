import React, {
  lazy,
  memo,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { VonderGOContext } from "Service/VonderGOContext";
// import GameManager from "Game/GameManager";
// import GameContent from "GameContent";
import GameState from "Game/GameState";
import "App.css";
import { SHOW_LOBBY } from "locales/en/translation.json";
import Axios from "axios";
import {
  STANBY,
  SHOW_MICRO_CONTENT,
  SHOW_QUESTION_TYPE,
  SHOW_QUESTION,
  SHOW_ANSWERING,
  SHOW_ANSWER_RESULT,
  SHOW_GAME_RESULT,
} from "locales/en/translation.json";
import styled from "styled-components";
import { getQueryString } from "Service/Utils";
import { Box, GoButton, GoModal, TextTypo } from "Game/Style/GameStyle";
import { useLocalizationContext } from "Service/LocalizationProvider";
import { AppContext } from "Game/AppContext";
import isEmpty from "lodash/isEmpty";
import filter from "lodash/filter";
import * as Sentry from "@sentry/react";

const LoadingPage = lazy(() => import("Game/Component/Common/LoadingPage"));

export default memo(function VonderGOContent() {
  const {
    setPIN,
    uuid,
    setUUID,
    wsConnect,
    wsSend,
    wsOn,
    wsClose,
    addEndUser,
    endUsers,
    updateEndUser,
    removeEndUser,
    disconnectEndUser,
    setAllowCharacters,
    setChallengeID,
    setChallengeCode,
    setChallengeContext,
    setLevel,
    dispatchGameState,
    updateSettings,
    setEndSessionLoading,
    dispatchGameContextState,
    setUserLike,
    setCurrentGameData,
    setLeaderboardData,
    sessionStart,
    setCurrentPhase,
    dispatchAnimationState,
    resetGameSession,
    onPhaseUpdateData,
    setChallengeImage,
    isExit,
    setIsExit,
    setMaxUser,
  } = useContext(VonderGOContext);
  const [, setWasCreateSessionSuccessful] = useState(false);
  const [loadingUser, setLoadingUser] = useState(false);
  const [connectionVisible, setConnectionVisible] = useState(false);
  const [errorVisible, setErrorVisible] = useState(false);
  const [gameQRCode, setGameQRCode] = useState("");
  const [endUserConnect, setEndUserConnect] = useState([]);
  const [ovelapConnection, setOverlapConnection] = useState(false);
  const [errorCode, setErrorCode] = useState("");
  const [wasConnectToSessionSuccessful, setWasConnectToSessionSuccessful] =
    useState(false);
  const {
    themeAsset,
    themeEnemies,
    themeName,
    castleIcon,
    bossIcon,
    setThemeAsset,
    setThemeEnemies,
    setThemeName,
    setThemeAudio,
    setCastleIcon,
    setBossIcon,
    isShowExplanation,
    preloadCharacter,
    isLoadedAllCharacter,
    updateCharacterToGameplay,
  } = useContext(AppContext);

  const { t } = useLocalizationContext();

  const getPin = getQueryString("pin");
  const getOtp = getQueryString("otp");
  const getThemeId = getQueryString("themeId");

  useEffect(() => {
    if (isEmpty(endUserConnect)) {
      setLoadingUser(false);
    } else {
      setLoadingUser(true);
    }
  }, [endUserConnect]);

  useEffect(() => {
    if (
      getPin === "undefined" ||
      getOtp === "undefined" ||
      getThemeId === "undefined"
    ) {
      setErrorVisible(true);
    }
  }, [getOtp, getPin, getThemeId]);

  const onConnectToSession = useCallback(
    (pin) => {
      wsConnect(`${process.env.REACT_APP_VONDER_WEBSOCKET}/?pin=${pin}`);
    },
    [wsConnect]
  );

  const onSetRoomProperty = useCallback(
    (pin, otp) => {
      setPIN(pin);
      setUUID(otp);
    },
    [setPIN, setUUID]
  );

  const onCreateSession = useCallback(async () => {
    if (getPin && getOtp) {
      onSetRoomProperty(getPin, getOtp);
      setWasCreateSessionSuccessful(true);
      onConnectToSession(getPin);
      const qrCodeBody = {
        returnType: "url",
        url: `${process.env.REACT_APP_VONDER_END_USER}/?pin=${getPin}`,
      };
      try {
        const postQrCode = await Axios.post(
          `${process.env.REACT_APP_VONDER_URL}/upload-signer/generate/qr`,
          qrCodeBody
        );
        if (postQrCode) {
          setGameQRCode(postQrCode?.data);
        }
      } catch (error) {
        Sentry.captureException(error);
        console.log("qrcode error", error);
      }
    } else {
      setWasCreateSessionSuccessful(false);
    }
  }, [getOtp, getPin, onConnectToSession, onSetRoomProperty]);

  const onPhaseUpdate = useCallback(
    (phaseData) => {
      // Handle every phase of the game.
      // *Remark* this should be supported when monitor is disconnected and re-connect to the server and continue by current state of the game.
      setCurrentPhase(phaseData.phase);
      onPhaseUpdateData(phaseData);

      switch (phaseData.phase) {
        case "lobby":
          dispatchGameState({ type: SHOW_LOBBY });
          dispatchGameContextState({ type: "reset" });
          break;
        case "standby":
          setCurrentGameData(phaseData);
          dispatchGameState({ type: STANBY });
          break;
        case "micro-contents":
          setCurrentGameData(phaseData);
          if (phaseData?.microContents.length !== 0) {
            dispatchGameState({ type: SHOW_MICRO_CONTENT });
          } else {
            wsSend(JSON.stringify({ event: "question-ready" }));
          }
          break;
        case "questioning":
          setCurrentGameData(phaseData);
          dispatchGameState({ type: SHOW_QUESTION_TYPE });
          setTimeout(() => {
            dispatchGameState({ type: SHOW_QUESTION });
          }, 4000);
          break;
        case "answering":
          setCurrentGameData(phaseData);
          dispatchGameState({ type: SHOW_ANSWERING });
          break;
        case "explanation":
          setCurrentGameData(phaseData);
          dispatchGameContextState({
            type: "set_all_explanation",
            payload: phaseData,
          });
          // dispatchGameContextState({
          //   type: "set_all_content_pointer",
          //   payload: phaseData,
          // });
          break;
        case "end":
          setCurrentGameData(phaseData);
          setLeaderboardData(phaseData);
          dispatchGameState({ type: SHOW_GAME_RESULT });
          break;
        default:
          break;
        // throw new Error(
        //   "Unknown phase has been occuired. This should not be happened!"
        // );
      }
    },
    [
      setCurrentPhase,
      onPhaseUpdateData,
      dispatchGameState,
      dispatchGameContextState,
      setCurrentGameData,
      setLeaderboardData,
      wsSend,
    ]
  );

  const wsOnClose = useCallback(() => {
    console.log("final");
    setConnectionVisible(true);
  }, [setConnectionVisible]);

  const onWebSocketMessageReceived = useCallback(
    (data) => {
      if (uuid) {
        let json;
        try {
          json = JSON.parse(data);
        } catch (e) {
          Sentry.captureException(e);
          console.log(e);
        }
        if (json) {
          switch (json.event) {
            case "connect":
              {
                let allowCharacters = [
                  "vnd-001",
                  "vnd-002",
                  "vnd-003",
                  "vnd-004",
                ];
                let level = "default";
                setAllowCharacters(allowCharacters);
                setChallengeID(json.challengeID);
                setChallengeCode(json.challengeCode);
                setChallengeContext(json.challengeContext);
                setChallengeImage(json?.challengeNewCoverImage?.large);
                setLevel(level);
                updateSettings(json.sessionSettings);
                setThemeAsset(json?.theme?.assets);
                setThemeEnemies(json?.theme?.enemies);
                setThemeName(json?.theme?.name);
                setCastleIcon(json?.theme?.icon);
                setThemeAudio(json?.theme?.audio);
                setMaxUser(json?.maxSession);
                const findBossIcon =
                  json?.theme?.enemies[0]?.boss[0]?.icon?.find(
                    (item) => item.type === "multi"
                  );
                setBossIcon(findBossIcon?.img);

                wsSend(
                  JSON.stringify({
                    event: "session-level",
                    level,
                    allowCharacters,
                  })
                );
                preloadCharacter(json?.characters);
              }
              break;
            case "session-level":
              // Do nothing.
              break;
            case "session-pre-start":
              // Countdown for 3 seconds and start the game. add 0.5 second for "Go" word
              setEndUserConnect([]);
              setLoadingUser(false);
              setTimeout(() => {
                wsSend(JSON.stringify({ event: "session-start" }));
              }, 3500);
              break;
            case "session-start":
              // Handle event for intro scenario in PIXIAppContent and called back when scenatio is finished.
              console.log("json", json);
              sessionStart(json?.topEndUser);
              break;
            case "phase":
              // Handle every phase of the game.
              // *Remark* this should be supported when monitor is disconnected and re-connect to the server and continue by current state of the game.
              onPhaseUpdate(json);
              break;
            case "doubt":
              return dispatchGameContextState({
                type: "set_all_doubt",
                payload: json,
              });
            case "enduser-connect":
              console.log("json", json);
              let { name, characterID, characterSpine, characterAudio } = json;
              if (name && characterID && characterSpine) {
                addEndUser(json.userID);
                updateEndUser(json.userID, {
                  name,
                  characterID,
                  characterSpine,
                  characterAudio,
                });
                updateCharacterToGameplay({
                  characterID,
                  characterPath: characterSpine,
                  userID: json.userID,
                  userName: name,
                });
                console.log("reconnect");
              }
              const endUserConnect = () => {
                if (!addEndUser(json.userID)) {
                  console.warn(
                    "Duplication of enduser connection has been found. Cannot add new enduser and this event will be ignored"
                  );
                } else {
                  console.log("New enduser has connected.");
                  setEndUserConnect((prev) => [...prev, json?.userID]);
                  setLoadingUser(true);
                }
              };
              return endUserConnect();
            case "enduser-register":
              setLoadingUser(false);
              const endUserRegister = () => {
                let { name, characterID, characterSpine, characterAudio } =
                  json;
                if (
                  !updateEndUser(json.userID, {
                    name,
                    characterID,
                    characterSpine,
                    characterAudio,
                    isConnect: true,
                  })
                )
                  console.warn(
                    "Cannot update enduser that isn't in game context. This event will be ignored"
                  );
                else {
                  console.log("Enduser has been registered.");
                  setEndUserConnect((prev) => {
                    const removeUserRegistered = filter(prev, function (id) {
                      return id !== json.userID;
                    });
                    return [...removeUserRegistered];
                  });
                }
              };
              return endUserRegister();
            // case "enduser-disconnect":
            //   const endUserDisconnect = () => {
            //     if (!removeEndUser(json.userID))
            //       console.warn(
            //         "Cannot remove enduser that isn't in game context. This event will be ignored"
            //       );
            //     else console.log("Enduser has disconnected.");
            //   };
            //   setLoadingUser(false);
            //   return endUserDisconnect();
            case "enduser-disconnect":
              const endUserDisconnect = () => {
                if (!disconnectEndUser(json.userID))
                  console.warn(
                    "Cannot remove enduser that isn't in game context. This event will be ignored"
                  );
                else console.log("Enduser has disconnected.");
              };
              setLoadingUser(false);
              return endUserDisconnect();

            case "enduser-kick":
              const endUserKick = () => {
                if (!removeEndUser(json.userID))
                  console.warn(
                    "Cannot remove enduser that isn't in game context. This event will be ignored"
                  );
                else console.log("Enduser has disconnected.");
              };
              setLoadingUser(false);
              return endUserKick();
            // return;
            case "enduser-answered":
              return dispatchGameContextState({
                type: "set_user_answers",
                payload: json,
              });
            case "doubt-like":
              setUserLike(json);
              break;
            case "session-end":
              resetGameSession();
              dispatchAnimationState({ type: "reset" });
              if (isExit) {
                wsSend(JSON.stringify({ event: "session-close" }));
                endUsers?.map((item) => {
                  return wsSend(
                    JSON.stringify({ event: "kick", userID: item?.userID })
                  );
                });
              }
              return setEndSessionLoading(false);
            case "session-close":
              wsClose();
              setIsExit(false);
              // closeWindow();
              window.onbeforeunload = () => null;
              window.close();
              break;
            case "error":
              setOverlapConnection(true);
              setErrorCode(json.code);
              // wsOnClose();
              break;
            default:
              console.warn("No events matched from WebSocket events.");
              break;
          }
          // setErrorCode(json.code || "");
        } else {
          console.error(
            "Cannot using vonderGoContext because it's updated by react DOM and contain no value."
          );
        }
      } else {
        console.error(
          "Cannot using vonderGoContext because it's updated by react DOM and contain no value."
        );
      }
    },
    [
      uuid,
      sessionStart,
      onPhaseUpdate,
      dispatchGameContextState,
      disconnectEndUser,
      setUserLike,
      resetGameSession,
      dispatchAnimationState,
      isExit,
      setEndSessionLoading,
      wsClose,
      setIsExit,
      setAllowCharacters,
      setChallengeID,
      setChallengeCode,
      setChallengeContext,
      setChallengeImage,
      setLevel,
      updateSettings,
      setThemeAsset,
      setThemeEnemies,
      setThemeName,
      setCastleIcon,
      setThemeAudio,
      setMaxUser,
      setBossIcon,
      wsSend,
      preloadCharacter,
      addEndUser,
      updateEndUser,
      updateCharacterToGameplay,
      removeEndUser,
      endUsers,
    ]
  );

  const wsOnOpen = useCallback(() => {
    console.log("Connected!");
    setWasConnectToSessionSuccessful(true);
    wsSend(JSON.stringify({ event: "connect", type: "controller", uuid }));
    dispatchGameState({ type: SHOW_LOBBY });
  }, [dispatchGameState, wsSend, uuid]);

  // const reConnect = useCallback(() => {
  //   setConnectionVisible(false);
  //   wsSend(JSON.stringify({ event: "connect", type: "controller", uuid }));
  // }, [uuid, wsSend]);

  const wsOnMessage = useCallback(
    (data) => {
      onWebSocketMessageReceived(data);
    },
    [onWebSocketMessageReceived]
  );

  const wsOnError = useCallback((e) => {
    console.error(e);
  }, []);

  useEffect(() => {
    wsOn("open", wsOnOpen);
    wsOn("close", wsOnClose);
    wsOn("message", wsOnMessage);
    wsOn("error", wsOnError);
  }, [wsOn, wsOnClose, wsOnError, wsOnMessage, wsOnOpen]);

  const onShowExplanation = useCallback(() => {
    dispatchGameState({ type: SHOW_ANSWER_RESULT });
    dispatchAnimationState({
      type: "show_answer_result_card",
      payload: "up",
    });
  }, [dispatchAnimationState, dispatchGameState]);

  useEffect(() => {
    if (isShowExplanation) {
      onShowExplanation();
    }
  }, [isShowExplanation, onShowExplanation]);

  //TODO: check load character successfully
  const displayGame = useMemo(() => {
    if (
      wasConnectToSessionSuccessful &&
      gameQRCode &&
      themeAsset &&
      themeEnemies &&
      themeName &&
      castleIcon &&
      bossIcon &&
      isLoadedAllCharacter
    ) {
      return <GameState loadingUser={loadingUser} gameQRCode={gameQRCode} />;
    } else {
      return (
        <FormContainer>
          <LoadingPage onCreateSession={onCreateSession} />
        </FormContainer>
      );
    }
  }, [
    bossIcon,
    castleIcon,
    gameQRCode,
    isLoadedAllCharacter,
    loadingUser,
    onCreateSession,
    themeAsset,
    themeEnemies,
    themeName,
    wasConnectToSessionSuccessful,
  ]);

  return (
    <>
      {displayGame}
      <GoModal
        visible={connectionVisible}
        footer={null}
        centered
        closable={false}
      >
        <Box align="center" direction="column" justify="space-evenly">
          <Box
            align="center"
            direction="column"
            justify="center"
            style={{ width: "100%" }}
          >
            {!ovelapConnection && (
              <TextTypo
                style={{ marginTop: "5%" }}
                size="1.5vw"
                type="secondary"
                strong="true"
              >
                {t("CONNECTION_LOST")}
              </TextTypo>
            )}
            <TextTypo
              style={{ margin: "0% 2% 5% 2%" }}
              size="1.5vw"
              type="secondary"
              strong="true"
            >
              {ovelapConnection
                ? t("CONNECTION_OVERLAP_MESSAGE", { code: errorCode })
                : t("CONNECTION_LOST_MESSAGE")}
            </TextTypo>
          </Box>
          {/* <GoButton size="small" type="warning" onClick={reConnect}>
            <TextTypo size="1.2vw" type="white">
              {t("BUTTON.RETRY")}
            </TextTypo>
          </GoButton> */}
          <GoButton size="small" type="primary" onClick={() => window.close()}>
            <TextTypo size="1.2vw" type="white">
              {t("BUTTON.OK")}
            </TextTypo>
          </GoButton>
        </Box>
      </GoModal>
      <GoModal visible={errorVisible} footer={null} centered closable={false}>
        <Box align="center" direction="column" justify="space-evenly">
          <TextTypo
            style={{ marginBottom: "3%" }}
            size="1.5vw"
            type="secondary"
            strong="true"
          >
            {t("NO_GAME_DATA")}
          </TextTypo>
          <GoButton size="small" type="warning" onClick={() => window.close()}>
            <TextTypo size="1.2vw" type="white">
              {t("BUTTON.CLOSE")}
            </TextTypo>
          </GoButton>
        </Box>
      </GoModal>
    </>
  );
});

const FormContainer = styled.div`
  width: 100vw;
  max-height: 56.25vw;
  max-width: 177.78vh;
  padding: 0px;
  margin: auto;
  border: 0px;
  position: absolute;
  overflow: hidden;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 3;
`;
