import * as PIXI from "pixi.js";

import StewardParticle1 from "Game/Assets/ParticleSprite/Steward_Particle_0000_Layer-75.png";
import StewardParticle2 from "Game/Assets/ParticleSprite/Steward_Particle_0001_Layer-75.png";
import StewardParticle3 from "Game/Assets/ParticleSprite/Steward_Particle_0002_Layer-69.png";
import * as Sentry from "@sentry/react";

const StewardParticle = {
  initial: async (app) => {},
  createAnimatedSprite: () => {
    const StewardTexture = [
      StewardParticle1,
      StewardParticle2,
      StewardParticle3,
    ];
    return new Promise((resolve, reject) => {
      let textureArr = [];
      for (let i = 0; i < StewardTexture.length; i++) {
        const texture = PIXI.Texture.from(StewardTexture[i]);
        // console.log(texture);
        textureArr.push(texture);
      }
      const animateSteward = new PIXI.AnimatedSprite(textureArr);
      const container = new PIXI.Container();
      container.addChild(animateSteward);
      resolve({ container, animateSteward });
    });
  },
  load: (app, animateContainer) => {
    return new Promise((resolve, reject) => {
      const loader = new PIXI.Loader();
      let promises = [StewardParticle.createAnimatedSprite()];
      Promise.all(promises)
        .then(([animatedSprite]) => {
          animateContainer.addChild(animatedSprite.container);
          animateContainer.position.y = -145;

          animatedSprite.animateSteward.animationSpeed = 0.75;
          animatedSprite.container.scale.set(0.3);
          animatedSprite.container.visible = false;

          let particle = {
            animatedSprite: animatedSprite,
          };
          resolve(particle);
        })
        .catch((e) => {
          Sentry.captureException(e);
          reject(e);
        });
      loader.onError.add((e) => {
        reject(e);
      });
    });
  },
};

export default StewardParticle;
