import React, { Suspense, useEffect } from "react";
import { VonderGOProvider } from "Service/VonderGOContext";
import GlobalStyles from "GlobalStyles";
import "App.css";
import Axios from "axios";
import { SWRConfig } from "swr";
import { AppProvider } from "Game/AppContext";
import { PixiProvider } from "Game/PixiContext";
import includes from "lodash/includes";
import { LocalizationProvider } from "Service/LocalizationProvider";
import GlobalComponent from "GlobalComponent";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";

const token =
  "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJfaWQiOiI1ZjhlYWVjZWI2Mjc2YjY5NTY4NGE5NDEiLCJ1c2VybmFtZSI6ImFkbWluMUB2b25kZXIuaW8iLCJvcmdhbml6YXRpb24iOnsiX2lkIjoiNWY4ZWFlYzM2NTA4MjQ2OTU3Njg3ZTM0IiwibmFtZSI6ImFkbWluMSIsImNvZGUiOiJhZG1pbjEifSwib3JnTGFiZWwiOiJhZG1pbjEiLCJvcmdOYW1lIjoiYWRtaW4xIiwicGVybWlzc2lvbiI6eyJ3b3JrZmxvdyI6eyJib3R0cmFpbmluZyI6MCwiY3JlYXRlbWljcm9sZWFybmluZyI6MCwicHVsc2VTdXJ2ZXkiOjAsIm1hbmFnZUF1dGhlbnRpY2F0aW9uIjowLCJib2FyZGNhc3QiOjAsInN1cnZleUFuYWx5dGljcyI6MCwibWljcm9sZWFybmluZyI6MCwibWFuYWdlU3VydmV5IjowLCJjaGF0Ym90TWFuYWdlciI6MCwiZmFxIjowLCJhZG1pbmRlbGVnYXRlcyI6MCwibWFuYWdlRW1wbG95ZWUiOjAsInF1aWNrUmVwbHkiOjAsIm1haW5NZW51IjowLCJtYW5hZ2VVc2VyR3JvdXAiOjAsIm1hbmFnZVBsYXRmb3JtIjowLCJtaXNzaW9uTWFuYWdlciI6MCwiZGFzaGJvYXJkIjowLCJrcGkiOjAsInNjb3JlQmFua2luZyI6MCwibWFuYWdlQ3VycmVuY3kiOjAsImFkbWluU2V0dGluZyI6MCwic2NvcmVCYW5rIjowLCJkZWVwbGlua0dlbmVyYXRvciI6MCwiY2hhdGxvZyI6MCwicGxhdGZvcm1NYW5hZ2VyIjowLCJtYW5hZ2VGbGFzaCI6Mn19LCJuYW1lIjoiIiwiZGF0YSI6eyJyZWZyZXNoVG9rZW4iOiJleUpoYkdjaU9pSklVekkxTmlJc0luUjVjQ0k2SWtwWFZDSjkuZXlKa1lYUmhJam9pTldZNFpXRmxZMlZpTmpJM05tSTJPVFUyT0RSaE9UUXhJaXdpYVdGMElqb3hOakUzTVRjNU1UazFOakU0TENKbGVIQWlPakUyTWpJek5qTXhPVFUyTVRoOS5Da1V4b0xTd0VvXzJPTlMzc1ZqTlZIVG9JOEVZcWU5RGZnWjQ0ODNoNVFzIiwidXBkYXRlZF9hdCI6IjIwMjAtMTItMDhUMTI6NDg6NTcuMjEyWiIsImdlbmRlciI6IiIsImpvYiI6IiIsImJpcnRoRGF5IjoiMjAyMS0wMy0zMVQwODoyNjoyNy41MzlaIn0sImlhdCI6MTYxNzE3OTE5NTYyMCwiZXhwIjoxNjE3MTc5NDk1NjIwfQ.Wht07pCOb7xoz_CJ3rm5zpW-OYO-rCL41LNnRbRZafM";

const isDev = process.env.REACT_APP_VONDER_URL.indexOf("dev") !== -1;
const isStg = process.env.REACT_APP_VONDER_URL.indexOf("stg") !== -1;
const host = window.location.hostname;

if (!isDev && !isStg) {
  console.log = function () {};
}

const fetcher = async (url) => {
  const axios = await import("axios");
  try {
    const response = await axios.get(url, {
      headers: {
        Authorization: token,
      },
    });
    return response.data;
  } catch (error) {
    Sentry.captureException(error);
    return Promise.reject(error);
  }
};

const App = () => {
  const axiosGlobalConfig = () => {
    const jwt = localStorage.getItem("user_token");
    Axios.interceptors.request.use(function (config) {
      const uploadUrl = includes(config?.url, "upload-signer");
      if (jwt && !uploadUrl) {
        config.headers.Authorization = `Bearer ${atob(token)}`;
      }
      return config;
    });
  };

  useEffect(() => {
    axiosGlobalConfig();
  }, []);

  // fetch lobby background image

  function getEnviromentSentry(hostName) {
    switch (hostName) {
      case "localhost" || "livego-dev.vonder.co.th":
        return "develop";
      case "livego-stg.vonder.co.th":
        return "staging";
      case "livego.vonder.co.th":
        return "production";
      default:
        break;
    }
  }

  useEffect(() => {
    Sentry.init({
      dsn: "https://e9509e302506459a87a134ab341ea1ba@o449293.ingest.sentry.io/4504756918812672",
      environment: getEnviromentSentry(host),
      integrations: [
        new BrowserTracing(),
        new Sentry.Replay({
          maskAllText: false,
          blockAllMedia: false,
        }),
      ],
      tracesSampleRate: 1.0,
        replaysSessionSampleRate: 0.1,
        replaysOnErrorSampleRate: 1.0,
    });
  }, []);

  return (
    <SWRConfig
      value={{
        revalidateOnFocus: false,
        fetcher,
      }}
    >
      <Suspense fallback={"..."}>
        <AppProvider>
          <PixiProvider>
            <LocalizationProvider>
              <VonderGOProvider>
                <GlobalComponent />
                <GlobalStyles />
              </VonderGOProvider>
            </LocalizationProvider>
          </PixiProvider>
        </AppProvider>
      </Suspense>
    </SWRConfig>
  );
};

export default App;
