import * as PIXI from "pixi.js";
import PIXISpine from "Plugins/pixi-spine";
import trail from "Game/Assets/trail.png";
import utils from "Service/UtilsCalculate";
import { generateParabola } from "Service/Parabola";
import { Tween, Tweener } from "Classes/Tween";
import RobinParticle from "Game/PixiCores/Particles/Robin/RobinParticle";
import * as Sentry from "@sentry/react";

const character = {
  scale: 0.1,
  sequence: {
    idle: [{ name: "Idle", loop: true }],
    move: [{ name: "Move", loop: true }],
    charge: [
      { name: "Charging", loop: false },
      { name: "ChargingLoop", loop: true, delay: 0 },
    ],
    attack: [
      { name: "Attack", loop: false },
      { name: "Idle", loop: true, delay: 0 },
    ],
    attackOnce: [
      { name: "Charging", loop: false },
      { name: "Attack", loop: false, delay: 0 },
      { name: "Idle", loop: true, delay: 0 },
    ],
    hit: [
      { name: "Hit", loop: false },
      { name: "Idle", loop: true, delay: 0 },
    ],
    win: [{ name: "Win", loop: true }],
    lose: [{ name: "Lose", loop: false }],
  },
  event: {
    ArrowFired: () => {
      character.controller.setFiredBulletTo(
        character.controller.targetPosition
      );
    },
    ArrowCharge: () => {},
    ChargeLoop: () => {},
  },
  eventListener: {
    event: (entry, { data }) => {
      const { name } = data;
      character.event[name]?.();
    },
  },

  playSequence: (spine, sequenceData) => {
    if (!sequenceData || sequenceData.length === 0) return;
    spine.state.setAnimation(0, sequenceData[0].name, sequenceData[0].loop);
    for (let i = 1; i < sequenceData.length; i++)
      spine.state.addAnimation(
        0,
        sequenceData[i].name,
        sequenceData[i].loop,
        sequenceData[i].delay
      );
  },
  initial: async (app, path) => {
    return new Promise((resolve, reject) => {
      const preload = new PIXI.Loader();
      preload.add("character_VND_003", path);
      preload.load(async (loader, resources) => {
        // console.log("resources", resources);
        if (app.loader.resources) {
          app.loader.resources.character_VND_003 = resources;
        }
        // console.log("app", app);
      });
      preload.onComplete.add(async () => {
        await RobinParticle.initial(app);
        resolve("load Robin complete");
      });
      preload.onError.add((e) => {
        reject(e);
      });
    });
  },
  create: async (app, audioRef, path, preload) => {
    return new Promise((resolve, reject) => {
      let promises = [
        new Promise((resolve, reject) => {
          // const preload = new PIXI.Loader();
          if (!preload.resources.character_VND_003) {
            preload.add(
              "character_VND_003",
              // process.env.REACT_APP_API_URL_GO_CHARACTER + "/VND-001/VND-001.json"
              path
            );
          }
          // preload.add(
          //   "character",
          //   // process.env.REACT_APP_API_URL_GO_CHARACTER + "/VND-003/VND-003.json"
          //   path
          // );
          preload.load(async (loader, resources) => {
            // let spineData = resources["character"].spineData;
            let spineData =
              resources["character_VND_003"].character_VND_003.spineData;
            if (spineData) {
              let spine = new PIXISpine.Spine(spineData);
              spine.scale.set(character.scale);
              spine.state.addListener(character.eventListener);
              let container = new PIXI.Container();
              let containerParticle = new PIXI.Container();
              let containerBullet = new PIXI.Container();
              let containerTrail = new PIXI.Container();
              let emitterController;
              container.addChild(spine);
              container.addChild(containerParticle);
              container.addChild(containerBullet);
              container.addChild(containerTrail);
              containerParticle.visible = false;

              //change Particle load
              await RobinParticle.load(app, containerBullet, loader).then(
                (particle) => {
                  emitterController = particle;
                }
              );

              const controller = {
                spine,
                container,
                containerParticle,
                containerBullet,
                emitterController,
                targetPosition: {
                  x: 0,
                  y: 0,
                },
                moveSpeed: 100,
                isMoving: false,
                infoCharacter: {
                  characterName: "robin",
                },
                setActive: (isActive) => {
                  container.visible = isActive;
                },
                setTargetPosition: (x, y) => {
                  character.controller.targetPosition = {
                    x: x,
                    y: y,
                  };
                },
                setParticleActive: (isActive) => {
                  containerParticle.visible = isActive;
                  // particle
                },
                charging: () => {
                  //
                },
                setFiredBulletTo: async (targetPosition) => {
                  // console.log("targetPosition", targetPosition);
                  // console.log("emitterController", emitterController);
                  emitterController.arrow.emitter.cleanup();
                  const trailTexture = PIXI.Texture.from(trail);
                  const historyX = [];
                  const historyY = [];
                  const historySize = 20;
                  const ropeSize = 20;
                  const points = [];

                  containerBullet.position.set(0, 0);
                  containerTrail.position.set(0, 0);
                  for (let i = 0; i < historySize; i++) {
                    historyX.push(containerBullet.position.x);
                    historyY.push(containerBullet.position.y);
                  }

                  for (let i = 0; i < ropeSize; i++) {
                    points.push(new PIXI.Point(0, 0));
                  }

                  const rope = new PIXI.SimpleRope(trailTexture, points);
                  rope.blendmode = PIXI.BLEND_MODES.ADD;
                  containerTrail.position.set(containerBullet.position.x, -80);
                  containerTrail.addChild(rope);

                  new Tweener(app.ticker)
                    .insert(
                      0.233,
                      new Tween(0.367, (pos, dt) => {
                        if (pos === 0) {
                          audioRef.current._group["VND-003"]._list[0].play(
                            "attack"
                          );
                        }
                        if (pos === 1) {
                          audioRef.current._group["VND-003"]._list[0].play(
                            "hitTarget"
                          );
                          containerBullet.visible = false;
                          containerBullet.position.set(0, 0);
                          containerTrail.position.set(0, 0);
                          containerTrail.removeChildren();
                          emitterController.arrow.emitter.cleanup();
                        } else {
                          containerBullet.position.x = pos * targetPosition.x;
                          containerBullet.position.y = generateParabola(
                            pos,
                            0.5,
                            0,
                            -10,
                            10
                          );
                          emitterController.arrow.emitter.update(dt);
                          if (!containerBullet.visible)
                            containerBullet.visible = true;
                          historyX.pop();
                          historyX.unshift(containerBullet.position.x);
                          historyY.pop();
                          historyY.unshift(containerBullet.position.y);
                          for (let i = 0; i < ropeSize; i++) {
                            const p = points[i];
                            const ix = utils.cubicInterpolation(
                              historyX,
                              (i / ropeSize) * historySize
                            );
                            const iy = utils.cubicInterpolation(
                              historyY,
                              (i / ropeSize) * historySize
                            );
                            p.x = ix;
                            p.y = iy;
                          }
                        }
                      })
                    )
                    .play();
                  await character.controller.routineWait(0.5).then(() => {
                    console.log("particle hit");
                  });
                },
                hurt: () => {},
                playState: (stateName) => {
                  if (character.sequence[stateName]) {
                    character.playSequence(
                      controller.spine,
                      character.sequence[stateName]
                    );
                  }
                },
                destroy: () => {
                  if (controller.spine) {
                    controller.spine.destroy();
                    controller.container?.destroy();
                    controller.containerParticle?.destroy();
                    controller.containerBullet?.destroy();
                    controller.containerTrail?.destroy();
                  }
                },
                routineWait: async (second) => {
                  await new Promise((resolve) => {
                    const ticker = app.ticker;
                    let current = 0;
                    const onUpdate = (elapsedTime) => {
                      const deltaTime = (1 / ticker.FPS) * ticker.speed;
                      current += deltaTime;
                      if (current > second) {
                        ticker.remove(onUpdate);
                        resolve();
                      }
                    };
                    ticker.add(onUpdate);
                  });
                },
              };
              character.controller = controller;
              resolve(controller);
            } else {
              reject({ error: "ROBIN_NO_SPINE_DATA" });
            }
          });
        }),
      ];
      Promise.all(promises)
        .then((results) => {
          const [character] = results;
          console.log({ character });
          resolve(character);
        })
        .catch((e) => {
          Sentry.captureException(e);
          reject(e);
        });
    });
  },
};

export default character;
