import { useRef, useEffect, useState } from "react";
import * as Sentry from "@sentry/react";

const useSound = (audioRef, options, group) => {
  const [isReady, setIsReady] = useState(false);
  const howlRef = useRef();
  useEffect(() => {
    audioRef.current
      .loadAsync(options, group)
      .then((howl) => {
        howlRef.current = howl;
        setIsReady(true);
      })
      .catch((e) => {
        Sentry.captureException(e);
        console.error(e);
      });
  }, [audioRef, group, howlRef, options]);
  return [isReady, howlRef];
};

export default useSound;
