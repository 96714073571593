import {
  // ParallaxBlock,
  // ParallaxLayer,
  ParallaxLoop,
} from "Classes/Parallax.js";
import * as PIXI from "pixi.js";

import * as Particles from "pixi-particles";
import snow_01 from "Game/Assets/LevelParticleSprite/Flipperborea/Snow_01.png";
import snow_02 from "Game/Assets/LevelParticleSprite/Flipperborea/Snow_02.png";
import snow_03 from "Game/Assets/LevelParticleSprite/Flipperborea/Snow_03.png";
import snow_04 from "Game/Assets/LevelParticleSprite/Flipperborea/Snow_04.png";
import snow_05 from "Game/Assets/LevelParticleSprite/Flipperborea/Snow_05.png";
import snow_06 from "Game/Assets/LevelParticleSprite/Flipperborea/Snow_06.png";
import snow_07 from "Game/Assets/LevelParticleSprite/Flipperborea/Snow_07.png";
import snow_08 from "Game/Assets/LevelParticleSprite/Flipperborea/Snow_08.png";
import snow from "Game/PixiCores/Levels/snow.json";

const level = {
  areaLength: 4000,
  load: ({ app, assets }) => {
    return new Promise((resolve, reject) => {
      const loader = new PIXI.Loader();
      let spriteList = [
        { name: "sprites_multi_building" },
        { name: "sprites_multi_large_0" },
        { name: "sprites_multi_large_1" },
        { name: "sprites_multi_environments_0" },
        { name: "sprites_multi_environments_1" },
        { name: "sprites_multi_environments_2" },
      ];
      assets.forEach((element) => {
        // console.log("element", element);
        const path = element.link;
        const arr = path.split("/");
        const filename = arr[arr.length - 1].split(".");
        const type = filename[0];
        if (
          spriteList.find((sprite) => {
            return sprite.name === type;
          })
        ) {
          loader.add(type, element.link);
        }
      });

      let particleList = [
        snow_01,
        snow_02,
        snow_03,
        snow_04,
        snow_05,
        snow_06,
        snow_07,
        snow_08,
      ];
      particleList.forEach((element, index) => {
        loader.add(index.toString(), element);
      });
      loader.load((loader, resources) => {
        const spritesheetTextureLarge0 =
          resources["sprites_multi_large_0"].spritesheet.textures;
        const spritesheetTextureLarge1 =
          resources["sprites_multi_large_1"].spritesheet.textures;
        const spritesheetTextureBuilding =
          resources["sprites_multi_building"].spritesheet.textures;
        const spritesheetTextureEnv2 =
          resources["sprites_multi_environments_2"].spritesheet.textures;
        const spritesheetTextureEnv1 =
          resources["sprites_multi_environments_1"].spritesheet.textures;
        const spritesheetTextureEnv0 =
          resources["sprites_multi_environments_0"].spritesheet.textures;

        const parallax = new ParallaxLoop(new PIXI.Container());

        const BG = new PIXI.Sprite(
          spritesheetTextureLarge0["Flipperborea_0017_Layer17_BG.png"]
        );
        BG.position.set(-960, -1080);

        const Mountain5 = new PIXI.Sprite(
          spritesheetTextureEnv1["Flipperborea_0016_Layer16_Mountain5.png"]
        );
        Mountain5.position.set(1560, -740);
        // Mountain5.scale.set(1.25);

        const Mountain4 = new PIXI.Sprite(
          spritesheetTextureLarge1["Flipperborea_0015_Layer15_Mountain4.png"]
        );
        Mountain4.position.set(100, -1020);

        const LeftStone = new PIXI.Sprite(
          spritesheetTextureEnv1["Flipperborea_0014_Layer14_LeftStone.png"]
        );
        LeftStone.position.set(2000, -860);

        const Camp3 = new PIXI.Sprite(
          spritesheetTextureBuilding["Flipperborea_0013_Layer13_Camp3.png"]
        );
        Camp3.position.set(1680, -440);

        const Camp2 = new PIXI.Sprite(
          spritesheetTextureBuilding["Flipperborea_0012_Layer12_Camp2.png"]
        );
        Camp2.position.set(1780, -570);

        const Spike_Front = new PIXI.Sprite(
          spritesheetTextureBuilding[
            "Flipperborea_0011_Layer11_Spike_Front.png"
          ]
        );
        Spike_Front.position.set(1500, -300);

        const RightStone = new PIXI.Sprite(
          spritesheetTextureEnv2["Flipperborea_0010_Layer10_RightStone.png"]
        );
        RightStone.position.set(2650, -880);

        const Camp1 = new PIXI.Sprite(
          spritesheetTextureBuilding["Flipperborea_0009_Layer9_Camp1.png"]
        );
        Camp1.position.set(2900, -337);

        const Spike_Back = new PIXI.Sprite(
          spritesheetTextureBuilding["Flipperborea_0008_Layer8_Spike_Back.png"]
        );
        Spike_Back.position.set(2160, -380);

        const Mountain3 = new PIXI.Sprite(
          spritesheetTextureEnv2["Flipperborea_0007_Layer7_Mountain3.png"]
        );
        Mountain3.position.set(-820, -1000);
        // Mountain3.scale.set(1.25);

        const Mountain2 = new PIXI.Sprite(
          spritesheetTextureEnv0["Flipperborea_0006_Layer6_Mountain2.png"]
        );
        Mountain2.position.set(-400, -900);
        // Mountain2.scale.set(1.25);

        const Mountain1 = new PIXI.Sprite(
          spritesheetTextureEnv2["Flipperborea_0005_Layer5_Mountain1.png"]
        );
        Mountain1.position.set(-820, -960);
        // Mountain1.scale.set(1.25);

        const Altar = new PIXI.Sprite(
          spritesheetTextureEnv2["Flipperborea_0004_Layer5_Altar.png"]
        );
        Altar.position.set(620, -380);
        // Altar.scale.set(1.25);

        const Forest_Back = new PIXI.Sprite(
          spritesheetTextureEnv0["Flipperborea_0003_Layer4_Forest_Back.png"]
        );
        Forest_Back.position.set(-960, -680);
        Forest_Back.scale.set(1.25);

        const Forest_Front = new PIXI.Sprite(
          spritesheetTextureEnv1["Flipperborea_0002_Layer3_Forest_Front.png"]
        );
        Forest_Front.position.set(-960, -555);
        Forest_Front.scale.set(1.25);

        const Castle = new PIXI.Sprite(
          spritesheetTextureBuilding["Flipperborea_0001_Layer2_Castle.png"]
        );
        // Castle.position.set(-830, -1060);
        Castle.position.set(-640, -880);

        const Floor = new PIXI.Sprite(
          spritesheetTextureLarge0["Flipperborea_0000_Layer1_Floor.png"]
        );
        Floor.position.set(-960, -120);

        parallax.container.addChild(BG);
        parallax.container.addChild(Mountain5);
        parallax.container.addChild(Mountain4);
        parallax.container.addChild(LeftStone);
        parallax.container.addChild(Camp3);
        parallax.container.addChild(Camp2);
        parallax.container.addChild(Spike_Front);
        parallax.container.addChild(RightStone);
        parallax.container.addChild(Camp1);
        parallax.container.addChild(Spike_Back);
        parallax.container.addChild(Mountain3);
        parallax.container.addChild(Mountain2);
        parallax.container.addChild(Mountain1);
        parallax.container.addChild(Altar);
        parallax.container.addChild(Forest_Back);
        parallax.container.addChild(Forest_Front);
        parallax.container.addChild(Castle);
        parallax.container.addChild(Floor);

        // parallax.addLayer(layerClouds);

        const textures = [];
        for (let index = 0; index < 8; index++) {
          textures.push(resources[index].texture);
        }
        const particleContainer = new PIXI.Container();
        const emitter = new Particles.Emitter(
          particleContainer,
          textures,
          snow.data
        );
        emitter.emit = true;
        let ticker = app.ticker;
        let deltaTime = (1 / ticker.FPS) * ticker.speed;
        let updateSnow = () => {
          emitter.update(deltaTime);
        };
        app.ticker.add(updateSnow);

        parallax.container.addChild(particleContainer);
        particleContainer.position.set(0, -1080);

        const controller = {
          parallax,
          areaLength: level.areaLength,

          update: () => {
            parallax.updateParallaxPosition();
          },
          setSize: (size) => {
            parallax.setSize(size);
          },
          setPosition: (position) => {
            parallax.setPosition(position);
          },
          destroy: () => {
            parallax.destroy();
            app.ticker.remove(updateSnow);
          },
        };
        resolve(controller);
      });
      loader.onError.add((e) => {
        reject(e);
      });
    });
  },
};

export default level;
