import * as PIXI from "pixi.js";
import * as Particles from "pixi-particles";

import SpikeImpactParticle from "Game/Assets/ParticleSprite/Dmitri-Particle_0000_SpikeImpactParticle.png";
import SpikeImpactDust from "Game/Assets/ParticleSprite/Dmitri-Particle_0001_SpikeImpactDust.png";
import IceSpike1 from "Game/Assets/ParticleSprite/Dmitri-Particle_0002_IceSpike1.png";
import IceSpike2 from "Game/Assets/ParticleSprite/Dmitri-Particle_0003_IceSpike2.png";
import IceSpike3 from "Game/Assets/ParticleSprite/Dmitri-Particle_0004_IceSpike3.png";
import StompImpactParticle from "Game/Assets/ParticleSprite/Dmitri-Particle_0005_StompImpactParticle.png";
import StompImpact from "Game/Assets/ParticleSprite/Dmitri-Particle_0006_StompImpact.png";
import StompImpactDust from "Game/Assets/ParticleSprite/Dmitri-Particle_0007_StompImpactDust.png";
import Arrow from "Game/Assets/ParticleSprite/Arrow.png";
import * as Sentry from "@sentry/react";

const DmitriParticle = {
  createParticle: (loader, name) => {
    return new Promise((resolve, reject) => {
      import(`./${name}.json`)
        .then((json) => {
          if (json.images && json.data) {
            // const loader = new PIXI.Loader();
            json.images.forEach((image, index) => {
              function getImg(name) {
                switch (name) {
                  case "SpikeImpactParticle":
                    return SpikeImpactParticle;
                  case "SpikeImpactDust":
                    return SpikeImpactDust;
                  case "IceSpike1":
                    return IceSpike1;
                  case "IceSpike2":
                    return IceSpike2;
                  case "IceSpike3":
                    return IceSpike3;
                  case "StompImpactParticle":
                    return StompImpactParticle;
                  case "StompImpact":
                    return StompImpact;
                  case "StompImpactDust":
                    return StompImpactDust;

                  default:
                    return Arrow;
                }
              }
              const path = getImg(name);

              if (!loader.resources[name]) {
                loader.add(name, path);
              }
            });
            loader.onError.add((e) => {
              console.log(e);
              reject({ error: "CANNOT_LOAD_PARTICLE" });
            });
            loader.load((loader, resources) => {
              const textures = resources[name].texture;
              const container = new PIXI.Container();
              const emitter = new Particles.Emitter(
                container,
                textures,
                json.data
              );

              resolve({ container, emitter });
            });
          }
        })
        .catch((e) => {
          Sentry.captureException(e);
          console.error(e);
          reject({ error: "CANNOT_IMPORT_CLASS" });
        });
    });
  },

  load: (
    app,

    stompParticle,
    spikeParticle,
    containerBulletGenerate
  ) => {
    return new Promise(async (resolve, reject) => {
      let allParticle = {};
      let allBullet = [];
      let allSpikes = [];

      for (let index = 0; index < 5; index++) {
        let bulletContainer = new PIXI.Container();
        containerBulletGenerate.addChild(bulletContainer);
        bulletContainer.controller = {};
        let containerArrow = new PIXI.Container();
        bulletContainer.addChild(containerArrow);

        bulletContainer.controller = {
          ...bulletContainer.controller,
          containerArrow,
        };
        await DmitriParticle.createParticle(app.loader, "DmitriArrow").then(
          (DmitriArrow) => {
            containerArrow.addChild(DmitriArrow.container);
            bulletContainer.controller = {
              ...bulletContainer.controller,
              DmitriArrow,
            };
          }
        );
        allBullet.push(bulletContainer);
      }
      allParticle = { ...allParticle, allBullet };

      for (let index = 0; index < 15; index++) {
        let spikeContainer = new PIXI.Container();
        spikeParticle.addChild(spikeContainer);
        spikeContainer.controller = {};

        let containerSpikeImpact = new PIXI.Container();
        let containerSpikeImpactDust = new PIXI.Container();
        let containerSpikeIce = new PIXI.Container();

        spikeContainer.addChild(containerSpikeImpact);
        spikeContainer.addChild(containerSpikeImpactDust);
        spikeContainer.addChild(containerSpikeIce);

        spikeContainer.controller = {
          ...spikeContainer.controller,
          containerSpikeImpact,
          containerSpikeImpactDust,
          containerSpikeIce,
        };

        await DmitriParticle.createParticle(
          app.loader,
          "SpikeImpactParticle"
        ).then((SpikeImpactParticle) => {
          containerSpikeImpact.addChild(SpikeImpactParticle.container);
          spikeContainer.controller = {
            ...spikeContainer.controller,
            SpikeImpactParticle,
          };
        });
        await DmitriParticle.createParticle(app.loader, "SpikeImpactDust").then(
          (SpikeImpactDust) => {
            containerSpikeImpactDust.addChild(SpikeImpactDust.container);
            spikeContainer.controller = {
              ...spikeContainer.controller,
              SpikeImpactDust,
            };
          }
        );
        let iceSpikeType = "IceSpike" + ((index % 3) + 1);
        await DmitriParticle.createParticle(app.loader, iceSpikeType).then(
          (iceSpike) => {
            containerSpikeIce.addChild(iceSpike.container);
            spikeContainer.controller = {
              ...spikeContainer.controller,
              iceSpike,
            };
          }
        );

        allSpikes.push(spikeContainer);
      }

      allParticle = { ...allParticle, allSpikes };

      await DmitriParticle.createParticle(
        app.loader,
        "StompImpactParticle"
      ).then((StompImpactParticle) => {
        stompParticle.addChild(StompImpactParticle.container);
        allParticle = { ...allParticle, StompImpactParticle };
      });
      await DmitriParticle.createParticle(app.loader, "StompImpact").then(
        (StompImpact) => {
          stompParticle.addChild(StompImpact.container);
          allParticle = { ...allParticle, StompImpact };
        }
      );
      await DmitriParticle.createParticle(app.loader, "StompImpactDust").then(
        (StompImpactDust) => {
          stompParticle.addChild(StompImpactDust.container);
          allParticle = { ...allParticle, StompImpactDust };
        }
      );

      // const loader = new PIXI.Loader();
      // let promises = [
      //   DmitriParticle.createParticle("SpikeImpactParticle"),
      //   DmitriParticle.createParticle("SpikeImpactDust"),
      //   DmitriParticle.createParticle("IceSpike1"),
      //   DmitriParticle.createParticle("IceSpike2"),
      //   DmitriParticle.createParticle("IceSpike3"),
      //   DmitriParticle.createParticle("StompImpactParticle"),
      //   DmitriParticle.createParticle("StompImpact"),
      //   DmitriParticle.createParticle("StompImpactDust"),
      //   DmitriParticle.createParticle("DmitriArrow"),
      // ];
      // Promise.all(promises)
      //   .then(
      //     ([
      //       SpikeImpactParticle,
      //       SpikeImpactDust,
      //       IceSpike1,
      //       IceSpike2,
      //       IceSpike3,
      //       StompImpactParticle,
      //       StompImpact,
      //       StompImpactDust,
      //       Arrow,
      //     ]) => {
      //       spikeParticle.addChild(IceSpike1.container);
      //       spikeParticle.addChild(IceSpike2.container);
      //       spikeParticle.addChild(IceSpike3.container);
      //       spikeParticle.addChild(SpikeImpactParticle.container);
      //       spikeParticle.addChild(SpikeImpactDust.container);
      //       stompParticle.addChild(StompImpactParticle.container);
      //       stompParticle.addChild(StompImpact.container);
      //       stompParticle.addChild(StompImpactDust.container);
      //       containerParticle.addChild(Arrow.container);

      //       let particle = {
      //         SpikeImpactParticle,
      //         SpikeImpactDust,
      //         IceSpike1,
      //         IceSpike2,
      //         IceSpike3,
      //         StompImpactParticle,
      //         StompImpact,
      //         StompImpactDust,
      //         Arrow,
      //       };
      //       resolve(particle);
      //     }
      //   )
      //   .catch((e) => {
      //     reject(e);
      //   });

      resolve(allParticle);

      app.loader.onError.add((e) => {
        reject(e);
      });
    });
  },
};

export default DmitriParticle;
