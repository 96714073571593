import { memo, useEffect, useMemo, useState } from "react";
import { Box, ChoiceCard, ChoiceList, TextTypo } from "Game/Style/GameStyle";
import styled from "styled-components";
import { StyleInputConverter } from "vonder-utils-input";

export default memo(function ChoiceText({ isResult, choice }) {
  const [choiceList, setChoiceList] = useState([]);

  useEffect(() => {
    if (choice) {
      setChoiceList([...choice]);
    }
  }, [choice]);

  const checkDisplayTextType = useMemo(() => {
    if (isResult) {
      return (
        <ResultList
          grid={{ gutter: 24, column: choiceList?.length < 4 ? 1 : 2 }}
          dataSource={choiceList}
          renderItem={(item) => (
            <ChoiceList.Item>
              <Box width="100%" height="100%" align="center" justify="center">
                <ChoiceCard
                  isCorrect={item?.isCorrect}
                  align="center"
                  padding="3%"
                  margin="0 3% 0 0"
                >
                  <StyleInputConverter
                    width="100%"
                    height="auto"
                    fontSize={item?.context?.length > 50 ? "1.2vw" : "1.4vw"}
                    overflowX="hidden"
                    value={item?.context}
                    style={{
                      lineHeight: "150%",
                      color: item?.isCorrect
                        ? "var(--white)"
                        : "var(--silver-900)",
                    }}
                  />
                  {/* <TextTypo
                    size={item?.context?.length > 50 ? "1.2vw" : "1.4vw"}
                    strong="true"
                    type={item?.isCorrect ? "white" : "secondary"}
                    align="start"
                  >
                    {item?.context}
                  </TextTypo> */}
                </ChoiceCard>
                <TextTypo
                  style={{ width: "15%" }}
                  size="1.5vw"
                  strong="true"
                  type={item?.isCorrect ? "correct" : "secondary"}
                >
                  {/* {item?.count} */}
                  {item?.countByAnswers}
                </TextTypo>
              </Box>
            </ChoiceList.Item>
          )}
        />
      );
    } else {
      return (
        <ChoiceList
          grid={{ gutter: 16, column: choiceList?.length < 4 ? 1 : 2 }}
          dataSource={choiceList}
          renderItem={(item) => (
            <ChoiceList.Item>
              <ChoiceCard align="center" padding="3%">
                <StyleInputConverter
                  width="100%"
                  height="auto"
                  fontSize={item?.context?.length > 50 ? "1.2vw" : "1.4vw"}
                  overflowX="hidden"
                  value={item?.context}
                  style={{ lineHeight: "150%" }}
                />
                {/* <TextTypo
                  size={item?.context?.length > 50 ? "1.2vw" : "1.4vw"}
                  strong="true"
                  align="start"
                  type="secondary"
                >
                  1
                </TextTypo> */}
              </ChoiceCard>
            </ChoiceList.Item>
          )}
        />
      );
    }
  }, [choiceList, isResult]);

  return <>{checkDisplayTextType}</>;
});

const ResultList = styled(ChoiceList)`
  &.ant-list-grid .ant-col > .ant-list-item {
    margin: 1% 3%;
  }
  .ant-row {
    justify-content: center;
  }
`;
