import * as PIXI from "pixi.js";
import * as Particles from "pixi-particles";

import smoke from "Game/Assets/ParticleSprite/Bandit_Particle.png";
import borris from "Game/Assets/ParticleSprite/Borris_weapon.png";
import kelvin from "Game/Assets/ParticleSprite/Kelvin_weapon.png";
import robert from "Game/Assets/ParticleSprite/Robert_weapon.png";
import * as Sentry from "@sentry/react";

const PenguinParticle = {
  createParticle: (name) => {
    return new Promise((resolve, reject) => {
      import(`./${name}.json`)
        .then((json) => {
          if (json.images && json.data) {
            const loader = new PIXI.Loader();
            json.images.forEach((image, index) => {
              // const path = EnvUrl.getGameParticles(image);
              const path =
                name === "Borris"
                  ? borris
                  : name === "Kelvin"
                  ? kelvin
                  : name === "Robert"
                  ? robert
                  : smoke;
              loader.add(index.toString(), path);
            });
            loader.onError.add((e) => {
              console.log(e);
              reject({ error: "CANNOT_LOAD_PARTICLE" });
            });
            loader.load((loader, resources) => {
              const textures = [];
              json.images.forEach((image, index) => {
                textures.push(resources[index].texture);
              });
              const container = new PIXI.Container();
              const emitter = new Particles.Emitter(
                container,
                textures,
                json.data
              );

              resolve({ container, emitter });
            });
          }
        })
        .catch((e) => {
          Sentry.captureException(e);
          console.error(e);
          reject({ error: "CANNOT_IMPORT_CLASS" });
        });
    });
  },

  load: (app, containerParticle, name, containerSmoke) => {
    return new Promise((resolve, reject) => {
      const loader = new PIXI.Loader();
      let promises = [
        PenguinParticle.createParticle(name),
        PenguinParticle.createParticle(name),
        PenguinParticle.createParticle(name),
        PenguinParticle.createParticle("smoke"),
      ];
      Promise.all(promises)
        .then(
          ([
            penguinSpear1st,
            penguinSpear2nd,
            penguinSpear3rd,
            disappeared,
          ]) => {
            containerParticle.addChild(penguinSpear1st.container);
            containerParticle.addChild(penguinSpear2nd.container);
            containerParticle.addChild(penguinSpear3rd.container);

            containerSmoke.addChild(disappeared.container);
            disappeared.container.position.set(0, -20);

            let particle = {
              penguinSpear1st: penguinSpear1st,
              penguinSpear2nd: penguinSpear2nd,
              penguinSpear3rd: penguinSpear3rd,
              disappeared: disappeared,
            };
            resolve(particle);
          }
        )
        .catch((e) => {
          Sentry.captureException(e);
          reject(e);
        });

      loader.onError.add((e) => {
        reject(e);
      });
    });
  },
};

export default PenguinParticle;
