import * as PIXI from "pixi.js";
import * as Particles from "pixi-particles";

import Aka from "Game/Assets/ParticleSprite/Japan_Particle_0002_Aka_Claw.png";
import Aki from "Game/Assets/ParticleSprite/Japan_Particle_0001_Aki_Claw.png";
import Aku from "Game/Assets/ParticleSprite/Japan_Particle_0000_Aku_Claw.png";
import smoke from "Game/Assets/ParticleSprite/Bandit_Particle.png";
import * as Sentry from "@sentry/react";

const TenkoMinionParticle = {
  createParticle: (name) => {
    return new Promise((resolve, reject) => {
      import(`./${name}.json`)
        .then((json) => {
          if (json.images && json.data) {
            const loader = new PIXI.Loader();
            json.images.forEach((image, index) => {
              // const path = EnvUrl.getGameParticles(image);
              const path =
                name === "Aka"
                  ? Aka
                  : name === "Aki"
                  ? Aki
                  : name === "Aku"
                  ? Aku
                  : smoke;
              loader.add(index.toString(), path);
            });
            loader.onError.add((e) => {
              console.log(e);
              reject({ error: "CANNOT_LOAD_PARTICLE" });
            });
            loader.load((loader, resources) => {
              const textures = [];
              json.images.forEach((image, index) => {
                textures.push(resources[index].texture);
              });
              const container = new PIXI.Container();
              const emitter = new Particles.Emitter(
                container,
                textures,
                json.data
              );

              resolve({ container, emitter });
            });
          }
        })
        .catch((e) => {
          Sentry.captureException(e);
          console.error(e);
          reject({ error: "CANNOT_IMPORT_CLASS" });
        });
    });
  },

  load: (app, containerParticle, name, containerSmoke) => {
    return new Promise(async (resolve, reject) => {
      const loader = new PIXI.Loader();
      let promises = [
        TenkoMinionParticle.createParticle(name),
        TenkoMinionParticle.createParticle("smoke"),
      ];
      Promise.all(promises)
        .then(([claw, disappeared]) => {
          containerParticle.addChild(claw.container);

          containerSmoke.addChild(disappeared.container);
          disappeared.container.position.set(0, -20);

          let particle = {
            claw: claw,
            disappeared: disappeared,
          };
          resolve(particle);
        })
        .catch((e) => {
          Sentry.captureException(e);
          reject(e);
        });

      loader.onError.add((e) => {
        reject(e);
      });
    });
  },
};

export default TenkoMinionParticle;
