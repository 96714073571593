import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

const LocalizationContext = React.createContext(null);

function LocalizationProvider({ children }) {
    const [currentLang, setCurrentLang] = useState('en')
    const [t, i18n] = useTranslation();

    const changeLanguage = useCallback((lng) => {
        setCurrentLang(lng)
        i18n.changeLanguage(lng);
    },[i18n]);

    useEffect(() => {
        changeLanguage('en')
    },[changeLanguage])

    const value = {
        changeLanguage,
        t,
        currentLang
    };

    return (
        <LocalizationContext.Provider value={value}>
            {children}
        </LocalizationContext.Provider>
    );
}

function useLocalizationContext() {
    const context = React.useContext(LocalizationContext);
    if (context === undefined) {
        throw new Error("Error context undefined");
    }
    return context;
}

export { LocalizationProvider, useLocalizationContext };
