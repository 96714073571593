import {
    memo,
    //  useState, 
    //  useRef, 
    //  useEffect, 
    //  useMemo, 
    //  useCallback 
} from "react";
// import { TextTypo } from "Game/Style/GameStyle";
import styled from 'styled-components'
// import Space from 'antd/lib/space'
// import Slider from 'antd/lib/slider'
// import {
//     CaretRightOutlined,
//     PauseOutlined
// } from '@ant-design/icons';

const Speaker = () => (
    <svg width="90" height="90" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"
        style={{ margin: '2%' }}>
        <path id="Path_600" data-name="Path 600"
            d="M14.886,20.116a.772.772,0,0,1-.18-1.522,6.782,6.782,0,0,0,0-13.189.772.772,0,0,1,.362-1.5,8.326,8.326,0,0,1,0,16.19A.8.8,0,0,1,14.886,20.116Z"
            fill="#BEC3CE"></path>
        <path id="Path_601" data-name="Path 601"
            d="M14.887,16.352a.772.772,0,0,1-.212-1.514h0a2.954,2.954,0,0,0,0-5.68A.772.772,0,0,1,15.1,7.674h0a4.5,4.5,0,0,1,0,8.647A.78.78,0,0,1,14.887,16.352Z"
            fill="#BEC3CE"></path>
        <path id="Path_602" data-name="Path 602"
            d="M7.523,7.154,6.177,8.469a.867.867,0,0,1-.6.245H3.419a.864.864,0,0,0-.865.864v4.846a.864.864,0,0,0,.865.864H5.573a.867.867,0,0,1,.6.245l1.346,1.316,3.5,3.414a.865.865,0,0,0,1.468-.619V4.356a.864.864,0,0,0-1.468-.619Z"
            fill="#BEC3CE"></path>
    </svg>
)

export default memo(function AudioPlayer({ audioSrc }) {
    // const [trackProgress, setTrackProgress] = useState(0);
    // const [isPlaying, setIsPlaying] = useState(false);

    // const audioRef = useRef(new Audio(audioSrc));
    // const intervalRef = useRef();
    // const isReady = useRef(false);
    // const [currentPlayTime, setCurrentPlayTime] = useState(0);

    // const { duration } = audioRef.current;

    // const startTimer = useCallback(() => {
    //     clearInterval(intervalRef.current);

    //     intervalRef.current = setInterval(() => {
    //         if (audioRef.current.ended) {
    //             setCurrentPlayTime(duration)
    //             setTrackProgress(duration);
    //         } else {
    //             setCurrentPlayTime(audioRef.current.currentTime)
    //             setTrackProgress(audioRef.current.currentTime);
    //         }
    //     }, 1000);
    // }, [duration])

    // const onScrub = (value) => {
    //     clearInterval(intervalRef.current);
    //     audioRef.current.currentTime = value;
    //     setCurrentPlayTime(audioRef.current.currentTime)
    //     setTrackProgress(audioRef.current.currentTime);
    // };

    // const onScrubEnd = () => {
    //     if (!isPlaying) {
    //         setIsPlaying(true);
    //     }
    //     startTimer();
    // };

    // useEffect(() => {
    //     if (isPlaying) {
    //         audioRef.current.play();
    //         startTimer();
    //     } else {
    //         audioRef.current.pause();
    //     }
    // }, [isPlaying, startTimer]);

    // useEffect(() => {
    //     audioRef.current.pause();
    //     audioRef.current = new Audio(audioSrc);
    //     setCurrentPlayTime(audioRef.current.currentTime)
    //     setTrackProgress(audioRef.current.currentTime);

    //     if (isReady.current) {
    //         audioRef.current.play();
    //         setIsPlaying(true);
    //     } else {
    //         isReady.current = true;
    //     }
    // }, [audioSrc]);

    // useEffect(() => {
    //     return () => {
    //         audioRef.current.pause();
    //         clearInterval(intervalRef.current);
    //     };
    // }, []);

    // function display(seconds) {
    //     const format = (val) => `0${Math.floor(val)}`.slice(-2)
    //     const minutes = (seconds % 3600) / 60

    //     return [minutes, seconds % 60].map(format).join(':')
    // }

    // const AudioControl = useMemo(() => {

    //     return (
    //         <Space direction='horizontal' align='baseline'>
    //             {isPlaying ? (
    //                 <PauseOutlined
    //                     onClick={() => {
    //                         setIsPlaying(false)
    //                     }}
    //                     style={{ fontSize: '2vw', }}
    //                 />
    //             ) : (
    //                 <CaretRightOutlined
    //                     onClick={() => {
    //                         setIsPlaying(true)
    //                     }}
    //                     style={{ fontSize: '2vw', }}
    //                 />
    //             )}
    //             <TextTypo size='1.3vw'>
    //                 {`${duration ? display(Math.floor(currentPlayTime)) : '00.00'} - ${duration ? display(Math.floor(duration)) : '00.00'}`}
    //             </TextTypo>
    //         </Space>
    //     )
    // }, [isPlaying, currentPlayTime, duration])

    return (

        // <Player>
        //     <Timer
        //         value={trackProgress}
        //         step={0.001}
        //         min={0}
        //         max={duration}
        //         onChange={(e) => onScrub(e)}
        //         onAfterChange={onScrubEnd}
        //         tooltipVisible={false}
        //     />
        //     {AudioControl}
        // </Player>

        <AudioContainer >
            {/* <img src={Speaker} alt="autio" width="80px" /> */}
            <Speaker />
            <audio
                controls
                controlsList='nodownload'
                src={audioSrc}
                style={{
                    width: "100%",
                    height: "40px",
                }}
            >
                Your browser does not support the
                <code>audio</code> element.
            </audio>
        </AudioContainer>
    )
})

const AudioContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: space-evenly;
  border-radius: var(--border-radius);
  background: #1b202d;
  padding: 2% 3%
`;

// const Player = styled.div`
//     width: 100%;
//     height: 8vw;
//     display: flex;
//     flex-direction: column;
//     padding: 2%;
//     position: relative;
//     background-color: #e5e7ea;
//     border-radius: var(--border-radius);;
// `

// const Timer = styled(Slider)`
//     display: flex;
//     flex:1;
//     flex-direction: column;
//     justify-content: center;
//     width: 97%;
//     align-self: center;

//     &.ant-slider-vertical {
//         height: 10px;
//         padding: 0px;
//         margin: 0px;
//         display: flex;
//         justify-content: center;
//     }
//     .ant-slider-rail {
//         position: absolute;
//         width: 100%;
//         height: 10px;
//         background-color: #f5f5f5;
//         border-radius: 5px;
//         transition: background-color 0.3s;
//     }
//     .ant-slider-track {
//         position: absolute;
//         height: 10px;
//         background-color: #91d5ff;
//         border-radius: 5px;
//         transition: background-color 0.3s;
//     }
//     &.ant-slider-vertical .ant-slider-rail {
//         background-color: #aaa;
//     }
//     &.ant-slider-vertical .ant-slider-track {
//         background-color: #2680EB;
//     }
//     .ant-slider-handle {
//         width: 16px;
//         height: 16px;
//         background-color: #2680EB;;
//         border: none;
//         margin-top: -1px;
//     }
//     &.ant-slider-vertical .ant-slider-handle {
//         margin: 0px;
//     }

// `