import * as PIXI from "pixi.js";
// import * as Particles from "pixi-particles";

import Shaka_particle_final_00 from "Game/Assets/ParticleSprite/ShakaArmy/Shaka_particle_final_00.png";
import Shaka_particle_final_01 from "Game/Assets/ParticleSprite/ShakaArmy/Shaka_particle_final_01.png";
import Shaka_particle_final_02 from "Game/Assets/ParticleSprite/ShakaArmy/Shaka_particle_final_02.png";
import Shaka_particle_final_03 from "Game/Assets/ParticleSprite/ShakaArmy/Shaka_particle_final_03.png";
import Shaka_particle_final_04 from "Game/Assets/ParticleSprite/ShakaArmy/Shaka_particle_final_04.png";
import Shaka_particle_final_05 from "Game/Assets/ParticleSprite/ShakaArmy/Shaka_particle_final_05.png";
import Shaka_particle_final_06 from "Game/Assets/ParticleSprite/ShakaArmy/Shaka_particle_final_06.png";
import Shaka_particle_final_07 from "Game/Assets/ParticleSprite/ShakaArmy/Shaka_particle_final_07.png";
import Shaka_particle_final_08 from "Game/Assets/ParticleSprite/ShakaArmy/Shaka_particle_final_08.png";
import Shaka_particle_final_09 from "Game/Assets/ParticleSprite/ShakaArmy/Shaka_particle_final_09.png";
import Shaka_particle_final_10 from "Game/Assets/ParticleSprite/ShakaArmy/Shaka_particle_final_10.png";
import Shaka_particle_final_11 from "Game/Assets/ParticleSprite/ShakaArmy/Shaka_particle_final_11.png";
import Shaka_particle_final_12 from "Game/Assets/ParticleSprite/ShakaArmy/Shaka_particle_final_12.png";
import Shaka_particle_final_13 from "Game/Assets/ParticleSprite/ShakaArmy/Shaka_particle_final_13.png";
import Shaka_particle_final_14 from "Game/Assets/ParticleSprite/ShakaArmy/Shaka_particle_final_14.png";
import Shaka_particle_final_15 from "Game/Assets/ParticleSprite/ShakaArmy/Shaka_particle_final_15.png";

import Shaka_impact_smoke from "Game/Assets/ParticleSprite/ShakaArmy/Shaka_impact_smoke.png";

const ShakaParticle = {
  createAnimatedSprite: () => {
    const rhinoTexture = [
      Shaka_particle_final_00,
      Shaka_particle_final_01,
      Shaka_particle_final_02,
      Shaka_particle_final_03,
      Shaka_particle_final_04,
      Shaka_particle_final_05,
      Shaka_particle_final_06,
      Shaka_particle_final_07,
      Shaka_particle_final_08,
      Shaka_particle_final_09,
      Shaka_particle_final_10,
      Shaka_particle_final_11,
      Shaka_particle_final_12,
      Shaka_particle_final_13,
      Shaka_particle_final_14,
      Shaka_particle_final_15,
    ];
    return new Promise((resolve, reject) => {
      let textureArr = [];
      for (let i = 0; i < rhinoTexture.length; i++) {
        const texture = PIXI.Texture.from(rhinoTexture[i]);
        textureArr.push(texture);
      }
      const rhino = new PIXI.AnimatedSprite(textureArr);
      rhino.loop = true;
      rhino.anchor.set(0.5, 0.8);
      rhino.scale.set(2);
      const smoke = new PIXI.Sprite(PIXI.Texture.from(Shaka_impact_smoke));
      smoke.anchor.set(0.5, 1);
      const container = new PIXI.Container();
      container.addChild(rhino);
      container.addChild(smoke);
      resolve({ container, rhino, smoke });
    });
  },
  load: ({ app, armyParticle, containerBulletGenerate }) => {
    return new Promise(async (resolve, reject) => {
      let allParticle = {};
      let allBullet = [];
      let allArmy = [];

      for (let index = 0; index < 5; index++) {
        let bulletContainer = new PIXI.Container();
        containerBulletGenerate.addChild(bulletContainer);
        bulletContainer.controller = {};

        await ShakaParticle.createAnimatedSprite().then(
          ({ container, rhino, smoke }) => {
            container.visible = false;
            bulletContainer.addChild(container);
            bulletContainer.controller = {
              ...bulletContainer.controller,
              container,
              rhino,
              smoke,
            };
          }
        );
        allBullet.push(bulletContainer);
      }
      allParticle = { ...allParticle, allBullet };

      for (let index = 0; index < 10; index++) {
        let armyContainer = new PIXI.Container();
        armyParticle.addChild(armyContainer);
        armyContainer.controller = {};

        await ShakaParticle.createAnimatedSprite().then(
          ({ container, rhino, smoke }) => {
            container.visible = false;
            armyContainer.addChild(container);
            armyContainer.controller = {
              ...armyContainer.controller,
              container,
              rhino,
              smoke,
            };
          }
        );

        allArmy.push(armyContainer);
      }

      allParticle = { ...allParticle, allArmy };

      resolve(allParticle);

      app.loader.onError.add((e) => {
        reject(e);
      });
    });
  },
};

export default ShakaParticle;
