import * as PIXI from "pixi.js";

import zivLaser from "Game/PixiCores/Particles/XarathrustraMinion/Xarathrustra-minion-particle_0000_Ziv.png";
import nivLaser from "Game/PixiCores/Particles/XarathrustraMinion/Xarathrustra-minion-particle_0001_Niv.png";
import divLaser from "Game/PixiCores/Particles/XarathrustraMinion/Xarathrustra-minion-particle_0002_Div.png";
import zivLaserImpact from "Game/PixiCores/Particles/XarathrustraMinion/Xarathrustra-minion-particle_0003_Ziv-impact.png";
import nivLaserImpact from "Game/PixiCores/Particles/XarathrustraMinion/Xarathrustra-minion-particle_0004_Niv-impact.png";
import divLaserImpact from "Game/PixiCores/Particles/XarathrustraMinion/Xarathrustra-minion-particle_0005_Div-impact.png";

const XarathrustraMinionParticle = {
  load: (containerParticle, name) => {
    return new Promise(async (resolve, reject) => {
      const laserContainer = new PIXI.Sprite(
        PIXI.Texture.from(
          name === "Ziv"
            ? zivLaser
            : name === "Niv"
            ? nivLaser
            : name === "Div"
            ? divLaser
            : divLaser
        )
      );
      laserContainer.anchor.set(1, 0.5);
      laserContainer.scale.set(0.1);
      laserContainer.position.set(0, -150);
      laserContainer.visible = false;

      // laserContainer.angle = -16 + index * 2.5 - index * 0.1;
      // laserContainer.scale.x = 0.35 + index * 0.1;
      // smallLaserContainer.scale.x = index * 0.15 + 0.35;

      const laserImpactContainer = new PIXI.Sprite(
        PIXI.Texture.from(
          name === "Ziv"
            ? zivLaserImpact
            : name === "Niv"
            ? nivLaserImpact
            : name === "Div"
            ? divLaserImpact
            : divLaserImpact
        )
      );
      laserImpactContainer.anchor.set(0.5);
      laserImpactContainer.scale.set(0.1);
      laserImpactContainer.visible = false;

      containerParticle.addChild(laserImpactContainer);
      containerParticle.addChild(laserContainer);

      resolve({ laserContainer, laserImpactContainer });
    });
  },
};

export default XarathrustraMinionParticle;
