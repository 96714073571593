import useWebSocket from "Hooks/useWebSocket";
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  // useEffect,
  useReducer,
  useState,
} from "react";
import { initialState, reducer } from "Reducer/GameReducer";
import { gameContextInitial, gameContextReducer } from "Reducer/ContextReducer";
import { AppContext } from "Game/AppContext";
// import { isEmpty } from "lodash";
import { animationInitial, animationReducer } from "Reducer/AnimationReducer";

// import { useTranslation } from 'react-i18next'

const VonderGOContext = createContext();

const initialEndUser = [];
const initialAllowCharacters = [];
const initialPhase = { phase: null };
const initialSettings = {
  allowDoubt: false,
  conditionCurrency: 0,
  conditionPercent: 0,
  maxLeaderboardPlayer: 0,
  shuffleAnswers: false,
  shuffleQuestions: false,
};

const VonderGOProvider = ({ children }) => {
  const appContextData = useContext(AppContext);
  const [pin, setPIN] = useState(null);
  const [uuid, setUUID] = useState(null);
  const [connectionID, setConnectionID] = useState(null);
  const [challengeID, setChallengeID] = useState(null);
  const [challengeCode, setChallengeCode] = useState(null);
  const [challengeImage, setChallengeImage] = useState(null);
  const [challengeContext, setChallengeContext] = useState(null);
  const [isReadyToPlay, setIsReadyToPlay] = useState(false);
  const [endSessionLoading, setEndSessionLoading] = useState(false);
  const [gameContextState, dispatchGameContextState] = useReducer(
    gameContextReducer,
    gameContextInitial
  );
  const [totalUsers, setTotalUsers] = useState(0);
  const [userLike, setUserLike] = useState(null);
  const [isExit, setIsExit] = useState(false);
  const [currentGameData, setCurrentGameData] = useState(null);
  const [leaderboardData, setLeaderboardData] = useState(null);
  const [qaList, setQAList] = useState([]);
  const [level, setLevel] = useState(null);
  const [maxUser, setMaxUser] = useState(0);
  const [showMediaModal, setShowMediaModal] = useState(false);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(null);
  const [currentGameState, dispatchGameState] = useReducer(
    reducer,
    initialState
  );
  const [currentPhase, setCurrentPhase] = useState(null);
  const [animationState, dispatchAnimationState] = useReducer(
    animationReducer,
    animationInitial
  );
  const [wsReadyState, wsConnect, wsSend, wsClose, wsOn] = useWebSocket();

  const [isJumpQuestionIndexFromDropDown, setIsJumpQuestionIndexFromDropDown] =
    useState(false);
  const reducerAllowCharacters = (state, action) => {
    return [...action(state)];
  };

  const [allowCharacters, dispatchAllowCharacters] = useReducer(
    reducerAllowCharacters,
    initialAllowCharacters
  );

  const setAllowCharacters = useCallback((characters) => {
    dispatchAllowCharacters(() => {
      return [...characters];
    });
  }, []);

  const clearAllowCharacters = useCallback(() => {
    dispatchAllowCharacters(() => {
      return [];
    });
  }, []);

  const reducerEndUsers = (state, action) => {
    return [...action(state)];
  };

  const [endUsers, dispatchEndUsers] = useReducer(
    reducerEndUsers,
    initialEndUser
  );

  const reducerPhase = (state, action) => {
    return { ...action(state) };
  };

  const [phase, dispatchPhase] = useReducer(reducerPhase, initialPhase);

  const reducerSettings = (state, action) => {
    return { ...action(state) };
  };

  const [settings, dispatchSettings] = useReducer(
    reducerSettings,
    initialSettings
  );

  const updateSettings = useCallback((newSettings) => {
    dispatchSettings((state) => {
      return { ...state, ...newSettings };
    });
  }, []);

  const addEndUser = useCallback((userID) => {
    let isSuccess = false;
    dispatchEndUsers((endUsers) => {
      const index = endUsers.findIndex((member) => {
        if (member.userID === userID) {
          member.isConnect = true;
        }
        return member.userID === userID;
      });
      if (index < 0) {
        endUsers.push({ userID });
        isSuccess = true;
      }
      return endUsers;
    });
    return isSuccess;
  }, []);

  const removeEndUser = useCallback(
    (userID) => {
      let isSuccess = false;
      dispatchEndUsers((endUsers) => {
        const index = endUsers.findIndex((member) => {
          return member.userID === userID;
        });
        if (index >= 0) {
          delete endUsers[index];
          endUsers.splice(index, 1);
          isSuccess = true;
        }
        return endUsers;
      });
      appContextData.removeEndUsers(userID);
      return isSuccess;
    },
    [appContextData]
  );
  const updateEndUser = useCallback(
    (userID, content) => {
      let isSuccess = false;
      dispatchEndUsers((endUsers) => {
        const index = endUsers.findIndex((member) => {
          return member.userID === userID;
        });
        console.log("index from remove", index);
        if (index >= 0) {
          endUsers[index] = { ...endUsers[index], ...content };
          isSuccess = true;
        }
        return endUsers;
      });
      appContextData.updateEndUsers(userID, content);
      return isSuccess;
    },
    [appContextData]
  );
  const disconnectEndUser = useCallback((userID) => {
    let isSuccess = false;
    dispatchEndUsers((endUsers) => {
      const index = endUsers.findIndex((member) => {
        return member.userID === userID;
      });
      if (index >= 0) {
        endUsers[index] = { ...endUsers[index], isConnect: false };
      }
      return endUsers;
    });
    return isSuccess;
  }, []);

  const sessionStart = useCallback(
    (topEndUser) => {
      appContextData.sessionStart(topEndUser);
    },
    [appContextData]
  );

  useEffect(() => {
    if (currentGameState) {
      // console.log("currentGameState", currentGameState);
      appContextData.setCurrentGameState(currentGameState);
    }
  }, [appContextData, currentGameState]);

  useEffect(() => {
    if (animationState) {
      // console.log("animationState", animationState);
      appContextData.setAnimationState(animationState);
    }
  }, [animationState, appContextData]);

  useEffect(() => {
    if (currentGameData) {
      appContextData.setCurrentGameData(currentGameData);
      // console.log("currentGameData", currentGameData);
    }
  }, [appContextData, currentGameData]);
  useEffect(() => {
    if (gameContextState) {
      appContextData.setGameContextState(gameContextState);
    }
  }, [appContextData, gameContextState]);

  return (
    <VonderGOContext.Provider
      value={{
        pin,
        setPIN,
        uuid,
        setUUID,
        wsReadyState,
        wsConnect,
        wsSend,
        wsClose,
        wsOn,
        connectionID,
        setConnectionID,
        challengeID,
        setChallengeID,
        challengeCode,
        setChallengeCode,
        challengeContext,
        setChallengeContext,
        isReadyToPlay,
        setIsReadyToPlay,
        allowCharacters,
        setAllowCharacters,
        clearAllowCharacters,
        endUsers,
        addEndUser,
        removeEndUser,
        updateEndUser,
        disconnectEndUser,
        level,
        setLevel,
        phase,
        dispatchPhase,
        settings,
        updateSettings,
        dispatchSettings,
        currentGameState,
        dispatchGameState,
        gameContextState,
        dispatchGameContextState,
        totalUsers,
        setTotalUsers,
        endSessionLoading,
        setEndSessionLoading,
        userLike,
        setUserLike,
        qaList,
        setQAList,
        currentGameData,
        setCurrentGameData,
        leaderboardData,
        setLeaderboardData,
        sessionStart,
        animationState,
        dispatchAnimationState,
        currentPhase,
        setCurrentPhase,
        challengeImage,
        setChallengeImage,
        resetGameSession: appContextData.resetGameSession,
        playSFX: appContextData.playSFX,
        onPhaseUpdateData: appContextData.onPhaseUpdate,
        isCharacterReady: appContextData.isCharacterReady,
        isExit,
        setIsExit,
        showMediaModal,
        setShowMediaModal,
        currentQuestionIndex,
        setCurrentQuestionIndex,
        maxUser,
        setMaxUser,
        isJumpQuestionIndexFromDropDown,
        setIsJumpQuestionIndexFromDropDown,
      }}
    >
      {children}
    </VonderGOContext.Provider>
  );
};

const useVonderGoContext = () => {
  const context = useContext(VonderGOContext);
  if (context === undefined)
    throw new Error(
      "Vonder GO Context is undefined, maybe called from outer provider scope."
    );
  return context;
};

export { VonderGOContext, VonderGOProvider, useVonderGoContext };
