import * as PIXI from "pixi.js";
import PIXISpine from "Plugins/pixi-spine";

import GustavParticle from "Game/PixiCores/Particles/Gustav/GustavParticle.js";
import { Tween, Tweener } from "Classes/Tween";
import * as Sentry from "@sentry/react";

const character = {
  controller: {},
  scale: 0.25,
  sequence: {
    idle: [{ name: "Idle", loop: true }],
    move: [{ name: "Move", loop: true }],
    attack: [
      { name: "Attack", loop: false },
      { name: "Idle", loop: true, delay: 0 },
    ],
    hit: [
      { name: "Hit", loop: false },
      { name: "Idle", loop: true, delay: 0 },
    ],
    win: [
      { name: "Win", loop: false },
      { name: "Idle", loop: true, delay: 0 },
    ],
    lose: [{ name: "Lose", loop: false }],
  },
  event: {
    AttackPortal: () => {},
    Attack: () => {},
    FinalAttack: () => {},
  },
  eventListener: {
    event: (entry, { data }) => {
      const { name } = data;
      character.event[name]?.();
    },
  },
  playSequence: (spine, sequenceData) => {
    if (!sequenceData || sequenceData.length === 0) return;
    spine.state.setAnimation(0, sequenceData[0].name, sequenceData[0].loop);
    for (let i = 1; i < sequenceData.length; i++)
      spine.state.addAnimation(
        0,
        sequenceData[i].name,
        sequenceData[i].loop,
        sequenceData[i].delay
      );
  },
  create: (app, audioRef, path) => {
    return new Promise((resolve, reject) => {
      let promises = [
        new Promise((resolve, reject) => {
          const preload = new PIXI.Loader();
          preload.add(
            "character",
            // process.env.REACT_APP_API_URL_GO_CHARACTER + "/GUSTAV/Gustav.json"
            path
          );
          preload.load(async (loader, resources) => {
            let spineData = resources["character"].spineData;
            if (spineData) {
              let spine = new PIXISpine.Spine(spineData);
              spine.scale.set(character.scale);
              spine.state.addListener(character.eventListener);

              let container = new PIXI.Container();
              let containerParticle = new PIXI.Container();
              let containerUltimate = new PIXI.Container();
              let containerBulletGenerate = new PIXI.Container();
              let emitterController;

              container.addChild(spine);
              container.addChild(containerParticle);
              container.addChild(containerUltimate);
              container.addChild(containerBulletGenerate);

              await GustavParticle.load(
                app,
                containerParticle,
                containerUltimate,
                containerBulletGenerate
              ).then((particle) => {
                emitterController = particle;
              });
              const controller = {
                spine,
                container,
                emitterController,
                targetPosition: {
                  x: 0,
                  y: 0,
                },
                infoCharacter: {
                  characterName: "gustav",
                },
                setTargetPosition: (x, y) => {
                  character.controller.targetPosition = {
                    x: x,
                    y: y,
                  };
                },
                setDisappearedParticleActive: () => {
                  new Tweener(app.ticker)
                    .insert(
                      0.0,
                      new Tween(1, (pos, dt) => {
                        emitterController.disappeared.emitter.emit = true;
                        emitterController.disappeared.emitter.update(dt);
                      })
                    )
                    .play();
                },
                attackPlayer: async (self, allTargetPlayer) => {
                  allTargetPlayer.forEach((player, index) => {
                    let bulletController =
                      emitterController.allBullet[index].controller;
                    let targetPosition = {
                      x:
                        player.container.position.x - self.container.position.x,
                      y: player.container.position.y,
                    };
                    console.log("bulletController", bulletController);
                    bulletController.containerSpawnBullet.visible = true;
                    bulletController.containerGroundEffect.visible = true;
                    bulletController.containerSmokeEffect.visible = true;

                    bulletController.containerSpawnBullet.position.x =
                      targetPosition.x;
                    bulletController.containerGroundEffect.position.x =
                      targetPosition.x;
                    bulletController.containerSmokeEffect.position.x =
                      targetPosition.x;

                    bulletController.GustavDustParticleBackLeft.container.position.y =
                      -100;
                    bulletController.GustavDustParticleBackRight.container.position.y =
                      -100;
                    bulletController.GustavDustParticleFrontLeft.container.position.y =
                      -50;
                    bulletController.GustavDustParticleFrontRight.container.position.y =
                      -50;
                    new Tweener(app.ticker)
                      .insert(
                        0,
                        new Tween(3.0, (pos, dt) => {
                          if (pos === 0) {
                            audioRef.current._group["BOS-001"]._list[0].play(
                              "portal"
                            );
                          }
                          if (pos === 1) {
                            bulletController.containerSpawnBullet.visible = false;
                            bulletController.GustavAttackPortal.emitter.cleanup();
                          }
                          bulletController.GustavAttackPortal.emitter.emit = true;
                          bulletController.GustavAttackPortal.emitter.update(
                            dt
                          );
                        })
                      )
                      .insert(
                        0.5,
                        new Tween(0.3, (pos, dt) => {
                          if (pos === 0) {
                            audioRef.current._group["BOS-001"]._list[0].play(
                              "stomp"
                            );
                          }
                          if (pos === 1) {
                            audioRef.current._group["BOS-001"]._list[0].play(
                              "sword"
                            );
                            bulletController.GustavSwordParticle.container.visible = false;
                            bulletController.GustavSwordParticle.emitter.emit = false;
                            bulletController.GustavSwordParticle.emitter.cleanup();

                            bulletController.GustavGroundHit.container.visible = false;
                            bulletController.GustavGroundHit.emitter.emit = false;
                            bulletController.GustavGroundHit.emitter.cleanup();
                          } else if (pos > 0.75) {
                            bulletController.GustavGroundHit.emitter.playOnce();
                            bulletController.GustavGroundHit.container.visible = true;
                            bulletController.GustavGroundHit.emitter.emit = true;
                            bulletController.GustavGroundHit.emitter.update(dt);
                          } else {
                            bulletController.GustavSwordParticle.container.visible = true;
                            bulletController.GustavSwordParticle.emitter.emit = true;
                            bulletController.GustavSwordParticle.emitter.update(
                              dt
                            );
                          }
                          bulletController.GustavSwordParticle.container.position.y =
                            -260 + pos ** 4 * 200;
                        })
                      )
                      .insert(
                        0.7,
                        new Tween(2, (pos, dt) => {
                          if (pos === 1) {
                            bulletController.GustavDustParticleBackLeft.container.visible = false;
                            bulletController.GustavDustParticleBackLeft.emitter.emit = false;
                            bulletController.GustavDustParticleBackLeft.emitter.cleanup();

                            bulletController.GustavDustParticleBackRight.container.visible = false;
                            bulletController.GustavDustParticleBackRight.emitter.emit = false;
                            bulletController.GustavDustParticleBackRight.emitter.cleanup();

                            bulletController.GustavDustParticleFrontLeft.container.visible = false;
                            bulletController.GustavDustParticleFrontLeft.emitter.emit = false;
                            bulletController.GustavDustParticleFrontLeft.emitter.cleanup();

                            bulletController.GustavDustParticleFrontRight.container.visible = false;
                            bulletController.GustavDustParticleFrontRight.emitter.emit = false;
                            bulletController.GustavDustParticleFrontRight.emitter.cleanup();
                          } else {
                            bulletController.GustavDustParticleBackLeft.container.visible = true;
                            bulletController.GustavDustParticleBackRight.container.visible = true;
                            bulletController.GustavDustParticleFrontLeft.container.visible = true;
                            bulletController.GustavDustParticleFrontRight.container.visible = true;

                            bulletController.GustavDustParticleBackLeft.emitter.emit = true;
                            bulletController.GustavDustParticleBackLeft.emitter.update(
                              dt
                            );

                            bulletController.GustavDustParticleBackRight.emitter.emit = true;
                            bulletController.GustavDustParticleBackRight.emitter.update(
                              dt
                            );

                            bulletController.GustavDustParticleFrontLeft.emitter.emit = true;
                            bulletController.GustavDustParticleFrontLeft.emitter.update(
                              dt
                            );

                            bulletController.GustavDustParticleFrontRight.emitter.emit = true;
                            bulletController.GustavDustParticleFrontRight.emitter.update(
                              dt
                            );

                            bulletController.GustavDustParticleBackLeft.container.position.x =
                              50 + pos ** (1 / 4) * -100;
                            bulletController.GustavDustParticleBackRight.container.position.x =
                              -50 + pos ** (1 / 4) * 100;
                            bulletController.GustavDustParticleFrontLeft.container.position.x =
                              pos ** (1 / 8) * -80;
                            bulletController.GustavDustParticleFrontRight.container.position.x =
                              pos ** (1 / 8) * 80;
                          }
                        })
                      )
                      .play();
                  });
                  await character.controller.routineWait(1).then(() => {
                    console.log("particle hit");
                  });
                },
                finalAttack: async (self, allTargetPlayer) => {
                  console.log("final attack from boss");
                  if (!containerUltimate.visible)
                    containerUltimate.visible = true;
                  containerUltimate.position.x = -1920 * 0.5;
                  emitterController.GustavFinalDustParticleBackLeft.container.position.y =
                    -180;
                  emitterController.GustavFinalDustParticleBackRight.container.position.y =
                    -180;
                  emitterController.GustavFinalDustParticleFrontLeft.container.position.y =
                    -100;
                  emitterController.GustavFinalDustParticleFrontRight.container.position.y =
                    -100;
                  new Tweener(app.ticker)
                    .insert(
                      1,
                      new Tween(5, (pos, dt) => {
                        if (pos === 1) {
                          containerUltimate.visible = false;
                        } else if (pos >= 3 / 5) {
                          emitterController.GustavFinalAttackPortal.emitter.cleanup();
                        } else {
                          emitterController.GustavFinalAttackPortal.emitter.emit = true;
                          emitterController.GustavFinalAttackPortal.emitter.update(
                            dt
                          );
                        }
                      })
                    )
                    .insert(
                      1.5,
                      new Tween(2, (pos, dt) => {
                        if (pos === 1) {
                          emitterController.GustavFinalSwordParticle.emitter.emit = false;
                          emitterController.GustavFinalSwordParticle.emitter.cleanup();
                        } else {
                          emitterController.GustavFinalSwordParticle.emitter.emit = true;
                          emitterController.GustavFinalSwordParticle.emitter.update(
                            dt
                          );
                        }
                      })
                    )
                    .insert(
                      2,
                      new Tween(3, (pos, dt) => {
                        if (pos === 1) {
                          emitterController.GustavFinalDustParticleBackLeft.container.visible = false;
                          emitterController.GustavFinalDustParticleBackLeft.emitter.emit = false;
                          emitterController.GustavFinalDustParticleBackLeft.emitter.cleanup();

                          emitterController.GustavFinalDustParticleBackRight.container.visible = false;
                          emitterController.GustavFinalDustParticleBackRight.emitter.emit = false;
                          emitterController.GustavFinalDustParticleBackRight.emitter.cleanup();

                          emitterController.GustavFinalDustParticleFrontLeft.container.visible = false;
                          emitterController.GustavFinalDustParticleFrontLeft.emitter.emit = false;
                          emitterController.GustavFinalDustParticleFrontLeft.emitter.cleanup();

                          emitterController.GustavFinalDustParticleFrontRight.container.visible = false;
                          emitterController.GustavFinalDustParticleFrontRight.emitter.emit = false;
                          emitterController.GustavFinalDustParticleFrontRight.emitter.cleanup();
                        } else {
                          emitterController.GustavFinalDustParticleBackLeft.container.visible = true;
                          emitterController.GustavFinalDustParticleBackRight.container.visible = true;
                          emitterController.GustavFinalDustParticleFrontLeft.container.visible = true;
                          emitterController.GustavFinalDustParticleFrontRight.container.visible = true;

                          emitterController.GustavFinalDustParticleBackLeft.emitter.emit = true;
                          emitterController.GustavFinalDustParticleBackLeft.emitter.update(
                            dt
                          );

                          emitterController.GustavFinalDustParticleBackRight.emitter.emit = true;
                          emitterController.GustavFinalDustParticleBackRight.emitter.update(
                            dt
                          );

                          emitterController.GustavFinalDustParticleFrontLeft.emitter.emit = true;
                          emitterController.GustavFinalDustParticleFrontLeft.emitter.update(
                            dt
                          );

                          emitterController.GustavFinalDustParticleFrontRight.emitter.emit = true;
                          emitterController.GustavFinalDustParticleFrontRight.emitter.update(
                            dt
                          );
                        }
                      })
                    )
                    .play();

                  audioRef.current._group["BOS-001"]._list[0].play("roar");
                  setTimeout(() => {
                    audioRef.current._group["BOS-001"]._list[0].play("stomp");
                  }, 500);
                  for (let index = 0; index < 10; index++) {
                    setTimeout(() => {
                      audioRef.current._group["BOS-001"]._list[0].play(
                        "portal"
                      );
                    }, 1000 + index * 200);
                    setTimeout(() => {
                      audioRef.current._group["BOS-001"]._list[0].play("sword");
                    }, 1500 + index * 200);
                  }
                  await character.controller.routineWait(1.5).then(() => {
                    console.log("particle hit");
                  });
                },
                setActive: (isActive) => {
                  container.visible = isActive;
                },
                setSkin: (skinName) => {
                  if (character.skins.indexOf(skinName) < 0) {
                    return false;
                  } else {
                    const skeleton = controller.spine.skeleton;
                    skeleton.setSkin(null);
                    skeleton.setSkinByName(skinName);
                    return true;
                  }
                },
                randomSkin: () => {
                  let index = Math.floor(
                    Math.random() * character.skins.length
                  );
                  controller.setSkin(character.skins[index]);
                },
                playState: (stateName) => {
                  if (character.sequence[stateName])
                    character.playSequence(
                      controller.spine,
                      character.sequence[stateName]
                    );

                  if (stateName === "lose") {
                    audioRef.current._group["BOS-001"]._list[0].play("dead");
                  }
                },
                destroy: () => {
                  controller.spine?.destroy();
                  controller.container?.destroy();
                },
                talk: () => {
                  if (audioRef.current._group["BOS-001"])
                    audioRef.current._group["BOS-001"]._list[0].play("talk");
                },
                routineWait: async (second) => {
                  await new Promise((resolve) => {
                    const ticker = app.ticker;
                    let current = 0;
                    const onUpdate = (elapsedTime) => {
                      const deltaTime = (1 / ticker.FPS) * ticker.speed;
                      current += deltaTime;
                      if (current > second) {
                        ticker.remove(onUpdate);
                        resolve();
                      }
                    };
                    ticker.add(onUpdate);
                  });
                },
              };
              character.controller = controller;
              resolve(controller);
            } else {
              reject({ error: "NO_SPINE_DATA_GUSTAV" });
            }
          });
        }),
      ];
      Promise.all(promises)
        .then((results) => {
          const [character] = results;
          resolve(character);
        })
        .catch((e) => {
          Sentry.captureException(e);
          reject(e);
        });
    });
  },
};

export default character;
